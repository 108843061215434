import React,{useState, useEffect} from 'react';
import AllImages from "../../constant/img";
import axios from "axios";
import confirmAPI from "../../constant/api";
import logo002 from "../../constant/img";
import { useJwt } from "react-jwt";
import {Link} from "react-router-dom";

const ConfirmPassword = (props) => {

const [newpassword, setNewPassword] = useState();
const [confirmpassword, setConfirmPassword] = useState();
const [responseBackend, setResponseBackend] = useState();



 
    const EmailAddress = props.location.pathname.split("/");

    // const { decodedToken, isExpired } = useJwt(EmailAddress[2]);
    

    const email = EmailAddress[2];



useEffect(()=>{
  document.getElementById("main_app1").classList.remove("main_board");
  document.getElementById("main_app2").classList.remove("main_board_inner");
},[])




const handleChangeNewPassword = (event) =>{
event.preventDefault();
setNewPassword(event.target.value);
}

const handleChangeConfirmPassword = (event) =>{
event.preventDefault();
setConfirmPassword(event.target.value);
}

const handleSubmit = async (event) =>{
event.preventDefault();
if(newpassword == confirmpassword)
{
   await axios.post(confirmAPI.RESETPASSWORD_API, {params:{newpassword, email}}).then((res, err)=>{
       document.getElementById("showResult").style.display="block";
       setResponseBackend("Password updated successfull");
       setNewPassword("");
       setConfirmPassword("");
   })
} 
else{
    document.getElementById("showResult").style.display="block";
    setResponseBackend("Password is not matching");
    setNewPassword("");
    setConfirmPassword("");
    return;
}  

}



return (
  <>
  <header class="scndHeader">
            
  <div class="loginHeader">
    <Link to="/">
    <img src = "https://dlevelns.stage02.obdemo.com/image/logo/headerImage_1711958521736.png"/>
      </Link>
  </div>
               
        </header>
<section class="signin_Newpage bg-light-dark repeat-section-login-New">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="loginbx_main_New">
          <div class="log_sign_inner_New">
          <div class="loginheadName">Change Password</div>
            <div class="tab-content">
              <div style={{display:"none"}} id="showResult" class="text-center text-success"><p>{responseBackend}</p></div>
              <div class="tab-pane active" id="tabs-1" role="tabpanel">
                <form action="get" onSubmit={handleSubmit}>
                  <div class="formgrop_main_New row">
                    <div class="col-sm-12 margin_top_new">
                      <label>New Password</label>
                      <div class="form-group icon_input signpass_New">
                        <input onChange={handleChangeNewPassword} type="password" value={newpassword} class="form-control New_control" placeholder="New Password" required />
                        {/* <a href="#" class="eyeshow_New"><img src={AllImages.EYE_IMAGE} alt="Icon" width="20" /></a>  */}
                      </div>
                    </div>
                    <div class="col-sm-12 mt-2">
                      <label>Confirm Password</label>
                      <div class="form-group icon_input signpass_New">
                        <input onChange={handleChangeConfirmPassword} type="password" value={confirmpassword} class="form-control New_control" placeholder="Confirm Password" required />
                        {/* <a href="#" class="eyeshow_New"><img src={AllImages.EYE_IMAGE} alt="Icon" width="20" /></a>  */}
                      </div>
                    </div>
                    <div class="col-sm-12 marginfour_side">
                      <div class="form-group text-center">
                        <button type="submit" class="btnx btn-secondaryx">submit</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
		</div>
		</div>
    </div>
  </div>
</section>
</>
    )
}

export default ConfirmPassword
