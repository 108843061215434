import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const Pictures = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [thumbnailWidth,setThumbnailWidth] = useState();
    const [thumbnailHeight,setThumbnailHeight] = useState();
    const [pictureWidth,setPictureWidth] = useState();
    const [pictureHeight,setPictureHeight] = useState();
    const [watermarkingOnpictureProductPage,setWatermarkingOnpictureProductPage] = useState();
    const [watermarkType,setWatermarkType] = useState();
    const [urlOfPngImage,setUrlOfPngImage] = useState();
    const [watermarkText,setWatermarkText] = useState();
    const [autoCroppingOfLargePictures,setAutoCroppingOfLargePictures] = useState();
    const [autoCroppingOfListingThumbnails,setAutoCroppingOfListingThumbnails] = useState();
    const [slideshowModule,setSlideshowModule] = useState();
    const [slideshowDelay,setSlideshowDelay] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/pictures/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setThumbnailWidth(res.data.data[0].picturesDimensionInListing[0].thumbnailWidth);
                    setThumbnailHeight(res.data.data[0].picturesDimensionInListing[0].thumbnailHeight);
                    setPictureWidth(res.data.data[0].picturesDimensionInListing[0].pictureWidth);
                    setPictureHeight(res.data.data[0].picturesDimensionInListing[0].pictureHeight);
                    setWatermarkingOnpictureProductPage(res.data.data[0].watermarking[0].watermarkingOnpictureProductPage);
                    setWatermarkType(res.data.data[0].watermarking[0].watermarkType);
                    setUrlOfPngImage(res.data.data[0].watermarking[0].urlOfPngImage);
                    setWatermarkText(res.data.data[0].watermarking[0].watermarkText);
                    setAutoCroppingOfLargePictures(res.data.data[0].croppingOfPicturesInListing[0].autoCroppingOfLargePictures);
                    setAutoCroppingOfListingThumbnails(res.data.data[0].croppingOfPicturesInListing[0].autoCroppingOfListingThumbnails);
                    setSlideshowModule(res.data.data[0].slideShowSetting[0].slideshowModule);
                    setSlideshowDelay(res.data.data[0].slideShowSetting[0].slideshowDelay);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
        if(watermarkingOnpictureProductPage == 1){
            var watermarkingOnpictureProductPage_check = true;
        }else{
            var watermarkingOnpictureProductPage_check = false;
        }
        if(autoCroppingOfLargePictures == 1){
            var autoCroppingOfLargePictures_check = true;
        }else{
            var autoCroppingOfLargePictures_check = false;
        }
        if(autoCroppingOfListingThumbnails == 1){
            var autoCroppingOfListingThumbnails_check = true;
        }else{
            var autoCroppingOfListingThumbnails_check = false;
        }
        if(slideshowModule == 1){
            var slideshowModule_check = true;
        }else{
            var slideshowModule_check = false;
        }
       
        var picturesDimensionInListing         = [ {
                                                    'thumbnailWidth': thumbnailWidth,
                                                    'thumbnailHeight':thumbnailHeight,
                                                    'pictureWidth':pictureWidth,
                                                    'pictureHeight':pictureHeight,
                                                }];
        var watermarking                    =   [ {
                                                    'watermarkingOnpictureProductPage': watermarkingOnpictureProductPage_check,
                                                    'watermarkType':watermarkType,
                                                    'urlOfPngImage':urlOfPngImage,
                                                    'watermarkText':watermarkText
                                                }];
        var croppingOfPicturesInListing         = [ {
                                                    'autoCroppingOfLargePictures': autoCroppingOfLargePictures_check,
                                                    'autoCroppingOfListingThumbnails':autoCroppingOfListingThumbnails_check,
                                                
                                                }];
        var slideShowSetting                =   [ {
                                                    'slideshowModule': slideshowModule_check,
                                                    'slideshowDelay':slideshowDelay,
                                                }];
       
        if(slug == 'PictureSetting'){
            await axios.post(`${PORT}/pictures/update`, {picturesDimensionInListing:JSON.stringify(picturesDimensionInListing),watermarking:JSON.stringify(watermarking),croppingOfPicturesInListing:JSON.stringify(croppingOfPicturesInListing),slideShowSetting:JSON.stringify(slideShowSetting)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/pictures/create`,{picturesDimensionInListing:JSON.stringify(picturesDimensionInListing),watermarking:JSON.stringify(watermarking),croppingOfPicturesInListing:JSON.stringify(croppingOfPicturesInListing),slideShowSetting:JSON.stringify(slideShowSetting)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();

    }
   
    const handleThumbnailWidth = (event) =>{
        setThumbnailWidth(event.target.value);
    }
    const handleThumbnailHeight = (event) =>{
        setThumbnailHeight(event.target.value);
    }
    const handlePictureWidth = (event) =>{
        setPictureWidth(event.target.value);
    }
    const handlePictureHeight = (event) =>{
        setPictureHeight(event.target.value);
    }
    const handleWatermarkingOnpictureProductPage = (event) =>{
        setWatermarkingOnpictureProductPage(event.target.value);
    }
    const handleWatermarkType = (event) =>{
        setWatermarkType(event.target.value);
    }
    const handleUrlOfPngImage = (event) =>{
        setUrlOfPngImage(event.target.value);
    }
    const handleWatermarkText = (event) =>{
        setWatermarkText(event.target.value);
    }
    const handleAutoCroppingOfLargePictures = (event) =>{
        setAutoCroppingOfLargePictures(event.target.value);
    }
    const handleAutoCroppingOfListingThumbnails = (event) =>{
        setAutoCroppingOfListingThumbnails(event.target.value);
    }
    const handleSlideshowModule = (event) =>{
        setSlideshowModule(event.target.value);
    }
    const handleSlideshowDelay = (event) =>{
        setSlideshowDelay(event.target.value);
    }
    return (
        <div class="tab-pane" id="basic04" role="tabpanel">
             <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Pictures</span></h3>
                <div class="labelsidetop">
                {/* <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Common</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Image quality</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh" name="" placeholder="" /><span class="equalspan_cont">Ranges from 0 to 100 (we recommend seting the quality to 70)</span>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Automatic picture upload</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test77" name="radio-group51" checked />
                                        <label for="test77" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test78" name="radio-group51" />
                                        <label for="test78" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div> */}

                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Picture Dimension in Listing</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Thumbnail width (in pixels)</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" onChange={handleThumbnailWidth} value={thumbnailWidth} class="fuilddesh minwidth02" placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span> Thumbnail height (in pixels)</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" class="fuilddesh minwidth02" onChange={handleThumbnailHeight} value={thumbnailHeight} placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Picture width (in pixels)</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" class="fuilddesh minwidth02" onChange={handlePictureWidth} value={pictureWidth} placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Picture height (in pixels)</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" class="fuilddesh minwidth02" onChange={handlePictureHeight} value={pictureHeight} placeholder="" />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Watermarking</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Watermark on picture product page</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="watermarkingOnpictureProductPage1" name="watermarkingOnpictureProductPage" value='1' onClick={handleWatermarkingOnpictureProductPage} checked={watermarkingOnpictureProductPage == 1?'checked':''}/>
                                        <label for="watermarkingOnpictureProductPage1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="watermarkingOnpictureProductPage2" name="watermarkingOnpictureProductPage" value='0' onClick={handleWatermarkingOnpictureProductPage} checked={watermarkingOnpictureProductPage == 0?'checked':''}/>
                                        <label for="watermarkingOnpictureProductPage2" class="label">Disable</label>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Watermark type</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <select class="fuilddesh" onChange={handleWatermarkType} value={watermarkType}>
                                    <option value="">Select</option>
                                    <option value="text">text</option>
                                    <option value="text">text</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>URL of a PNG image</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid onlyfor_full_width">
                                <textarea type="text" class="fuilddesh fuilddesh_textarea minwidth02" onChange={handleUrlOfPngImage} value={urlOfPngImage} placeholder="" ></textarea> 
                                <span class="equalspan_cont">We recommend using an image in png-24  format with a transparent background</span>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Watermark text</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" onChange={handleWatermarkText} value={watermarkText} class="fuilddesh minwidth02" placeholder="jLevels" /><span class="equalspan_cont">Leave the fuild empty to enable your domin name on all pictures</span>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Cropping of pictures in Listings</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Autocroping of large pictures</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                            <input type="radio" id="autoCroppingOfLargePictures1" name="autoCroppingOfLargePictures" value='1' onClick={handleAutoCroppingOfLargePictures} checked={autoCroppingOfLargePictures == 1?'checked':''}/>
                                            <label for="autoCroppingOfLargePictures1" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                            <input type="radio" id="autoCroppingOfLargePictures2" name="autoCroppingOfLargePictures" value='0' onClick={handleAutoCroppingOfLargePictures} checked={autoCroppingOfLargePictures == 0?'checked':''}/>
                                            <label for="autoCroppingOfLargePictures2" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Autocropping of listing thumbnails</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                         <li class="list__item">
                                            <input type="radio" id="autoCroppingOfListingThumbnails1" name="autoCroppingOfListingThumbnails" value='1' onClick={handleAutoCroppingOfListingThumbnails} checked={autoCroppingOfListingThumbnails == 1?'checked':''}/>
                                            <label for="autoCroppingOfListingThumbnails1" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                            <input type="radio" id="autoCroppingOfListingThumbnails2" name="autoCroppingOfListingThumbnails" value='0' onClick={handleAutoCroppingOfListingThumbnails} checked={autoCroppingOfListingThumbnails == 0?'checked':''}/>
                                            <label for="autoCroppingOfListingThumbnails2" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Cropping by users</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="test85" name="radio-group55" />
                                        <label for="test85" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="test86" name="radio-group55" checked="" />
                                        <label for="test86" class="label">Disable</label>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div> */}
                    </div>
                    </div>
                </div>

                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Slideshow settings</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Slideshow module</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                            <input type="radio" id="slideshowModule1" name="slideshowModule" value='1' onClick={handleSlideshowModule} checked={slideshowModule == 1?'checked':''}/>
                                            <label for="slideshowModule1" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                            <input type="radio" id="slideshowModule2" name="slideshowModule" value='0' onClick={handleSlideshowModule} checked={slideshowModule == 0?'checked':''}/>
                                            <label for="slideshowModule2" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Slideshow delay</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" class="fuilddesh" onChange={handleSlideshowDelay} value={slideshowDelay} name="" placeholder="" /><span class="equalspan_cont">In Seconds</span>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Pictures;
