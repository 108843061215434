import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Learn = ({ props }) => {
 // console.log("categorypropsdata", props);
  const allPathEdit = props.split("/");
 // console.log("allpath", allPathEdit);

  useEffect(() => {
    if (
      props == "/learn" ||
      props == "/learn-add" ||
      allPathEdit[1] == "learn-edit" ||
      props == "/learn-categories" ||
      props == "/learn-addcategories" ||
      allPathEdit[1] == "learn-editcategories" ||
      props == "/learn-subcategories" ||
      props == "/learn-addsubcategories" ||
      allPathEdit[1] == "learn-editsubcategories"
    ) {
      document.getElementById("collapseLearn").classList.add("show");
    }
  }, []);

  return (
    <li className="sidebar-dropdown mabot_mainli">
      <Link to="#" data-toggle="collapse" data-target="#collapseLearn">
        <span>Learn</span>
        <i className="fa fa-tags"></i>
      </Link>
      <div
        className="sidebar-submenu"
        id="collapseLearn"
        class="collapse"
        data-parent="#accordion"
      >
        <ul>
          <li>
            <Link to="/learn-categories">Learn categories</Link>
          </li>
          <li>
            <Link to="/learn-subcategories">Learn sub categories</Link>
          </li>
          <li>
            <Link to="/learn">Learn</Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Learn;
