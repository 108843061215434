import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./fieldsList.css";
import PORTNUMBER from "../../../constant/api";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import ReactPaginate from "react-paginate";
import FieldAdd from "../addField/addField";
import FieldEdit from "../editField/editField";

const FieldsList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allRecords, setAllRecords] = useState();
  const [firstsubbtuton, setFirstsubbutton] = useState();
  const [firstcat, setFirstcat] = useState();
  const [secondsubbutton, setSecondsubbutton] = useState();
  const [secondsubcat, setSecondsubcat] = useState();
  const [thirdsubbutton, setThirdsubbutton] = useState();
  const [thirdsubcat, setThirdsubcat] = useState();

  const [catModal, setCatModal] = useState(false);
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  const [id, setId] = useState();
  const [modalActive, setModalActive] = useState(false);
  const [modalDeactive, setModalDeactive] = useState(false);

  const [deleteFrom, setDeleteFrom] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  const [statusFilter, setStatusFilter] = useState();
  const [allCategory, setAllCategory] = useState([{ value: null }]);

  useEffect(() => {
    GetAll();
    getCategoryListing(); // calls function for get main category dropdown filter.
  }, [statusFilter, firstcat, secondsubcat, searchItem]);

  
  const generateQueryString = (filter)  =>  {
    const searchParams = new URLSearchParams();
    const params = filter;
    Object.keys(filter).forEach(key => searchParams.append(key, params[key]));
    return searchParams.toString();
  }

  const getCategoryListing=()=>{
      axios.get(`${PORT}/getCategorys`).then((response, err) => {
      setAllCategory(response);
    });
  }

  const GetAll = async () => {

    let filter = {};
    if(firstcat){
      filter.first_category_id = firstcat;
    }
    if(secondsubcat){
      filter.second_category_id = secondsubcat;
    }
    if(statusFilter){
      if(statusFilter == 'active'){
        filter.status   = 'Active';
      }else if(statusFilter == 'inactive'){
        filter.status   = 'Inactive';
      }
    }
    if(searchItem){
      filter.search = searchItem;
    }

    let queryStr  = generateQueryString(filter);
    await axios.get(`${PORT}/fields?${queryStr}`).then((res, err) => {
      setAllRecords(res);
    });

  };

  useEffect(() => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "add"
    ) {
      toast.success("You have successfully added a field");
      GetAll();
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "add",
  ]);

  useEffect(() => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit"
    ) {
      toast.success("You have successfully edited a field");
      GetAll();
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit",
  ]);

  const handleDelete = async (id) => {
    await axios
      .post(`${PORT}/fields/delete`, { params: { id } })
      .then((res, err) => {
        toast.success("You have successfully deleted this field");
      });
    await axios.get(`${PORT}/fields`).then((res, err) => {
      setAllRecords(res);
    });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleModalField = (id) => {
    setCatModal(true);
    setId(id);
  };

  const handleRecordModalYes = () => {
    handleDelete(id);
    setCatModal(false);
  };

  const handleModalClose = () => {
    setCatModal(false);
  };

  const handleClickDeactive = (id) => {
    setModalDeactive(true);
    setId(id);
  };
  const handleClickActive = (id) => {
    setModalActive(true);
    setId(id);
  };
  const handleYesDeactive = async () => {
    await axios
      .post(`${PORT}/fields/status_update`, {
        params: { id, status: "Inactive" },
      })
      .then((res, err) => {
        toast.success("You have successfully deactived this field");

        setModalDeactive(false);
      });
    await axios.get(`${PORT}/fields`).then((res, err) => {
      setAllRecords(res);
    });
  };
  const handleYesActive = async () => {
    await axios
      .post(`${PORT}/fields/status_update`, {
        params: { id, status: "Active" },
      })
      .then((res, err) => {
        toast.success("You have successfully activated this field");
        setModalActive(false);
      });
    await axios.get(`${PORT}/fields`).then((res, err) => {
      setAllRecords(res);
    });
  };
  const handleClose = () => {
    setModalActive(false);
    setModalDeactive(false);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const pagesVisited = currentPage * postsPerPage;

  const pageCount =
    Math.ceil(
      (allRecords && allRecords.data && allRecords.data.length) / postsPerPage
    ) || 1;
  const currentPosts =
    allRecords &&
    allRecords.data &&
    allRecords.data.slice(pagesVisited, pagesVisited + postsPerPage);

  /* const handleSearch = async (event) => {
    setSearchItem(event.target.value);
    await axios
      .post(`${PORT}/fields/search`, { search: event.target.value })
      .then((res, err) => {
        if (res) {
          setAllRecords(res);
        }
      });
  }; */

  const handleSearch = async (e, fieldName) => {

    let val   = e.target.value;

    if(fieldName == 'category'){
      setFirstcat(val);
    }else if(fieldName == 'subcategory'){
      setSecondsubcat(val);
    }else if(fieldName == 'search'){
      setSearchItem(val);
    }else if(fieldName == 'status'){
      setStatusFilter(val);
    }
  };

  /* const handleCategorySearch = async (event) => {
    await axios
      .post(`${PORT}/fields/searchbycategory`, { search: event.target.value })
      .then((res, err) => {
        if (res) {
          setAllRecords(res);
          // console.log(res);
        }
      });
  }; */

  const handleAdd = (event) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(undefined);
  };
  const handleEdit = (event, id) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(id);
  };

  return (
    <div className="work_space">
      <div className="workspace_title_hd">
        <div className="row">
          <div className="col-md-12">
            <div className="titleof_page">
              <ul className="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i
                    style={{ padding: "5px" }}
                    className="fa fa-angle-right"
                  ></i>
                </li>
                <li>Fields</li>
              </ul>
              <h2 className="main_title">Fields</h2>
            </div>
            <div className="addpackk">
              <Link onClick={handleAdd} className="addpkgbtn savesidebutt">
                <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add
                Field
              </Link>
            </div>
          </div>

          <div className="col-md-12">
            <div className="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  <div class="dropdownsid">
                    <select class="fuilddesh"
                      onChange={(e) => handleSearch(e, 'status')}
                    >
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>

                <div class="left_innserSearch d-flex">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Category:</div>
                    <div class="lefelab_fuild">
                      <select 
                        onChange={(e) => handleSearch(e, 'category')}
                        value={firstcat}
                        className="fuilddesh1"
                      >
                        <option value={""}>{"SELECT CATEGORY"}</option>
                        {allCategory &&
                          allCategory.data &&
                          allCategory.data.map((cat) => {
                            return (
                              <option value={cat._id}>
                                {cat.categoryName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Sub Category:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={(e) => handleSearch(e, 'subcategory')}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={(e) => handleSearch(e, 'search')}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} className="tabscroll_perent">
                <div className="tabscroll_inner">
                  <div className="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div className="tableflex topsmall_margin_top">
                      <div className="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="table_cell">Category</div>
                        <div className="table_cell">Field Name</div>
                        <div className="table_cell">Sub Category</div>
                        <div className="table_cell">Field Type</div>
                        <div className="table_cell">Status</div>
                        <div className="table_cell">Actions</div>
                      </div>

                      {currentPosts &&
                        currentPosts
                          
                          .map((cate) => {
                            return (
                              <div className="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="table_cell">
                                  {cate?.category?.categoryName}
                                </div>
                                <div className="table_cell">
                                  {cate.fieldName}
                                </div>
                                <div className="table_cell">
                                {cate.subCategories &&
                                    cate.subCategories
                                    .reduce((subCat, current, i) => {
                                      let cat2Found   = false;
                                      if(subCat == '' && current.label === '2nd') {
                                        cat2Found =   true;
                                        subCat    =   current.subCategoryName+' ('+current.label+')';
                                      }
                                      if(cat2Found){
                                        return subCat;
                                      }else{
                                        return cate.subCategories[0].subCategoryName+' ('+cate.subCategories[0].label+')';
                                      }
                                      
                                  }, '')}
                                </div>
                                <div className="table_cell">
                                  {cate.fieldType}
                                </div>
                                <div className="table_cell">
                                  {cate.status == "Active" ? (
                                    <span className="labltable lablesct">
                                      {cate.status}
                                    </span>
                                  ) : (
                                    <span className="labltable lablesct_red">
                                      {cate.status}
                                    </span>
                                  )}
                                </div>
                                <div className="table_cell">
                                  <span className="table_bbtnn">
                                    <Link
                                      onClick={(e) => handleEdit(e, cate._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                  </span>

                                  {cate.status == "Active" ? (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Inactive"
                                        id="deactive"
                                        onClick={() =>
                                          handleClickDeactive(cate._id)
                                        }
                                      >
                                        <i class="fa fa-ban"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        id="active"
                                        data-placement="top"
                                        title="Active"
                                        onClick={() =>
                                          handleClickActive(cate._id)
                                        }
                                      >
                                        <i class="fa fa-check-circle-o"></i>
                                      </a>
                                    </span>
                                  )}
                                  <span className="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        handleModalField(cate._id)
                                      }
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                    >
                                      <i className="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <div class="showingflexx">
                  <div class="showingleft">
                    Showing {currentPage + 1}-{pageCount} of {pageCount}
                  </div>
                  <div class="left_paginistion">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      pageCount={pageCount}
                      onPageChange={handlePageChange}
                      containerClassName={"paginationBttns"}
                      previousLinkClassName={"previousBttn"}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                  </div>
                </div>
              </div>
              <Modal isOpen={catModal}>
                <div class="modalChild">
                  <div class="modalchildPadding">
                    <h6>Do you want to delete this field?</h6>
                  </div>
                  <Button
                    onClick={handleRecordModalYes}
                    className="savesidebutt"
                    variant="primary"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={handleModalClose}
                    className="savesidebutt close-savesidebutt"
                    variant="secondary"
                  >
                    Close
                  </Button>
                </div>
              </Modal>

              <Modal isOpen={modalActive}>
                <div class="modalChild">
                  <div class="modalchildPadding">
                    <h6>Do you want to Active this field?</h6>
                  </div>
                  <Button
                    onClick={handleYesActive}
                    className="savesidebutt"
                    variant="primary"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={handleClose}
                    className="savesidebutt close-savesidebutt"
                    variant="secondary"
                  >
                    Close
                  </Button>
                </div>
              </Modal>

              <Modal isOpen={modalDeactive}>
                <div class="modalChild">
                  <div class="modalchildPadding">
                    <h6>Do you want to Inactive this field?</h6>
                  </div>

                  <Button
                    onClick={handleYesDeactive}
                    className="savesidebutt"
                    variant="primary"
                  >
                    Yes
                  </Button>
                  <Button
                    onClick={handleClose}
                    className="savesidebutt close-savesidebutt"
                    variant="secondary"
                  >
                    Close
                  </Button>
                </div>
              </Modal>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      {propsid == undefined ? <FieldAdd /> : <FieldEdit props={propsid} />}
    </div>
  );
};

export default FieldsList;
