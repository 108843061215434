import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const Contact = ({props}) => {

 // console.log("categorypropsdata",props);
  const allPathEdit = props.split("/");
 // console.log("allpath", allPathEdit);

  
  useEffect(() => {
    if(props == "/contact-us")
    {
      document.getElementById("collapseContact").classList.add("show");
    }
  },[])

    return (
              <li class="sidebar-dropdown mabot_mainli">
                           <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseContact">
                             <span>Contact Us</span>
                             <i class="fa fa-phone"></i>
                           </a>
                           <div className="sidebar-submenu" id="collapseContact" class=" collapse" data-parent="#accordion">
                             <ul>
                               <li>
                                 <Link to="/contact-us">Contact Us</Link>
                               </li>
                             </ul>
                           </div>
               </li>
    )
}

export default Contact
