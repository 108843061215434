import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";

const SubFirst = (props) => {


    const [firstSub, setFirstSub] = useState();




    const ButtonName =  props.match.params.data;
    console.log("dfebvjhdsbf", ButtonName);

    useEffect(() => {
      axios.post("http://192.168.1.115:5000/getFirstSubCategory", {params:{categoryName:ButtonName}}).then((res, err)=>{
          console.log("allfirstsubcateg", res);
          setFirstSub(res);
        })
    }, [])

    const handleDelete = async (Id) =>{
      console.log("delete click");
      await axios.post("http://192.168.1.115:5000/deleteFirstSubCategory", {params:{id:Id}}).then((res, err)=>{
        console.log("deleted firstSub", res);
      })
      await axios.post("http://192.168.1.115:5000/getFirstSubCategory", {params:{categoryName:ButtonName}}).then((res, err)=>{
        console.log("allfirstsubcateg", res);
        setFirstSub(res);
      })
    }
    


    return (
        <div class="col-sm-6 col-md-4 col-lg-3 using_onresponsie">
        <div class="addmore_cat small_margin_top">
          <div class="addmore_flex">
            <div class="flexleft_addmore">
              <span>For {ButtonName}</span>
              <span>For sales</span>
            </div>
            <div class="flexright_addmore">
              <Link to={'/addSubCategory/' + ButtonName}><i class="fa fa-plus"></i>Category</Link>
               <Link to="#"><i class="fa fa-plus"></i>Category</Link>
            </div>
          </div>
          { firstSub && firstSub.data && firstSub.data.map((fsub)=>{
            return(
              <div class="subsmall_cat">
              <div class="subsml_flrex topsmall_margin_top">
                <div class="clsubsml_left">
                  <Link to={'/' + ButtonName + "/" + fsub.subcategoryName + "/subSecond"}>{fsub.subcategoryName}</Link>
                </div>
                <div class="clsubsml_right">                                          
                  <Link to={'/editSubCategory/' + fsub._id} class="editfix_bttnn" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>&nbsp;
                  <a href="#" data-toggle="tooltip" data-placement="top" title="Close"><i onClick={()=>handleDelete(fsub._id)} class="fa fa-trash"></i></a>
                </div>
              </div>
              </div>
            )
          })
          }
        </div>
      </div>
    )
}

export default SubFirst
