import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from "react-html-parser";

const ContactView = (props) => {

    const PORT = PORTNUMBER.PORTNUMBER;


    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const id = props.match.params.id;

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [subject, setSubject] = useState();
    const [time, setTime] = useState();


    useEffect(async ()=>{
    await axios.post(`${PORT}/contact/get_single`, {id}).then((res, err)=>{
        if(res)
        {
            setName(res.data.data.name);
            setEmail(res.data.data.email);
            setMessage(res.data.data.message);
            setSubject(res.data.data.subject);
            setTime(res.data.data.time);
        } 
        else{
             toast.info("Something went wrong");
        }
    })
    },[])


    return (
      <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
            <div class="col-md-12">
              <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li>
                    <a href="#">Admin Panel </a>
                  </li>
                  <li>
                    <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                  </li>
                  <li>View Contact</li>
                </ul>
                <h2 class="main_title">View Contact</h2>
              </div>
            </div>
            <div class="col-md-12">
              <div class="main_oflistingForm onlyforcol_support information-sec">
                <div class="custom_fuild_addM topsmall_top flexcoluman">
 
                  <div class="">
               
                    <div class="">
                    <div class="card-header text-center">Contact View</div>
                    <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <td>{name}</td>
                                </tr>
                                <tr>
                                  <th>Email</th>
                                  <td> {email}</td>
                                </tr>
                                <tr>
                                  <th>Subject</th>
                                  <td>{subject}</td>
                                </tr>
                                <tr>
                                  <th>Messsage</th>
                                  <td>{message}</td>
                                </tr>
                                <tr>
                                  <th>Add Date</th>
                                  <td> {time && time.split("T")[0]}</td>
                                </tr>
                              </tbody>
                          </table>
            
                
                    </div>
                  </div>
                </div>

           
              </div>
            
                
            </div>
          </div>
          
        </div>
        <ToastContainer />
      </div>
    );
}

export default ContactView
