import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const Maps = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [mapsModule,setMapsModule] = useState();
    const [locationDefault,setLocationDefault] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/maps/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setMapsModule(res.data.data[0].common_setting[0].mapsModule);
                    setLocationDefault(res.data.data[0].common_setting[0].locationDefault);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
        if(mapsModule == 1){
            var mapsModule_check = true;
        }else{
            var mapsModule_check = false;
        }
        
        var common_settings         = [ {
                                        'mapsModule': mapsModule_check,
                                        'locationDefault':locationDefault,
                                    }];
       
        if(slug == 'MapsSetting'){
            await axios.post(`${PORT}/maps/update`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/maps/create`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();

    }
  
    const handleMapsModule = (event) =>{
        setMapsModule(event.target.value);
    }
    const handleLocationDefault = (event) =>{
        setLocationDefault(event.target.value);
    }
    
    return (
        <div class="tab-pane" id="basic09" role="tabpanel">
             <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Maps/Geocoding</span></h3>
                <div class="labelsidetop">
                    <div class="boxrepetform">
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Maps module</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                                <input type="radio" id="mapsModule1" name="mapsModule" value='1' onClick={handleMapsModule} checked={mapsModule == 1?'checked':''}/>
                                                <label for="mapsModule1" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                                <input type="radio" id="mapsModule2" name="mapsModule" value='0' onClick={handleMapsModule} checked={mapsModule == 0?'checked':''}/>
                                                <label for="mapsModule2" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/* <div class="Table-row">
                            <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                <span>Service provider</span>
                                </div>
                            </div>
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <select class="fuilddesh">
                                        <option value="">Google Maps</option>
                                    </select>
                                </div>
                            </div>
                            </div> */}
                            <div class="Table-row">
                            <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                <span>Location default zoom</span>
                                </div>
                            </div>
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <select class="fuilddesh" onChange={handleLocationDefault} value={locationDefault}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                    </select>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    {/* <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Search on Map page</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Default location</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="text" class="fuilddesh" name="" placeholder="" />
                                    <span class="equalspan_cont">Leave the fuild empty to use visitor location for lisiting on the Maps.</span>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                            <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                <span>Default zoom</span>
                                </div>
                            </div>
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <select class="fuilddesh">
                                        <option value="">14</option>
                                    </select>
                                </div>
                            </div>
                            </div>
                            <div class="Table-row">
                            <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                <span>Limit of markers on map</span>
                                </div>
                            </div>
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="text" class="fuilddesh" name="" placeholder="" />
                                </div>
                            </div>
                            </div>
                            <div class="Table-row">
                            <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                <span>limit of markers on map (mobile)</span>
                                </div>
                            </div>
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="text" class="fuilddesh" name="" placeholder="" />
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Static maps</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Service provider</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                        <select class="fuilddesh">
                                            <option value="">Yandex Static Maps</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    {/* <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Geocoding</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Service provider</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                        <select class="fuilddesh">
                                            <option value="">ArcGIS</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                    </div>
                    <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Maps
