import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {Multiselect} from "multiselect-react-dropdown";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import {useHistory, useLocation} from "react-router-dom";
import "./addCategoryField.css";




const AddCategoryFields = (props) => {


    const PORT = PORTNUMBER.PORTNUMBER;

    const [allCategory, setAllCategory] = useState();
    const [allfirstcategory, setAllFirstCategory] = useState();
    const [allsecondcategory, setAllSecondCategory] = useState();
    const [allthirdcategory, setAllThirdCategory] = useState();

    const [categoryFields, setCategoryFields] = useState();
    const [selectcategory, setSelectCategory] = useState();
    const [selectfirstcategory, setSelectFirstCategory] = useState();
    const [selectsecondcategory, setSelectSecondCategory] = useState();
    const [selectthirdcategory, setSelectThirdCategory] = useState();

    const [required, setRequired] = useState(false);
    const [categoryType, setCategoryType] = useState([{}]);
    const [dropdown, setDropdown] = useState([{value:null}]);

    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;


    
    
    useEffect(async () => {
    await axios.get(`${PORT}/getCategorys`).then((res, err) =>{
      if(res)
      {
        setAllCategory(res);
      } 
      else{
        toast.info("Something went wrong");
      }  
    })
    },[])

    useEffect(async () => {
    await axios.get(`${PORT}/get_all_category_subcategory`).then((res, err) =>{
      if(res)
      {
        setAllFirstCategory(res);
      } 
      else{
        toast.info("Something went wrong");
      }
    })
    },[])

    useEffect(async () => {
    await axios.get(`${PORT}/get_all_subcategory_secondsubcategory`).then((res, err) =>{
        if(res)
        {
          console.log("dfvbndsfvnds", res);
          setAllSecondCategory(res);
        } 
        else{
          toast.info("Something went wrong");
        }  
      })
    },[])

    useEffect(async () => {
    await axios.get(`${PORT}/get_all_secondsubcategory_thirdsubcategory`).then((res, err) =>{
          if(res)
          {
            setAllThirdCategory(res);
          } 
          else{
            toast.info("Something went wrong");
          }  
        })
    },[])


    const handleCategory = (data) =>{
      document.getElementById("required-category").style.display="none";
      setSelectCategory(data);
    }

    const handleCategoryFields = (e) =>{
      document.getElementById("required-categoryfield").style.display="none";
      setCategoryFields(e.target.value);
    }

    const handleFirstCategory = (data) =>{
      document.getElementById("required-firstcategory").style.display="none";
      console.log("first", data);
      setSelectFirstCategory(data);
    }

    const handleSecondCategory = (data) =>{
      console.log("second", data);
      setSelectSecondCategory(data);
    }

    const handleThirdCategory = (data) =>{
      console.log("third", data);
      setSelectThirdCategory(data);
    }

    const handleSubmit = async (e) =>{
      e.preventDefault();
      console.log("submit")

      if(!categoryFields)
      {
       document.getElementById("required-categoryfield").style.display="block";
       return;
      }

      if(selectcategory)
      {
        var categoryArray = [];
        selectcategory.map(category => {
          categoryArray.push(category._id);
        })
        console.log(categoryArray);
      }
      else{
        document.getElementById("required-category").style.display="block";
        return;
      }
      if(selectfirstcategory)
      {
        var firstcategoryArray = [];
        selectfirstcategory.map(category => {
          firstcategoryArray.push(category._id);
        })
        console.log(firstcategoryArray);
      }
      else{
        document.getElementById("required-firstcategory").style.display="block";
        return;
      }
      if(selectsecondcategory)
      {
        var secondcategoryArray = [];
        selectsecondcategory.map(category => {
          secondcategoryArray.push(category._id);
        })
        console.log(secondcategoryArray);
      }

      if(selectthirdcategory)
      {
        var thirdcategoryArray = [];
        selectthirdcategory.map(category => {
          thirdcategoryArray.push(category._id);
        })
        console.log(thirdcategoryArray);
      }

      if(!required)
      {
        categoryType.push({required:false});
      }
      if(dropdown)
      {
        categoryType.push(dropdown);
      }
      
  
      await axios.post(`${PORT}/add_category_fields`, {categoryFieldType:categoryType, categoryId:categoryArray,categoryFieldName:categoryFields, firstCategoryId:firstcategoryArray, secondCategoryId:secondcategoryArray, thirdCategoryId:thirdcategoryArray}).then((res, err) =>{
        if(res)
        {
         console.log("dfvcdv", res);
         toast.success("You have successfully added category fields");
         setCategoryType([{}]);
         history.push({pathname:"/category", customdata:{pathname}});

        } 
        else{
          toast.info("Something went wrong");
        }
      })
    }


    const handleReset = (e) =>{
     e.preventDefault();
     setCategoryFields("");
     setSelectCategory(undefined);
     setSelectFirstCategory(undefined);
     setSelectSecondCategory(undefined);
     setSelectThirdCategory(undefined);
    }


    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       
    const handleTextField = (data) =>{

       setCategoryType([{}]);
       setCategoryType(categoryType => [...categoryType,  {text_type:data}]);

       document.getElementById("text-field").style.display = "block";
       document.getElementById("text-area").style.display = "none";
       document.getElementById("radio-button").style.display = "none";
       document.getElementById("number-field").style.display = "none";
       document.getElementById("dropdownid").style.display = "none";
       document.getElementById("check-box").style.display = "none";
    }


    const handleTextArea = (data) =>{

      setCategoryType([{}]);
      setCategoryType(categoryType => [...categoryType,  {text_type:data}]);

      document.getElementById("text-area").style.display = "block";
      document.getElementById("radio-button").style.display = "none";
      document.getElementById("text-field").style.display = "none";
      document.getElementById("number-field").style.display = "none";
      document.getElementById("dropdownid").style.display = "none";
      document.getElementById("check-box").style.display = "none";
    }

    const handleCheckBox = (data) =>{

      setCategoryType([{}]);
      setCategoryType(categoryType => [...categoryType,  {text_type:data}]);

      document.getElementById("check-box").style.display = "block";
      document.getElementById("text-field").style.display = "none";
      document.getElementById("text-area").style.display = "none";
      document.getElementById("number-field").style.display = "none";
      document.getElementById("radio-button").style.display = "none";
      document.getElementById("dropdownid").style.display = "none";
    }

    const handleRadioButton = (data) =>{

      setCategoryType([{}]);
      setCategoryType(categoryType => [...categoryType,  {text_type:data}]);

      document.getElementById("radio-button").style.display = "block";
      document.getElementById("text-field").style.display = "none";
      document.getElementById("text-area").style.display = "none";
      document.getElementById("number-field").style.display = "none";
      document.getElementById("dropdownid").style.display = "none";
      document.getElementById("check-box").style.display = "none";
    }

    const handleDropDown = (data) =>{

      setCategoryType([{}]);
      setCategoryType(categoryType => [...categoryType,  {text_type:data}]);

      document.getElementById("dropdownid").style.display = "block";
      document.getElementById("text-field").style.display = "none";
      document.getElementById("text-area").style.display = "none";
      document.getElementById("number-field").style.display = "none";
      document.getElementById("radio-button").style.display = "none";
      document.getElementById("check-box").style.display = "none";
    }

    const handleNumberField = (data) =>{

      setCategoryType([{}]);
      setCategoryType(categoryType => [...categoryType,  {text_type:data}]);

      document.getElementById("number-field").style.display = "block";
      document.getElementById("text-field").style.display = "none";
      document.getElementById("text-area").style.display = "none";
      document.getElementById("radio-button").style.display = "none";
      document.getElementById("dropdownid").style.display = "none";
      document.getElementById("check-box").style.display = "none";
    }

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


    const handleRequired = (e) =>{
      console.log(e.target.checked);
      setRequired(e.target.checked);
  
      setCategoryType(categoryType => [...categoryType, {required:e.target.checked}]);
    }


    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const handleChangeFields = (i, event) =>{
      const values = [...dropdown];
      values[i].value = event.target.value;
      setDropdown(values);
      console.log(dropdown);
    }
    
    const handleAddFields = () => {
    const values = [...dropdown];
    values.push({ value: null });
    setDropdown(values);
    }
    
    
    const handleRemoveFields = (i) => {
    const values = [...dropdown];
    values.splice(i, 1);
    setDropdown(values);
    }

    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


    const handleInput = (e) =>{
     console.log(e.target.value);
    }

    const handleCross = (id) =>{
      document.getElementById(id).style.display="none";
      setCategoryType([{}]);
      setCategoryType([{text_type:""}]);
      return;
    }

    return (
        <div className="work_space">
        <div className="workspace_title_hd">
          <div className="row">
                <div className="col-md-12">
                  <div className="titleof_page">
                <ul className="breadcrumb_list topsmall_bottom">
                  <li><a href="#">Admin Panel </a></li>
                  <li><i style={{padding:"5px"}} className="fa fa-angle-right"></i></li>
                  <li>Add Category Fields</li>
                </ul>
                    <h2 className="main_title">Add Category Fields</h2>
                  </div>
                </div>
            <div className="col-md-12">
              <div className="main_oflistingForm bg_light_gray onlyforcol_support">
                <div className="custom_fuild_addM topsmall_top flexcoluman">
                  
                               
                  <form onSubmit={handleSubmit}>     


                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Category Field</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input onChange={handleCategoryFields} value={categoryFields} type="text" className="fuilddesh" placeholder="Category Field" /><span className="requlab_span">
                            <div className="checkBtablss">     
                              <ul>
                                <li>
                                  <div className="check-box">
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </span>
                      </div>
                    </div>
                    <p id="required-categoryfield" style={{display:"none"}} className="text-danger">Category Field is required*</p>
                  </div>


                <div className="bindfields">
                    <h4 className="fieldsec_lab">Categories</h4>
                    <div className="field_sec_repetM">
                    <div className="repwat_one multSelectWidth">           
                     <Multiselect  
                      onSelect={handleCategory}
                      onRemove={handleCategory} 
                      options={allCategory && allCategory.data}
                      displayValue="categoryName"  
                      required      
                      /> 
                      <span className="requlab_span">
                            <div className="checkBtablss">     
                              <ul>
                                <li>
                                  <div className="check-box">
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                      </span>   
                    </div> 
                    </div>
                    <p id="required-category" style={{display:"none"}} className="text-danger">Category is required*</p>
                </div>


                <div className="bindfields">
                    <h4 className="fieldsec_lab">First Sub Categories</h4>
                    <div className="field_sec_repetM">
                    <div className="repwat_one multSelectWidth">           
                     <Multiselect  
                      onSelect={handleFirstCategory} 
                      onRemove={handleFirstCategory} 
                      groupBy="categoryName"
                      options={allfirstcategory && allfirstcategory.data}
                      displayValue="subcategoryName"        
                      /> 
                      <span className="requlab_span">
                            <div className="checkBtablss">     
                              <ul>
                                <li>
                                  <div className="check-box">
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                      </span>   
                    </div>
                    </div>
                    <p id="required-firstcategory" style={{display:"none"}} className="text-danger">Category is required*</p>
                </div>


                <div className="bindfields">
                    <h4 className="fieldsec_lab">Second Sub Categories</h4>
                    <div className="field_sec_repetM">
                    <div className="repwat_one multSelectWidth">           
                     <Multiselect  
                      onSelect={handleSecondCategory} 
                      onRemove={handleSecondCategory} 
                      groupBy="firstSubCategoryName"
                      options={allsecondcategory && allsecondcategory.data}
                      displayValue="secondSubCategoryName"        
                      />  
                    </div>
                    </div>
                </div>

                <div className="bindfields">
                    <h4 className="fieldsec_lab">Third Sub Categories</h4>
                    <div className="field_sec_repetM">
                    <div className="repwat_one multSelectWidth">           
                     <Multiselect  
                      onSelect={handleThirdCategory} 
                      onRemove={handleThirdCategory} 
                      groupBy="secondSubCategoryName"
                      options={allthirdcategory && allthirdcategory.data}
                      displayValue="thirdSubCategoryName"        
                      />    
                    </div>
                    </div>
                </div>


 

                <div class="bindfields">
                      <div class="addfieldbt_last topsmall_margin_top">
                        <a onClick={(e)=>handleTextField("input_text")} href="javascript:void(0)"><i class="fa fa-plus"></i> Text Field</a>
                        <a onClick={(e)=>handleTextArea("input_textarea")} href="javascript:void(0)"><i class="fa fa-plus"></i> Text Area</a>
                        <a onClick={(e)=>handleCheckBox("input_checkbox")} href="javascript:void(0)"><i class="fa fa-plus"></i> Checkbox Group</a>
                        <a onClick={(e)=>handleRadioButton("input_radiobutton")} href="javascript:void(0)"><i class="fa fa-plus"></i> Radio Button Group</a>
                        <a onClick={(e)=>handleDropDown("input_dropdown")} href="javascript:void(0)"><i class="fa fa-plus"></i> Drop Down</a>
                        <a onClick={(e)=>handleNumberField("input_number")} href="javascript:void(0)"><i class="fa fa-plus"></i> Number Field</a>
                      </div>
                </div>

                    {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}

                    {/*Text Field */}

                    <div style={{display: "none"}} id="text-field" className="bindfields">
                      <h4 className="fieldsec_lab" style={{display:'inline-block'}}>Text Field</h4>
                      <i onClick={(e)=>handleCross("text-field")} style={{ paddingLeft: "20px", color:"#000" }} className="fa fa-times fa-1x"></i>
                      <div className="field_sec_repetM">
                        <div className="repwat_one">
                          <input onChange={handleInput} type="text" className="fuilddesh" placeholder="Text Field" />
                          <span className="requlab_span">
                            <div className="checkBtablss">
                              <ul>
                                <li>
                                  <div className="check-box">
                                    <input onChange={handleRequired} type="checkbox" id="Company103" />
                                    <label>*Requird Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>


                    {/* Radio Button                  */}


                    <div style={{ display: "none" }} id="radio-button" className="bindfields">
                      <h4 className="fieldsec_lab" style={{display:'inline-block'}}>Radio Button Group</h4>
                      <i onClick={(e)=>handleCross("radio-button")} style={{ paddingLeft: "20px", color:"#000" }} className="fa fa-times fa-1x"></i>
                      <div className="field_sec_repetM repeatDisUnset">

                        {dropdown.map((field, index) => {
                          return (
                            <div className="repwat_one flexCatGrow" key={`${field}-${index}`}>
                              <input onChange={(e) => handleChangeFields(index, e)} type="text" name="checkbox" className="fuilddesh" />

                              <span className="requlab_span">
                                <div className="checkBtablss">
                                  <ul>
                                    <li>
                                      <div className="check-box">
                                        {
                                          index == 0 ?
                                            <div>
                                              {/* <i onClick={handleAddFields} style={{ padding: "2px" }} className="fa fa-plus-circle fa-1x"></i> */}
                                              <input onChange={handleRequired} type="checkbox" id="Company102" />
                                              <label style={{paddingLeft:"5px"}}>Required Field</label>
                                            </div>
                                            :
                                            <i onClick={() => handleRemoveFields(index)} style={{ padding: "2px", color:"#888" }} className="fa fa-times fa-2x"></i>
                                        }
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </span>
                            </div>
                          )
                        })
                        }
                         <div class="addfieldbt_last topsmall_margin_top">
                          <a onClick={handleAddFields} href="javascript:void(0)"><i style={{ padding: "5px", color:"#fff" }} class="fa fa-plus"></i>Add Option</a>
                        </div>

                      </div>
                    </div>


                    {/* Text Area                */}

                    <div style={{ display: "none" }} id="text-area" class="bindfields">
                      <h4 class="fieldsec_lab" style={{display:'inline-block'}}>Text Area</h4>
                      <i onClick={(e)=>handleCross("text-area")} style={{ paddingLeft: "20px", color:"#000" }} className="fa fa-times fa-1x"></i>
                      <div class="field_sec_repetM">
                        <div class="repwat_one">
                          <textarea onChange={handleInput} rows="5" type="text" class="fuilddesh" placeholder="Text Area"></textarea>
                          <span class="requlab_span">
                            <div class="checkBtablss">
                              <ul>
                                <li>
                                  <div class="check-box">
                                  <input onChange={handleRequired} type="checkbox" name="option" id="Company103" />
                                    <label>*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Number Field */}

                    <div style={{ display: "none" }} id="number-field" class="bindfields">
                      <h4 class="fieldsec_lab" style={{display:'inline-block'}}>Number Field </h4>
                      <i onClick={(e)=>handleCross("number-field")} style={{ paddingLeft: "20px", color:"#000" }} className="fa fa-times fa-1x"></i>
                      <div class="field_sec_repetM">
                        <div class="repwat_one">
                          <input onChange={handleInput} type="number" class="fuilddesh" placeholder="Number Field" />
                          <span class="requlab_span">
                            <div class="checkBtablss">
                              <ul>
                                <li>
                                  <div class="check-box">
                                    <input onChange={handleRequired} type="checkbox" name="option" id="Company103" />
                                    <label>*Requird Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* check Box */}


                    <div style={{ display: "none" }} id="check-box" className="bindfields">
                      <h4 className="fieldsec_lab" style={{display:'inline-block'}}>Checkbox Group</h4>
                      <i onClick={(e)=>handleCross("check-box")} style={{ paddingLeft: "20px", color:"#000" }} className="fa fa-times fa-1x"></i>
                      <div className="field_sec_repetM repeatDisUnset">

                        {dropdown.map((field, index) => {
                          return (
                            <div className="repwat_one flexCatGrow" key={`${field}-${index}`}>
                              <input onChange={(e) => handleChangeFields(index, e)} type="text" name="checkbox" className="fuilddesh" />

                              <span className="requlab_span">
                                <div className="checkBtablss">
                                  <ul>
                                    <li>
                                      <div className="check-box">
                                        {
                                          index == 0 ?
                                            <div>
                                              {/* <i onClick={handleAddFields} style={{ padding: "2px" }} className="fa fa-plus-circle fa-1x"></i> */}
                                              <input onChange={handleRequired} type="checkbox" id="Company102" />
                                              <label style={{paddingLeft:"5px"}}>Required Field</label>
                                            </div>
                                            :
                                            <i onClick={() => handleRemoveFields(index)} style={{ padding: "2px", color:"#888" }} className="fa fa-times fa-2x"></i>
                                        }
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </span>
                            </div>
                          )
                        })
                        }
                         <div class="addfieldbt_last topsmall_margin_top">
                          <a onClick={handleAddFields} href="javascript:void(0)"><i style={{ padding: "5px", color:"#fff" }} class="fa fa-plus"></i>Add Option</a>
                        </div>

                      </div>
                    </div>


                    {/* DropDown */}


                    <div style={{ display: "none" }} id="dropdownid" className="bindfields">
                      <h4 className="fieldsec_lab" style={{display:'inline-block'}}>Dropdown Group</h4>
                      <i onClick={(e)=>handleCross("dropdownid")} style={{ paddingLeft: "20px", color:"#000" }} className="fa fa-times fa-1x"></i>
                      <div className="field_sec_repetM repeatDisUnset">

                        {dropdown.map((field, index) => {
                          return (
                            <div>
                            <div className="repwat_one flexCatGrow" key={`${field}-${index}`}>
                              <input onChange={(e) => handleChangeFields(index, e)} type="text" className="fuilddesh" />
                              <span className="requlab_span">
                                <div className="checkBtablss">
                                  <ul>
                                    <li>
                                      <div className="check-box">
                                        {
                                          index == 0 ?
                                            <div>
                                              <input onChange={handleRequired} type="checkbox" id="Company102" />
                                              <label style={{paddingLeft:"5px"}}>Required Field</label>      
                                            </div>
                                            :
                                            <i onClick={() => handleRemoveFields(index)} style={{ padding: "2px", color:"#888" }} className="fa fa-times fa-2x"></i>
                                        }
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </span>
                            </div>
                            </div>
                          )
                        })
                        }
                        <div class="addfieldbt_last topsmall_margin_top">
                          <a onClick={handleAddFields} href="javascript:void(0)"><i style={{ padding: "5px", color:"#fff" }} class="fa fa-plus"></i>Add Option</a>
                        </div>

                      </div>
                    </div>
                    {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}



                  <div className="bottomsend_button text-center small_margin_top">
                  <button type="submit" className="savesidebutt closefix">Save</button>
                  <button type="button" onClick={handleReset} className="savesidebutt closefix">Reset</button>
                  </div>

                  </form>

                 
                </div>
             
               </div>
            </div>
          </div>
        </div>
        <ToastContainer />
  </div>
    )
}

export default AddCategoryFields

