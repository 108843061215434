import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Logo = ({props}) => {

    // console.log("categorypropsdata",props);
    // const allPathEdit = props.split("/");
    // console.log("allpath", allPathEdit);
  
    
    useEffect(() => {
      if(props == "/logo")
      {
        document.getElementById("collapseLogo").classList.add("show");
      }
    },[])
  
    return (
        <li className="sidebar-dropdown mabot_mainli">
      <Link to="#" data-toggle="collapse" data-target="#collapseLogo">
        <span>Logo</span>
        <i className="fa fa-tags"></i>
      </Link>
      <div
        className="sidebar-submenu"
        id="collapseLogo"
        class="collapse"
        data-parent="#accordion"
      >
        <ul>
          <li>
            <Link to="/logo">Logo</Link>
          </li>
        </ul>
      </div>
    </li>
        
        )
  }
  
  export default Logo