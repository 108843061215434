import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Modal from 'react-modal';
import Button from "react-bootstrap/Button";
import axios from "axios";
import PORTNUMBER from "../../constant/api";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import EditFooterLogo from "./editFooterLogo";
import EditHeaderLogo from "./editHeaderLogo";
import EditMobileLogo from "./editMobileLogo";

const Logo = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [propsid, setPropsId] = useState();
  const [headerLogo, setheaderLogo] = useState();
  const [footerLogo, setfooterLogo] = useState();
  const [mobileLogo, setmobileLogo] = useState();
    useEffect(() => {
      if(props == "/logo")
      {
        document.getElementById("collapseLogo").classList.add("show");
      }
     },[])
    useEffect(() => {
      axios.get(`${PORT}/getLatestLogo`).then((response, err) => {
        //console.log("latest logo", response);
        setheaderLogo(response.data.data.headerLogo);
        setfooterLogo(response.data.data.footerLogo);
        setmobileLogo(response.data.data.mobileLogo)
      });
    }, []);
  
  
    const handleEdit = (type) =>{
      document.body.classList.add('fixedit_modal');
      setPropsId(type)
    }

    return (
      <div class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
              <div class="col-md-12">
                <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li><a href="javascript:void(0)" >Admin Panel </a></li>
                <li><i style={{padding:"5px"}} className="fa fa-angle-right"></i></li>
                <li>Logo</li>
              </ul>
                  <h2 class="main_title">Logo</h2>
                </div>
              </div>
          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">

            <div style={{overflowX:'auto'}} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">                       
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          Logo Name
                        </div>
                        <div class="table_cell">
                          Image Name
                        </div>
                        <div class="table_cell">
                          Actions
                        </div>
                      </div>  
                      <div class="table_row">
                        <div class="table_cell">
                          Header Logo
                        </div> 
                        <div class="table_cell">
                          <div class="cateimag">
                              <div class="acttiimmg">
                                {headerLogo ? (
                                  <img
                                    src={
                                      `${PORT}/image/logo/` +
                                      headerLogo
                                    }
                                    alt={`Header Logo`}
                                  />
                                ) : (
                                  <img
                                    src="/images/no-image.jpg"
                                    alt="logo"
                                  />
                                )}
                              </div>
                          </div>
                        </div>  
                        <div class="table_cell"> 
                              <span class="table_bbtnn">
                              <Link onClick={(e)=>handleEdit(1)}  data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>
                              </span> 
                        </div>
                      </div>
                      <div class="table_row">
                        <div class="table_cell">
                          Footer Logo
                        </div> 
                        <div class="table_cell">
                          <div class="cateimag">
                              <div class="acttiimmg">
                                {footerLogo ? (
                                  <img
                                    src={
                                      `${PORT}/image/logo/` +
                                      footerLogo
                                    }
                                    alt={`Footer Logo`}
                                  />
                                ) : (
                                  <img
                                    src="/images/no-image.jpg"
                                    alt="logo"
                                  />
                                )}
                              </div>
                          </div>
                        </div>  
                        <div class="table_cell"> 
                              <span class="table_bbtnn">
                              <Link onClick={(e)=>handleEdit(2)}  data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>
                              </span> 
                        </div>
                      </div>
                      <div class="table_row">
                        <div class="table_cell">
                          Mobile Logo
                        </div> 
                        <div class="table_cell">
                          <div class="cateimag">
                              <div class="acttiimmg">
                                {mobileLogo ? (
                                  <img
                                    src={
                                      `${PORT}/image/logo/` +
                                      mobileLogo
                                    }
                                    alt={`Mobile Logo`}
                                  />
                                ) : (
                                  <img
                                    src="/images/no-image.jpg"
                                    alt="logo"
                                  />
                                )}
                              </div>
                          </div>
                        </div>  
                        <div class="table_cell"> 
                              <span class="table_bbtnn">
                              <Link onClick={(e)=>handleEdit(3)}  data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>
                              </span> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
      {
        propsid == undefined
        ?
        null
        :
        (propsid == 1 ? <EditHeaderLogo props={propsid} /> : (propsid == 2 ?<EditFooterLogo props={propsid} /> : <EditMobileLogo props={propsid} />))
      }
</div>
    )
}

export default Logo;
