import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const Maps = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [autoDetection,setAutoDetection] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/geoFilter/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setAutoDetection(res.data.data[0].common_setting[0].autoDetection);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
        if(autoDetection == 1){
            var autoDetection_check = true;
        }else{
            var autoDetection_check = false;
        }
        
        var common_settings         = [ {
                                        'autoDetection': autoDetection_check,
                                    }];
       
        if(slug == 'GeoFilterSetting'){
            await axios.post(`${PORT}/geoFilter/update`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/geoFilter/create`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();
    }
  
    const handleAutoDetection = (event) =>{
        setAutoDetection(event.target.value);
    }
    
    return (
        <div class="tab-pane" id="basic012" role="tabpanel">
             <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Geo Filter</span></h3>
                    <div class="labelsidetop">
                    <div class="boxrepetform">
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Auto detection of user location</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                                <input type="radio" id="autoDetection1" name="autoDetection" value='1' onClick={handleAutoDetection} checked={autoDetection == 1?'checked':''}/>
                                                <label for="autoDetection1" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                                <input type="radio" id="autoDetection2" name="autoDetection" value='0' onClick={handleAutoDetection} checked={autoDetection == 0?'checked':''}/>
                                                <label for="autoDetection2" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/* <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Cookie expiration period</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="text" class="fuilddesh" name="" placeholder="" />
                                    <span class="equalspan_cont">The number of days, during which the system WIll keep a user  location in the browser cookie</span>
                                </div>
                                </div>
                            </div> */}
                        </div>
                        </div>
                    </div>
                    {/* <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Autocomplete field</h4>                                      
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Autocomplete field in Location Filter box</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test139" name="radio-group80" checked />
                                            <label for="test139" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test140" name="radio-group80" />
                                            <label for="test140" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Number of suggested locations</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="text" class="fuilddesh" name="" placeholder="" />
                                    <span class="equalspan_cont">set 0 to return all</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}

                    {/* <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">SEO</h4>                                      
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Location structure in URLs</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                    <div class="dropdownsid">
                                        <select class="fuilddesh">
                                            <option value="">Mixed</option>
                                            <option value="">Fill</option>
                                            <option value="">City Only</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Location on subdomains</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test141" name="radio-group81" checked />
                                            <label for="test141" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test142" name="radio-group81" />
                                            <label for="test142" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Add locations to listing URLs</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid radioequal_flex">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test143" name="radio-group82" checked />
                                            <label for="test143" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test144" name="radio-group82" />
                                            <label for="test144" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                    <span class="equalspan_cont">Generates the URLs of a Listing Details using location details specified in the listings</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}

                    {/* <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Filtering Listing /Accounts on Pages</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Auction</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test145" name="radio-group83" checked />
                                            <label for="test145" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test146" name="radio-group83" />
                                            <label for="test146" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test150" name="radio-group-four" />
                                            <label for="test150" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Agriculture</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test152" name="radio-group84" checked />
                                            <label for="test152" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test153" name="radio-group84" />
                                            <label for="test153" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test154" name="radio-group-four" />
                                            <label for="test154" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Community</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test155" name="radio-group85" checked />
                                            <label for="test155" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test156" name="radio-group85" />
                                            <label for="test156" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test157" name="radio-group-four" />
                                            <label for="test157" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>For Sale</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test158" name="radio-group86" checked />
                                            <label for="test158" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test159" name="radio-group86" />
                                            <label for="test159" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test160" name="radio-group-four" />
                                            <label for="test160" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Jobs & Services</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test161" name="radio-group87" checked />
                                            <label for="test161" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test162" name="radio-group87" />
                                            <label for="test162" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test163" name="radio-group-four" />
                                            <label for="test163" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Motors</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test164" name="radio-group88" checked />
                                            <label for="test164" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test146" name="radio-group88" />
                                            <label for="test146" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test165" name="radio-group-four" />
                                            <label for="test165" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Property</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test166" name="radio-group89" checked />
                                            <label for="test166" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test167" name="radio-group89" />
                                            <label for="test167" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test168" name="radio-group-four" />
                                            <label for="test168" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Live Streaming</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test169" name="radio-group90" checked />
                                            <label for="test169" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test170" name="radio-group90" />
                                            <label for="test170" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <span class="equalspan_cont">
                                    <div class="lisiting_genral_checklist">
                                        <ul class="list">                            
                                        <li class="list__item">
                                            <div class="list-check-box">
                                            <input type="checkbox" id="test171" name="radio-group-four" />
                                            <label for="test171" class="label">Add location to URL</label>
                                            </div>
                                        </li> 
                                        </ul>
                                    </div>
                                </span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Prefilling Locations in Fields on Pages</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Search</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test172" name="radio-group91" checked />
                                            <label for="test172" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test173" name="radio-group91" />
                                            <label for="test173" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Search on Map</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test174" name="radio-group92" checked />
                                            <label for="test174" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test175" name="radio-group92" />
                                            <label for="test175" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Posy an Ad</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test176" name="radio-group93" checked />
                                            <label for="test176" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test177" name="radio-group93" />
                                            <label for="test177" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Registration</span>
                                </div>
                                </div>                                              
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="test178" name="radio-group94" checked />
                                            <label for="test178" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="test179" name="radio-group94" />
                                            <label for="test179" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}

                    </div>
                    <div class="onliperacont">
                    <p>Note: The plugin only adds current/selected locations to location-related filds</p>
                    </div>
                    <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Maps
