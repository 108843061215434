import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";
import "./sendEmail.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SendEmail = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const [email, setEmail] = useState();
    
  const [subject, setSubject] = useState();
  const [emailBody, setEmailBody] = useState();

  console.log("props", props);

  useEffect(async () => {
    setEmail(props);
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!subject) {
        document.getElementById("subject-text").style.display = "block";
        return;
    }

    if (!emailBody) {
        document.getElementById("email-body-text").style.display = "block";
        return;
    }
   await axios
      .post(`${PORT}/sendReportProductEmail`, {
        params: {  email:email, subject:subject,emailBody:emailBody},
      })
      .then((response, err) => {
        if (response) {
          handleClose();
          history.push({
            pathname: "/ReportAd",
            MatchValue: "edit",
          });
        }
      });
  };

  const handleSubjectText = (event) => {
    document.getElementById("subject-text").style.display = "none";
    setSubject(event.target.value);
  };

  const handleEmailBodyText = (event) => {
    document.getElementById("email-body-text").style.display = "none";
    setEmailBody(event.target.value);
  };

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Send Email</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Subject</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                          <input type="text" class="fuilddesh" onChange={handleSubjectText} value={subject}/>
                        
                        <p
                          id="subject-text"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Subject is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Email Body</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                      <CKEditor
                            editor={ ClassicEditor }
                            data=""
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                document.getElementById("email-body-text").style.display = "none";
                                setEmailBody(data);
                            } }
                        />
                        <p
                          id="email-body-text"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Email Body is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  

                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SEND
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default SendEmail;

