import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from "react-html-parser";

const EditPage = ({props}) => {

    const PORT = PORTNUMBER.PORTNUMBER;

    console.log("props", props);
    // console.log("propsdata", props.match.params.id);
    // const id = props.match.params.id;


    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const [pageName, setPageName] = useState();
    const [pageTitle, setPageTitle] = useState();
    const [pageURL, setPageURL] = useState();
    const [pageDescription, setPageDescription] = useState();

 

    useEffect(async ()=>{
        await axios.post(`${PORT}/find_page`, {id:props}).then((response, err)=>{
            if(response)
            {
                setPageName(response.data.data.pageName);
                setPageTitle(response.data.data.pageTitle);
                setPageDescription(response.data.data.pageDescription);
            }
          })
    },[props])

    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(!pageTitle)
        {
            document.getElementById("page-title").style.display="block";
            return;
        }
        if(!pageDescription)
        {
            document.getElementById("page-description").style.display="block";
            return;
        }
       await axios.post(`${PORT}/update_page`, {id:props, pageName, pageTitle, pageDescription}).then((response, err)=>{ 
        if(response)
        {
          
            handleClose();
            history.push({pathname:"/Pages", MatchValue:"edit"});
        
        } 
        else{
            toast.info("Something went wrong");
        }     
      })
      }



    const handlePageName = (event) =>{
        document.getElementById("page-name").style.display="none";
        setPageName(event.target.value);

    }

    const handlePageTitle = (event) =>{
        document.getElementById("page-title").style.display="none";
        setPageTitle(event.target.value);
    }

    const handlePageURL = (event) =>{
        document.getElementById("page-url").style.display="none";
        setPageURL(event.target.value);
    }

    const handlePageDescription = (event, editor) =>{
        document.getElementById("page-description").style.display="none";
        setPageDescription(editor.getData());
    }

    const handleClose = (event) =>{
        document.body.classList.remove('fixedit_modal');
      }

  


    return (
        <div class="editfix main_bxshadow">
        <div class="headeredi_fix text-right">
         <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
         <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="table_fixe">
            <div class="labelsidetop">
              <div class="boxrepetform">
                <h4 class="tabcont_Sublable">Page Settings</h4>
                <div class="Tbaleflex">
                  <div class="Table">
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Page Name</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <input value={pageName} type="text" class="fuilddesh" placeholder="" readonly />
                          <p className="text-danger" id="page-name" style={{ display: "none" }}>Page Name is required*</p>
                        </div>
                      </div>
                    </div>
        

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Page Title</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <input onChange={handlePageTitle} value={pageTitle} type="text" class="fuilddesh" placeholder="" />
                          <p className="text-danger" id="page-title" style={{ display: "none" }}>Page Title is required*</p>
                        </div>
                      </div>
                    </div>

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Page Description</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <CKEditor
                        editor={ClassicEditor}
                        onChange={(e, editor) =>
                        handlePageDescription(e, editor)
                        }
                        data={pageDescription}
                        />
                             <p className="text-danger" id="page-description" style={{ display: "none" }}>Page Description is required*</p>
                        </div>
                      </div>
                    </div>


                   
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomsend_button text-center small_margin_top">
              <button type="submit" class="savesidebutt closefix">SAVE</button>
            </div>
          </div>
          </form>
          <ToastContainer />
        </div>
    )
}

export default EditPage


  // <div class="work_space">
        //     <div class="workspace_title_hd">
        //         <div class="row">
        //             <div class="col-md-12">
        //                 <div class="titleof_page">
        //                     <ul class="breadcrumb_list topsmall_bottom">
        //                         <li><a href="#">Admin Panel </a></li>
        //                         <li><i style={{ padding: "5px" }} class="fa fa-angle-right"></i></li>
        //                         <li>Edit Page</li>
        //                     </ul>
        //                     <h2 class="main_title">Edit Page</h2>
        //                 </div>
        //             </div>
        //             <div class="col-md-12">
        //                 <div class="main_oflistingForm bg_light_gray onlyforcol_support">
        //                     <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">

        //                         <form onSubmit={handleSubmit}>
        //                             <div class="row">
        //                                 <div class="col-md-6">
        //                                     <div class=" form-group">
        //                                         <h4 class="fieldsec_lab">Page Name<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
        //                                         <div class="field_sec_repetM">
        //                                             <div class="repwat_one">
        //                                                 <input value={pageName} type="text" class="fuilddesh" placeholder="Page Name" readonly />

        //                                             </div>
        //                                         </div>
        //                                         <p className="text-danger" id="page-name" style={{ display: "none" }}>Page Name is required*</p>
        //                                     </div>
        //                                 </div>
        //                                 <div class="col-md-6">

        //                                     <div class=" form-group">
        //                                         <h4 class="fieldsec_lab">Page Title<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
        //                                         <div class="field_sec_repetM">
        //                                             <div class="repwat_one">
        //                                                 <input onChange={handlePageTitle} value={pageTitle} type="text" class="fuilddesh" placeholder="Page Title" />

        //                                             </div>
        //                                         </div>
        //                                         <p className="text-danger" id="page-title" style={{ display: "none" }}>Page Title is required*</p>
        //                                     </div>

        //                                 </div>


        //                                 <div class="col-md-12">
        //                                     <div className=" form-group">
        //                                         <h4 className="fieldsec_lab">Page Description<p className="text-danger" style={{ display: "inline-block" }}>*</p></h4>
        //                                         <div className="field_sec_repetM">
        //                                             <div className="repwat_one">
        //                                                 <CKEditor
        //                                                     editor={ClassicEditor}
        //                                                     onChange={(e, editor) =>
        //                                                         handlePageDescription(e, editor)
        //                                                     }
        //                                                     data={pageDescription}
        //                                                 />
        //                                             </div>

        //                                         </div>
        //                                         <p
        //                                             className="text-danger"
        //                                             id="page-description"
        //                                             style={{ display: "none" }}
        //                                         >
        //                                             Page Description is required*
        //                                       </p>
        //                                     </div>
        //                                 </div>
        //                             </div>



        //                             <div className="bottomsend_button text-center small_margin_top">
        //                                 <button type="submit" className="savesidebutt closefix">Save</button>
        //                             </div>

        //                         </form>

        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     <ToastContainer />
        // </div>
