import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PORTNUMBER from "../../../../constant/api";
import Button from "react-bootstrap/Button";
import Pagination from "../../../pagination/pagination";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import ReactPaginate from "react-paginate";
import AllImages from "../../../../constant/img";
import FaqCategoriesAdd from "../faqCategoriesAdd/faqCategoriesAdd";
import FaqCategoriesEdit from "../faqCategoriesEdit/faqCategoriesEdit";

const FaqCategoriesList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allfaqcategories, setAllfaqcategories] = useState();
  const [Id, setId] = useState();

  const [deleteModal, setDeleteModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [deactiveModal, setDeactiveModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    GetAll();
  }, []);

  const GetAll = async () =>{
    await axios.get(`${PORT}/faqcategories`).then((response, err) => {
      setAllfaqcategories(response);
    });
  }

  useEffect(() => {
    if (typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "edit"){
       GetAll();
       toast.success("You have successfully edited FAQ Categories");
    }
  }, [typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "edit"]);


  
  useEffect(() => {
    if (typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "add"){
       GetAll();
       toast.success("You have successfully added FAQ Categories");
    }
  }, [typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "add"]);



  const handleClose = () => {
    setDeleteModal(false);
    setActiveModal(false);
    setDeactiveModal(false);
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleModalActive = (id) => {
    setActiveModal(true);
    setId(id);
  };

  const handleModalDeactive = (id) => {
    setDeactiveModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteCategory(Id);
    setDeleteModal(false);
  };

  const handleActiveYes = () => {
    handleClickActive(Id);
    setActiveModal(false);
  };

  const handleDeactiveYes = () => {
    handleClickDeactive(Id);
    setDeactiveModal(false);
  };

  const handleDeleteCategory = async (id) => {
    await axios
      .post(`${PORT}/faqcategories/delete`, { id })
      .then((response, err) => {
        toast.success("You have successfully deleted this faq categories");
      });
    await axios.get(`${PORT}/faqcategories`).then((response, err) => {
      setAllfaqcategories(response);
    });
  };

  const handleClickDeactive = async (id) => {
    await axios
      .post(`${PORT}/faqcategories/status`, { id, status: "Inactive" })
      .then((response, err) => {
        toast.success("You have successfully Inactivated this faq categories");
      });
    await axios.get(`${PORT}/faqcategories`).then((response, err) => {
      setAllfaqcategories(response);
    });
  };

  const handleClickActive = async (id) => {
    await axios
      .post(`${PORT}/faqcategories/status`, { id, status: "Active" })
      .then((response, err) => {
        toast.success("You have successfully activated this faq categories");
      });
    await axios.get(`${PORT}/faqcategories`).then((response, err) => {
      setAllfaqcategories(response);
    });
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount = Math.ceil(
    (allfaqcategories &&
      allfaqcategories.data &&
      allfaqcategories.data.data &&
      allfaqcategories.data.data.length) / postsPerPage
  ) || 1;

  const currentPosts =
    allfaqcategories &&
    allfaqcategories.data &&
    allfaqcategories.data.data &&
    allfaqcategories.data.data.slice(pagesVisited, pagesVisited + postsPerPage);
  // const currentPosts = allfaqcategories && allfaqcategories.data && allfaqcategories.data.data && allfaqcategories.data.data.slice(indexOfFirstPost, indexOfLastPost);
  console.log("sdbhdfhf", currentPosts);

  const paginate = (pagenumber) => {
    setCurrentPage(pagenumber);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleSelect = async (event) => {
    if (event.target.value == "all") {
      await axios.get(`${PORT}/faqcategories`).then((response, err) => {
        setAllfaqcategories(response);
      });
    } else if (event.target.value == "active") {
      await axios
        .post(`${PORT}/faqcategories/get_status`, { status: "Active" })
        .then((response, err) => {
          setAllfaqcategories(response);
        });
    } else if (event.target.value == "inactive") {
      await axios
        .post(`${PORT}/faqcategories/get_status`, { status: "Inactive" })
        .then((response, err) => {
          setAllfaqcategories(response);
        });
    }
  };



  const handleAdd = (event) =>{
    document.body.classList.add('fixedit_modal');
    setPropsId(undefined)
  }
  const handleEdit = (event, id) =>{
    document.body.classList.add('fixedit_modal');
    setPropsId(id)
  }

  return(
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>FAQ Categories</li>
              </ul>
              <h2 class="main_title">FAQ Categories</h2>
            </div>
            <div className="addpackk">
              <Link onClick={handleAdd} className="addpkgbtn savesidebutt">
                <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add Faq
                Category
              </Link>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  <div class="dropdownsid">
                    <select class="fuilddesh" onChange={handleSelect}>
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">Category Name</div>
                        <div class="table_cell">Status</div>
                        <div class="table_cell">Actions</div>
                      </div>

                      {currentPosts &&
                        currentPosts
                          .filter((val) => {
                            if (searchItem == null) {
                              return val;
                            } else if (
                              val.category_name
                                .toLowerCase()
                                .includes(searchItem.toLowerCase())
                            ) {
                              return val;
                            } else {
                              return false;
                            }
                          })
                          .map((faqcategories) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="table_cell">
                                  {faqcategories.category_name}
                                </div>
                                <div className="table_cell">
                                  {
                                    faqcategories.status == "Active"
                                    ?
                                    <span className="labltable lablesct">
                                    {faqcategories.status}
                                  </span>
                                    :
                                    <span className="labltable lablesct_red">
                                    {faqcategories.status}
                                  </span>
                                  }
                                </div>
                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      // to={
                                      //   "/faq-editcategories/" +
                                      //   faqcategories._id
                                      // }
                                      onClick={(e)=>handleEdit(e, faqcategories._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </Link>
                                  </span>

                                  {faqcategories.status == "Active" ? (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Inactive"
                                        id="deactive"
                                        onClick={() =>
                                          handleModalDeactive(faqcategories._id)
                                        }
                                      >
                                        <i class="fa fa-ban"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        id="active"
                                        data-placement="top"
                                        title="Active"
                                        onClick={() =>
                                          handleModalActive(faqcategories._id)
                                        }
                                      >
                                        <i class="fa fa-check-circle-o"></i>
                                      </a>
                                    </span>
                                  )}

                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        handleModalDelete(faqcategories._id)
                                      }
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal isOpen={deleteModal}>
      <div class="modalChild">
      <div class="modalchildPadding">
        <h6>Do you want to delete this faq categories?</h6>
        </div>
        <Button
          onClick={handleDeleteYes}
          className="savesidebutt"
          variant="primary"
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          className="savesidebutt close-savesidebutt"
          variant="secondary"
        >
          Close
        </Button>
        </div>
      </Modal>

      <Modal isOpen={activeModal}>
      <div class="modalChild">
      <div class="modalchildPadding">
        <h6>Do you want to active this faq categories?</h6>
        </div>
        <Button
          onClick={handleActiveYes}
          className="savesidebutt"
          variant="primary"
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          className="savesidebutt close-savesidebutt"
          variant="secondary"
        >
          Close
        </Button>
        </div>
      </Modal>

      <Modal isOpen={deactiveModal}>
      <div class="modalChild">
      <div class="modalchildPadding">
        <h6>Do you want to Inactive this faq categories?</h6>
        </div>
        <Button
          onClick={handleDeactiveYes}
          className="savesidebutt"
          variant="primary"
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          className="savesidebutt close-savesidebutt"
          variant="secondary"
        >
          Close
        </Button>
        </div>
      </Modal>
      {
        propsid == undefined
        ?
        <FaqCategoriesAdd />
        :
        <FaqCategoriesEdit props={propsid} />
      }
    </div>
  ) 
};

export default FaqCategoriesList;
