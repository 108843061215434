import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const SearchByDistance = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [distanceRange,setDistanceRange] = useState();
    const [defaultDistance,setDefaultDistance] = useState();
    const [searchMode,setSearchMode] = useState();
    const [distanceUnit,setDistanceUnit] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/searchByDistance/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setDistanceRange(res.data.data[0].common_setting[0].distanceRange);
                    setDefaultDistance(res.data.data[0].common_setting[0].defaultDistance);
                    setSearchMode(res.data.data[0].common_setting[0].searchMode);
                    setDistanceUnit(res.data.data[0].common_setting[0].distanceUnit);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
       
        var common_settings         = [ {
                                        'distanceRange': distanceRange,
                                        'defaultDistance':defaultDistance,
                                        'searchMode':searchMode,
                                        'distanceUnit':distanceUnit,
                                    }];
       
        if(slug == 'SearchByDistance'){
            await axios.post(`${PORT}/searchByDistance/update`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/searchByDistance/create`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();

    }
  
    const handleDistanceRange = (event) =>{
        setDistanceRange(event.target.value);
    }
    const handleDefaultDistance = (event) =>{
        setDefaultDistance(event.target.value);
    }
    const handleSearchMode = (event) =>{
        setSearchMode(event.target.value);
    }
    const handleDistanceUnit = (event) =>{
        setDistanceUnit(event.target.value);
    }
    return (
        <div class="tab-pane" id="basic017" role="tabpanel">
            <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Search by Distance</span></h3>
                <div class="labelsidetop">
                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Common Settings</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Distance range</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="number" value={distanceRange} onChange={handleDistanceRange} class="fuilddesh" name="distanceRange" placeholder="" />
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Default distance</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="number" value={defaultDistance} onChange={handleDefaultDistance} class="fuilddesh" name="defaultDistance" placeholder="" />
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Search mode</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid radioequal_flex">
                                    <select class="fuilddesh" value={searchMode} onChange={handleSearchMode}>
                                    <option value="">Select</option>
                                        <option value="Mixed">Mixed</option>
                                    </select>
                                </div>
                                <span class="equalspan_cont">zip- search by zip code only,mixed-search by locations or zip cods</span>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Distance unit</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid radioequal_flex">
                                    <select class="fuilddesh" value={distanceUnit} onChange={handleDistanceUnit}>
                                    <option value="">Select</option>
                                        <option value="auto">auto</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    {/* <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Listing Fields Mapping</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Zip code field</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <select class="fuilddesh">
                                        <option value="">Zip Code</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Country field</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid radioequal_flex">
                                    <select class="fuilddesh">
                                        <option value="">Country</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Default country</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid radioequal_flex">
                                    <select class="fuilddesh">
                                        <option value="">United States</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Accounts Fields Mapping</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Zip code field</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <select class="fuilddesh">
                                        <option value="">Zip Code</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Country field</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid radioequal_flex">
                                    <select class="fuilddesh">
                                        <option value="">Country</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Listing Settings</h4>
                        <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Single search limit</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <input type="text" class="fuilddesh" name="" placeholder="" />
                                    <span class="equalspan_cont">listings</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div> */}

                    </div>
                    <div class="bottomsend_button text-center">
                        <button type="submit" class="savesidebutt">SAVE</button>
                        <ToastContainer />
                    </div>
                </form>
            </div>
        
    )
}

export default SearchByDistance;
