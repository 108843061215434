import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from "react-html-parser";

const AddPage = () => {

    const PORT = PORTNUMBER.PORTNUMBER;


    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const [pageName, setPageName] = useState();
    const [pageTitle, setPageTitle] = useState();
    const [pageURL, setPageURL] = useState();
    const [pageDescription, setPageDescription] = useState();

 

    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(!pageName)
        {
            document.getElementById("page-name").style.display="block";
            return;
        }
        if(!pageTitle)
        {
            document.getElementById("page-title").style.display="block";
            return;
        }
        if(!pageDescription)
        {
            document.getElementById("page-description").style.display="block";
            return;
        }
        if(!pageURL)
        {
            document.getElementById("page-url").style.display="block";
            return;
        }
        await axios.post(`${PORT}/addPage`, {pageName, pageTitle, slug: pageURL, pageDescription}).then((response, err)=>{ 
        if(response)
        {
          history.push({pathname:"/Pages", customdata:{pathname}});
        } 
        else{
            toast.info("Something went wrong");
        }     
      })
      }

    const handleReset = (event) =>{
     setPageName(" ");
     setPageTitle(" ");
     setPageURL(" ");
     setPageDescription(" ");
    }



    const handlePageName = (event) =>{
        document.getElementById("page-name").style.display="none";
        setPageName(event.target.value);
    }

    const handlePageTitle = (event) =>{
        document.getElementById("page-title").style.display="none";
        setPageTitle(event.target.value);
        let data = event.target.value;
        let data1 = data.toLowerCase();

        data1 = data1.replace(/\s/g, "-");
        setPageURL(data1)
    }

    const handlePageURL = (event) =>{
        document.getElementById("page-url").style.display="none";
    }

    const handlePageDescription = (event, editor) =>{
        document.getElementById("page-description").style.display="none";
        setPageDescription(editor.getData());
    }

  


    return (
      <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
            <div class="col-md-12">
              <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li>
                    <a href="#">Admin Panel </a>
                  </li>
                  <li>
                    <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                  </li>
                  <li>Add Page</li>
                </ul>
                <h2 class="main_title">Add Page</h2>
              </div>
            </div>
            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                    <div class="col-md-6">
                    <div class=" form-group">
                      <h4 class="fieldsec_lab">
                        Page Name<p className="text-danger" style={{display:"inline-block"}}>*</p>
                      </h4>
                      <div class=" ">
                        <div class="repwat_one">
                          <input
                            onChange={handlePageName}
                            type="text"
                            class="fuilddesh1"
                            placeholder="Page Name"
                            value={pageName}
                          />
                        
                        </div>
                      </div>
                      <p
                        className="text-danger"
                        id="page-name"
                        style={{ display: "none" }}
                      >
                        Page Name is required*
                      </p>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class=" form-group">
                      <h4 class="fieldsec_lab">
                        Page Title<p className="text-danger" style={{display:"inline-block"}}>*</p>
                      </h4>
                      <div class=" ">
                        <div class="repwat_one">
                          <input
                            onChange={handlePageTitle}
                            type="text"
                            class="fuilddesh1"
                            placeholder="Page Title"
                            value={pageTitle}
                          />
                         
                        </div>
                      </div>
                      <p
                        className="text-danger"
                        id="page-title"
                        style={{ display: "none" }}
                      >
                        Page Title is required*
                      </p>
                    </div>
                    </div>
                   
                    <div style={{display:"none"}} class="col-md-6">
                    <div class=" form-group">
                      <h4 class="fieldsec_lab">
                        Page URL<p className="text-danger" style={{display:"inline-block"}}>*</p>
                      </h4>
                      <div class=" ">
                        <div class="repwat_one">
                          <input
                            onChange={handlePageURL}
                            type="text"
                            class="fuilddesh1"
                            placeholder="Page URL"
                            value={pageURL}
                          />
                        
                        </div>
                      </div>
                      <p
                        className="text-danger"
                        id="page-url"
                        style={{ display: "none" }}
                      >
                        Page URL is required*
                      </p>
                    </div>
</div>
<div class="col-md-12">
                    <div className=" form-group">
                      <h4 className="fieldsec_lab">Page Description<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                      <div className=" ">
                        <div className="repwat_one">
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(e, editor) =>
                            handlePageDescription(e, editor)
                            }
                          />
                        </div>
                       
                      </div>
                      <p
                        className="text-danger"
                        id="page-description"
                        style={{ display: "none" }}
                      >
                        Page Description is required*
                      </p>
                    </div>
                    </div>
</div>
                    <div className="bottomsend_button text-center small_margin_top">
                      <button type="submit" className="savesidebutt closefix">
                        Save
                      </button>
                      <button
                        onClick={handleReset}
                        type="button"
                        className="savesidebutt closefix"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
}

export default AddPage
