import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";
import AllImages from "../../../constant/img";
import "./addWeeklyDeals.css";
import Resizer from "react-image-file-resizer";
import DatePicker from "react-multi-date-picker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
const AddWeeklyDeals = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const [allProducts,setAllProducts] = useState([]);
  const [endDate,setEndDate] = useState();
  const [product,setProduct] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [selectedProducts,setSelectedProducts] = useState([]);
  useEffect(() => {
    axios.get(`${PORT}/weeklydeals/getAllProducts`).then((response, err) => {
     setAllProducts(response.data);
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (product.length == 0) {
      document.getElementById("product-text").style.display = "block";
      return;
    }

    if(discountList){
      for(var i=0;i<discountList.length;i++){
        if(discountList[i].options == ''){
          document.getElementById("discount-list_"+i).style.display = "block";
          return;
        }
      }
    }
    
    if (!endDate) {
      document.getElementById("end-date").style.display = "block";
      return;
    }

    await axios
      .post(`${PORT}/weeklydeals/create`, { product:product,endDate,discountList:JSON.stringify(discountList)} )
      .then((response, err) => {
        if (response) {
          handleClose();
          setSelectedProducts([]);
          setEndDate("");
          setDiscountList([]);
          history.push({
            pathname: "/WeeklyDeals",
            MatchValue: "add",
          });
        } else {
          toast.info("Something went wrong");
          return;
        }
      });
  };

  const handleClose = (event) => {
    setSelectedProducts([]);
    setEndDate("");
    setDiscountList([]);
    document.body.classList.remove("fixedit_modal");
  };

  const handleEndDate = async (date) => { 
    setEndDate(date);
    document.getElementById("end-date").style.display = "none";
  };

  const handleProduct = (event,value) => {
    document.getElementById("product-text").style.display = "none";
    event.preventDefault();
    let selectedProdcutIds = [];
    let selectedProdcuts = [];
    value.forEach((val) => {
      selectedProdcuts.push(val);
      selectedProdcutIds.push(val._id);
    });
    setProduct(selectedProdcutIds);
    setSelectedProducts(selectedProdcuts);
  }

  const handleAddDiscountOption = () => {
    setDiscountList([...discountList, {options: ""}])
  };

  const handleRemoveDiscountOption = (i) => {
    let newDiscountList = [...discountList];
    newDiscountList.splice(i, 1);
    setDiscountList(newDiscountList);
  }

  
  const handleDiscountChange = (i, e) => {
    let newDiscountList = [...discountList];
    if(e.target.name == 'image'){
      newDiscountList[i][e.target.name] = URL.createObjectURL(e.target.files[0]);
    }else{
      newDiscountList[i][e.target.name] = e.target.value;
    }
    setDiscountList(newDiscountList);
  }
  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Add Deals</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Product</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                      <Autocomplete
                          multiple
                          onChange={
                            handleProduct
                          }
                          value={selectedProducts}
                          options={allProducts}
                          getOptionLabel={(option) =>
                            option.productName
                          }
                          
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Select Products"
                            />
                          )}
                        />
                        <p
                          id="product-text"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Product is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Discount</span> 
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <button type="button" onClick={handleAddDiscountOption} className="addDiscountOption">
                          <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add Options
                        </button>
                        {discountList.map((element, index) => (
                            <div id={"attribute_row_"+index} className="table_row">
                              <div className="table_cell">
                                <input type="number" name="options" class="fuilddesh" placeholder="%" onChange={e => handleDiscountChange(index, e)}/>
                                <div> <p id={"discount-list_"+index} className="text-danger discount-option-required" style={{ display: "none" }} >Option is required*</p></div>
                              </div>
                              <div className="table_cell">
                                <a onClick={() => handleRemoveDiscountOption(index)}><i class="fa fa-trash " ></i></a>
                              </div>
                            </div> 
                          ))}
                        
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>End Date</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                            <DatePicker minDate={new Date()} inputClass="fuilddesh" required id="endDate" placeholder="Select Date" value={endDate} onChange={handleEndDate} />

                        <p
                          id="end-date"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          End Date is required*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddWeeklyDeals;

