import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useHistory, useLocation, Redirect, Link } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import UserAccount from "../userAccount/userAccount";

const EditUser = ({props}) => {

  console.log("props", props);
  const id = props;
  const PORT = PORTNUMBER.PORTNUMBER;


  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [seller, setSeller] = useState();


  const history = useHistory();

  useEffect(async () => {
    await axios.post(`${PORT}/getUser`, { params: { id } }).then((res, err) => {
      console.log("edituser", res);
      setFirstName(res.data[0].firstName);
      setLastName(res.data[0].lastName);
      setEmail(res.data[0].email);
      setPhoneNumber(res.data[0].mobileNumber);
      setSeller(res.data[0].sellerType);
    })
  }, [props])


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!firstName) {
      document.getElementById("first-name").style.display = "block";
      return;
    }
    if (!lastName) {
      document.getElementById("last-name").style.display = "block";
      return;
    }
    if (!email) {
      document.getElementById("email").style.display = "block";
      return;
    }
    if (email) {
      let emailResult = validateEmail(email);
      if (!emailResult) {
        document.getElementById("email").style.display = "block";
        return;
      }
    }
    if(!seller)
    {
      document.getElementById("seller").style.display="block";
      return;
    }
    else{
      await axios.post(`${PORT}/updateUser`, {params:{id, firstName, lastName, email, phoneNumber, seller}}).then(async (res, err) =>{
        console.log(res);
        if(res)
        {
          handleClose();
          history.push({pathname:"/accounts", MatchValue:'edit'});
        }
       })
    }
  }




  const handleFirstName = (event) => {
    event.preventDefault();
    document.getElementById("first-name").style.display = "none";
    setFirstName(event.target.value);
    console.log("f");
  }
  const handleLastName = (event) => {
    event.preventDefault();
    document.getElementById("last-name").style.display = "none";
    setLastName(event.target.value);
  }
  const handleEmail = (event) => {
    event.preventDefault();
    document.getElementById("email").style.display = "none";
    setEmail(event.target.value);
  }
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  const handlePhoneNumber = (event) => {
    event.preventDefault();
    document.getElementById("phone-number").style.display = "none";
    setPhoneNumber(event.target.value);
  }
  const handleSeller = (event) =>{
    console.log(event.target.value)
    document.getElementById("seller").style.display="none";
    if(event.target.value)
    {
      setSeller(event.target.value);
    }
  }

  const handleClose = async (event) =>{
    document.body.classList.remove('fixedit_modal');
  }

  return (
            <div class="editfix main_bxshadow">
            <div class="headeredi_fix text-right">
             <a href="/userAccount" class="fixhbtn"><i class="fa fa-check"></i></a>
             <Link onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></Link>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="table_fixe">
                <div class="labelsidetop">
                  <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Profile Settings</h4>
                    <div class="Tbaleflex">
                      <div class="Table">
                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>First Name</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleFirstName} type="text" class="fuilddesh" name="" placeholder="" value={firstName}/>
                              <p id="first-name" style={{display:"none"}} className="text-danger">First Name is required*</p>
                            </div>
                          </div>
                        </div>
            

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Last Name</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleLastName} type="text" class="fuilddesh" name="" placeholder="" value={lastName} />
                              <p id="last-name" style={{display:"none"}} className="text-danger">Last Name is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Email</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleEmail} type="text" class="fuilddesh" name="" placeholder="" value={email} />
                              <p id="email" style={{display:"none"}} className="text-danger">Valid Email Address is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Mobile Number</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handlePhoneNumber} type="number" class="fuilddesh" name="" placeholder="" value={phoneNumber}/>
                              <p id="phone-number" style={{display:"none"}} className="text-danger">Mobile Number is required*</p>
                            </div>
                          </div>
                        </div>


                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Seller Type</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <div class="form_group">
                              
                               <ul class="list">
                               <li class="list__item">
                               <input onChange={handleSeller} value="Private" type="radio" id="test44" name="radio-group44" checked={seller === 'Private'}  />
                               <label for="test44" class="label">Private</label>
                             </li>
                             <li class="list__item">
                               <input onChange={handleSeller} value="Company" type="radio" id="test45" name="radio-group44" checked={seller === 'Company'}/>
                               <label for="test45" class="label">Company</label>
                             </li>
                             <p id="seller" style={{display:"none"}} className="text-danger">seller type is required*</p>
                             </ul>
                              
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottomsend_button text-center small_margin_top">
                  <button type="submit" class="savesidebutt closefix">SAVE</button>
                </div>
              </div>
              </form>
              <ToastContainer />
            </div>
  )
}
export default EditUser

