import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";

const FaqSubcategoriesEdit = ({props}) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allfaqcategories, setAllfaqcategories] = useState();
  const [category_name, setCategory] = useState();
  const [sub_category_name, setSubcategory] = useState();


  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const id = props;


  useEffect(async ()=>{
    await axios.get(`${PORT}/faqcategories`).then((response, err)=>{
        setAllfaqcategories(response);
    })
  },[])


  useEffect(async ()=>{
    await axios.post(`${PORT}/faqsubcategories/singlerecord`, {id}).then((response, err)=>{
        console.log("allfaqcateall", response);
        setCategory(response.data.data.faqcategoryId);
        setSubcategory(response.data.data.sub_category_name);
    })
  },[props])



  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!category_name)
    {
        document.getElementById("category-name").style.display="block";
        return;
    }
    if(!sub_category_name)
    {
       document.getElementById("subcategory-name").style.display="block";
       return;
    }
      await axios
        .post(`${PORT}/faqsubcategories/update`,
        {id, sub_category_name, faqcategoryId: category_name},
        )
        .then((res, err) => {
          if(res)
          {
            handleClose();
            history.push({ pathname: "/faq-subcategories", MatchValue:"edit" });
          }
        });
    }

  const handleSubCategory = (event) => {
    event.preventDefault();
    document.getElementById("subcategory-name").style.display="none";
    setSubcategory(event.target.value);
  };

  const handleCategory = (event) =>{
    event.preventDefault();
    document.getElementById("category-name").style.display="none";
    setCategory(event.target.value);
  }


  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) =>{
    document.body.classList.remove('fixedit_modal');
  }



  return (
    <div class="editfix main_bxshadow">
    <div class="headeredi_fix text-right">
     <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
     <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
    </div>
    <form onSubmit={handleSubmit}>
      <div class="table_fixe">
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">FAQ Sub categories Settings</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Category</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <select
                          onChange={handleCategory}
                          value={category_name}
                          class="fuilddesh"
                        >
                          <option value={""}>{"SELECT CATEGORY"}</option>
                          {allfaqcategories &&
                            allfaqcategories.data &&
                            allfaqcategories.data.data &&
                            allfaqcategories.data.data.map((category) => {
                              return (
                                <option value={category._id}>{category.category_name}</option>
                              );
                            })}
                        </select>
                        <p
                      className="text-danger"
                      id="category-name"
                      style={{ display: "none" }}
                    >
                      Category Name is required*
                    </p>
                    </div>
                  </div>
                </div>
    

                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Sub category</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <input
                          onChange={handleSubCategory}
                          type="text"
                          className="fuilddesh"
                          placeholder=""
                          value={sub_category_name}
                        />
                         <p
                      className="text-danger"
                      id="subcategory-name"
                      style={{ display: "none" }}
                    >
                      Sub category Name is required
                    </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center small_margin_top">
          <button type="submit" class="savesidebutt closefix">SAVE</button>
        </div>
      </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default FaqSubcategoriesEdit;
