// const PORTNUMBER = "http://192.168.235.245:6030";
const PORTNUMBER = "https://dlevelns.stage02.obdemo.com";

const API = {
  PORTNUMBER: `${PORTNUMBER}`,
  LOGIN_API: `${PORTNUMBER}/adminLogin`,
  FORGETPASSWORD_API: `${PORTNUMBER}/forgetPassword`,
  RESETPASSWORD_API: `${PORTNUMBER}/resetPassword`,
};
export default API;
