import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const FAQ = ({props}) => {

  //  console.log("categorypropsdata",props);
    const allPathEdit = props.split("/");
   // console.log("allpath", allPathEdit);
  
  
    useEffect(() => {
      if(props == "/faq" || props == "/faq-add" || allPathEdit[1] == "faq-edit" || props == "/faq-categories" || props == "/faq-addcategories" || allPathEdit[1] == "faq-editcategories" || props == "/faq-subcategories" || props == "/faq-addsubcategories" || allPathEdit[1] == "faq-editsubcategories")
      {
        document.getElementById("collapseFAQ").classList.add("show");
      }
   },[])


    return (
      <li className="sidebar-dropdown mabot_mainli">
        <Link to="#" data-toggle="collapse" data-target="#collapseFAQ">
          <span>FAQ</span>
          <i className="fa fa-tags"></i>
        </Link>
        <div
          className="sidebar-submenu"
          id="collapseFAQ"
          class="collapse"
          data-parent="#accordion"
        >
          <ul>
            <li>
              <Link to="/faq-categories">Faq categories</Link>
            </li>
            <li>
              <Link to="/faq-subcategories">Faq sub categories</Link>
            </li>
            <li>
              <Link to="/faq">Faq</Link>
            </li>
          </ul>
        </div>
      </li>
    );
}

export default FAQ
