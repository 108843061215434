import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./subCategoryList.css";
import PORTNUMBER from "../../../constant/api";
import Modal from "react-modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import ReactPaginate from "react-paginate";
import EditSubCategory from "../editSubCategory/editSubCategory";

const SubCategoryList = (props) => {
  console.log("customfieldprops", props.location.customdata);

  const PORT = PORTNUMBER.PORTNUMBER;

  const [allSubCategory, setAllSubCategory] = useState();

  console.log(allSubCategory, "allSubCategory");
  const [firstsubbtuton, setFirstsubbutton] = useState();
  const [firstsubcat, setFirstsubcat] = useState();
  const [secondsubbutton, setSecondsubbutton] = useState();
  const [secondsubcat, setSecondsubcat] = useState();
  const [thirdsubbutton, setThirdsubbutton] = useState();
  const [thirdsubcat, setThirdsubcat] = useState();

  const [catModal, setSubCatModal] = useState(false);
  const [firstModal, setFirstModal] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [thirdModal, setThirdModal] = useState(false);
  const [id, setId] = useState();

  const [statusFilter, setStatusFilter] = useState();
  const [categoryLabel, setCategoryLabel] = useState();

  const [firstCategoryId, setfirstCategoryId] = useState();
  const [secondCategoryId, setsecondCategoryId] = useState();
  const [thirdCategoryId, setthirdCategoryId] = useState();

  const [modalActive, setModalActive] = useState(false);
  const [modalDeactive, setModalDeactive] = useState(false);

  const [deleteFrom, setDeleteFrom] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  const [categoryId, setSelectCategory] = useState();
  const [allCategory, setAllCategory] = useState([{ value: null }]);
  const [allSubCategorysearch, setAllSubCategorysearch] = useState(null);

  console.log(currentPage, "currentPage");

  // category
  useEffect(() => {
    GetAll(); // calls function for get all subcategories listing data.
    getCategoryListing(); // calls function for get main category dropdown filter.
  }, [statusFilter, firstCategoryId, searchItem, categoryLabel]);

  const generateQueryString = (filter) => {
    const searchParams = new URLSearchParams();
    const params = filter;
    Object.keys(filter).forEach((key) => searchParams.append(key, params[key]));
    return searchParams.toString();
  };

  const GetAll = async () => {
    let filter = {};
    if (firstCategoryId) {
      filter.id = firstCategoryId;
    }
    if (statusFilter) {
      if (statusFilter == "active") {
        filter.status = "Active";
      } else if (statusFilter == "inactive") {
        filter.status = "Inactive";
      }
    }
    if (searchItem) {
      filter.search = searchItem;
    }
    if (categoryLabel) {
      filter.label = categoryLabel;
    }

    let queryStr = generateQueryString(filter);
    await axios.get(`${PORT}/getSubCategorys?${queryStr}`).then((res, err) => {
      setAllSubCategory(res);
    });
  };

  const getCategoryListing = (catgId) => {
    axios
      .get(`${PORT}/getCategorys` + "?id=" + catgId)
      .then((response, err) => {
        setAllCategory(response);
        // console.log(response)
      });
  };

  const handleSearch = async (e, fieldName) => {
    let val = e.target.value;

    if (fieldName == "search") {
      setSearchItem(val);
    } else if (fieldName == "category") {
      setfirstCategoryId(val);
    } else if (fieldName == "status") {
      setStatusFilter(val);
    } else if (fieldName == "label") {
      setCategoryLabel(val);
    }
    //}
  };

  useEffect(() => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit"
    ) {
      GetAll();
      toast.success("You have successfully edited subcategory");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit",
  ]);

  const handleDelete = async (id) => {
    await axios
      .post(`${PORT}/deleteSubCategory`, { params: { id } })
      .then((res, err) => {
        toast.success("You have successfully deleted this sub category");
      });
    GetAll();
  };

  const handleModalSubCategory = (id) => {
    setSubCatModal(true);
    setId(id);
  };

  const handleCategoryModalYes = () => {
    handleDelete(id);
    setSubCatModal(false);
  };

  const handleModalClose = () => {
    setSubCatModal(false);
  };

  const handleClickDeactive = (id) => {
    setModalDeactive(true);
    setId(id);
  };
  const handleClickActive = (id) => {
    setModalActive(true);
    setId(id);
  };
  const handleYesDeactive = async () => {
    await axios
      .post(`${PORT}/update/status`, { params: { id, status: "Inactive" } })
      .then((res, err) => {
        toast.success("You have successfully deactived this sub category");
        setModalDeactive(false);
      });
    GetAll();
  };
  const handleYesActive = async () => {
    await axios
      .post(`${PORT}/update/status`, { params: { id, status: "Active" } })
      .then((res, err) => {
        toast.success("You have successfully activated this sub category");

        setModalActive(false);
      });
    GetAll();
  };
  const handleClose = () => {
    setModalActive(false);
    setModalDeactive(false);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount =
    Math.ceil(
      (allSubCategory && allSubCategory.data && allSubCategory.data.length) /
        postsPerPage
    ) || 1;

  console.log(currentPage, "currentPage");

  const currentPosts =
    allSubCategory &&
    allSubCategory.data &&
    allSubCategory.data.slice(pagesVisited, pagesVisited + postsPerPage);

  console.log("currentPosts", currentPosts);

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(postsPerPage, "pageCount");
    setCurrentPage(selected);
  };

  const handleEdit = (event, id) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(id);
  };

  return (
    <div className="work_space">
      <div className="workspace_title_hd">
        <div className="row">
          <div className="col-md-12">
            <div className="titleof_page">
              <ul className="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i
                    style={{ padding: "5px" }}
                    className="fa fa-angle-right"
                  ></i>
                </li>
                <li>Sub categories</li>
              </ul>
              <h2 className="main_title">Sub categories</h2>
            </div>
            <div className="addpackk">
              <Link to="/sub-categories/add" className="addpkgbtn savesidebutt">
                <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add Sub
                category
              </Link>
            </div>
          </div>

          <div className="col-md-12">
            <div className="main_oflistingForm bg_light_gray onlyforcol_support">
              <div className="inner_listing_formS">
                <div className="left_innserSearchone">
                  <div className="dropdownsid">
                    <select
                      className="fuilddesh"
                      onChange={(e) => handleSearch(e, "status")}
                    >
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>

                <div className="left_innserSearch">
                  <div className="srch_fuildsecT leftlabs">
                    <div className="leftlabs listing_title">Categories:</div>
                    <div className="lefelab_fuild">
                      <select
                        onChange={(e) => handleSearch(e, "category")}
                        value={categoryId}
                        className="fuilddesh1"
                      >
                        <option value={""}>{"SELECT CATEGORY"}</option>
                        {allCategory &&
                          allCategory.data &&
                          allCategory.data.map((cat) => {
                            return (
                              <option value={cat._id}>
                                {cat.categoryName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="left_innserSearch">
                  <div className="srch_fuildsecT leftlabs">
                    <div className="leftlabs listing_title">Label:</div>
                    <div className="lefelab_fuild">
                      <select
                        className="fuilddesh1"
                        onChange={(e) => handleSearch(e, "label")}
                      >
                        <option value={""}>{"SELECT LABEL"}</option>

                        <option value="2nd">2nd</option>
                        <option value="3rd">3rd</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* <div className="left_innserSearch">
                  <div className="srch_fuildsecT leftlabs">
                    <div className="leftlabs listing_title">Category:</div>
                    <div className="lefelab_fuild">
                      <input
                        onChange={searchCategory}
                        type="text"
                        className="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div> */}
                <div className="left_innserSearch">
                  <div className="srch_fuildsecT leftlabs">
                    <div className="leftlabs listing_title">Search:</div>
                    <div className="lefelab_fuild">
                      <input
                        onChange={(e) => handleSearch(e, "search")}
                        type="text"
                        className="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} className="tabscroll_perent">
                <div className="tabscroll_inner">
                  <div className="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div className="tableflex topsmall_margin_top">
                      <div className="tableHead_row">
                        <div className="table_cell">
                          <div className="checkBtabls">
                            <ul>
                              <li>
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="table_cell">Category</div>
                        <div className="table_cell">Sub Category Name</div>
                        <div className="table_cell">Parent Category Name</div>
                        <div className="table_cell">Label</div>
                        <div className="table_cell">Status</div>
                        <div className="table_cell">Actions</div>
                      </div>

                      {currentPosts &&
                        currentPosts
                          // .filter((val) => {
                          //   if (searchItem == null) {
                          //     return val;
                          //   } else if (
                          //     val.categoryId.categoryName
                          //       .toLowerCase()
                          //       .includes(searchItem.toLowerCase())
                          //   ) {
                          //     return val;
                          //   } else {
                          //     return false;
                          //   }
                          // })

                          .map((cate) => {
                            return (
                              <div className="table_row">
                                <div className="table_cell">
                                  <div className="checkBtabls">
                                    <ul>
                                      <li>
                                        <div className="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="table_cell">
                                  {cate?.categoryId?.categoryName}
                                </div>
                                <div className="table_cell">
                                  {cate.subCategoryName}
                                </div>
                                <div className="table_cell">
                                  {cate.parentId?.subCategoryName !== undefined
                                    ? cate.parentId.subCategoryName
                                    : "__"}
                                </div>
                                <div className="table_cell">{cate.label}</div>
                                <div className="table_cell">
                                  {cate.status == "Active" ? (
                                    <span className="labltable lablesct">
                                      {cate.status}
                                    </span>
                                  ) : (
                                    <span className="labltable lablesct_red">
                                      {cate.status}
                                    </span>
                                  )}
                                </div>
                                <div className="table_cell">
                                  <span className="table_bbtnn">
                                    <Link
                                      onClick={(e) => handleEdit(e, cate._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i className="fa fa-pencil"></i>
                                    </Link>
                                  </span>

                                  {cate.status == "Active" ? (
                                    <span className="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Inactive"
                                        id="deactive"
                                        onClick={() =>
                                          handleClickDeactive(cate._id)
                                        }
                                      >
                                        <i className="fa fa-ban"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span className="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        id="active"
                                        data-placement="top"
                                        title="Active"
                                        onClick={() =>
                                          handleClickActive(cate._id)
                                        }
                                      >
                                        <i className="fa fa-check-circle-o"></i>
                                      </a>
                                    </span>
                                  )}

                                  <span className="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        handleModalSubCategory(cate._id)
                                      }
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                    >
                                      <i className="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="showingflexx">
              <div className="showingleft">
                {/* {console.log("showing entry",allSubCategory?.data?.length)}
                Showing {currentPage + 1}-{(postsPerPage)} of {allSubCategory?.data?.length} */}
                Showing{" "}
                {allSubCategory?.data?.length > 0 ? 1 + postsPerPage * (currentPage ) : 0} To{" "}
                {allSubCategory?.data?.length < postsPerPage
                  ? allSubCategory?.data?.length
                  :  postsPerPage * (currentPage * 1 + 1)} {""}
                of {allSubCategory?.data?.length} 
              </div>
              <div className="left_paginistion">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Modal isOpen={catModal}>
        <div className="modalChild">
          <div className="modalchildPadding">
            <h6>Do you want to delete this Sub category?</h6>
          </div>
          <Button
            onClick={handleCategoryModalYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleModalClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={modalActive}>
        <div className="modalChild">
          <div className="modalchildPadding">
            <h6>Do you want to Active this Sub category?</h6>
          </div>
          <Button
            onClick={handleYesActive}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={modalDeactive}>
        <div className="modalChild">
          <div className="modalchildPadding">
            <h6>Do you want to Inactive this Sub category?</h6>
          </div>
          <Button
            onClick={handleYesDeactive}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>
      {propsid == undefined ? null : <EditSubCategory props={propsid} />}
    </div>
  );
};

export default SubCategoryList;
