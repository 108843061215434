import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
// import Pagination from "../pagination/pagination";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import Modal from "react-modal";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
// import Modal from "react-bootstrap/Modal";
// import ModalDialog from 'react-bootstrap/ModalDialog';
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
import PlanAdd from "../planAdd/planAdd";
import PlanEdit from "../planEdit/planEdit";

const PlanList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [AllPlans, setAllPlans] = useState(["not"]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  const [id, setId] = useState();
  const [modalActive, setModalActive] = useState(false);
  const [modalDeactive, setModalDeactive] = useState(false);

  useEffect(() => {
    if (typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "add") {
        GetAll();
        toast.success("You have successfully added plan");
    }
  }, [typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "add"]);

  useEffect(() => {
    if (typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "edit") {
        GetAll();
        toast.success("You have successfully edited plan");
    }
  }, [typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "edit"]);

  useEffect(() => {
   GetAll();
  }, []);

  const GetAll = async () =>{
    await axios.get(`${PORT}/plan/get_all`).then((res, err) => {
      setAllPlans(res);
    });
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  console.log("currentPosts", indexOfLastPost);
  console.log("sdbhdfhf", indexOfFirstPost);

  const pagesVisited = currentPage * postsPerPage;
  const pageCount = Math.ceil(
    (AllPlans &&
      AllPlans.data &&
      AllPlans.data.data &&
      AllPlans.data.data.length) / postsPerPage
  ) || 1;

  const currentPosts =
    AllPlans &&
    AllPlans.data &&
    AllPlans.data.data &&
    AllPlans.data.data.slice(pagesVisited, pagesVisited + postsPerPage);
  // const currentPosts = AllPlans && AllPlans.data && AllPlans.data.slice(indexOfFirstPost, indexOfLastPost);
  console.log("sdbhdfhf", currentPosts);

  const paginate = (pagenumber) => {
    setCurrentPage(pagenumber);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  };

  const handleClickDelete = (event) => {
    setDeleteUser(event);
    setIsOpen(true);
  };

  const closeModal = (event) => {
    setIsOpen(false);
  };

  const handleYes = async (event) => {
    event.preventDefault();
    await axios
      .post(`${PORT}/plan/delete`, { id: deleteUser })
      .then((res, err) => {
        console.log("deletedusers", res);
      });
    await axios.get(`${PORT}/plan/get_all`).then((res, err) => {
      setAllPlans(res);
    });
    setIsOpen(false);
    toast.success("You have successfully deleted plan");
  };

  const handleClose = (event) => {
    event.preventDefault();
    setIsOpen(false);
    setModalDeactive(false);
    setModalActive(false);
  };

  const handleClickDeactive = (id) => {
    setModalDeactive(true);
    setId(id);
  };
  const handleClickActive = (id) => {
    setModalActive(true);
    setId(id);
  };
  const handleYesDeactive = async () => {
    await axios
      .post(`${PORT}/plan/status`, { id, status: "Inactive" })
      .then((res, err) => {
        toast.success("You have successfully Inactived this plan");
        setModalDeactive(false);
      });
    await axios.get(`${PORT}/plan/get_all`).then((res, err) => {
      setAllPlans(res);
    });
  };
  const handleYesActive = async () => {
    await axios
      .post(`${PORT}/plan/status`, { id, status: "Active" })
      .then((res, err) => {
        toast.success("You have successfully activated this plan");
        setModalActive(false);
      });
    await axios.get(`${PORT}/plan/get_all`).then((res, err) => {
      setAllPlans(res);
    });
  };

  const handleSelect = async (event) => {
    if (event.target.value == "all") {
      await axios.get(`${PORT}/plan/get_all`).then((res, err) => {
        setAllPlans(res);
      });
    } else if (event.target.value == "Active") {
      await axios
        .post(`${PORT}/plan/getstatus`, { status: "Active" })
        .then((res, err) => {
          setAllPlans(res);
        });
    } else if (event.target.value == "Inactive") {
      await axios
        .post(`${PORT}/plan/getstatus`, { status: "Inactive" })
        .then((res, err) => {
          setAllPlans(res);
        });
    }
  };

  const handleAdd = (event) =>{
    document.body.classList.add('fixedit_modal');
    setPropsId(undefined)
  }
  const handleEdit = (event, id) =>{
    document.body.classList.add('fixedit_modal');
    setPropsId(id)
  }

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Listing Plans</li>
              </ul>
              <h2 class="main_title">Listing Plans</h2>
            </div>
            <div className="addpackk">
              <Link onClick={handleAdd} className="addpkgbtn savesidebutt">
                <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add
                Plan
              </Link>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  <div class="dropdownsid">
                    <select class="fuilddesh" onChange={handleSelect}>
                      <option value="all">All</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">Ad Type</div>
                        <div class="table_cell">Duration</div>
                        <div class="table_cell">Amount</div>
                        <div class="table_cell">Status</div>
                        <div class="table_cell">Actions</div>
                      </div>

                      <ToastContainer />

                      {currentPosts &&
                        currentPosts
                          .filter((val) => {
                            if (searchItem == null) {
                              return val;
                            } else if (
                              val.ad_type
                                .toLowerCase()
                                .includes(searchItem.toLowerCase())
                            ) {
                              return val;
                            } else {
                              return false;
                            }
                          })
                          .map((plandata) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="table_cell">
                                  {plandata.ad_type}
                                </div>
                                <div class="table_cell">
                                  {plandata.duration} Days
                                </div>
                                <div class="table_cell">
                                  {plandata.amount}
                                </div>

                                <div className="table_cell">
                                  {plandata.status == "Active" ? (
                                    <span
                                      //  onClick={() => handleApprove(plandata._id)}
                                      className="labltable lablesct"
                                    >
                                      Active
                                    </span>
                                  ) : (
                                    <span
                                      //  onClick={() => handleReject(plandata._id)}
                                      className="labltable lablesct_red"
                                    >
                                      Inactive
                                    </span>
                                  )}
                                </div>

                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      // to={"/plan-edit/" + plandata._id}
                                      onClick={(e)=> handleEdit(e, plandata._id)}
                                      title="Edit"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </Link>
                                  </span>

                                  {plandata.status == "Active" ? (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Inactive"
                                        id="deactive"
                                        onClick={() =>
                                          handleClickDeactive(plandata._id)
                                        }
                                      >
                                        <i class="fa fa-ban"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        id="active"
                                        data-placement="top"
                                        title="Active"
                                        onClick={() =>
                                          handleClickActive(plandata._id)
                                        }
                                      >
                                        <i class="fa fa-check-circle-o"></i>
                                      </a>
                                    </span>
                                  )}

                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() =>
                                        handleClickDelete(plandata._id)
                                      }
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}

                      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
                      <div class="modalChild">
                      <div class="modalchildPadding">
                        <h6>Do you want to delete this plan?</h6>
                        </div>
                        <Button
                          onClick={handleYes}
                          className="savesidebutt"
                          variant="primary"
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={handleClose}
                          className="savesidebutt close-savesidebutt"
                          variant="secondary"
                        >
                          Close
                        </Button>
                        </div>
                      </Modal>

                      <Modal isOpen={modalActive}>
                      <div class="modalChild">
                      <div class="modalchildPadding">
                        <h6>Do you want to Active this plan?</h6>
                        </div>
                        <Button
                          onClick={handleYesActive}
                          className="savesidebutt"
                          variant="primary"
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={handleClose}
                          className="savesidebutt close-savesidebutt"
                          variant="secondary"
                        >
                          Close
                        </Button>
                        </div>
                      </Modal>

                      <Modal isOpen={modalDeactive}>
                      <div class="modalChild">
                      <div class="modalchildPadding">
                        <h6>Do you want to Inactive this plan?</h6>
                        </div>
                        <Button
                          onClick={handleYesDeactive}
                          className="savesidebutt"
                          variant="primary"
                        >
                          Yes
                        </Button>
                        <Button
                          onClick={handleClose}
                          className="savesidebutt close-savesidebutt"
                          variant="secondary"
                        >
                          Close
                        </Button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="showingflexx">
        <div class="showingleft">
          Showing {currentPage + 1}-{pageCount} of {pageCount}
        </div>
        <div class="left_paginistion">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      </div>
      {
        propsid == undefined
        ?
        <PlanAdd />
        :
        <PlanEdit props={propsid} />
      }
    </div>
  );
};

export default PlanList;
