import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";

const FaqSubcategoriesAdd = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allfaqcategories, setAllfaqcategories] = useState();
  const [category_name, setCategory] = useState();
  const [sub_category_name, setSubcategory] = useState();


  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(async ()=>{
    await axios.get(`${PORT}/faqcategories`).then((response, err)=>{
        console.log("allfaqcate", response);
        setAllfaqcategories(response);
    })
  },[])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!category_name)
    {
        document.getElementById("category-name").style.display="block";
        return;
    }
    if(!sub_category_name)
    {
       document.getElementById("subcategory-name").style.display="block";
       return;
    }
      await axios
        .post(`${PORT}/faqsubcategories/create`,
        {sub_category_name, faqcategoryId: category_name},
        )
        .then((res, err) => {
          if(res)
          {
            handleClose();
            setCategory("");
            setSubcategory("");
            history.push({ pathname: "/faq-subcategories", MatchValue:"add" });
          }
        });
    }

  const handleSubCategory = (event) => {
    event.preventDefault();
    document.getElementById("subcategory-name").style.display="none";
    setSubcategory(event.target.value);
  };

  const handleCategory = (event) =>{
    event.preventDefault();
    document.getElementById("category-name").style.display="none";
    setCategory(event.target.value);
  }


  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) =>{
    document.body.classList.remove('fixedit_modal');
  }



  return (
    <div class="editfix main_bxshadow">
    <div class="headeredi_fix text-right">
     <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
     <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
    </div>
    <form onSubmit={handleSubmit}>
      <div class="table_fixe">
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">FAQ Sub categories Settings</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Category</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <select
                          onChange={handleCategory}
                          value={category_name}
                          class="fuilddesh"
                        >
                          <option value={""}>{"SELECT CATEGORY"}</option>
                          {allfaqcategories &&
                            allfaqcategories.data &&
                            allfaqcategories.data.data &&
                            allfaqcategories.data.data.map((category) => {
                              return (
                                <option value={category._id}>{category.category_name}</option>
                              );
                            })}
                        </select>
                        <p
                      className="text-danger"
                      id="category-name"
                      style={{ display: "none" }}
                    >
                      Category Name is required*
                    </p>
                    </div>
                  </div>
                </div>
    

                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Sub category</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <input
                          onChange={handleSubCategory}
                          type="text"
                          className="fuilddesh"
                          placeholder=""
                          value={sub_category_name}
                        />
                         <p
                      className="text-danger"
                      id="subcategory-name"
                      style={{ display: "none" }}
                    >
                      Sub category Name is required
                    </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center small_margin_top">
          <button type="submit" class="savesidebutt closefix">SAVE</button>
        </div>
      </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default FaqSubcategoriesAdd;


   // <div className="work_space">
    //   <div className="workspace_title_hd">
    //     <div className="row">
    //       <div className="col-md-12">
    //         <div className="titleof_page">
    //           <ul className="breadcrumb_list topsmall_bottom">
    //             <li>
    //               <a href="#">Admin Panel </a>
    //             </li>
    //             <li>
    //               <i
    //                 style={{ padding: "5px" }}
    //                 className="fa fa-angle-right"
    //               ></i>
    //             </li>
    //             <li>Add FAQ Sub categories</li>
    //           </ul>
    //           <h2 className="main_title">Add FAQ Sub categories</h2>
    //         </div>
    //       </div>
    //       <div className="col-md-12">
    //         <div className="main_oflistingForm bg_light_gray onlyforcol_support">
    //           <div className="custom_fuild_addM topsmall_top flexcoluman edit-form">
    //             <form onSubmit={handleSubmit}>
    //               <ToastContainer />
    //               <div class="row">
    //                 <div class="col-md-6">

    //               <div class="form-group">
    //                 <h4 class="fieldsec_lab">Category<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
    //                 <div class="   ">
    //                   <div class="repwat_one">
    //                     <select
    //                       onChange={handleCategory}
    //                       value={category_name}
    //                       class="fuilddesh"
    //                     >
    //                       <option value={""}>{"SELECT CATEGORY"}</option>
    //                       {allfaqcategories &&
    //                         allfaqcategories.data &&
    //                         allfaqcategories.data.data &&
    //                         allfaqcategories.data.data.map((category) => {
    //                           return (
    //                             <option value={category._id}>{category.category_name}</option>
    //                           );
    //                         })}
    //                     </select>
                    
    //                   </div>
    //                 </div>
    //                 <p
    //                   className="text-danger"
    //                   id="category-name"
    //                   style={{ display: "none" }}
    //                 >
    //                   Category Name is required*
    //                 </p>
    //               </div>

    //               </div>

    //               <div class="col-md-6">
    //               <div className="form-group">
    //                 <h4 className="fieldsec_lab">Sub category<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
    //                 <div className="   ">
    //                   <div className="repwat_one">
    //                     <input
    //                       onChange={handleSubCategory}
    //                       type="text"
    //                       className="fuilddesh"
    //                       placeholder="Sub category"
    //                       value={sub_category_name}
    //                     />
                      
    //                   </div>
    //                 </div>
    //                 <p
    //                   className="text-danger"
    //                   id="subcategory-name"
    //                   style={{ display: "none" }}
    //                 >
    //                   Sub category Name is required
    //                 </p>
    //               </div>
    //               </div>
    //               </div>

    //               <div className="bottomsend_button text-center small_margin_top">
    //                 <button type="submit" className="savesidebutt closefix">
    //                   Save
    //                 </button>
    //                 <button
    //                   type="button"
    //                   onClick={handleReset}
    //                   className="savesidebutt closefix"
    //                 >
    //                   Reset
    //                 </button>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
