import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";

const AddField = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [fieldName, setFieldName] = useState();
  const [allcategory, setAllCategory] = useState();
  const [category, setCategory] = useState();
  const [allSubCategory, setAllSubCategory] = useState();
  const [filter, setFilter] = useState();
  const [subcategory, setSubCategory] = useState();
  const [fieldType, setFieldType] = useState();
  const [isMain, setIsMain] = useState(false);
  const [isValidationRequired, setIsValidationRequired] = useState(false);
  const [position, setPosition] = useState(1);
  const [dropdown, setDropdown] = useState([]);

  const history = useHistory();
  const id = props;

  useEffect(() => {
    axios
      .post(`${PORT}/fields/getSingleRecord`, { params: { id } })
      .then((res, err) => {
        setFieldName(res.data.fieldName);
        setCategory(res.data.category);
        setFilter(res.data.Is_filter);
        GetAllSubcategory(res.data.category);
        GetSubCategoryByIdArray(res.data.subCategories);
        setFieldType(res.data.fieldType);
        setIsMain(res.data?.isMain);
        setIsValidationRequired(res.data?.isValidationRequired);
        if(res.data?.position && res.data.position){
          setPosition(res.data?.position);
        }
        

        // console.log("editfield", res);
      });
  }, [props]);

  useEffect(() => {
    axios
      .post(`${PORT}/fields/getFieldsValuesByFieldId`, { id })
      .then((res, err) => {
        if (res.data.length) {
          let values = [];
          for (let item of res.data) {
            values.push({ value: item.name });
            setDropdown(values);
          }
          document.getElementById("check-box").style.display = "flex";
        }
      });
  }, [props]);

  useEffect(async () => {
    await axios.get(`${PORT}/getCategorys`).then((res, err) => {
      if (res) {
        setAllCategory(res);
      } else {
        toast.info("Something went wrong");
      }
    });
  }, []);

  const handleCategory = (event) => {
    document.getElementById("category").style.display = "none";
    setCategory(event.target.value);
    setSubCategory(null);
    GetAllSubcategory(event.target.value);
  };

  const GetAllSubcategory = async (category_id) => {
    await axios
      .post(`${PORT}/subcategorybyid/getall`, { category_id })
      .then((res, err) => {
        if(res.data.length > 0){
          res.data.map((item)=>{
            //console.log('item', item);
            let name  = item.subCategoryName+' ('+item.label+') ';
            if(item.parentId && item.parentId.subCategoryName){
              name  +=  '---'+ item.parentId.subCategoryName;
            }
            item.name               = name;
            item.subCategoryName    = item.name;
          })
        }
        setAllSubCategory(res);
      });
  };

  const GetSubCategoryByIdArray = async (arrayId) => {
    await axios
      .post(`${PORT}/subcategories/getbyIdArray`, { arrayId })
      .then((res, err) => {
        console.log("arrayidresult", res);
        if (res) {
          setSubCategory(res.data);
        }
      });
  };

  const handleSubcategory = (data) => {
    document.getElementById("sub-category").style.display = "none";
    setSubCategory(data);
  };

  const handleFieldName = (event) => {
    event.preventDefault();
    document.getElementById("field-name").style.display = "none";
    setFieldName(event.target.value);
  };

  const handleFilter = (event) => {
    document.getElementById("filter").style.display = "none";
    setFilter(event.target.value);
  };

  const handleFieldType = (event) => {
    event.preventDefault();
    document.getElementById("field-type").style.display = "none";
    setFieldType(event.target.value);

    if (
      event.target.value == "Radio" ||
      event.target.value == "Dropdown" ||
      event.target.value == "Checkbox"
    ) {
      document.getElementById("check-box").style.display = "flex";
    } else {
      document.getElementById("check-box").style.display = "none";
    }
  };

  const handleChangeFields = (i, event) => {
    const values = [...dropdown];
    values[i].value = event.target.value;
    setDropdown(values);
    console.log("fieldchnage", dropdown);
  };

  const handleAddFields = () => {
    const values = [...dropdown];
    values.push({ value: null });
    setDropdown(values);
    console.log("fieldadd", dropdown);
  };

  const handleRemoveFields = (i) => {
    const values = [...dropdown];
    values.splice(i, 1);
    setDropdown(values);
    console.log("fieldremove", dropdown);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!category) {
      document.getElementById("category").style.display = "block";
      return;
    }
    if (!subcategory) {
      document.getElementById("sub-category").style.display = "block";
      return;
    }
    if (!fieldName) {
      document.getElementById("field-name").style.display = "block";
      return;
    }
    if (!filter) {
      document.getElementById("filter").style.display = "block";
      return;
    }
    await axios
      .post(`${PORT}/fields/update`, {
        params: {
          id,
          category,
          subcategory,
          fieldName,
          fieldType,
          filter,
          dropdown,
          isMain,
          isValidationRequired,
          position
        },
      })
      .then((res, err) => {
        handleClose();
        history.push({ pathname: "/fields", MatchValue: "edit" });
      });
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
  };

  ////////////////////////////////////////////////////////////////////

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Field Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Categories</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleCategory}
                          value={category}
                          class="fuilddesh "
                        >
                          <option value={""}>{"Select Category"}</option>
                          {allcategory &&
                            allcategory.data &&
                            allcategory.data.map((data) => {
                              return (
                                <option value={data._id}>
                                  {data.categoryName}
                                </option>
                              );
                            })}
                        </select>
                        <p
                          className="text-danger"
                          id="category"
                          style={{ display: "none" }}
                        >
                          Category is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Sub categories</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid multiSelectBars">
                        <Multiselect
                          onSelect={handleSubcategory}
                          onRemove={handleSubcategory}
                          selectedValues={subcategory}
                          options={allSubCategory && allSubCategory.data}
                          displayValue="subCategoryName"
                        />
                        <p
                          id="sub-category"
                          style={{ display: "none" }}
                          className="text-danger"
                        >
                          Sub category is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Field Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleFieldName}
                          type="text"
                          className="fuilddesh "
                          placeholder=""
                          value={fieldName}
                        />
                        <p
                          id="field-name"
                          style={{ display: "none" }}
                          className="text-danger"
                        >
                          Field name is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Search In Main Category</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={(e) => setIsMain(e.target.checked)}
                          type="checkbox"
                          // className="fuilddesh "
                          placeholder=""
                          // value={isMain}
                          checked={isMain}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Field position</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          class="fuilddesh"
                          onChange={(e) => setPosition(e.target.value)}
                          type="number"
                          min={1}
                          value={position}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Must be filled</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={(e) => setIsValidationRequired(e.target.checked)}
                          type="checkbox"
                          checked={isValidationRequired}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Is Filter</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleFilter}
                          value={filter}
                          class="fuilddesh "
                        >
                          <option value={""}>{"Select filter"}</option>
                          <option value={"Yes"}>Yes</option>
                          <option value={"No"}>No</option>
                        </select>
                        <p
                          className="text-danger"
                          id="filter"
                          style={{ display: "none" }}
                        >
                          Filter is required*
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Field Type</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleFieldType}
                          value={fieldType}
                          class="fuilddesh "
                        >
                          <option value={""}>{"Select field type"}</option>
                          <option value={"Input"}>Input</option>
                          <option value={"Textarea"}>Text Area</option>
                          <option value={"Checkbox"}>Checkbox</option>
                          <option value={"Radio"}>Radio</option>
                          <option value={"Dropdown"}>Dropdown</option>
                        </select>
                        <p
                          className="text-danger"
                          id="field-type"
                          style={{ display: "none" }}
                        >
                          Field type is required*
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="Table-row"
                    id="check-box"
                    style={{ display: "none" }}
                  >
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Field Values</span>
                      </div>
                    </div>
                    <div class="Table-row-item table-row-flex">
                      <div class="dropdownsid">
                        {dropdown.map((field, index) => {
                          return (
                            <div
                              className="repwat_one flexCatGrow inner-field"
                              key={`${field}-${index}`}
                            >
                              <input
                                onChange={(e) => handleChangeFields(index, e)}
                                type="text"
                                value={field.value}
                                name="checkbox"
                                className="fuilddesh "
                              />
                              <span className=" requlab_span pl-0">
                                <div className="checkBtablss">
                                  <ul>
                                    <li>
                                      <div className="check-box">
                                        {index == 0 ? (
                                          <div>
                                            <i
                                              onClick={() =>
                                                handleRemoveFields(index)
                                              }
                                              style={{ padding: "2px" }}
                                              className="fa fa-times fa-2x"
                                            ></i>
                                            {/* <i onClick={handleAddFields} style={{ padding: "2px" }} className="fa fa-plus-circle fa-1x"></i> */}
                                          </div>
                                        ) : (
                                          <i
                                            onClick={() =>
                                              handleRemoveFields(index)
                                            }
                                            style={{ padding: "2px" }}
                                            className="fa fa-times fa-2x"
                                          ></i>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      <div class="addfieldbt_last topsmall_margin_top">
                        <a onClick={handleAddFields} href="javascript:void(0)">
                          <i
                            style={{ padding: "5px", color: "#fff" }}
                            class="fa fa-plus"
                          ></i>
                          Add Option
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddField;
