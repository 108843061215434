import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import Modal from "react-modal";
import SendEmail from "../sendEmail/sendEmail";

const ReportProductsList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [allReport, setAllReport] = useState();
  const [Id, setId] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  const [userEmail, setUserEmail] = useState();

  useEffect(async () => {
    GetAllReports();
  }, []);

  const GetAllReports = async () => {
    await axios.get(`${PORT}/productReport`).then((response, err) => {
      setAllReport(response);
      // console.log(response);
    });
  };

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit"
    ) {
      GetAllReports();
      toast.success("You have successfully edited ad");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit",
  ]);

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "add"
    ) {
      GetAllReports();
      toast.success("You have successfully added ad");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "add",
  ]);

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount =
    Math.ceil(
      (allReport &&
        allReport.data &&
        allReport.data.data &&
        allReport.data.data.length) / postsPerPage
    ) || 1;

  const currentPosts =
    allReport &&
    allReport.data &&
    allReport.data.data &&
    allReport.data.data.slice(pagesVisited, pagesVisited + postsPerPage);
  // const currentPosts = allBrand && allBrand.data && allBrand.data.data && allBrand.data.data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pagenumber) => {
    setCurrentPage(pagenumber);
  };

  const handleSearch = async (event) => {
    setSearchItem(event.target.value);
    if (event.target.value) {
      await axios
        .post(`${PORT}/search_report_product`, { search: event.target.value })
        .then((response, err) => {
          // console.log("total product", response);
          setAllReport(response);
        });
    } else {
      GetAllReports();
    }
  };

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleEdit = (event, email) => {
    document.body.classList.add("fixedit_modal");
    if (email != "") {
      setPropsId(email);
    }
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteReport(Id);
    setDeleteModal(false);
  };

  const handleDeleteReport = async (id) => {
    await axios
      .post(`${PORT}/reportAdDelete`, { params: { id } })
      .then((response, err) => {
        toast.success("You have successfully deleted this ad");
      });
    await axios.get(`${PORT}/reportAd`).then((response, err) => {
      setAllReport(response);
    });
  };

  const getReport = (report_id) => {
    var report = "";
    for (var i = 0; i < report_id.length; i++) {
      report += report_id[i].name + ",";
    }
    report = report.replace(/,\s*$/, "");
    return <div>{report}</div>;
  };
  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Report Products</li>
              </ul>
              <h2 class="main_title">Report Products</h2>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  {/* <div class="dropdownsid">
                    <select class="fuilddesh">
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div> */}
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">Report Name</div>
                        <div class="table_cell">Product Name</div>
                        <div class="table_cell">Name</div>
                        <div class="table_cell">Email</div>
                        <div class="table_cell">Action</div>
                      </div>
                      {currentPosts &&
                        currentPosts
                          // .filter((val) => {
                          //   if (searchItem == null) {
                          //     return val;
                          //   } else if (
                          //     val.name
                          //       .toLowerCase()
                          //       .includes(searchItem.toLowerCase())
                          //   ) {
                          //     return val;
                          //   } else {
                          //     return false;
                          //   }
                          // })
                          .map((r) => {
                            var email = "";
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div class="table_cell">
                                  {getReport(r.report_id)}
                                </div>
                                <div class="table_cell">
                                  {r.productId ? r.productId.productName : ""}
                                </div>
                                <div class="table_cell">
                                  {r.userId
                                    ? r.userId.firstName +
                                      " " +
                                      r.userId.lastName
                                    : r.name}
                                </div>
                                <div class="table_cell">
                                  {r.userId ? r.userId.email : r.email}
                                </div>

                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      className="savesidebutt"
                                      onClick={(e) =>
                                        handleEdit(
                                          e,
                                          r.userId ? r.userId.email : r.email
                                        )
                                      }
                                    >
                                      Send Email
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              //   onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal isOpen={deleteModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to delete this ad?</h6>
          </div>
          <Button
            onClick={handleDeleteYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      {propsid === undefined ? "" : <SendEmail props={propsid} />}
    </div>
  );
};

export default ReportProductsList;
