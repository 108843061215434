import React, {useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import "./addCategory.css";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import formData from "form-data";

const AddCategory = () => {


    const PORT = PORTNUMBER.PORTNUMBER;

    const [category, setCategory] = useState();
    const [slug, setSlug] = useState();
    const [mainImage, setMainImage] = useState();
    const [popular, setPopular] = useState();
    const [order, setOrder] = useState();

    const [dropName, setDropName] = useState([{value:null}]);
    const [alldropName, setAllDropName] = useState([{value:null}]);
    const [categoryFields, setCategoryFields] = useState([{value:null}]);
    const [categoryTypes, setCategoryTypes] = useState([{value:null}]);


    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;


    const handleReset = (event) =>{
        event.preventDefault();
        setCategory("");
        setSlug("");
        setCategoryFields([null]);
        setCategoryTypes([null]);
    }


    const handleSubmit = async (event) =>{
        event.preventDefault();

        if(!category)
        {
          document.getElementById("category-name").style.display="block";
          return;
        }
        if(!slug)
        {
          document.getElementById("slug").style.display="block";
          return;
        }
        if(!mainImage)
        {
          document.getElementById("category-image").style.display="block";
          return;
        }
        if(!order)
        {
          document.getElementById("category-order").style.display="block";
          return;
        }
        if(order == -1)
        {
          document.getElementById("exist-orderid").style.display="block";
          return;
        }
        else{
          var id = Math.floor(Math.random() * 10000);

          await axios.post(`${PORT}/addCategory`, {params:{id, category, slug, mainImage, popular, order}}).then((res, err) =>{
              console.log("dfgdfgbf", res);
              toast.success("You have successfully added a new category");
          }) 
          setCategory("");
          setSlug("");
          // setCategoryFields([null]);
          // setCategoryTypes([null]);
          history.push({pathname:"/category", customdata:{pathname}});
        }

     
    }


    const handleCategory = (event) =>{
        event.preventDefault();
        document.getElementById("category-name").style.display="none";
        setCategory(event.target.value);

        let data = event.target.value;
        let data1 = data.toLowerCase();

        data1 = data1.replace(/\s/g , "-");
        
        setSlug(data1);
    }

    const handleSlug = (event) =>{
        event.preventDefault();
        document.getElementById("slug").style.display="none";
        setSlug(event.target.value);
    }


const handleMainCategoryImage = (event) => {
  document.getElementById("category-image").style.display="none";
  const files = event.target.files[0];

  let fData = new formData();
  fData.append('categoryImage', files);        
 

  var config = {
      method: 'post',
      url: `${PORT}/uploadCategory`,
      data: fData
  };

  axios(config)
  .then(function (response) {
      if(response.data.success == 1){
          console.log(response.data.image_url.split("/"));
          const image = response.data.image_url.split("/");
          setMainImage(image[6]);
      }       
  })
  .catch(function (error) {
      console.log(error);
  });

}

const handlePopular = (e) =>{
  console.log("checked", e.target.checked);
  if (e.target.checked == true) {
    console.log("checked");
    setPopular(true);
  }
  else {
    console.log("not checked");
    setPopular(false);
  }
}

const handleCategoryOrder = async (e) =>{
  console.log("dfvjcdsnfjvndjsf", e.target.value);
  document.getElementById("category-order").style.display="none";

  if(e.target.value.trim())
  {
    await axios.post(`${PORT}/get_by_order`, {orderId:e.target.value}).then((res, err) =>{
      if(res.data.categoryOrder)
      {
        document.getElementById("exist-orderid").style.display="block";
        setOrder(-1);
        return;
      }
      else{
        document.getElementById("exist-orderid").style.display="none";
        setOrder(e.target.value);
      }
    })
  }
  else{
    document.getElementById("exist-orderid").style.display="none";
  }
}


// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
const handleDropdown = async (index, e) =>{
  if(e.target.value == "dropdown")
  {
    console.log(index, categoryFields[index]);
    await setDropName(dropName => [...dropName, categoryFields[index]]);
  }
  else{
    return;
  }
}

const handleChangeTypes = async (i, event, fieldname) =>{
  console.log("fieldname", fieldname);

  await setAllDropName(alldropName => [...alldropName, fieldname,[event.target.value]]);
  console.log("alldropname", alldropName);

  const values = [...categoryTypes];
  values[i].value = event.target.value;
  setCategoryTypes(values);
  console.log("categoryType",categoryTypes);
}


const handleAddTypes = () => {
const values = [...categoryTypes];
values.push({ value: null });
setCategoryTypes(values);
}


const handleRemoveTypes = (i) => {
const values = [...categoryTypes];
values.splice(i, 1);
setCategoryTypes(values);
}
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@


const handleChangeFields = (i, event) =>{
  const values = [...categoryFields];
  values[i].value = event.target.value;
  setCategoryFields(values);
  console.log(categoryFields);
}

const handleAddFields = () => {
const values = [...categoryFields];
values.push({ value: null });
setCategoryFields(values);
}


const handleRemoveFields = (i) => {
const values = [...categoryFields];
values.splice(i, 1);
setCategoryFields(values);
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@




    return (
        <div className="work_space">
        <div className="workspace_title_hd">
          <div className="row">
                <div className="col-md-12">
                  <div className="titleof_page">
                <ul className="breadcrumb_list topsmall_bottom">
                  <li><a href="#">Admin Panel </a></li>
                  <li><i style={{padding:"5px"}} className="fa fa-angle-right"></i></li>
                  <li>Add Category</li>
                </ul>
                    <h2 className="main_title">Add Category</h2>
                  </div>
                </div>
            <div className="col-md-12">
              <div className="main_oflistingForm bg_light_gray onlyforcol_support">
                <div className="custom_fuild_addM topsmall_top flexcoluman">
                  
                  
                  
                  <form onSubmit={handleSubmit}>

                  <ToastContainer />



                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Category Name</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input onChange={handleCategory} type="text" className="fuilddesh" placeholder="Category Name" value={category} />
                          <span className="requlab_span">
                            <div className="checkBtablss">
                              <ul>
                                <li>
                                  <div className="check-box">
                                    {/* <input type="checkbox" name="option" id="Company103" /> */}
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </span>
                      </div>
                    </div>
                    <p className="text-danger" id="category-name" style={{display:'none'}}>Category name is required*</p>
                  </div>


                  <div className="bindfields">
                    <h4 className="fieldsec_lab">Slug</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input onChange={handleSlug} type="text" className="fuilddesh" name="" placeholder="Slug" value={slug} /><span className="requlab_span">
                            <div className="checkBtablss">     
                              <ul>
                                <li>
                                  <div className="check-box">
                                    {/* <input type="checkbox" name="option" id="Company103" /> */}
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </span>
                      </div>
                    </div>
                    <p className="text-danger" id="slug" style={{display:'none'}}>Slug is required*</p>
                  </div>

                  <div className="bindfields">
                        <h4 className="fieldsec_lab">Category Image</h4>
                        <div className="field_sec_repetM">
                            <div className="repwat_one">
                                <input onChange={handleMainCategoryImage} type="file" className="fuilddesh" name="img" placeholder="Category Image" />
                                <span className="requlab_span">
                                    <div className="checkBtablss">
                                        <ul>
                                            <li>
                                                <div className="check-box">
                                                    <label for="Company103">*Required Field</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <p className="text-danger" id="category-image" style={{display:'none'}}>Category Image is required*</p>
                    </div>




                  {/* <div className="bindfields">
                    <h4 className="fieldsec_lab">Category Fields</h4>
                    <div className="field_sec_repetM repeatDisUnset">
                      
                      {categoryFields.map((field, index) =>{
                          return(
                            <div className="repwat_one flexCatGrow" key={`${field}-${index}`}>
                            <input onChange={e => handleChangeFields(index, e)} type="text" className="fuilddesh" placeholder="Category Fields" required/>

                              <div className="left_innserSearchone">
                                <div className="dropdownsid">
                                  <select onChange={(e) =>handleDropdown(index, e)} style={{backgroundColor:"#f3f3f3"}} className="form-control">
                                    <option value="text">Text</option>
                                    <option value="dropdown">Dropdown</option>
                                  </select>
                                </div>
                              </div>

                         <span className="requlab_span">
                             <div className="checkBtablss">     
                               <ul>
                                 <li>
                                   <div className="check-box">
                                     {
                                       index == 0 ?
                                       <i onClick={handleAddFields} style={{padding:"2px"}} classNameName="fa fa-plus-circle fa-1x"></i>
                                        :
                                        <i onClick={() => handleRemoveFields(index)} style={{padding:"2px"}} classNameName="fa fa-trash fa-1x"></i>
                                     }
                                     <label for="Company103">*Required Field</label>
                                   </div>
                                 </li>
                               </ul>
                             </div>
                         </span>
                       </div>
                          )
                        })
                      }
            
                    </div>
                  </div> */}




                   {/* {
                     dropName && dropName.map((data)=>{
                       if(data.value == null)
                       {
                         return;
                       }
                       else{
                        return(
                          <div className="bindfields">
                          <h4 className="fieldsec_lab">{data.value}</h4>
                          <div className="field_sec_repetM repeatDisUnset">
                            
                            {
                             categoryTypes.map((field, index) =>{
                               return(
                                  <div className="repwat_one" 
                                  key={`${field}-${index}`}
                                  >
                                  <input 
                                  onChange={e => handleChangeTypes(index, e, data.value)} 
                                  type="text" className="fuilddesh" placeholder="Category Types"/>
      
                               
      
                               <span className="requlab_span">
                                   <div className="checkBtablss">     
                                     <ul>
                                       <li>
                                         <div className="check-box">
                                           {
                                             index == 0 ?
                                             <i onClick={handleAddTypes} style={{padding:"2px"}} classNameName="fa fa-plus-circle fa-1x"></i>
                                             :
                                             <i 
                                              onClick={() => handleRemoveTypes(index)} 
                                              style={{padding:"2px"}} classNameName="fa fa-trash fa-1x"></i>
                                           }
                                         </div>
                                       </li>
                                     </ul>
                                   </div>
                               </span>
                             </div>
                               )
                              })
                            }
                  
                          </div>
                        </div>
                         )
                       }
                     })
                   } */}
                  





                    <div className="bindfields">
                      <h4 className="fieldsec_lab">Is Popular</h4>
                      <div className="field_sec_repetM">
                        <div className="repwat_one">
                          <input type="checkbox" onClick={handlePopular} />
                        </div>
                      </div>
                    </div>


                    <div className="bindfields">
                    <h4 className="fieldsec_lab">Category Order</h4>
                    <div className="field_sec_repetM">
                      <div className="repwat_one">
                        <input onChange={handleCategoryOrder}  type="number" className="fuilddesh" placeholder="Category Order" min="1" />
                        <p style={{display:"none"}} id="exist-orderid" className="text-danger">Order Id already exist</p>
                      </div>
                        <span className="requlab_span">
                          <div className="checkBtablss">
                            <ul>
                              <li>
                                <div className="check-box">
                                  <label for="Company103">*Required Field</label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                    </div>
                    <p className="text-danger" id="category-order" style={{display:'none'}}>Category Order is required*</p>
                  </div>


                  <div className="bottomsend_button text-center small_margin_top">
                  <button type="submit" className="savesidebutt closefix">Save</button>
                  <button type="button" onClick={handleReset} className="savesidebutt closefix">Reset</button>
                  </div>

                  </form>
                 
                </div>
             
               </div>
            </div>
          </div>
        </div>
     
  </div>
    )
}

export default AddCategory
