import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const UserAccountMenu = ({ props }) => {
  const allpath = props.split("/");

  useEffect(() => {
    if (
      props == "/accounts" ||
      props == "/newUser" ||
      allpath[1] == "editUser" ||
      props == "/pending-accounts" ||
      props == "/suspended-accounts"
    ) {
      document.getElementById("collapseUser").classList.add("show");
    }
  }, []);

  return (
    <li class="sidebar-dropdown mabot_mainli">
      <Link data-toggle="collapse" data-target="#collapseUser" to="#">
        <span>User Accounts</span>
        <i class="fa fa-user"></i>
      </Link>
      <div
        className="sidebar-submenu"
        id="collapseUser"
        class="collapse"
        data-parent="#accordion"
      >
        <ul>
          <li>
            <Link to="/accounts">Accounts</Link>
          </li>
          <li>
            <Link to="/pending-accounts">Pending Accounts</Link>
          </li>
          <li>
            <Link to="/suspended-accounts">Suspended Accounts</Link>
          </li>
          <li>
            <Link to="/subscribed-users">Subscribed Users</Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default UserAccountMenu;
