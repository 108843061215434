import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import axios from "axios";
import ReactPaginate from "react-paginate";
import EditNote from "../editNote/EditNote";

 const NoteList = (props) => {
 const PORT = PORTNUMBER.PORTNUMBER;
  const [allNote, setAllNote] = useState();
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  useEffect(async () => {
    GetAllNotes();
  }, []);

  const GetAllNotes = async () => {
    await axios
      .post(`${PORT}/balanceActivateNote`,{})
      .then((response, err) => {
        if(response.data.status === 200){
          setAllNote(response.data.data);
        }
      });
  };

  useEffect(async () => {
    if (
      typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit"
    ) {
      GetAllNotes();
      toast.success("You have successfully edited note");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue == "edit",
  ]);

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };
  
  const handleEdit = (event, id) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(id);
  };
 
  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Balance Activate Notes</li>
              </ul>
              <h2 class="main_title">Notes</h2>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">User Type</div>
                        <div class="table_cell">Actions</div>
                      </div>
                      {allNote &&
                        allNote
                          .filter((val) => {
                            if (searchItem == null) {
                              return val;
                            } else if (
                              val.note
                                .toLowerCase()
                                .includes(searchItem.toLowerCase())
                            ) {
                              return val;
                            } else {
                              return false;
                            }
                          })
                          .map((r) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="table_cell">{(r.role === 1)?"Personal":(r.role === 2 ? "Bussiness" : "Sole Trader")}</div>
                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      onClick={(e) => handleEdit(e, r._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </Link>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      { (propsid != undefined && propsid != "") &&
      <EditNote props={propsid}/>
      }
    </div>
  );
};

export default NoteList;
