import React, { useState, useEffect } from "react";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { toast, ToastContainer } from "react-toastify";

const LiveVideoStreaming = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [liveStreamVideo, setLiveStreamVideo] = useState();
  const [numberOfLiveStreamers, setNumberOfLiveStreamers] = useState();
  const [numberOfViewersPerStreaming, setNumberOfViewersPerStreaming] =
    useState();
  const [registerBid, setRegisterBid] = useState(0);
  const [slotsInterval, setSlotsInterval] = useState();
  const [liveStreamingCategory, setLiveStreamingCategory] = useState("");
  const [allCategories, setAllCategories] = useState([]);

  const [slug, setSlug] = useState();

  useEffect(async () => {
    GETALL();
    GetAllCategories();
  }, []);

  const GETALL = async () => {
    await axios.get(`${PORT}/livestreamingvideo/all`).then((res, err) => {
      if (res) {
        // console.log(res.data.data);

        if (res.data.data != "") {
          setSlug(res.data.data[0].slug);
          setLiveStreamVideo(
            res.data.data[0].common_setting[0].liveStreamVideo
          );
          setNumberOfLiveStreamers(
            res.data.data[0].common_setting[0].numberOfLiveStreamers
          );
          setNumberOfViewersPerStreaming(
            res.data.data[0].common_setting[0].numberOfViewersPerStreaming
          );
          setSlotsInterval(res.data.data[0].common_setting[0].slotsInterval);
          setRegisterBid(res.data.data[0].common_setting[0]?.registerBidAmount);
          setLiveStreamingCategory(
            res.data.data[0].common_setting[0].liveStreamingCategory
          );
        }
      }
    });
  };

  const GetAllCategories = async () => {
    await axios.get(`${PORT}/categories/list`).then((res, err) => {
      if (!err) {
        if (res.data.data != "") {
          setAllCategories(res.data.data);
        }
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (liveStreamVideo == 1) {
      var liveStreamVideo_check = true;
    } else {
      var liveStreamVideo_check = false;
    }

    var common_settings = [
      {
        liveStreamVideo: liveStreamVideo_check,
        numberOfLiveStreamers: numberOfLiveStreamers,
        numberOfViewersPerStreaming: numberOfViewersPerStreaming,
        slotsInterval: slotsInterval,
        liveStreamingCategory,
        registerBidAmount: registerBid,
      },
    ];

    if (slug == "LiveStreamingVideo") {
      await axios
        .post(`${PORT}/livestreamingvideo/update`, {
          common_settings: JSON.stringify(common_settings),
        })
        .then((res, err) => {
          if (res) {
            toast.success("You have successfully changed the settings");
          }
        });
    } else {
      await axios
        .post(`${PORT}/livestreamingvideo/create`, {
          common_settings: JSON.stringify(common_settings),
        })
        .then((res, err) => {
          if (res) {
            toast.success("You have successfully changed the settings");
          }
        });
    }
    GETALL();
  };

  const handleLiveStreamVideo = (event) => {
    setLiveStreamVideo(event.target.value);
  };
  const handleNoOfLiveSTreamers = (event) => {
    setNumberOfLiveStreamers(event.target.value);
  };
  const handleNumberOfViewersPerStreaming = (event) => {
    setNumberOfViewersPerStreaming(event.target.value);
  };
  const handleSlotsInterval = (event) => {
    setSlotsInterval(event.target.value);
  };

  return (
    <div class="tab-pane" id="basic07" role="tabpanel">
      <form onSubmit={handleSubmit}>
        <h3 class="tabcont_lable">
          Basic settings for <span>Live Video Streaming</span>
        </h3>
        <div class="labelsidetop">
          <div class="boxrepetform">
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Enable</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <div class="form_group">
                        <ul class="list">
                          <li class="list__item">
                            <input
                              type="radio"
                              id="liveStreamVideo1"
                              name="liveStreamVideo"
                              value="1"
                              onClick={handleLiveStreamVideo}
                              checked={liveStreamVideo == 1 ? "checked" : ""}
                            />
                            <label for="liveStreamVideo1" class="label">
                              Enable
                            </label>
                          </li>
                          <li class="list__item">
                            <input
                              type="radio"
                              id="liveStreamVideo2"
                              name="liveStreamVideo"
                              value="0"
                              onClick={handleLiveStreamVideo}
                              checked={liveStreamVideo == 0 ? "checked" : ""}
                            />
                            <label for="liveStreamVideo2" class="label">
                              Disable
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>No of live streamers</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        onChange={handleNoOfLiveSTreamers}
                        value={numberOfLiveStreamers}
                        class="fuilddesh minwidth02"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Slots Interval</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <select
                        class="fuilddesh"
                        onChange={handleSlotsInterval}
                        value={slotsInterval}
                      >
                        <option value="">Select</option>
                        <option value="Interval 1">Interval 1</option>
                        <option value="Interval 2">Interval 2</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>No of viewers per streaming</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        onChange={handleNumberOfViewersPerStreaming}
                        value={numberOfViewersPerStreaming}
                        class="fuilddesh minwidth02"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Register Bid Amount</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input
                        type="number"
                        onChange={(e) => setRegisterBid(e.target.value)}
                        value={registerBid}
                        class="fuilddesh minwidth02"
                        placeholder=""
                        min={0}
                      />
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Select livestreaming category</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <select
                        class="fuilddesh"
                        onChange={(e) =>
                          setLiveStreamingCategory(e.target.value)
                        }
                        value={liveStreamingCategory}
                      >
                        <option value="">Select category</option>
                        {allCategories &&
                          allCategories.map((category) => (
                            <option value={category._id} key={category._id}>
                              {category.categoryName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center">
          <button type="submit" class="savesidebutt">
            SAVE
          </button>
          <ToastContainer />
        </div>
      </form>
    </div>
  );
};

export default LiveVideoStreaming;
