import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";
import Resizer from "react-image-file-resizer";

const AddProduct = () => {

    const PORT = PORTNUMBER.PORTNUMBER;

    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const [productName, setProductName] = useState();
    const [productDescription, setProductDescription] = useState();
    const [mainImage, setMainImage] = useState();
    const [productImages, setProductImages] = useState();

    const [regularPrice, setRegularPrice] = useState();
    const [salePrice, setSalePrice] = useState(0);

    const [premium, setPremium] = useState();
    const [weaklyDeal, setWeaklyDeal] = useState();
    const [liveStream, setLiveStream] = useState();

    const [allCategory, setAllCategory] = useState([{ value: null }]);
    const [allUser, setAllUser] = useState([{ value: null }]);

    const [firstSubCat, setFirstSubCat] = useState([{ value: null }]);
    const [secondSubCat, setSecondSubCat] = useState([{ value: null }]);
    const [thirdSubCat, setThirdSubCat] = useState([{ value: null }]);

    const [selectCategory, setSelectCategory] = useState();
    const [selectUser, setSelectUser] = useState();
    const [selectFirstSubCat, setSelectFirstSubCat] = useState();
    const [selectSecondSubCat, setSelectSecondSubCat] = useState();
    const [selectThirdSubCat, setSelectThirdSubCat] = useState();


    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(productName, productDescription, mainImage, selectCategory, selectFirstSubCat, selectSecondSubCat, selectThirdSubCat, regularPrice, salePrice, premium, weaklyDeal, liveStream, selectUser);

        if (!productName) {
            document.getElementById("product-name").style.display = "block";
            return;
        }
        if (!productDescription) {
            document.getElementById("product-description").style.display = "block";
            return;
        }
        if (!regularPrice) {
            document.getElementById("regular-price").style.display = "block";
            return;
        }
        if (!mainImage) {
            document.getElementById("product-main-img").style.display = "block";
            return;
        }
        if (!productImages) {
            document.getElementById("product-imgs").style.display = "block";
            return;
        }
        if (!selectCategory) {
            document.getElementById("category-name").style.display = "block";
            return;
        }
        if (!selectUser) {
            document.getElementById("user").style.display = "block";
            return;
        }

        axios.post(`${PORT}/addLatestProducts`, { productName, productDescription, mainImage, productImages, selectCategory, selectFirstSubCat, selectSecondSubCat, selectThirdSubCat, regularPrice, salePrice, premium, weaklyDeal, liveStream, selectUser }).then((response, err) => {
            console.log("sdsd", response);
            history.push({ pathname: "/ProductList", customdata: { pathname } });

        })

    }


    useEffect(async () => {
        await axios.get(`${PORT}/getCategorys`).then((response, err) => {
            setAllCategory(response);
        })
    }, [])

    useEffect(async () => {
        await axios.get(`${PORT}/getUsers`).then((response, err) => {
            console.log("total Users", response);
            setAllUser(response);
        })
    }, [])

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                180,
                180,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });



    const handleProductName = (event) => {
        document.getElementById("product-name").style.display = "none";
        setProductName(event.target.value);
    }

    const handleProductDescription = (event) => {
        document.getElementById("product-description").style.display = "none";
        setProductDescription(event.target.value);
    }

    const handleMainProductImage = async (event) => {

        document.getElementById("product-main-img").style.display = "none";
        const files = event.target.files[0];
        // const image = await resizeFile(files);


        let fData = new formData();
        fData.append('ProductImage', files);


        var config = {
            method: 'post',
            url: `${PORT}/uploadProduct`,
            data: fData
        };

        await axios(config)
            .then(function (response) {
                if (response.data.success == 1) {
                    console.log(response.data.image_url.split("/"));
                    const image = response.data.image_url.split("/");
                    setMainImage(image[5]);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const handleProductImages = async (event) => {
        console.log("frontallfiles", event.target.files);
        document.getElementById("product-imgs").style.display = "none";

        var fmData = new formData();
        for (let i = 0; i < event.target.files.length; i++) {
            let files = event.target.files[i];
            //   const image = await resizeFile(files);
            fmData.append('ProductImages', files);
        }

        var config = {
            method: 'post',
            url: `${PORT}/uploadProductMultiple`,
            data: fmData
        };

        await axios(config)
            .then(function (response) {
                if (response.data.success == 1) {
                    console.log("alluploadedimages", response.data.allValue);
                    setProductImages(response.data.allValue);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    //////////////////////////////////////////////////////////////////////////
    const handleCategoryName = async (event) => {
        document.getElementById("category-name").style.display = "none";
        setSelectCategory(event.target.value);
        await axios.post(`${PORT}/getFirstSubCategory`, { params: { id: event.target.value } }).then((response, err) => {
            setFirstSubCat(response);
        })
    }

    const handleUserName = (event) => {
        console.log(event.target.value);
        document.getElementById("user").style.display = "none";
        setSelectUser(event.target.value);
    }

    const handleFirstSubCategoryName = (event) => {
        setSelectFirstSubCat(event.target.value);
        axios.post(`${PORT}/getSecondCategorys`, { params: { firstSubCategory: event.target.value } }).then((response, err) => {
            setSecondSubCat(response);
        })
    }

    const handleSecondSubCategoryName = (event) => {
        setSelectSecondSubCat(event.target.value);
        axios.post(`${PORT}/getThirdCategorys`, { params: { secondSubCategory: event.target.value } }).then((response, err) => {
            setThirdSubCat(response);
            console.log("total thirdsubcategorys", response);
        })
    }

    const handleThirdSubCategoryName = (event) => {
        setSelectThirdSubCat(event.target.value);
    }
    //////////////////////////////////////////////////////////////////////////


    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handlePremium = (e) => {
        console.log("checked", e.target.checked);
        if (e.target.checked == true) {
            console.log("checked");
            setPremium(true);
        }
        else {
            console.log("not checked");
            setPremium(false);
        }
    }

    const handleWeaklyDeals = (e) => {
        console.log("checked", e.target.checked);
        if (e.target.checked == true) {
            console.log("checked");
            setWeaklyDeal(true);
        }
        else {
            console.log("not checked");
            setWeaklyDeal(false);
        }
    }


    const handleLiveStream = (e) => {
        console.log("checked", e.target.checked);
        if (e.target.checked == true) {
            console.log("checked");
            setLiveStream(true);
        }
        else {
            console.log("not checked");
            setLiveStream(false);
        }
    }
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@






    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleSalePrice = (e) => {
        console.log(e.target.value);
        setSalePrice(e.target.value);
    }

    const handleRegularPrice = (e) => {
        console.log(e.target.value);
        document.getElementById("regular-price").style.display = "none";
        setRegularPrice(e.target.value);
    }
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@





    return (
        <div class="work_space">
            <div class="workspace_title_hd">
                <div class="row">
                    <div class="col-md-12">
                        <div class="titleof_page">
                            <ul class="breadcrumb_list topsmall_bottom">
                                <li><a href="#">Admin Panel </a></li>
                                <li><i style={{ padding: "5px" }} class="fa fa-angle-right"></i></li>
                                <li>Add Product</li>
                            </ul>
                            <h2 class="main_title">Add Product</h2>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                            <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">

                                <form onSubmit={handleSubmit}>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">                   
                                              <h4 class="fieldsec_lab">Product Name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input onChange={handleProductName} type="text" class="fuilddesh1" placeholder="Product Name" />
                                                      
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="product-name" style={{ display: "none" }}>Product Name is required*</p>
                                            </div>
                                        </div>



                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Product Description<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <textarea rows="8" onChange={handleProductDescription} type="text" class="fuilddesh1" placeholder="Product Description"></textarea>
                                                    
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="product-description" style={{ display: "none" }}>Product Description is required*</p>
                                            </div>
                                        </div>



                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Regular Price<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input onChange={handleRegularPrice} type="number" class="fuilddesh1" placeholder="$750" />
                               
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="regular-price" style={{ display: "none" }}>Regular Price is required*</p>
                                            </div>
                                        </div>


                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Sale Price</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input onChange={handleSalePrice} type="number" class="fuilddesh1" placeholder="$500" /><span class="requlab_span pl-0">
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Product Main Image<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input onChange={handleMainProductImage} type="file" class="fuilddesh1" name="img" placeholder="Product Image(main)" />
                                                     
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="product-main-img" style={{ display: "none" }}>Product Main Image is required*</p>
                                            </div>
                                        </div>


                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Product Images<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input multiple onChange={handleProductImages} type="file" class="fuilddesh1" name="img" placeholder="Product Images" />
                                                      
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="product-imgs" style={{ display: "none" }}>Product Images is required*</p>
                                            </div>
                                        </div>



                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Category Name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <select onChange={handleCategoryName} value={selectCategory} class="fuilddesh1">
                                                            <option value={""}>{"SELECT CATEGORY"}</option>
                                                            {
                                                                allCategory && allCategory.data && allCategory.data.map((cat) => {
                                                                    return (
                                                                        <option value={cat._id}>{cat.categoryName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="category-name" style={{ display: "none" }}>Category Name is required*</p>
                                            </div>
                                        </div>



                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">First Subcategory Name</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <select onChange={handleFirstSubCategoryName} value={selectFirstSubCat} class="fuilddesh1">
                                                            <option value={""}>{"SELECT CATEGORY"}</option>
                                                            {
                                                                firstSubCat && firstSubCat.data && firstSubCat.data.map((cat) => {
                                                                    return (
                                                                        <option value={cat._id}>{cat.subcategoryName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {/* <span class="requlab_span pl-0">
                                                    <div class="checkBtablss">
                                                        <ul>
                                                            <li>
                                                                <div class="check-box">
                                                                    <label for="Company103">*Required Field</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Second Subcategory Name</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <select onChange={handleSecondSubCategoryName} value={selectSecondSubCat} class="fuilddesh1">
                                                            <option value={""}>{"SELECT CATEGORY"}</option>
                                                            {
                                                                secondSubCat && secondSubCat.data && secondSubCat.data.map((cat) => {
                                                                    return (
                                                                        <option value={cat._id}>{cat.secondSubCategoryName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {/* <span class="requlab_span pl-0">
                                                    <div class="checkBtablss">
                                                        <ul>
                                                            <li>
                                                                <div class="check-box">
                                                                    <label for="Company103">*Required Field</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Third Subcategory Name</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <select onChange={handleThirdSubCategoryName} value={selectThirdSubCat} class="fuilddesh1">
                                                            <option value={""}>{"SELECT CATEGORY"}</option>
                                                            {
                                                                thirdSubCat && thirdSubCat.data && thirdSubCat.data.map((cat) => {
                                                                    return (
                                                                        <option value={cat._id}>{cat.thirdSubCategoryName}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        {/* <span class="requlab_span pl-0">
                                                    <div class="checkBtablss">
                                                        <ul>
                                                            <li>
                                                                <div class="check-box">
                                                                    <label for="Company103">*Required Field</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </span> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">User<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <select onChange={handleUserName} value={selectUser} class="fuilddesh1">
                                                            <option value={""}>{"SELECT USER"}</option>
                                                            {
                                                                allUser && allUser.data && allUser.data.map((user) => {
                                                                    return (
                                                                        <option value={user._id}>{user.email}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="text-danger" id="user" style={{ display: "none" }}>User is required*</p>
                                            </div>
                                        </div>
                                       
                                    </div>



                                    <div class="row">
                                    <div class="col-md-4">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Is Premium</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input id="isPremium" hidden type="checkbox" onClick={handlePremium} class="" />
                                                        <label class="customLabel" for="isPremium"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">                                        <h4 class="fieldsec_lab">Weakly Deals</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input type="checkbox" id="weeklyCheck" hidden onClick={handleWeaklyDeals} class="" />
                                                        <label class="customLabel" for="weeklyCheck"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <h4 class="fieldsec_lab">Live Streaming</h4>
                                                <div class=" ">
                                                    <div class="repwat_one">
                                                        <input type="checkbox" id="liveStrema" hidden onClick={handleLiveStream} class="" />
                                                        <label class="customLabel" for="liveStrema"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    <div className="bottomsend_button text-center small_margin_top">
                                        <button type="submit" className="savesidebutt closefix">Save</button>
                                        {/* <button type="button" className="savesidebutt closefix">Reset</button> */}
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default AddProduct
