import React from "react";
import AllImages from "../../constant/img";
import { useLocation, Link } from "react-router-dom";
import Commmon from "../menu/common";
import Logo from "../menu/logo";
import Monetization from "../menu/monetization";
import Cms from "../menu/cms";
import UserAccount from "../menu/userAccount";
import Categories from "../menu/categories";
import Product from "../menu/product";
import Faq from "../menu/faq";
import Brand from "../menu/brand";
import NewsletterManagement from "../menu/newslettermanagement";
import ContactUs from "../menu/contact";
import DeactivateAccountReason from "../menu/deactivateaccountreason";
import Learn from "../menu/learn";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  // console.log("pathnamehaiyesidebarme", location.pathname);

  const allPathEdit = pathname.split("/");
  // console.log("allpathinsidebar", allPathEdit);

  const handleRemove = () => {
    document.body.classList.remove("overflow-none");
  };
  const siderRemove = () => {
    document.body.classList.remove("hidemenu_title");
  };

  if (
    location.pathname == "/" ||
    location.pathname == "/forgetPassword" ||
    allPathEdit[1] == "confirmPassword"
  ) {
    return null;
  } else {
    return (
      <div
        class="left_menubar chiller-theme toggled mCustomScrollbar"
        data-mcs-theme="dark"
      >
        <div onClick={handleRemove} class="transparent-layer"></div>
        <nav onMouseEnter={siderRemove} id="sidebar" class="sidebar-wrapper">
          <div onClick={handleRemove} id="filter-closer">
            <img src={AllImages.CANCEL_IMAGE} alt="icon" />
          </div>
          <div class="logo_header">
            <Link to="/admin">
              <img
                class="logoslice01"
                // src={AllImages.LOGO02_IMAGE}
                src = "https://dlevelns.stage02.obdemo.com/image/logo/headerImage_1711958521736.png"
                alt="Logo"
                style={{backgroundColor:"white", padding:"3px"}}
              />
              {/* <img
                class="logoslice02"
                src={AllImages.LOGO03_IMAGE}
                alt="Logo"
              /> */}
            </Link>
          </div>

          <div class="sidebar-content">
            <div className="sidebar-menu" id="accordion">
              <ul>
                <Commmon props={pathname} />
                <Logo props={pathname} />
                <Monetization props={pathname} />
                <Categories props={pathname} />
                <UserAccount props={pathname} />
                <Cms props={pathname} />
                <Product props={pathname} />
                <Brand props={pathname} />
                <Faq props={pathname} />
                <Learn props={pathname} />
                <ContactUs props={pathname} />
                <NewsletterManagement props={pathname} />
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default Sidebar;
