import React, { useState, useEffect } from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { toast, ToastContainer } from "react-toastify";

const Accounts = () => {

    const PORT = PORTNUMBER.PORTNUMBER;

    const [emailChangeVerification, setEmailChangeVerification] = useState();
    const [emailAddress, setEmailAddress] = useState();
    const [facebook, setFacebook] = useState();
    const [google, setGoogle] = useState();
    const [terminationOfUserAccount, setTerminationOfUserAccount] = useState();
    const [sendUserAccountPasswordEmail, setSendUserAccountPasswordEmail] = useState();
    const [sendNotificationToAdmin, setSendNotificationToAdmin] = useState();
    const [newListingsInAdded, setNewListingsInAdded] = useState();
    const [userRegisters, setUserRegisters] = useState();
    const [itemPending, setItemPending] = useState();
    const [sendNotificationToUser, setSendNotificationToUser] = useState();
    const [listingIsPosted, setListingIsPosted] = useState();
    const [itemExpired, setItemExpired] = useState();
    const [slug, setSlug] = useState();
    const[chargebackPenalty,setChargebackPenalty] = useState();

    useEffect(async () => {
        GETALL();
    }, [])

    // Get Function for ALL DATA

    const GETALL = async () => {
        await axios.get(`${PORT}/account/all`).then((res, err) => {
            if (res) {
                console.log(res.data.data);

                if (res.data.data != '') {
                    setSlug(res.data.data[0].slug);

                    setEmailChangeVerification(res.data.data[0].common_setting[0].emailChangeVerification);
                    setEmailAddress(res.data.data[0].common_setting[0].emailAddress);
                    setFacebook(res.data.data[0].common_setting[0].facebook);
                    setGoogle(res.data.data[0].common_setting[0].google);
                    setTerminationOfUserAccount(res.data.data[0].common_setting[0].terminationOfUserAccount);
                    setSendUserAccountPasswordEmail(res.data.data[0].common_setting[0].sendUserAccountPasswordEmail);
                    setSendNotificationToAdmin(res.data.data[0].common_setting[0].sendNotificationToAdmin);
                    setNewListingsInAdded(res.data.data[0].common_setting[0].newListingsInAdded);
                    setUserRegisters(res.data.data[0].common_setting[0].userRegisters);
                    setItemPending(res.data.data[0].common_setting[0].itemPending);
                    setSendNotificationToUser(res.data.data[0].common_setting[0].sendNotificationToUser);
                    setListingIsPosted(res.data.data[0].common_setting[0].listingIsPosted);
                    setItemExpired(res.data.data[0].common_setting[0].itemExpired);
                    setChargebackPenalty(res.data.data[0].common_setting[0].chargebackPenalty)
                }
            }
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (emailChangeVerification == 1) {
            var emailChangeVerification_Check = true;
        } else {
            var emailChangeVerification_Check = false;
        }
        if (emailAddress == 1) {
            var emailAddress_Check = true;
        } else {
            var emailAddress_Check = false;
        }
        if (facebook == 1) {
            var facebook_Check = true;
        } else {
            var facebook_Check = false;
        }
        if (google == 1) {
            var google_Check = true;
        } else {
            var google_Check = false;
        }
        if (terminationOfUserAccount == 1) {
            var terminationOfUserAccount_Check = true;
        } else {
            var terminationOfUserAccount_Check = false;
        }
        if (sendUserAccountPasswordEmail == 1) {
            var sendUserAccountPasswordEmailn_Check = true;
        } else {
            var sendUserAccountPasswordEmailn_Check = false;
        }
        if (sendNotificationToAdmin == 1) {
            var sendNotificationToAdmin_Check = true;
        } else {
            var sendNotificationToAdmin_Check = false;
        }
        if (newListingsInAdded == 1) {
            var newListingsInAdded_Check = true;
        } else {
            var newListingsInAdded_Check = false;
        }
        if (userRegisters == 1) {
            var userRegisters_Check = true;
        } else {
            var userRegisters_Check = false;
        }
        if (itemPending == 1) {
            var itemPending_Check = true;
        } else {
            var itemPending_Check = false;
        }
        if (sendNotificationToUser == 1) {
            var sendNotificationToUser_Check = true;
        } else {
            var sendNotificationToUser_Check = false;
        }
        if (listingIsPosted == 1) {
            var listingIsPosted_Check = true;
        } else {
            var listingIsPosted_Check = false;
        }
        if (itemExpired == 1) {
            var itemExpired_Check = true;
        } else {
            var itemExpired_Check = false;
        }

        var common_settings = [{
            'emailChangeVerification': emailChangeVerification_Check,
            'emailAddress': emailAddress_Check,
            'facebook': facebook_Check,
            'google': google_Check,
            'terminationOfUserAccount': terminationOfUserAccount_Check,
            'sendUserAccountPasswordEmail': sendUserAccountPasswordEmailn_Check,
            'sendNotificationToAdmin': sendNotificationToAdmin_Check,
            'newListingsInAdded': newListingsInAdded_Check,
            'userRegisters': userRegisters_Check,
            'itemPending': itemPending_Check,
            'sendNotificationToUser': sendNotificationToUser_Check,
            'listingIsPosted': listingIsPosted_Check,
            'itemExpired': itemExpired_Check,
            'chargebackPenalty': chargebackPenalty 
        }];
        console.log(slug);

        if (slug == 'Account') {
            await axios.post(`${PORT}/account/update`, { common_settings: JSON.stringify(common_settings) }).then((res, err) => {
                if (res) {
                    toast.success("You have successfully changed the settings");
                }
            })
        } else {
            await axios.post(`${PORT}/account/create`, { common_settings: JSON.stringify(common_settings) }).then((res, err) => {
                if (res) {
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();

    }
    const handleFacebook = (event) => {
        setFacebook(event.target.value);
    }
    const handleGoogle = (event) => {
        setGoogle(event.target.value);
    }
    const handleEmailChangeVerification = (event) => {
        setEmailChangeVerification(event.target.value);
    }
    const handleEmailAddress = (event) => {
        setEmailAddress(event.target.value);
    }
    const handleTerminationOfUserAccount = (event) => {
        setTerminationOfUserAccount(event.target.value);
    }
    const handleChargebackPenalty = (event) =>{
        setChargebackPenalty(event.target.value);
    }
    const handleSendUserAccountPasswordEmail = (event) => {
        setSendUserAccountPasswordEmail(event.target.value);
    }
    const handleSendNotificationToAdmin = (event) => {
        setSendNotificationToAdmin(event.target.value);
    }
    const handleNewListingsInAdded = (event) => {
        setNewListingsInAdded(event.target.value);
    }
    const handleUserRegisters = (event) => {
        setUserRegisters(event.target.value);
    }
    const handleItemPending = (event) => {
        setItemPending(event.target.value);
    }
    const handleSendNotificationToUser = (event) => {
        setSendNotificationToUser(event.target.value);
    }
    const handleListingIsPosted = (event) => {
        setListingIsPosted(event.target.value);
    }
    const handleItemExpired = (event) => {
        setItemExpired(event.target.value);
    }
    return (
        <div class="tab-pane" id="basic02" role="tabpanel">
            <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Accounts</span></h3>
                <div class="labelsidetop">
                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Common Settings</h4>
                        <div class="Tbaleflex">
                            <div class="Table">
                                {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Password strength control</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                    <input type="radio" id="test21" name="radio-group" checked />
                                    <label for="test21" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                    <input type="radio" id="test22" name="radio-group" />
                                    <label for="test22" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Allow users to stay signed in</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                    <input type="radio" id="test23" name="radio-group23" />
                                    <label for="test23" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                    <input type="radio" id="test24" name="radio-group23" checked />
                                    <label for="test24" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>email change verification</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="emailChangeVerification1" name="emailChangeVerification" value='1' onClick={handleEmailChangeVerification} checked={emailChangeVerification == 1 ? 'checked' : ''} />
                                                        <label for="emailChangeVerification1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="emailChangeVerification2" name="emailChangeVerification" value='0' onClick={handleEmailChangeVerification} checked={emailChangeVerification == 0 ? 'checked' : ''} />
                                                        <label for="emailChangeVerification2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>User sign in with (Username) </span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="test27" name="radio-group25" />
                                        <label for="test27" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="test28" name="radio-group25" checked />
                                        <label for="test28" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>email address</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="emailAddress1" name="emailAddress" value='1' onClick={handleEmailAddress} checked={emailAddress == 1 ? 'checked' : ''} />
                                                        <label for="emailAddress1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="emailAddress2" name="emailAddress" value='0' onClick={handleEmailAddress} checked={emailAddress == 0 ? 'checked' : ''} />
                                                        <label for="emailAddress2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Facebook</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="facebook1" name="facebook" value='1' onClick={handleFacebook} checked={facebook == 1 ? 'checked' : ''} />
                                                        <label for="facebook1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="facebook2" name="facebook" value='0' onClick={handleFacebook} checked={facebook == 0 ? 'checked' : ''} />
                                                        <label for="facebook2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Google</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="google1" name="google" value='1' onClick={handleGoogle} checked={google == 1 ? 'checked' : ''} />
                                                        <label for="google1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="google2" name="google" value='0' onClick={handleGoogle} checked={google == 0 ? 'checked' : ''} />
                                                        <label for="google2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Apple</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">                                                  
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="test35" name="radio-group29" />
                                        <label for="test35" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="test36" name="radio-group29" checked />
                                        <label for="test36" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Termination of user accounts</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid radioequal_flex">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="terminationOfUserAccount1" name="terminationOfUserAccount" value='1' onClick={handleTerminationOfUserAccount} checked={terminationOfUserAccount == 1 ? 'checked' : ''} />
                                                        <label for="terminationOfUserAccount1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="terminationOfUserAccount2" name="terminationOfUserAccount" value='0' onClick={handleTerminationOfUserAccount} checked={terminationOfUserAccount == 0 ? 'checked' : ''} />
                                                        <label for="terminationOfUserAccount2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <span class="dropradio">Enable "Remove Account" button in user profiles allowing them to remove their accounts</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Send user account password in email</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid radioequal_flex">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="sendUserAccountPasswordEmail1" name="sendUserAccountPasswordEmail" value='1' onClick={handleSendUserAccountPasswordEmail} checked={sendUserAccountPasswordEmail == 1 ? 'checked' : ''} />
                                                        <label for="sendUserAccountPasswordEmail1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="sendUserAccountPasswordEmail2" name="sendUserAccountPasswordEmail" value='0' onClick={handleSendUserAccountPasswordEmail} checked={sendUserAccountPasswordEmail == 0 ? 'checked' : ''} />
                                                        <label for="sendUserAccountPasswordEmail2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <span class="dropradio">When Disabled password to user accounts will be replaced with a dummy text for security reasons.</span>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Spare</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                    <input type="radio" id="test41" name="radio-group32" />
                                    <label for="test41" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                    <input type="radio" id="test42" name="radio-group32" checked />
                                    <label for="test42" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Send notification to admin</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid multial_lbel">
                                            <div class="flexradioS special_display">
                                                <div class="labelsidefx">
                                                    <p></p>
                                                </div>
                                                <div class="form_group">
                                                    <ul class="list">
                                                        <li class="list__item">
                                                            <input type="radio" id="sendNotificationToAdmin1" name="sendNotificationToAdmin" value='1' onClick={handleSendNotificationToAdmin} checked={sendNotificationToAdmin == 1 ? 'checked' : ''} />
                                                            <label for="sendNotificationToAdmin1" class="label">Enable</label>
                                                        </li>
                                                        <li class="list__item">
                                                            <input type="radio" id="sendNotificationToAdmin2" name="sendNotificationToAdmin" value='0' onClick={handleSendNotificationToAdmin} checked={sendNotificationToAdmin == 0 ? 'checked' : ''} />
                                                            <label for="sendNotificationToAdmin2" class="label">Disable</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>When new listings in added</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="newListingsInAdded1" name="newListingsInAdded" value='1' onClick={handleNewListingsInAdded} checked={newListingsInAdded == 1 ? 'checked' : ''} />
                                                        <label for="newListingsInAdded1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="newListingsInAdded2" name="newListingsInAdded" value='0' onClick={handleNewListingsInAdded} checked={newListingsInAdded == 0 ? 'checked' : ''} />
                                                        <label for="newListingsInAdded2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>When user registers</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="userRegisters1" name="userRegisters" value='1' onClick={handleUserRegisters} checked={userRegisters == 1 ? 'checked' : ''} />
                                                        <label for="userRegisters1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="userRegisters2" name="userRegisters" value='0' onClick={handleUserRegisters} checked={userRegisters == 0 ? 'checked' : ''} />
                                                        <label for="userRegisters2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>When item pending</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="itempending1" name="itempending" value='1' onClick={handleItemPending} checked={itemPending == 1 ? 'checked' : ''} />
                                                        <label for="itempending1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="itempending2" name="itempending" value='0' onClick={handleItemPending} checked={itemPending == 0 ? 'checked' : ''} />
                                                        <label for="itempending2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Send notification to user</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid multial_lbel">
                                            <div class="flexradioS special_display">
                                                <div class="labelsidefx">
                                                    <p></p>
                                                </div>
                                                <div class="form_group">
                                                    <ul class="list">
                                                        <li class="list__item">
                                                            <input type="radio" id="sendNotificationToUser1" name="sendNotificationToUser" value='1' onClick={handleSendNotificationToUser} checked={sendNotificationToUser == 1 ? 'checked' : ''} />
                                                            <label for="sendNotificationToUser1" class="label">Enable</label>
                                                        </li>
                                                        <li class="list__item">
                                                            <input type="radio" id="sendNotificationToUser2" name="sendNotificationToUser" value='0' onClick={handleSendNotificationToUser} checked={sendNotificationToUser == 0 ? 'checked' : ''} />
                                                            <label for="sendNotificationToUser2" class="label">Disable</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>When listing is posted</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="listingIsPosted1" name="listingIsPosted" value='1' onClick={handleListingIsPosted} checked={listingIsPosted == 1 ? 'checked' : ''} />
                                                        <label for="listingIsPosted1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="listingIsPosted2" name="listingIsPosted" value='0' onClick={handleListingIsPosted} checked={listingIsPosted == 0 ? 'checked' : ''} />
                                                        <label for="listingIsPosted2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>When item expired</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="itemExpired1" name="itemExpired" value='1' onClick={handleItemExpired} checked={itemExpired == 1 ? 'checked' : ''} />
                                                        <label for="itemExpired1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="itemExpired2" name="itemExpired" value='0' onClick={handleItemExpired} checked={itemExpired == 0 ? 'checked' : ''} />
                                                        <label for="itemExpired2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Chargeback Penalty</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" id="number_of_attempts_allowed" class="fuilddesh minwidth02" placeholder="" value={chargebackPenalty} onChange={handleChargebackPenalty}/>
                            </div>
                            </div>
                        </div>
                                {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Before item expires with</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid"> 
                            <div class="form_group">                       
                                <span>
                                <div class="dropdownsid">
                                    <select class="fuilddesh">
                                    <option value="">Days</option>
                                    <option value="">Days</option>
                                    </select>
                                </div>
                                </span>
                            </div>
                        </div>
                        </div>
                    </div> */}

                            </div>
                        </div>
                    </div>

                    {/* <div class="boxrepetform">
                <h4 class="tabcont_Sublable">Membership Setting</h4>
                <div class="Tbaleflex">
                <div class="Table">
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Preferred language redirect</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                    <input type="radio" id="test57" name="radio-group40" />
                                    <label for="test57" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                    <input type="radio" id="test58" name="radio-group40" checked />
                                    <label for="test58" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div> */}
                </div>
                <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Accounts
