import React, {useState, useEffect} from 'react';
import axios from "axios";
import {useLocation, useHistory} from "react-router-dom";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import {Multiselect} from "multiselect-react-dropdown";


import PORTNUMBER from "../../../constant/api";



const EditSubCategory = (props) => {


    const PORT = PORTNUMBER.PORTNUMBER;

    const [subCategory, setSubCategory] = useState();
    const [slug, setSlug] = useState();

    const [selectCategoryField, setSelectCategoryField] = useState();
    const [selectCategoryType, setSelectCategoryType] = useState();
    
    const [allCategoryField, setAllCategoryField] = useState();
    const [allCategoryType, setAllCategoryType] = useState();


    const id = props.match.params.id;
    const location = useLocation();
    const history = useHistory();
    const pathname = location.pathname;


    useEffect(() => {
      axios.post(`${PORT}/getFirstSubCategoryEdit`, {params:{id}}).then((res, err) =>{
        console.log("editedit",res);
        setSubCategory(res.data.subcategoryName);
        setSlug(res.data.slug);
    })
    },[])
    

    const handleReset = (event) =>{
        event.preventDefault();
        setSubCategory("");
        setSlug("");
    }


    const handleSubmit = async (event) =>{
        event.preventDefault();      
        console.log(subCategory);
        console.log(slug);
        if(!subCategory)
        {
          document.getElementById("category-name").style.display="block"
          return;
        } 
        if(!slug)
        {
          document.getElementById("slug").style.display="block";
          return;
        }
        await axios.post(`${PORT}/editFirstSubCategory`, {params:{id, subCategory, slug}}).then((res, err) =>{
            console.log(res);
            history.push({pathname:"/category", customdata:{pathname}});
        }) 
        
    }



    const handleSubCategory = (event) =>{
      event.preventDefault();
      document.getElementById("category-name").style.display="none";
      setSubCategory(event.target.value);

      let data = event.target.value;
      let data1 = data.toLowerCase();

      data1 = data1.replace(/\s/g , "-");
      
      setSlug(data1);
    }

    const handleSlug = (event) =>{
      event.preventDefault();
      document.getElementById("slug").style.display="none";
      setSlug(event.target.value);
    }


    const handleMultipleCategoryFieldSelect = (data) =>{
      data.map((selectdata)=>{
        if(selectdata.value === "Category Type")
        {
          return(
           document.getElementById("show-type").style.display="block"
            )
        }
      }) 
      setSelectCategoryField(data);
    }

    const handleMultipleCategoryFieldRemove = (data) =>{
      data.map((selectdata)=>{
        if(selectdata.value === "Category Type")
        {
          return;
        }
        else{
          return(
            document.getElementById("show-type").style.display="none"
             )
        }
      })
    }

    const handleMultipleCategoryType = (data) =>{
      setSelectCategoryType(data);
    }



    return (
        <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
                <div class="col-md-12">
                  <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li><a href="#">Admin Panel </a></li>
                  <li><i style={{padding:"5px"}} class="fa fa-angle-right"></i></li>
                  <li>Edit Firstsub Category</li>
                </ul>
                    <h2 class="main_title">Edit Firstsub Category</h2>
                  </div>
                </div>
            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                <div class="custom_fuild_addM topsmall_top flexcoluman">
                  
                  
                  
                  <form onSubmit={handleSubmit}>

                  <ToastContainer />


                  <div class="bindfields">
                    <h4 class="fieldsec_lab">Sub Category Name</h4>
                    <div class="field_sec_repetM">
                      <div class="repwat_one">
                        <input onChange={handleSubCategory} type="text" class="fuilddesh" placeholder="Sub Category Name" value={subCategory} /><span class="requlab_span">
                            <div class="checkBtablss">     
                              <ul>
                                <li>
                                  <div class="check-box">
                                    {/* <input type="checkbox" name="option" id="Company103" /> */}
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </span>
                      </div>
                    </div>
                    <p className="text-danger" id="category-name" style={{display:"none"}}>Category Name is required*</p>
                  </div>


                  <div class="bindfields">
                    <h4 class="fieldsec_lab">Slug</h4>
                    <div class="field_sec_repetM">
                      <div class="repwat_one">
                        <input onChange={handleSlug} type="text" class="fuilddesh" name="" placeholder="Slug" value={slug} /><span class="requlab_span">
                            <div class="checkBtablss">     
                              <ul>
                                <li>
                                  <div class="check-box">
                                    {/* <input type="checkbox" name="option" id="Company103" /> */}
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </span>
                      </div>
                    </div>
                    <p className="text-danger" id="slug" style={{display:"none"}}>Slug is required*</p>
                  </div>

     


                  <div className="bottomsend_button text-center small_margin_top">
                  <button type="submit" className="savesidebutt closefix">Save</button>
                  <button type="button" onClick={handleReset} className="savesidebutt closefix">Reset</button>
                  </div>


                  </form>

                </div>
             
               </div>
            </div>
          </div>
        </div>
     
  </div>
    )
}

export default EditSubCategory

