import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";
import TimezoneSelect from 'react-timezone-select'
const General = () => {

    const PORT = PORTNUMBER.PORTNUMBER;
    const [allTimeZone, setAllTimeZone] = useState();
   
    const [facebook, setFacebook] = useState();
    const [twitter, setTwitter] = useState();
    const [adminServiceCharge, setAdminServiceCharge] = useState();
    const [timezone, setTimeZone] = useState({});
    const [slug,setSlug]  = useState();
    const [maxShippingDay, setMaxShippingDay] = useState();
    const [maxReturnDay, setMaxReturnDay] = useState();
    const [maxCompleteDay, setMaxCompleteDay] = useState();
    const [buyerProtection, setBuyerProtection] = useState();
    const [deactivateAccount, setDeactivateAccount] = useState();


    useEffect(async () => {
        await axios.get(`${PORT}/general/timezone`).then((res, err)=>{
            if(res)
            {
                setAllTimeZone(res);
            }
        })
    }, [])

    useEffect(async () => {
    GETALL();
    }, [])

    const GETALL = async () =>{
        await axios.get(`${PORT}/general/all`).then((res, err)=>{
            console.log(res,'12345678')
            if(res) {
            
                if(res?.data?.data != ''){
                    setSlug(res.data.data[0].slug);
                   if(res?.data?.data?.common_setting){ setTimeZone(res.data.data[0].common_setting[0].timezone);}
                    setFacebook(res.data.data[0].miscellaneous[0].facebook);
                    setTwitter(res.data.data[0].miscellaneous[0].twitter);
                    if(res.data.data[0].miscellaneous[0].adminServiceCharge){
                        setAdminServiceCharge(res.data.data[0].miscellaneous[0].adminServiceCharge);
                    }
                    if(res.data.data[0].miscellaneous[0].maxShippingDay){
                        setMaxShippingDay(res.data.data[0].miscellaneous[0].maxShippingDay);
                    }
                    if(res.data.data[0].miscellaneous[0].maxReturnDay){
                        setMaxReturnDay(res.data.data[0].miscellaneous[0].maxReturnDay);
                    }
                    if(res.data.data[0].miscellaneous[0].maxCompleteDay){
                        setMaxCompleteDay(res.data.data[0].miscellaneous[0].maxCompleteDay);
                    }
                    if(res.data.data[0].miscellaneous[0].buyerProtection){
                        setBuyerProtection(res.data.data[0].miscellaneous[0].buyerProtection);
                    }
                    if(res.data.data[0].miscellaneous[0].deactivateAccount){
                        setDeactivateAccount(res.data.data[0].miscellaneous[0].deactivateAccount);
                    }
                }
            }
        })
    }

    const handleFacebook = (event) =>{
        setFacebook(event.target.value);
    }

    const handleTwitter = (event) =>{
        setTwitter(event.target.value);
    }
    
    const handleAdminServiceCharge = (event) =>{
        setAdminServiceCharge(event.target.value);
    }

    const handleTimeZone = (event) =>{
        setTimeZone(event.target.value);
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
        
        var common_settings     =   [ {
                                        'timezone': timezone.value,
                                    }];
       
        var miscellaneous        = [ {
                                        'facebook': facebook,
                                        'twitter':twitter,
                                        'adminServiceCharge':adminServiceCharge,
                                        'maxShippingDay':maxShippingDay,
                                        'maxReturnDay':maxReturnDay,
                                        'maxCompleteDay':maxCompleteDay,
                                        'buyerProtection':buyerProtection,
                                        'deactivateAccount' : deactivateAccount
                                    }];
      if(slug == 'GeneralSetting'){
            await axios.post(`${PORT}/general/update`, {common_settings:JSON.stringify(common_settings), miscellaneous:JSON.stringify(miscellaneous)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/general/create`,{common_settings:JSON.stringify(common_settings), miscellaneous:JSON.stringify(miscellaneous)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();
    }

    const handleMaxDayShipping = (event) =>{
        setMaxShippingDay(event.target.value);
    }

    const handleMaxDayReturn = (event) =>{
        setMaxReturnDay(event.target.value);
    }

    const handleMaxDayComplete = (event) =>{
        setMaxCompleteDay(event.target.value);
    }

    const onChangeBuyerProtection = (event) =>{
        setBuyerProtection(event.target.value);
    }

    const onChangeDeactivateAccount = (event) =>{
        setDeactivateAccount(event.target.value);
    }

    return (

      
        <div class="tab-pane active" id="basic01" role="tabpanel">
            <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>General</span></h3>
                <div class="labelsidetop">
                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Common Settings</h4>
                        <div class="Tbaleflex">
                            <div class="Table">
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Time zone</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                        <TimezoneSelect
                                            value={timezone}
                                            onChange={setTimeZone}
                                            class="fuilddesh"  />
                                            
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Miscellaneous</h4>
                        <div class="Tbaleflex">

                            <div class="Table">
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Your Facebook account URL</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input onChange={handleFacebook} value={facebook} type="text" class="fuilddesh minwidth02" placeholder="https://www.facebook.com/....." />
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Your Twitter account URL</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input onChange={handleTwitter} value={twitter} type="text" class="fuilddesh minwidth02" placeholder="https://www.twittercom/....." />
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Admin Service Charge</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input onChange={handleAdminServiceCharge} value={adminServiceCharge} type="number" class="fuilddesh minwidth02" placeholder="3" step="any" />
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Max Day Of Shipping</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input onChange={handleMaxDayShipping} value={maxShippingDay} type="number" class="fuilddesh minwidth02" placeholder="Days..." />
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Buyer Return Max Day</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input onChange={handleMaxDayReturn} value={maxReturnDay} type="number" class="fuilddesh minwidth02" placeholder="Days..." />
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Max Day of Order Complete</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input onChange={handleMaxDayComplete} value={maxCompleteDay} type="number" class="fuilddesh minwidth02" placeholder="Days..." />
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Buyer Protection</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <textarea onChange={onChangeBuyerProtection} value={buyerProtection} type="text" class="fuilddesh fuilddesh_textarea minwidth02" placeholder="Buyer Protection" ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Deactivate Account</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <textarea onChange={onChangeDeactivateAccount} value={deactivateAccount} type="text" class="fuilddesh fuilddesh_textarea minwidth02" placeholder="Deactivate Account" ></textarea>
                                        </div>
                                    </div>
                                </div>

                                
                            </div>

                        </div>
                    </div>
                </div>
 
                <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default General
