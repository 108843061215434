import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const LearnCategoriesAdd = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [category_name, setCategory] = useState();
  const [image_name, setImageName] = useState();

  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const handleReset = (event) => {
    event.preventDefault();
    setCategory("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!category_name) {
      document.getElementById("category-name").style.display = "block";
      return;
    }
    if (!image_name) {
      document.getElementById("image").style.display = "block";
      return;
    }
    await axios
      .post(`${PORT}/learncategories/create`, { category_name, image_name })
      .then((res, err) => {
        if (res) {
          handleClose();
          setCategory("");
          history.push({ pathname: "/learn-categories", MatchValue: "add" });
        }
      });
  };

  const handleCategory = (event) => {
    event.preventDefault();
    document.getElementById("category-name").style.display = "none";
    setCategory(event.target.value);
  };

  const handleImage = async (event) => {
    console.log("Ok");
    document.getElementById("image").style.display = "none";
    const files = event.target.files[0];
    // const image = await resizeFile(files);

    let fData = new formData();
    await fData.append("LearnCategoryImage", files);

    var config = {
      method: "post",
      url: `${PORT}/learncategory/image`,
      data: fData,
    };

    await axios(config)
      .then(function (response) {
        if (response.data.success == 1) {
          console.log(response.data.image_url.split("/"));
          const learncategoryimage = response.data.image_url.split("/");
          setImageName(learncategoryimage[6]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Learn Categories Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleCategory}
                          type="text"
                          className="fuilddesh"
                          placeholder=""
                          value={category_name}
                        />
                        <p
                          className="text-danger"
                          id="category-name"
                          style={{ display: "none" }}
                        >
                          Category Name is required
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Image</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleImage}
                          type="file"
                          class=""
                          name="img"
                          placeholder=""
                        />
                        <p
                          id="image"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Image is required*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default LearnCategoriesAdd;

//<div className="work_space">
//   <div className="workspace_title_hd">
//     <div className="row">
//       <div className="col-md-12">
//         <div className="titleof_page">
//           <ul className="breadcrumb_list topsmall_bottom">
//             <li>
//               <a href="#">Admin Panel </a>
//             </li>
//             <li>
//               <i
//                 style={{ padding: "5px" }}
//                 className="fa fa-angle-right"
//               ></i>
//             </li>
//             <li>Add FAQ Categories</li>
//           </ul>
//           <h2 className="main_title">Add FAQ Categories</h2>
//         </div>
//       </div>
//       <div className="col-md-12">
//         <div className="main_oflistingForm bg_light_gray onlyforcol_support">
//           <div className="custom_fuild_addM topsmall_top flexcoluman  edit-form">
//             <form onSubmit={handleSubmit}>
//               <ToastContainer />
//               <div class="row">
//                 <div class="col-md-6">
//               <div className=" form-group">
//                 <h4 className="fieldsec_lab">Category<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//                 <div className="    ">
//                   <div className="repwat_one">
//                     <input
//                       onChange={handleCategory}
//                       type="text"
//                       className="fuilddesh"
//                       placeholder="Category"
//                       value={category_name}
//                     />

//                   </div>
//                 </div>
//                 <p
//                   className="text-danger"
//                   id="category-name"
//                   style={{ display: "none" }}
//                 >
//                   Category Name is required
//                 </p>
//               </div>   </div>

//               <div class="col-md-6">

//               <div class=" form-group">
//                 <h4 class="fieldsec_lab">
//                   Image<p className="text-danger" style={{display:"inline-block"}}>*</p>
//                 </h4>
//                 <div class="      ">
//                   <div class="repwat_one">
//                     <input
//                       onChange={handleImage}
//                       type="file"
//                       class="fuilddesh"
//                       name="img"
//                       placeholder="Image"
//                     />
//                     <p
//                       id="image"
//                       className="text-danger"
//                       style={{ display: "none" }}
//                     >
//                      Image is required*
//                     </p>
//                   </div>

//                 </div>
//               </div>

//               </div>    </div>

//               <div className="bottomsend_button text-center small_margin_top">
//                 <button type="submit" className="savesidebutt closefix">
//                   Save
//                 </button>
//                 <button
//                   type="button"
//                   onClick={handleReset}
//                   className="savesidebutt closefix"
//                 >
//                   Reset
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
