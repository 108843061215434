import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import General from "../../helpers/General";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";



const PackageAdd = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allVideoStream, setAllVideoStream] = useState([]);
  const history = useHistory();

  const [packageName, setPackageName] = useState("");
  const [duration, setDuration] = useState("");
  const [adFeatured, setAdFeatured] = useState("");
  const [privateSellerAmount, setPrivateAmount] = useState("");
  const [businessSellerAmount, setBusinessAmount] = useState("");
  const [soletraderSellerAmount, setSoleTraderAmount] = useState("");
  const [videoStream, setVideoStream] = useState([""]);
  const [status, setStatus] = useState("");



  useEffect(async () => {
    await axios.get(`${PORT}/video-stream-charge/get_all`).then((res, err) => {
      setAllVideoStream(res.data.data);
    });
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!packageName) {
      document.getElementById("package-name").style.display = "block";
      return;
    }
    if (!duration) {
      document.getElementById("duration").style.display = "block";
      return;
    }
    
    if (!privateSellerAmount) {
      document.getElementById("private_amount").style.display = "block";
      return;
    }

    if (!businessSellerAmount) {
      document.getElementById("business_amount").style.display = "block";
      return;
    }

    if (!soletraderSellerAmount) {
      document.getElementById("soletrader_amount").style.display = "block";
      return;
    }

    if (!status) {
      document.getElementById("status").style.display = "block";
      return;
    }
    
    await axios.post(`${PORT}/package/create`, { packageName, duration, adFeatured, videoStream, privateSellerAmount, businessSellerAmount, soletraderSellerAmount, status })
      .then((res, err) => {
        if (res) {
          setPackageName("");
          setDuration("");
          setPrivateAmount("");
          setBusinessAmount("");
          setSoleTraderAmount("");
          setStatus("");
          setAdFeatured("");
          setVideoStream([""]);
          handleClose();
          history.push({ pathname: "/listing-packages", MatchValue: "add" });
        }
        else {
          toast.info("Something went wrong");
        }
      });
  }
  
  const handleClose = (event) => {
    document.body.classList.remove('fixedit_modal');
  }

  const handlePackage = (event) => {
    document.getElementById("package-name").style.display = "none";
    setPackageName(event.target.value);
  }

  const handleDuration = (event) => {
    document.getElementById("duration").style.display = "none";
    setDuration(event.target.value);
  }

  const handleAdFeatured = (event) => {
    setAdFeatured(event.target.value);
  }

  const handlePrivateAmount = (event) => {
    document.getElementById("private_amount").style.display = "none";
    setPrivateAmount(event.target.value);
  }
  const handleBusinessAmount = (event) => {
    document.getElementById("business_amount").style.display = "none";
    setBusinessAmount(event.target.value);
  }
  const handleSoletraderAmount = (event) => {
    document.getElementById("soletrader_amount").style.display = "none";
    setSoleTraderAmount(event.target.value);
  }

  const handleStatus = (event) => {
    setStatus(event.target.value);
  }

  const handleVideoStream = (i,event) => {
    let data = [...videoStream];
    data[i] = event.target.value;
    setVideoStream(data);
  }


  const addVideoStreamBlock = (i, e) => {
    setVideoStream([...videoStream, ""]);
  };


  const removeVideoStreamBlock = (i, e) => {
    let data = [...videoStream];
    data.splice(i, 1);
    setVideoStream(data);
  };

  

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
        <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Package Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Package Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          name="package"
                          onChange={handlePackage}
                          value={packageName}
                          class="fuilddesh"
                        >
                          <option value="">Select Package</option>
                          <option value="Basic">Basic</option>
                          <option value="Premium">Premium</option>
                          <option value="VIP">VIP</option>
                        </select>
                        <p
                          className="text-danger"
                          id="package-name"
                          style={{ display: "none" }}
                        >
                          Package Name is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Duration</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          name="duration"
                          onChange={handleDuration}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={duration}
                        />
                        <p
                          className="text-danger"
                          id="duration"
                          style={{ display: "none" }}
                        >
                          Duration is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Ad's featured</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          name="ad_reatured"
                          onChange={handleAdFeatured}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={adFeatured}
                        />
                        <p
                          className="text-danger"
                          id="ad_featured"
                          style={{ display: "none" }}
                        >
                          Ad's featured is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Private Amount</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          name="private_amount"
                          onChange={handlePrivateAmount}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={privateSellerAmount}
                        />
                        <p
                          className="text-danger"
                          id="private_amount"
                          style={{ display: "none" }}
                        >
                          Private amount is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Business Amount</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          name="business_amount"
                          onChange={handleBusinessAmount}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={businessSellerAmount}
                        />
                        <p
                          className="text-danger"
                          id="business_amount"
                          style={{ display: "none" }}
                        >
                          Business amount is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Soletrader Amount</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          name="soletrader_amount"
                          onChange={handleSoletraderAmount}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={soletraderSellerAmount}
                        />
                        <p
                          className="text-danger"
                          id="soletrader_amount"
                          style={{ display: "none" }}
                        >
                          Soletrader amount is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Video Streaming</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div>
                    {videoStream && videoStream.map((vsId,index) => {
                      return (<div class="display_webkit" key={index}>
                          <div class="dropdownsid">
                            <select
                              name="video_stream"
                              onChange={(event) => handleVideoStream(index,event)}
                              value={vsId}
                              class="fuilddesh"
                            >
                              <option value="">Select</option>
                              {allVideoStream && allVideoStream.map((data,i) => {
                                return (
                                  <option value={data._id} key={i}>{`${data.slot} ${data.label} - ${General.showPrice(data.price)}`}</option>
                                )
                              })}
                            </select>
                          </div>
                          {index === 0 ? 
                          <div className="col-2">
                            <a href={void(0)} onClick= {event => addVideoStreamBlock(index, event)} ><i class="fa fa-plus" aria-hidden="true"></i></a>
                          </div> : <div className="col-2">
                            <a href={void(0)} onClick= {event => removeVideoStreamBlock(index, event)} ><i class="fa fa-remove" aria-hidden="true"></i></a>
                          </div> }
                        </div>)
                    })}
                    </div>
                    </div>
                    
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Status</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          name="status"
                          onChange={handleStatus}
                          value={status}
                          class="fuilddesh"
                        >
                          <option value="">Select</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                        <p
                          className="text-danger"
                          id="status"
                          style={{ display: "none" }}
                        >
                          Status is required*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">SAVE</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PackageAdd;


