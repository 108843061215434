import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from "react-html-parser";

const AddBlock = () => {

    const PORT = PORTNUMBER.PORTNUMBER;

    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const [inputField , setInputField] = useState({
        categoryId: '',
        title: '',
        subTitle: '',
        description: ''
    });

    const [allCategory, setAllCategory] = useState([{ value: null }]);

    useEffect(async () => {
      await axios.get(`${PORT}/getCategorys`).then((response, err) => {
        setAllCategory(response);
      });
    }, []);

    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(!inputField.categoryId){
            document.getElementById("categoryId").style.display="block";
            return;
        }
        if(!inputField.title){
            document.getElementById("title").style.display="block";
            return;
        }
        await axios.post(`${PORT}/add_block`, {inputField}).then((response, err)=>{ 
        if(response){
          history.push({pathname:"/Blocks", customdata:{pathname}});
        }else{
            toast.info("Something went wrong");
        }     
      })
      }

    const handleReset = (event) =>{
      setInputField(() => ({
        categoryId: '',
        title: '',
        subTitle: '',
        description: ''
      }));
    }

    const handlePageDescription = (event, editor) =>{
        setInputField((prevState) => ({
          ...prevState,
          description: editor.getData(),
        }));
    }

    const inputsHandler = (e) =>{
      const { name, value } = e.target;
      setInputField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    return (
      <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
            <div class="col-md-12">
              <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li>
                    <a href="#">Admin Panel </a>
                  </li>
                  <li>
                    <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                  </li>
                  <li>Add Block</li>
                </ul>
                <h2 class="main_title">Add Block</h2>
              </div>
            </div>
            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">
                  <form onSubmit={handleSubmit}>
                    <div class="row">
                    <div class="col-md-6">
                    <div class=" form-group">
                      <h4 class="fieldsec_lab">
                        Title<p className="text-danger" style={{display:"inline-block"}}>*</p>
                      </h4>
                      <div class=" ">
                        <div class="repwat_one">
                          <input
                            onChange={inputsHandler}
                            name="title"
                            type="text"
                            class="fuilddesh1"
                            placeholder="Title"
                            value={inputField.title}
                          />
                        
                        </div>
                      </div>
                      <p
                        className="text-danger"
                        id="title"
                        style={{ display: "none" }}
                      >
                        Title is required*
                      </p>
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class=" form-group">
                      <h4 class="fieldsec_lab">
                        Sub Title
                      </h4>
                      <div class=" ">
                        <div class="repwat_one">
                          <input
                            onChange={inputsHandler}
                            name="subTitle"
                            type="text"
                            class="fuilddesh1"
                            placeholder="Sub Title"
                            value={inputField.subTitle}
                          />
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <h4 class="fieldsec_lab">
                          Category
                          <p
                            className="text-danger"
                            style={{ display: "inline-block" }}
                          >
                            *
                          </p>
                        </h4>
                        <div class="">
                          <div class="repwat_one">
                            <select
                              onChange={inputsHandler}
                              name="categoryId"
                              value={inputField.categoryId}
                              class="fuilddesh1  "
                            >
                              <option value={""}>{"SELECT CATEGORY"}</option>
                              {allCategory &&
                                allCategory.data &&
                                allCategory.data.map((cat) => {
                                  return (
                                    <option value={cat._id}>
                                      {cat.categoryName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <p
                          className="text-danger"
                          id="categoryId"
                          style={{ display: "none" }}
                        >
                          Category Name is required*
                        </p>
                      </div>
                    </div>
                   
                  <div class="col-md-12">
                    <div className=" form-group">
                      <h4 className="fieldsec_lab">Description</h4>
                      <div className=" ">
                        <div className="repwat_one">
                          <CKEditor
                            editor={ClassicEditor}
                            onChange={(e, editor) =>
                            handlePageDescription(e, editor)
                            }
                          />
                        </div>
                       
                      </div>
                    </div>
                    </div>
                    </div>
                    <div className="bottomsend_button text-center small_margin_top">
                      <button type="submit" className="savesidebutt closefix">
                        Save
                      </button>
                      <button
                        onClick={handleReset}
                        type="button"
                        className="savesidebutt closefix"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
}

export default AddBlock
