import React, { useState, useEffect } from "react";
import HttpsRedirect from "react-https-redirect";
import "./App.css";
import "./AppMedia.css";
import Admin from "./pages/admin/admin";
import Signup from "./pages/signup/signup";
import ForgetPassword from "./pages/forgetPassword/forgetPassword";
import ConfirmPassword from "./pages/confirmPassword/confirmPassword";
import Sidebar from "./components/sidebar/sidebar";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import UserAccount from "./components/userAccount/userAccount";
import SubscribedUser from "./components/subscribedUser/subscribedUser";
import ChangePassword from "./components/changePassword/changePassword";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import NewUser from "./components/newUser/newUser";
import EditUser from "./components/editUser/editUser";
import Category from "./components/categorySection/categoryField/categoryField";

import CategoryList from "./components/category/categoryList/categoryList"; //Added by Lokesh
import CategoryAdd from "./components/category/addCategory/addCategory"; //Added by Lokesh
import CategoryEdit from "./components/category/editCategory/editCategory"; //Added by Lokesh

import FieldsList from "./components/fields/fieldsList/fieldsList"; //Added by Lokesh
import FieldsAdd from "./components/fields/addField/addField"; //Added by Lokesh
import FieldsEdit from "./components/fields/editField/editField"; //Added by Lokesh
// import CategoryEdit from "./components/category/editCategory/editCategory"; //Added by Lokesh

import SubCategoryList from "./components/subCategory/subCategoryList/subCategoryList"; //Added by Lokesh
import SubCategoryAdd from "./components/subCategory/AddSubCategory/AddSubCategory"; //Added by Lokesh
import SubCategoryEdit from "./components/subCategory/editSubCategory/editSubCategory"; //Added by Lokesh

import AddCategory from "./components/categorySection/addCategory/addCategory";
import EditCategory from "./components/categorySection/editCategory/editCategory";

import AddSubCategory from "./components/categorySection/AddSubCategory/AddSubCategory";
// import SubCategory from "./components/categorySection/subCategoryField/subCategoryField";
import EditSubCategory from "./components/categorySection/editSubCategory/editSubCategory";
import SubFirst from "./components/categorySection/subCategoryField/subFirst/subFirst";

// import SubSecond from "./components/categorySection/subCategoryField/subSecond/subSecond";
// import AddSubSecond from "./components/categorySection/subCategoryField/subSecond/addSubSecond";
// import EditSubSecond from "./components/categorySection/subCategoryField/subSecond/editSubSecond";

// import SubThird from "./components/categorySection/subCategoryField/subThird/subThird";
// import AddSubThird from "./components/categorySection/subCategoryField/subThird/addSubThird";
// import EditSubThird from "./components/categorySection/subCategoryField/subThird/editSubThird";

import ProductList from "./components/productSection/productList/productList";
import ProductAdd from "./components/productSection/addProduct/addProduct";
import ProductEdit from "./components/productSection/editProduct/editProduct";
import ProductView from "./components/productSection/viewProduct/viewProduct";

import BrandList from "./components/brandSection/brandList/brandList";
import BrandAdd from "./components/brandSection/brandAdd/brandAdd";
import BrandEdit from "./components/brandSection/brandEdit/brandEdit";

import WeeklyDealsList from "./components/weeklyDeals/weeklyDealsList/weeklyDealsList";
// import WeeklyDealsAdd from "./components/weeklyDeals/weeklyDealsAdd/weeklyDealsAdd";
// import WeeklyDealsEdit from "./components/weeklyDeals/weeklyDealsEdit/weeklyDealsEdit";

import AddCategoryField from "./components/categorySection/addCategoryFields/addCategoryField";

import BasicSetting from "./components/basicSettingSection/basicSettingList";

import Pages from "./components/CMSSection/Pages/Pages";
import AddPage from "./components/CMSSection/addPages/addPages";
import EditPage from "./components/CMSSection/editPages/editPages";

import Blocks from "./components/CMSSection/blocks/list";
import AddBlock from "./components/CMSSection/blocks/add";
import EditBlock from "./components/CMSSection/blocks/edit";

import FaqCategories from "./components/faqSection/faqCategories/faqCategoriesList/faqCategoriesList";
import FaqCategoriesAdd from "./components/faqSection/faqCategories/faqCategoriesAdd/faqCategoriesAdd";
import FaqCategoriesEdit from "./components/faqSection/faqCategories/faqCategoriesEdit/faqCategoriesEdit";

import FaqSubcategories from "./components/faqSection/faqSubcategories/faqSubcategoriesList/faqSubcategoriesList";
import FaqSubcategoriesAdd from "./components/faqSection/faqSubcategories/faqSubcategoriesAdd/faqSubcategoriesAdd";
import FaqSubcategoriesEdit from "./components/faqSection/faqSubcategories/faqSubcategoriesEdit/faqSubcategoriesEdit";

import Faq from "./components/faqSection/faq/faqList/faqList";
import FaqAdd from "./components/faqSection/faq/faqAdd/faqAdd";
import FaqEdit from "./components/faqSection/faq/faqEdit/faqEdit";

import PlanList from "./components/planSection/planList/planList";
import PlanAdd from "./components/planSection/planAdd/planAdd";
import PlanEdit from "./components/planSection/planEdit/planEdit";

import FeatureList from "./components/featureSection/featureList";
import FeatureAdd from "./components/featureSection/featureAdd";
import FeatureEdit from "./components/featureSection/featureEdit";

import VideoStreamList from "./components/videoStreamSection/videoStreamList";
import VideoStreamAdd from "./components/videoStreamSection/videoStreamAdd";
import VideoStreamEdit from "./components/videoStreamSection/videoStreamEdit";

import NewsletterSubscriber from "./components/newsLetterSubscribers/newsLetterSubscribersList/newsLetterSubscribersList";

import Contact from "./components/contactSection/contactList/contactList";
import ConatctView from "./components/contactSection/contactView/contactView";

import PackageList from "./components/packageSection/packageList";

import PaymentGatewaysList from "./components/paymentGateways/PaymentGatewaysList";

import EmailTemplateList from "./components/EmailTemplate/EmailTemplateList";

import AllImages from "./constant/img";
import NoteList from "./components/balanceActivateNote/noteList/NoteList";
import ReasonList from "./components/deactivateAccountReason/reasonList/reasonList";
import LiveAuctionDurationList from "./components/liveAuctionDuration/durationList/durationList";

import ReportAd from "./components/reportAd/reportList/reportList";
import TransactionsList from "./components/transactions/transactionList/transactionList";
import ReportProductsList from "./components/reportProducts/reportProductsList/reportProductsList";
import PendingProductList from "./components/productSection/productList/pendingProductList";
import ExpiredProductList from "./components/productSection/productList/expiredProductList";
import PendingAccount from "./components/userAccount/pendingAccounts";
import SuspendedAccount from "./components/userAccount/suspendedAccounts";
import Logo from "./components/logoSection/logo";
import LearnCategoriesList from "./components/learnSection/learnCategories/learnCategoriesList/learnCategoriesList";
import LearnCategoriesAdd from "./components/learnSection/learnCategories/learnCategoriesAdd/learnCategoriesAdd";
import LearnCategoriesEdit from "./components/learnSection/learnCategories/learnCategoriesEdit/learnCategoriesEdit";
import LearnSubcategoriesList from "./components/learnSection/learnSubcategories/learnSubcategoriesList/learnSubcategoriesList";
import LearnSubcategoriesAdd from "./components/learnSection/learnSubcategories/learnSubcategoriesAdd/learnSubcategoriesAdd";
import LearnSubcategoriesEdit from "./components/learnSection/learnSubcategories/learnSubcategoriesEdit/learnSubcategoriesEdit";
import LearnList from "./components/learnSection/learn/learnList/learnList";
import LearnAdd from "./components/learnSection/learn/learnAdd/learnAdd";
import LearnEdit from "./components/learnSection/learn/learnEdit/LearnEdit";

import BoughtSoldProductList from "./components/productSection/productList/boughtSoldProductList";
import DisputeProducts from "./components/productSection/productList/disputeProductList";
const App = () => {
  // useEffect(() => {
  //   document.body.classList.remove('fixedit_modal');
  // }, [])

  const handleClick = () => {
    document.body.classList.remove("fixedit_modal");
  };

  return (
    // <HttpsRedirect>
    <div className="App">
      <div onClick={handleClick} class="overForm"></div>
      <div class="main_board bg-gray" id="main_app1">
        <div
          style={{ flexWrap: "wrap", overflowX: "hidden" }}
          className="main_board_inner"
          id="main_app2"
        >
          <BrowserRouter>
            <Header />
            <Sidebar />
            <Switch>
              <Route exact path="/" component={Signup} />
              <Route exact path="/forgetPassword" component={ForgetPassword} />
              <Route
                exact
                path="/confirmPassword/:data"
                component={ConfirmPassword}
              />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/accounts" component={UserAccount} />
              <Route
                exact
                path="/pending-accounts"
                component={PendingAccount}
              />
              <Route
                exact
                path="/suspended-accounts"
                component={SuspendedAccount}
              />
              <Route
                exact
                path="/subscribed-users"
                component={SubscribedUser}
              />
              <Route exact path="/newUser" component={NewUser} />
              <Route
                exact
                path="/change-password/:id"
                component={ChangePassword}
              />
              <Route exact path="/editUser/:id" component={EditUser} />
              <Route exact path="/category" component={Category} />

              <Route exact path="/fields" component={FieldsList} />
              <Route exact path="/fields/add" component={FieldsAdd} />
              <Route exact path="/fields/edit/:id" component={FieldsEdit} />

              <Route exact path="/categories" component={CategoryList} />
              <Route exact path="/categories/add" component={CategoryAdd} />
              <Route
                exact
                path="/categories/edit/:id"
                component={CategoryEdit}
              />

              <Route exact path="/sub-categories" component={SubCategoryList} />
              <Route
                exact
                path="/sub-categories/add"
                component={SubCategoryAdd}
              />
              <Route
                exact
                path="/sub-categories/edit/:id"
                component={SubCategoryEdit}
              />

              <Route exact path="/addCategory" component={AddCategory} />
              <Route exact path="/editCategory/:id" component={EditCategory} />
              <Route
                exact
                path="/:category/AddSubCategory"
                component={AddSubCategory}
              />
              {/* <Route exact path="/subCategory" component={SubCategory} /> */}
              <Route
                exact
                path="/:category/:id/EditSubCategory"
                component={EditSubCategory}
              />
              <Route exact path="/:data/SubFirst" component={SubFirst} />

              <Route exact path="/ProductList" component={ProductList} />
              <Route
                exact
                path="/PendingProductList"
                component={PendingProductList}
              />
              <Route
                exact
                path="/ExpiredProductList"
                component={ExpiredProductList}
              />
              <Route exact path="/ProductAdd" component={ProductAdd} />
              <Route exact path="/ProductEdit/:id" component={ProductEdit} />
              <Route exact path="/product/view/:id" component={ProductView} />
              <Route exact path="/BrandList" component={BrandList} />
              <Route exact path="/BrandAdd" component={BrandAdd} />
              <Route exact path="/BrandEdit/:id" component={BrandEdit} />
              <Route
                exact
                path="/AddCategoryField"
                component={AddCategoryField}
              />
              <Route exact path="/basic-settings" component={BasicSetting} />
              <Route exact path="/Pages" component={Pages} />
              <Route exact path="/AddPage" component={AddPage} />
              <Route exact path="/EditPage/:id" component={EditPage} />
              <Route exact path="/Blocks" component={Blocks} />
              <Route exact path="/AddBlock" component={AddBlock} />
              <Route exact path="/EditBlock/:id" component={EditBlock} />
              <Route exact path="/faq-categories" component={FaqCategories} />
              <Route
                exact
                path="/faq-addcategories"
                component={FaqCategoriesAdd}
              />
              <Route
                exact
                path="/faq-editcategories/:id"
                component={FaqCategoriesEdit}
              />
              <Route
                exact
                path="/faq-subcategories"
                component={FaqSubcategories}
              />
              <Route
                exact
                path="/faq-addsubcategories"
                component={FaqSubcategoriesAdd}
              />
              <Route
                exact
                path="/faq-editsubcategories/:id"
                component={FaqSubcategoriesEdit}
              />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/faq-add" component={FaqAdd} />
              <Route exact path="/faq-edit/:id" component={FaqEdit} />

              <Route
                exact
                path="/learn-categories"
                component={LearnCategoriesList}
              />
              <Route
                exact
                path="/learn-addcategories"
                component={LearnCategoriesAdd}
              />
              <Route
                exact
                path="/learn-editcategories/:id"
                component={LearnCategoriesEdit}
              />
              <Route
                exact
                path="/learn-subcategories"
                component={LearnSubcategoriesList}
              />
              <Route
                exact
                path="/learn-addsubcategories"
                component={LearnSubcategoriesAdd}
              />
              <Route
                exact
                path="/learn-editsubcategories/:id"
                component={LearnSubcategoriesEdit}
              />
              <Route exact path="/learn" component={LearnList} />
              <Route exact path="/learn-add" component={LearnAdd} />
              <Route exact path="/learn-edit/:id" component={LearnEdit} />
              <Route exact path='/logo' component={Logo} />
              <Route exact path="/listing-plans" component={PlanList} />
              <Route exact path="/plan-add" component={PlanAdd} />
              <Route exact path="/plan-edit/:id" component={PlanEdit} />

              <Route exact path="/listing-features" component={FeatureList} />
              <Route exact path="/features-add" component={FeatureAdd} />
              <Route exact path="/features-edit/:id" component={FeatureEdit} />

              <Route exact path="/listing-video-stream" component={VideoStreamList} />
              <Route exact path="/features-video-stream-add" component={VideoStreamAdd} />
              <Route exact path="/features-video-stream-edit/:id" component={VideoStreamEdit} />

              <Route
                exact
                path="/news-letter-subscribers"
                component={NewsletterSubscriber}
              />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/contact-view/:id" component={ConatctView} />
              <Route exact path="/listing-packages" component={PackageList} />
              <Route
                exact
                path="/payment-gateways"
                component={PaymentGatewaysList}
              />
              <Route
                exact
                path="/email-templates"
                component={EmailTemplateList}
              />
              <Route
                exact
                path="/balanceActiveNotes"
                component={NoteList}
              />
              <Route
                exact
                path="/DeactivateReasonList"
                component={ReasonList}
              />
              <Route exact path="/ReportAd" component={ReportAd} />

              <Route exact path="/Transactions" component={TransactionsList} />
              <Route
                exact
                path="/ReportProductList"
                component={ReportProductsList}
              />
              <Route
                exact
                path="/LiveAuctionDuration"
                component={LiveAuctionDurationList}
              />

              <Route
                exact
                path="/BoughtSoldProductList"
                component={BoughtSoldProductList}
              />
              <Route
                exact
                path="/DisputeProductList"
                component={DisputeProducts}
              />

              <Route exact path="/WeeklyDeals" component={WeeklyDealsList} />
              {/* <Route exact path="/WeeklyDealsAdd" component={WeeklyDealsAdd} />
              <Route exact path="/WeeklyDealsEdit/:id" component={WeeklyDealsEdit} /> */}
              <Redirect to="/" />
            </Switch>
            <Footer />
          </BrowserRouter>
        </div>
      </div>
    </div>
    //  </HttpsRedirect>
  );
};

export default App;
