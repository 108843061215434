import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const EditNote = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const [reason, setReason] = useState();

  const [formData, setFormData] = useState({
    note: "",
    footer_note: "",
  });

  const handleNoteText = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]:value
    });
  };

  useEffect(async () => {
    await axios
      .post(`${PORT}/getBalanceActivateNoteById`, { id: props })
      .then((response, err) => {
        if(response.data.data){
          setFormData({
            note : response.data.data.note,
            footer_note : response.data.data.footer_note
          });
        }
      });
  }, [props]);

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    /* if (!reason) {
      document.getElementById("reason-text").style.display = "block";
      return;
    } */
    await axios
      .post(`${PORT}/updateBalanceActivateNote`, {
        id : props,
        data:formData,
      })
      .then((response, err) => {
        if (response) {
          handleClose();
          history.push({
            pathname: "/balanceActiveNotes",
            MatchValue: "edit",
          });
        }
      });
  };

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
    /* setFormData({
      note: "",
      footer_note: "" 
    }); */
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Edit Notes</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Note</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <textarea name="note" class="fuilddesh" onChange={handleNoteText} value={formData.note} rows={10} />
                        <p
                          id="note"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Reason is required*
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Footer Note</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <textarea name="footer_note" class="fuilddesh" onChange={handleNoteText} value={formData.footer_note} rows={15} />
                        <p
                          id="footer-note"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Reason is required*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditNote;
