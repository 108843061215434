import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";

const ProductView = (props) => {

    const PORT = PORTNUMBER.PORTNUMBER;


    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const id = props.match.params.id;

    const [name, setName] = useState();
    const [description, setDescription] = useState();
    const [image, setImage] = useState();
    const [status, setStatus] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubcategory] = useState();
    const [productlocation, setProductLocation] = useState();
    const [price, setPrice] = useState();
    const [premium, setPremium] = useState();
    const [weekly, setWeekly] = useState();
    const [live, setLive] = useState();


    useEffect(async ()=>{
    await axios.post(`${PORT}/product/view`, {params:{id}}).then((res, err)=>{
      console.log("ggggggggggggg", res);
        if(res)
        {
            setName(res.data.data.productName);
            setDescription(res.data.data.productDescription);
            setImage(res.data.data.productImages);
            setStatus(res.data.data.active);
            setCategory(res.data.data.categoryId);
            setSubcategory(res.data.data.subCategoryId);
            setProductLocation(res.data.data.location);
            setPrice(res.data.data.productPrice);
            setPremium(res.data.data.IsPremium);
            setWeekly(res.data.data.IsWeaklyDeals);
            setLive(res.data.data.IsLiveStreaming);
        } 
        else{
             toast.info("Something went wrong");
        }
    })
    },[])


    return (
      <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
            <div class="col-md-12">
              <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li>
                    <a href="#">Admin Panel </a>
                  </li>
                  <li>
                    <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                  </li>
                  <li>View Product</li>
                </ul>
                <h2 class="main_title">View Product</h2>
              </div>
            </div>
            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                <div class="custom_fuild_addM topsmall_top flexcoluman product-view">
                  <div class="card text-center">
                    <div class="card-header">Product View</div>
                    <div class="card-body table-responsive-sm">
                         <div class="row align-items-center">
                           <div class="col-lg-12 col-xl-12"> 
                           <div class="row">
                           {
                             image && image.map((data)=>{
                               return(
                                <div class="col-md-3">
                                <img src={`${PORT}/image/products/` + data} alt="Product Image" />
                                </div>
                               )
                             })
                           }
                              </div>
                            </div>
                           <div class="col-lg-12 col-xl-12"> 



                           <div class="text-left text-block mt-3 mt-lg-0">

<div class="table-responsive py-5 product-viewtbl">
                           <table class="table">
                              <thead>
                                <tr>
                                  <th scope="col">Product Name</th>
                                  <th scope="col">  <h4>{name}</h4></th>
                                
                                </tr>
                              </thead>
                              <tbody>
    <tr>
      <th scope="row">product description</th>
      <td>{description}</td>
   
    </tr>
    <tr>
      <th scope="row">Status</th>
      <td>    {status == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                            }  </td>

    </tr>
    <tr>
      <th scope="row">Price </th>
      <td>     $ {price}</td>

    </tr>


    <tr>
      <th scope="row">Location  </th>
      <td>      {productlocation} </td>

    </tr>


    <tr>
      <th scope="row">Category  </th>
      <td>       {category && category.categoryName}</td>

    </tr>


    <tr>
      <th scope="row">Sub category   </th>
      <td>         {subCategory && subCategory.subCategoryName}</td>

    </tr>


    <tr>
      <th scope="row">Premium   </th>
      <td>   {premium == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                          }</td>

    </tr>

    <tr>
      <th scope="row">WeeklyDeals    </th>
      <td>    {weekly == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                          } </td>

    </tr>
    <tr>
      <th scope="row">Live Streaming    </th>
      <td>   {live == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                           } </td>

    </tr>
  </tbody>
                        </table>
                        </div>
                           {/* <h4>{name}</h4> 
                           <p>{description}</p> */}
                        {/* <div class="d-flex">  <strong class="pr-2">Status:</strong>  
                           {status == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                            }  
                        </div> */}
                        {/* <div class="d-lg-flex">
                         <div class="pr-3">  <strong  class="pr-2">Price :</strong>    
                         $ {price} </div> 
                         <div >   <strong class="pr-2">Location :</strong>  
                          {productlocation} </div> 
                        </div> */}
                        {/* <div class="d-lg-flex">
                         <div class="pr-3">  <strong  class="pr-2">Category :</strong>    
                         {category && category.categoryName} </div> 
                         <div >   <strong class="pr-2">Sub category :</strong>  
                          {subCategory && subCategory.subCategoryName} </div> 
                        </div> */}
                        {/* <div class="d-lg-flex"> */}
                         {/* <div class="d-flex" style={{paddingRight:"40px"}}>  <strong  class="pr-2">Premium :</strong>    
                         {premium == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                          }
                          </div>  */}
                         {/* <div class="d-flex" style={{paddingRight:"40px"}}>   <strong class="pr-2">WeeklyDeals :</strong>  
                         {weekly == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                          } 
                          </div>  */}
                         {/* <div class="d-flex" style={{paddingRight:"40px"}}>   
                           <strong class="pr-2">Live Streaming :</strong>  
                           {live == true 
                           ?
                            <div>Active</div>
                           :
                            <div>Inactive</div>
                           } 
                          </div>  */}
                        {/* </div> */}
                           </div>
                           </div>
                          </div>
                      </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
}

export default ProductView
