import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const EditReport = ({ props }) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const [ad, setAd] = useState();

  console.log("props", props);
  // console.log("propsdata", props.match.params.id);
  // const id = props.match.params.id;

  useEffect(async () => {
   await axios
      .post(`${PORT}/getReportAdById`, { params: { id: props } })
      .then((response, err) => {
        setAd(response.data.data.name);
      });
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!ad) {
      document.getElementById("report-text").style.display = "block";
      return;
    }
    await axios
      .post(`${PORT}/updateReportAd`, {
        params: { id: props, ad:ad },
      })
      .then((response, err) => {
        if (response) {
          handleClose();
          history.push({
            pathname: "/ReportAd",
            MatchValue: "edit",
          });
        }
      });
  };

  const handleNameText = (event) => {
    document.getElementById("report-text").style.display = "none";
    setAd(event.target.value);
  };

  const handleClose = (event) => {
    document.body.classList.remove("fixedit_modal");
  };

  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn">
          <i class="fa fa-check"></i>
        </a>
        <a onClick={handleClose} class="fixhbtn closefix">
          <i class="fa fa-times"></i>
        </a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Edit Report</h4>
              <div class="Tbaleflex">
                <div class="Table">
                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <textarea class="fuilddesh" onChange={handleNameText} value={ad} />
                          
                        
                        {/* <input
                          value={reason}
                          onChange={handleNameText}
                          type="text"
                          class="fuilddesh"
                          placeholder=""
                        /> */}
                        <p
                          id="report-text"
                          className="text-danger"
                          style={{ display: "none" }}
                        >
                          Name is required*
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">
              SAVE
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditReport;

