import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";



const PlanAdd = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allcategory, setAllCategory] = useState();
  const [allsubcategory, setAllSubcategory] = useState();
  const [adtype, setAdType] = useState();
  const [field, setField] = useState();
  const [amount, setAmount] = useState();
  const [category, setCategory] = useState();
  const [duration, setDuartion] = useState();
  const [status, setStatus] = useState();


  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;


  useEffect(async () => {
    await axios.get(`${PORT}/getCategorys`).then((res, err) => {
      console.log("category", res)
      setAllCategory(res);
    });
  }, [])

  useEffect(async () => {
    await axios.get(`${PORT}/get_all_subcategory`).then((res, err) => {
      console.log("subcategory", res)
      setAllSubcategory(res);
    });
  }, [])


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!adtype) {
      document.getElementById("ad-type").style.display = "block";
      return;
    }
    if (!duration) {
      document.getElementById("duration").style.display = "block";
      return;
    }
    if (!amount) {
      document.getElementById("amount").style.display = "block";
      return;
    }
    if (!field) {
      document.getElementById("field-name").style.display = "block";
      return;
    }
    if (!category) {
      document.getElementById("category").style.display = "block";
      return;
    }
    if (!status) {
      document.getElementById("status").style.display = "block";
      return;
    }

    await axios
      .post(`${PORT}/plan/create`,
        { adtype, duration, amount, category, field, status }
      )
      .then((res, err) => {
        if (res) {
          handleClose();
          setAdType("");
          setDuartion("");
          setAmount("");
          setCategory("");
          setStatus("");
          setField("");
          history.push({ pathname: "/listing-plans", MatchValue: "add" });
        }
        else {
          toast.info("Something went wrong");
        }
      });
  }



  const handleAmount = (event) => {
    event.preventDefault();
    document.getElementById("amount").style.display = "none";
    setAmount(event.target.value);
  };


  const handleStatus = (event) => {
    event.preventDefault();
    document.getElementById("status").style.display = "none";
    if (event.target.value == "") {
      document.getElementById("status").style.display = "block";
      setStatus(false);
      return;
    }
    else {
      setStatus(event.target.value);
      return;
    }

  }


  const handleAdType = (event) => {
    event.preventDefault();
    document.getElementById("ad-type").style.display = "none";
    setAdType(event.target.value);
  }

  const handleDuration = (event) => {
    document.getElementById("duration").style.display = "none";
    setDuartion(event.target.value)
  }

  const handleCategory = (data) => {
    document.getElementById("category").style.display = "none";
    setCategory(data)
  }

  const handleFieldName = (event) => {
    document.getElementById("field-name").style.display = "none";
    setField(event.target.value);
  }


  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) => {
    document.body.classList.remove('fixedit_modal');
  }



  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
        <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Plan Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Ad Type</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleAdType}
                          value={adtype}
                          class="fuilddesh"
                        >
                          <option value="">Select</option>
                          <option value="Urgent">Urgent</option>
                          <option value="Featured">Featured</option>
                          <option value="Spotlight">Spotlight</option>
                        </select>
                        <p
                          className="text-danger"
                          id="ad-type"
                          style={{ display: "none" }}
                        >
                          Ad Type is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Duration</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleDuration}
                          value={duration}
                          type="number"
                          class="fuilddesh"
                        />
                        <p
                          className="text-danger"
                          id="duration"
                          style={{ display: "none" }}
                        >
                          Duration is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Amount</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleAmount}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={amount}
                        />
                        <p
                          className="text-danger"
                          id="amount"
                          style={{ display: "none" }}
                        >
                          Amount is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Field Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleFieldName}
                          value={field}
                          class="fuilddesh"
                        >
                          <option value="">{`Select Field Name`}</option>
                          {
                            allcategory && allcategory.data && allcategory.data.map((data) => {
                              return (
                                <option value={data._id}>{data.categoryName}</option>
                              )
                            })
                          }
                        </select>
                        <p
                          className="text-danger"
                          id="field-name"
                          style={{ display: "none" }}
                        >
                          Field Name is required*
                    </p>
                      </div>
                    </div>
                  </div>




                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid multiSelectBars">
                        <Multiselect
                          onSelect={handleCategory}
                          onRemove={handleCategory}
                          selectedValues={category}
                          options={allsubcategory && allsubcategory.data}
                          displayValue="subCategoryName"
                        />
                        <p
                          className="text-danger"
                          id="category"
                          style={{ display: "none" }}
                        >
                          Category is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Status</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleStatus}
                          value={status}
                          class="fuilddesh"
                        >
                          <option value="">Select</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                        <p
                          className="text-danger"
                          id="status"
                          style={{ display: "none" }}
                        >
                          Status is required*
                    </p>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">SAVE</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PlanAdd;

//<div className="work_space">
//       <div className="workspace_title_hd">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="titleof_page">
//               <ul className="breadcrumb_list topsmall_bottom">
//                 <li>
//                   <a href="#">Admin Panel </a>
//                 </li>
//                 <li>
//                   <i
//                     style={{ padding: "5px" }}
//                     className="fa fa-angle-right"
//                   ></i>
//                 </li>
//                 <li>Add Plan</li>
//               </ul>
//               <h2 className="main_title">Add Plan</h2>
//             </div>
//           </div>
//           <div className="col-md-12">
//             <div className="main_oflistingForm bg_light_gray onlyforcol_support">
//               <div className="custom_fuild_addM topsmall_top flexcoluman edit-form">
//                 <form onSubmit={handleSubmit}>
//                   <ToastContainer />


//                   <div class="row">
//                                 <div class="col-md-6">
//                   <div className="form-group">
//                     <h4 className="fieldsec_lab">Plan name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//                     <div className=" ">
//                       <div className="repwat_one">
//                         <input
//                           onChange={handlePlan}
//                           type="text"
//                           className="fuilddesh"
//                           placeholder="Plan name"
//                           value={plan}
//                         />

//                       </div>
//                     </div>
//                     <p
//                       className="text-danger"
//                       id="plan-name"
//                       style={{ display: "none" }}
//                     >
//                       Plan name is required*
//                     </p>
//                   </div>
// </div>
//                   <div class="col-md-6">
//                   <div className="form-group">
//                     <h4 className="fieldsec_lab">Price<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//                     <div className=" ">
//                       <div className="repwat_one">
//                         <input
//                           onChange={handlePrice}
//                           type="number"
//                           className="fuilddesh1"
//                           placeholder="Price"
//                           value={price}
//                         />

//                       </div>
//                     </div>
//                     <p
//                       className="text-danger"
//                       id="price"
//                       style={{ display: "none" }}
//                     >
//                       Price is required*
//                     </p>
//                   </div>
//                   </div>
//                   <div class="col-md-6">

//                   <div className="form-group">
//                     <h4 className="fieldsec_lab">Start Date<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//                     <div className=" ">
//                       <div className="repwat_one">
//                         <input
//                           onChange={handleStartDate}
//                           type="date"
//                           className="fuilddesh1"
//                           placeholder="Start Date"
//                           value={startDate}
//                         />

//                       </div>
//                     </div>
//                     <p
//                       className="text-danger"
//                       id="start-date"
//                       style={{ display: "none" }}
//                     >
//                       Start Date is required*
//                     </p>
//                   </div>
//                   </div>
//                   <div class="col-md-6">

//                   <div className="form-group">
//                     <h4 className="fieldsec_lab">End Date<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//                     <div className=" ">
//                       <div className="repwat_one">
//                         <input
//                           onChange={handleEndDate}
//                           type="date"
//                           className="fuilddesh1"
//                           placeholder="End Date"
//                           value={endDate}
//                           min={startDate}
//                         />

//                       </div>
//                     </div>
//                     <p
//                       className="text-danger"
//                       id="end-date"
//                       style={{ display: "none" }}
//                     >
//                       End Date is required*
//                     </p>
//                   </div>
//                   </div>


//                   <div class="col-md-6">

//                   <div class="form-group">
//                     <h4 class="fieldsec_lab">Status<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//                     <div class=" ">
//                       <div class="repwat_one">
//                         <select
//                           onChange={handleStatus}
//                           value={status}
//                           class="fuilddesh1"
//                         >
//                           <option value="">Select</option>
//                           <option value="Active">Active</option>
//                           <option value="Inactive">Inactive</option>  
//                         </select>

//                       </div>
//                     </div>
//                     <p
//                       className="text-danger"
//                       id="status"
//                       style={{ display: "none" }}
//                     >
//                       Status is required*
//                     </p>
//                   </div>
//                   </div>


//                   <div class="col-md-12">


// <div className="form-group">
//   <h4 className="fieldsec_lab">Description<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
//   <div className=" ">
//     <div className="repwat_one">
//      <CKEditor
//      editor={ClassicEditor}
//      onChange={(e, editor)=> handleDescription(e, editor)}
//      />
//     </div>

//   </div>
//   <p
//     className="text-danger"
//     id="description"
//     style={{ display: "none" }}
//   >
//     Description is required*
//   </p>
// </div>
// </div>
//                   </div>
//                   <div className="bottomsend_button text-center small_margin_top">
//                     <button type="submit" className="savesidebutt closefix">
//                       Save
//                     </button>
//                     <button
//                       type="button"
//                       onClick={handleReset}
//                       className="savesidebutt closefix"
//                     >
//                       Reset
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
