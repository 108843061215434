import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";



const VideoStreamAdd = () => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [inputField , setInputField] = useState({
      slot: '',
      price: '',
  });

  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;


  useEffect(async () => {
  }, [])


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if(!inputField.slot){
        document.getElementById("slot").style.display="block";
        return;
    }
    if(!inputField.price){
        document.getElementById("price").style.display="block";
        return;
    }

    await axios
      .post(`${PORT}/video-stream-charge/create`,
        { inputField }
      )
      .then((res, err) => {
        if (res) {
          handleClose();
          setInputField({
              slot: '',
              price: '',
          });
          history.push({ pathname: "/listing-video-stream", MatchValue: "add" });
        }
        else {
          toast.info("Something went wrong");
        }
      });
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) => {
    document.body.classList.remove('fixedit_modal');
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
      setInputField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
  };

  return (
    <div class="editfix main_bxshadow addfix">
      <div class="headeredi_fix text-right">
        <a href={void(0)} class="fixhbtn"><i class="fa fa-check"></i></a>
        <a  href={void(0)} onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Video Streaming Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">
                      <div class="Table-row">
                        <div class="Table-row-item">
                          <div class="lefttabl_lable">
                            Slot
                          </div>
                        </div>
                        <div class="Table-row-item">
                          <div class="dropdownsid">
                            <input className="fuilddesh" name="slot" type="number" min="0" placeholder="Slots" onChange={ event => handleChange(event) } value={inputField.slot} />
                            <p
                              className="text-danger"
                              id="slot"
                              style={{ display: "none" }}
                            >
                              Slot is required*
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="Table-row">
                        <div class="Table-row-item">
                          Price
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <input className="fuilddesh" name="price" type="number" min="0" placeholder="Price" onChange={ event => handleChange(event) } value={inputField.price} />
                            <p
                              className="text-danger"
                              id="price"
                              style={{ display: "none" }}
                            >
                              Price is required*
                            </p>
                          </div>
                        </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">SAVE</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default VideoStreamAdd;
