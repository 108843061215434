import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PORTNUMBER from "../../../constant/api";
import Button from "react-bootstrap/Button";
import Pagination from "../.././pagination/pagination";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import ReactPaginate from "react-paginate";

const ContactUs = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allContact, setAllContact] = useState();
  const [Id, setId] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchItem, setSearchItem] = useState();

  useEffect(async () => {
    await axios.get(`${PORT}/contact/get_all`).then((response, err) => {
      console.log("total contact", response);
      setAllContact(response);
    });
  }, []);

  useEffect(() => {
    if (
      typeof props.location.customdata != "undefined" &&
      props.location.customdata
    ) {
      console.log("addedpage", props.location.customdata);
      if (props.location.customdata.pathname == "/contact-add") {
        toast.success("You have successfully added new contact");
      }
      if (props.location.customdata.pathname) {
        const editpath = props.location.customdata.pathname.split("/");
        console.log("editpathname", editpath);
        if (editpath[1] == "EditPage") {
          toast.success("You have successfully edited page");
        }
      }
    } else {
      return;
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteContact(Id);
    setDeleteModal(false);
  };

  const handleDeleteContact = async (id) => {
    await axios.post(`${PORT}/contact/delete`, { id }).then((response, err) => {
      toast.success("You have successfully deleted contact");
    });
    await axios.get(`${PORT}/contact/get_all`).then((response, err) => {
      if (response) {
        setAllContact(response);
      } else {
        toast.info("Something went wrong");
      }
    });
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount = Math.ceil(
    (allContact &&
      allContact.data &&
      allContact.data.data &&
      allContact.data.data.length) / postsPerPage
  ) || 1;

  const currentPosts =
    allContact &&
    allContact.data &&
    allContact.data.data &&
    allContact.data.data.slice(pagesVisited, pagesVisited + postsPerPage);
  // const currentPosts = allContact && allContact.data && allContact.data.data && allContact.data.data.slice(indexOfFirstPost, indexOfLastPost);
  console.log("sdbhdfhf", currentPosts);

  const paginate = (pagenumber, TagID) => {
    document.getElementById(TagID).classList.add("active");
    setCurrentPage(pagenumber);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Contact Us</li>
              </ul>
              <h2 class="main_title">Contact Us</h2>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">Name</div>
                        <div class="table_cell">Email</div>
                        <div class="table_cell">Add Date</div>
                        <div class="table_cell">Actions</div>
                      </div>

                      {currentPosts &&
                        currentPosts
                          .filter((val) => {
                            if (searchItem == null) {
                              return val;
                            } else if (
                              val.name
                                .toLowerCase()
                                .includes(searchItem.toLowerCase())
                            ) {
                              return val;
                            } else {
                              return false;
                            }
                          })
                          .map((contact) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="table_cell">{contact.name}</div>
                                <div class="table_cell">{contact.email}</div>
                                <div class="table_cell">
                                  {contact.time.split("T")[0]}
                                </div>

                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      to={"/contact-view/" + contact._id}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View"
                                    >
                                      <i class="fa fa-eye"></i>
                                    </Link>
                                  </span>

                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        handleModalDelete(contact._id)
                                      }
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal isOpen={deleteModal}>
      <div class="modalChild">
      <div class="modalchildPadding">
        <h6>Do you want to delete this?</h6>
        </div>
        <Button
          onClick={handleDeleteYes}
          className="savesidebutt"
          variant="primary"
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          className="savesidebutt close-savesidebutt"
          variant="secondary"
        >
          Close
        </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ContactUs;
