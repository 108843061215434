import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'

const Brand = ({props}) => {


//  console.log("categorypropsdata",props);
  const allPathEdit = props.split("/");
 // console.log("allpath", allPathEdit);


  useEffect(() => {
    if(props == "/BrandList" || props == "/BrandAdd" || allPathEdit[1] == "BrandEdit")
    {
      document.getElementById("collapseBrand").classList.add("show");
    }
 },[])


    return (
                         <li className="sidebar-dropdown mabot_mainli">
                           <Link to="#" data-toggle="collapse" data-target="#collapseBrand">
                             <span>Brands</span>
                             <i className="fa fa-tags"></i>
                           </Link>
                           <div className="sidebar-submenu" id="collapseBrand" class="collapse" data-parent="#accordion">
                             <ul>
                               <li>
                                 <Link to="/BrandList">Brands</Link>
                               </li>
                             </ul>
                           </div>
                        </li> 
    )
}

export default Brand
