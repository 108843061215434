import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const FaqCategoriesEdit = ({props}) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [category_name, setCategory] = useState();
  const [image_name, setImageName] = useState();


  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const id = props;

 useEffect(async ()=>{
    await axios
    .post(`${PORT}/faqcategories/singlerecord`,
    {id},
    )
    .then((res, err) => {
        console.log("faq", res);
      setCategory(res.data.data.category_name);
      setImageName(res.data.data.image_name);
    });
 },[props])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!category_name)
    {
       document.getElementById("category-name").style.display="block";
       return;
    }
    if(!image_name)
    {
       document.getElementById("image").style.display="block";
       return;
    }
      await axios
        .post(`${PORT}/faqcategories/update`,
        {id, category_name, image_name},
        )
        .then((res, err) => {
          if(res)
          {
            handleClose();
            history.push({ pathname: "/faq-categories", MatchValue:"edit" });
          }
        });
    }

  const handleCategory = (event) => {
    event.preventDefault();
    document.getElementById("category-name").style.display="none";
    setCategory(event.target.value);
  };



  const handleImage = async (event) => {
    document.getElementById("image").style.display = "none";
    const files = event.target.files[0];
    // const image = await resizeFile(files);

    let fData = new formData();
    await fData.append('FaqCategoryImage', files);        
   

    var config = {
        method: 'post',
        url: `${PORT}/faqcategory/image`,
        data: fData
    };

    await axios(config)
    .then(function (response) {
        if(response.data.success == 1){
            console.log(response.data.image_url.split("/"));
            const faqcategoryimage = response.data.image_url.split("/");
            setImageName(faqcategoryimage[6]);
        }       
    })
    .catch(function (error) {
        console.log(error);
    });

}


  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) =>{
    document.body.classList.remove('fixedit_modal');
  }



  return (
    <div class="editfix main_bxshadow">
    <div class="headeredi_fix text-right">
     <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
     <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
    </div>
    <form onSubmit={handleSubmit}>
      <div class="table_fixe">
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">FAQ Categories Settings</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Category</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <input
                  onChange={handleCategory}
                  type="text"
                  className="fuilddesh"
                  placeholder=""
                  value={category_name}
                />
                          <p
              className="text-danger"
              id="category-name"
              style={{ display: "none" }}
            >
              Category Name is required
            </p>
                    </div>
                  </div>
                </div>
    

                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Image</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <input
                  onChange={handleImage}
                  type="file"
                  class=""
                  name="img"
                  placeholder=""
                />
                       <p
                  id="image"
                  className="text-danger"
                  style={{ display: "none" }}
                >
                 Image is required*
                </p>
                    </div>
                  </div>
                </div>

              
              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center small_margin_top">
          <button type="submit" class="savesidebutt closefix">SAVE</button>
        </div>
      </div>
      </form>
      <ToastContainer />
    </div>

  );
};

export default FaqCategoriesEdit;
