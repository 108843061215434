import Lodash from "lodash";
import numeral from "numeral";

class General {

  getCurrency() {
    return "£";
  }

  showPrice(price) {
    return this.getCurrency() + " " + numeral(price).format("0,0");
  }

  showPriceDecimal(price) {
    return this.getCurrency() + " " + numeral(price).format("0,0.00");
  }
  
}

export default new General();
