import React, {useEffect, useState} from 'react';
import {Link, Redirect} from "react-router-dom";
// import Pagination from "../pagination/pagination";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import Modal from 'react-modal';
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
// import Modal from "react-bootstrap/Modal";
// import ModalDialog from 'react-bootstrap/ModalDialog';
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";



const NewsLetterSubscribersList = (props) => {


  
  const PORT = PORTNUMBER.PORTNUMBER;

  const [AllSubscribers, setAllSubscribers] = useState(["not"]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(10);
  const [searchItem, setSearchItem] = useState();


  useEffect(()=>{
    if(typeof props.location.customdata != "undefined" && props.location.customdata)
    {
      console.log("addeduser", props.location.customdata);
      if(props.location.customdata.pathname == "/plan-add")
      {
        toast.success("You have successfully added new plan");
      }
      if(props.location.customdata.pathname)
      {
        const editpath = props.location.customdata.pathname.split("/");
        console.log("editpathname", editpath);
        if(editpath[1] == "plan-edit")
        {
          toast.success("You have successfully updated plan");
        }
      } 
    }
    else{
      return;
    }
  },[])


  useEffect(()=>{
   axios.get(`${PORT}/newsletter/get_all`).then((res, err)=>{
    setAllSubscribers(res);
   })
  }, [])



                                   
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  console.log("currentPosts", indexOfLastPost);
  console.log("sdbhdfhf", indexOfFirstPost);


  const pagesVisited = currentPage * postsPerPage;
  const pageCount = Math.ceil((AllSubscribers && AllSubscribers.data && AllSubscribers.data.data && AllSubscribers.data.data.length)/postsPerPage) || 1;

  const currentPosts = AllSubscribers && AllSubscribers.data && AllSubscribers.data.data && AllSubscribers.data.data.slice(pagesVisited, pagesVisited+postsPerPage);
  // const currentPosts = AllSubscribers && AllSubscribers.data && AllSubscribers.data.slice(indexOfFirstPost, indexOfLastPost); 
  console.log("sdbhdfhf",currentPosts);

  const paginate = (pagenumber) =>{
    setCurrentPage(pagenumber);
  }

  const handleSearch = (event) =>{
    setSearchItem(event.target.value);
  }

  const handlePageChange = ({selected}) =>{
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  }



  const handleClickDelete = (event) =>{
    setDeleteUser(event);
    setIsOpen(true) 
  }

  const closeModal = (event) =>{
    setIsOpen(false)
  }

  const handleYes = async (event) =>{
    event.preventDefault();
    await axios.post(`${PORT}/delete_newslettersubscribers`, {id:deleteUser}).then((res, err)=>{
      console.log("deletedusers", res);
    })
    await axios.get(`${PORT}/newsletter/get_all`).then((res, err)=>{
        setAllSubscribers(res);
    })
    setIsOpen(false);
    toast.success("You have successfully deleted subscriber");
  }


  const handleClose = (event) =>{
    event.preventDefault();
    setIsOpen(false); 
  }



    return (
      <div style={{ width: "100%" }} class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
            <div class="col-md-12">
              <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li>
                    <a href="javascript:void(0)">Admin Panel </a>
                  </li>
                  <li>
                    <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                  </li>
                  <li>News Letter</li>
                </ul>
                <h2 class="main_title">News Letter</h2>
              </div>
            </div>

            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                {/* <div className="text-right">
                            <Link to="/plan-add" id="add-newUser" style={{backgroundColor:"#FE5F55", marginRight:"15px"}} className="btn btn-primary">Add Plan</Link>
                          </div> */}
                <br />
                <div class="inner_listing_formS">
                  <div class="left_innserSearchone">
                    {/* <div className="text-right">
                                    <Link to="/plan-add" id="add-newUser" style={{backgroundColor:"#FE5F55", marginRight:"15px"}} className="btn btn-primary">Add Plan</Link>
                                  </div> */}
                  </div>
                  <div class="left_innserSearch">
                    <div class="srch_fuildsecT leftlabs">
                      <div class="leftlabs listing_title">Search:</div>
                      <div class="lefelab_fuild">
                        <input
                          onChange={handleSearch}
                          type="text"
                          class="fuilddesh"
                          name=""
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                  <div class="tabscroll_inner">
                    <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                      <div class="tableflex topsmall_margin_top">
                        <div class="tableHead_row">
                          <div class="table_cell">
                            <div class="checkBtabls">
                              <ul>
                                <li>
                                  <div class="check-box">
                                    <input
                                      type="checkbox"
                                      name="option"
                                      id="Company01"
                                    />
                                    <label for="Company01"></label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div class="table_cell">Email</div>
                          <div class="table_cell">Add Date</div>
                          <div class="table_cell">Actions</div>
                        </div>

                        <ToastContainer />

                        {currentPosts &&
                          currentPosts
                            .filter((val) => {
                              if (searchItem == null) {
                                return val;
                              } else if (
                                val.email
                                  .toLowerCase()
                                  .includes(searchItem.toLowerCase())
                              ) {
                                return val;
                              } else {
                                return false;
                              }
                            })
                            .map((subscribedata) => {
                              return (
                                <div class="table_row">
                                  <div class="table_cell">
                                    <div class="checkBtabls">
                                      <ul>
                                        <li>
                                          <div class="check-box">
                                            <input
                                              type="checkbox"
                                              name="option"
                                              id="Company01"
                                            />
                                            <label for="Company01"></label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div class="table_cell">
                                    {subscribedata.email}
                                  </div>
                                  <div class="table_cell">
                                    {subscribedata.time.split("T")[0]}
                                  </div>

                                  <div class="table_cell">
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        onClick={() =>
                                          handleClickDelete(subscribedata._id)
                                        }
                                      >
                                        <i class="fa fa-times"></i>
                                      </a>
                                    </span>
                                    {/* <span class="table_bbtnn">
                                          <Link to={'/plan-edit/' + subscribedata._id} title="Edit" ><i class="fa fa-pencil"></i></Link> 
                                          </span>  */}
                                  </div>
                                </div>
                              );
                            })}

                        <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
                        <div class="modalChild">
                        <div class="modalchildPadding">
                          <h6>Do you want to delete this?</h6>
                          </div>
                          <Button
                            onClick={handleYes}
                            className="savesidebutt"
                            variant="primary"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={handleClose}
                            className="savesidebutt close-savesidebutt"
                            variant="secondary"
                          >
                            Close
                          </Button>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-12">
                      <Pagination postPerPage={postsPerPage} totalPosts={AllSubscribers && AllSubscribers.data && AllSubscribers.data.length} paginate={paginate}/>
                      </div> */}
            {/* <div className="col-md-12" style={{ marginTop: "50px" }}>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
                    </div> */}
          </div>
          <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
        </div>
      </div>
    );
}

export default NewsLetterSubscribersList
