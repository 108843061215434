import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EmailTemplateEdit = ({props}) => {

    const PORT = PORTNUMBER.PORTNUMBER;

    console.log("props", props);
    const id = props;


    const history = useHistory();

    const [name, setName] = useState();
    const [subject, setSubject] = useState();
    const [constant, setConstant] = useState();
    const [emailBody, setEmailBody] = useState();

 

    useEffect(async ()=>{
        await axios.post(`${PORT}/get_one_emailtemplate`, {id}).then((response, err)=>{
            if(response)
            {
                setName(response.data.data.name);
                setSubject(response.data.data.subject);
                setConstant(response.data.data.constant);
                setEmailBody(response.data.data.emailBody);
            }
          })
    },[props])


    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(!name)
        {
            document.getElementById("name").style.display="block";
            return;
        }
        if(!subject)
        {
            document.getElementById("subject").style.display="block";
            return;
        }
        if(!emailBody)
        {
            document.getElementById("email-body").style.display="block";
            return;
        }
       await axios.post(`${PORT}/edit_emailtemplate`, {id, name, subject, constant, emailBody}).then((response, err)=>{ 
           console.log(response)
        if(response)
        {
          
            handleClose();
            history.push({pathname:"/email-templates", MatchValue:"edit"});
        
        } 
        else{
            toast.info("Something went wrong");
        }     
      })
      }



    const handleName = (event) =>{
        document.getElementById("name").style.display="none";
        setName(event.target.value);

    }

    const handleSubject = (event) =>{
        document.getElementById("subject").style.display="none";
        setSubject(event.target.value);
    }

    const handleConstant = (event) =>{
        document.getElementById("constant").style.display="none";
        setConstant(event.target.value);
    }

    const handleEmailBody = (event, editor) =>{
        document.getElementById("email-body").style.display="none";
        setEmailBody(editor.getData());
    }

    const handleClose = (event) =>{
        document.body.classList.remove('fixedit_modal');
      }

  


    return (
        <div class="editfix main_bxshadow">
        <div class="headeredi_fix text-right">
         <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
         <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="table_fixe">
            <div class="labelsidetop">
              <div class="boxrepetform">
                <h4 class="tabcont_Sublable">Email Templates Settings</h4>
                <div class="Tbaleflex">
                  <div class="Table">
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Name</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <input value={name} onChange={handleName} type="text" class="fuilddesh" placeholder="" />
                          <p className="text-danger" id="name" style={{ display: "none" }}>Name is required*</p>
                        </div>
                      </div>
                    </div>
        

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Subject</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <input onChange={handleSubject} value={subject} type="text" class="fuilddesh" placeholder="" />
                          <p className="text-danger" id="subject" style={{ display: "none" }}>Subject is required*</p>
                        </div>
                      </div>
                    </div>

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Constant</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <select class="fuilddesh" value={constant} onChange={handleConstant}>
                              <option value="">Select One</option>
                              <option value="USER_NAME">USER_NAME</option>
                              <option value="EMAIL">EMAIL</option>
                              <option value="PASSWORD">PASSWORD</option>
                              <option value="LINK">LINK</option>
                          </select>
                          <p className="text-danger" id="constant" style={{ display: "none" }}>Constant is required*</p>
                        </div>
                      </div>
                    </div>

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Email Body</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <CKEditor
                        editor={ClassicEditor}
                        onChange={(e, editor) =>
                        handleEmailBody(e, editor)
                        }
                        data={emailBody}
                        />
                             <p className="text-danger" id="email-body" style={{ display: "none" }}>Email body is required*</p>
                        </div>
                      </div>
                    </div>


                   
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomsend_button text-center small_margin_top">
              <button type="submit" class="savesidebutt closefix">SAVE</button>
            </div>
          </div>
          </form>
          <ToastContainer />
        </div>
    )
}

export default EmailTemplateEdit
