import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const Maps = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [confirmSubscriptionByEmail,setConfirmSubscriptionByEmail] = useState();
    const [autoSubscription,setAutoSubscription] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/massmailer/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setConfirmSubscriptionByEmail(res.data.data[0].common_setting[0].confirmSubscriptionByEmail);
                    setAutoSubscription(res.data.data[0].common_setting[0].autoSubscription);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
        if(autoSubscription == 1){
            var autoSubscription_check = true;
        }else if(confirmSubscriptionByEmail == 0){
            var autoSubscription_check = false;
        }else{
            var autoSubscription_check = '';
        }
        
        if(confirmSubscriptionByEmail == 1){
            var confirmSubscriptionByEmail_check = true;
        }else if(confirmSubscriptionByEmail == 0){
            var confirmSubscriptionByEmail_check = false;
        }else{
            var confirmSubscriptionByEmail_check = '';
        }
        
        var common_settings         =   [ {
                                            'autoSubscription': autoSubscription_check,
                                            'confirmSubscriptionByEmail':confirmSubscriptionByEmail_check
                                        }];
       
        if(slug == 'MassMailerSetting'){
            await axios.post(`${PORT}/massmailer/update`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/massmailer/create`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();
    }
  
    const handleConfirmSubscriptionByEmail = (event) =>{
        setConfirmSubscriptionByEmail(event.target.value);
    }
    const handleAutoSubscription = (event) =>{
        setAutoSubscription(event.target.value);
    }
    
    return (
        <div class="tab-pane" id="basic018" role="tabpanel">
             <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Massmailer/Newsletters</span></h3>
                    <div class="labelsidetop">
                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Common Settings</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Confirm subscription by email</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid radioequal_flex">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                            <input type="radio" id="confirmSubscriptionByEmail1" name="confirmSubscriptionByEmail" value='1' onClick={handleConfirmSubscriptionByEmail} checked={confirmSubscriptionByEmail == 1 && confirmSubscriptionByEmail != null?'checked':''}/>
                                            <label for="confirmSubscriptionByEmail1" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                            <input type="radio" id="confirmSubscriptionByEmail2" name="confirmSubscriptionByEmail" value='0' onClick={handleConfirmSubscriptionByEmail} checked={confirmSubscriptionByEmail == 0 && confirmSubscriptionByEmail != null?'checked':''}/>
                                            <label for="confirmSubscriptionByEmail2" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Auto subscription</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid radioequal_flex">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                            <input type="radio" id="autoSubscription1" name="autoSubscription" value='1' onClick={handleAutoSubscription} checked={autoSubscription == 1 && autoSubscription != null?'checked':''}/>
                                            <label for="autoSubscription1" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                            <input type="radio" id="autoSubscription2" name="autoSubscription" value='0' onClick={handleAutoSubscription} checked={autoSubscription == 0 && autoSubscription != null?'checked':''}/>
                                            <label for="autoSubscription2" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                                <span class="equalspan_cont">if enabled the ''subscribe to newsletters'' checkbox in front end will be checked by default.</span>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

                </div>
                <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Maps
