import React, { useEffect, useState } from "react";
import "./product.css";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PORTNUMBER from "../../../constant/api";
import Button from "react-bootstrap/Button";
import Pagination from "../.././pagination/pagination";
import Rating from "@material-ui/lab/Rating";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import ReactPaginate from "react-paginate";

const ProductList = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allProduct, setAllProduct] = useState();
  const [allReviews, setAllReviews] = useState([]);
  const [allReviewReports, setAllReviewReports] = useState([]);
  const [Id, setId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const [activeModal, setActiveModal] = useState(false);
  const [deactiveModal, setDeactiveModal] = useState(false);

  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();

  useEffect(() => {
    axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
      //console.log("total product", response);
      setAllProduct(response);
    });
  }, []);

  useEffect(() => {
    if (
      typeof props.location.customdata != "undefined" &&
      props.location.customdata
    ) {
     // console.log("addeduser", props.location.customdata);
      if (props.location.customdata.pathname == "/ProductAdd") {
        toast.success("You have successfully added new product");
      }
      if (props.location.customdata.pathname) {
        const editpath = props.location.customdata.pathname.split("/");
        //console.log("editpathname", editpath);
        if (editpath[1] == "ProductEdit") {
          toast.success("You have successfully edited product");
        }
      }
    } else {
      return;
    }
  }, []);

  const handleClose = () => {
    setDeleteModal(false);
    setActiveModal(false);
    setDeactiveModal(false);
    setApproveModal(false);
    setRejectModal(false);
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleModalActive = (id) => {
    setActiveModal(true);
    setId(id);
  };

  const handleModalDeactive = (id) => {
    setDeactiveModal(true);
    setId(id);
  };

  const handleModalApprove = (id) => {
    setApproveModal(true);
    setId(id);
  };

  const handleModalReject = (id) => {
    setRejectModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteProduct(Id);
    setDeleteModal(false);
  };

  const handleActiveYes = () => {
    handleClickActive(Id);
    setActiveModal(false);
  };

  const handleDeactiveYes = () => {
    handleClickDeactive(Id);
    setDeactiveModal(false);
  };

  const handleApproveYes = () => {
    handleClickApprove(Id);
    setApproveModal(false);
  };

  const handleRejectYes = () => {
    handleClickReject(Id);
    setRejectModal(false);
  };

  const handleDeleteProduct = async (id) => {
    await axios
      .post(`${PORT}/deleteProduct`, { params: { id } })
      .then((response, err) => {
       // console.log("Delete Product");
        toast.success("You have successfully deleted this product");
      });
    await axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
     // console.log("total product", response);
      setAllProduct(response);
    });
  };

  const handleClickDeactive = async (id) => {
    await axios
      .post(`${PORT}/statusPorduct`, {
        params: { id, status: false, key: "active" },
      })
      .then((response, err) => {
      //  console.log("Delete Product");
        toast.success("You have successfully Inactivated this product");
      });
    await axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
    //  console.log("total product", response);
      setAllProduct(response);
    });
  };

  const handleClickReject = async (id) => {
    await axios
      .post(`${PORT}/statusPorduct`, {
        params: { id, status: false, key: "approve" },
      })
      .then((response, err) => {
      //  console.log("Delete Product");
        toast.success("You have successfully rejected this product");
      });
    await axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
     // console.log("total product", response);
      setAllProduct(response);
    });
  };

  const handleClickApprove = async (id) => {
    await axios
      .post(`${PORT}/statusPorduct`, {
        params: { id, status: true, key: "approve" },
      })
      .then((response, err) => {
      //  console.log("Delete Product");
        toast.success("You have successfully approved this product");
      });
    await axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
     // console.log("total product", response);
      setAllProduct(response);
    });
  };

  const handleClickActive = async (id) => {
    await axios
      .post(`${PORT}/statusPorduct`, {
        params: { id, status: true, key: "active" },
      })
      .then((response, err) => {
       // console.log("Delete Product");
        toast.success("You have successfully activated this product");
      });
    await axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
    //  console.log("total product", response);
      setAllProduct(response);
    });
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount =
    Math.ceil(
      (allProduct &&
        allProduct.data &&
        allProduct.data.data &&
        allProduct.data.data.length) / postsPerPage
    ) || 1;
 // console.log("allProduct",allProduct)
  const currentPosts =
    allProduct &&
    allProduct.data &&
    allProduct.data.data &&
    allProduct.data.data.slice(pagesVisited, pagesVisited + postsPerPage);
  // const currentPosts = allProduct && allProduct.data && allProduct.data.data && allProduct.data.data.slice(indexOfFirstPost, indexOfLastPost);
// console.log("sdbhdfhf", currentPosts);

  const paginate = (pagenumber, TagID) => {
    document.getElementById(TagID).classList.add("active");
    setCurrentPage(pagenumber);
  };

  const handleSearch = async (event) => {
    setSearchItem(event.target.value);
    await axios
      .post(`${PORT}/search_product`, { search: event.target.value })
      .then((response, err) => {
       // console.log("total product", response);
        setAllProduct(response);
      });
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handlePageChange = ({ selected }) => {
   // console.log("selectedpage", selected);
   // console.log(pageCount);
    setCurrentPage(selected);
  };

  const DateDiffrence = (date) => {
    const date2 = date.split("T")[0];
    const datee = new Date();
    const todayDate = datee.getDate();
    const todayMonth = datee.getMonth() + 1;
    const todayYear = datee.getFullYear();
    const date1 = todayYear + "-" + todayMonth + "-" + todayDate;

    const date11 = new Date(`${date1}`);
    const date22 = new Date(`${date2}`);
    // console.log(
    //   "datedatedate",
    //   date1,
    //   date2,
    //   datee,
    //   todayDate,
    //   todayMonth,
    //   todayYear
    // );

    const diffInMs = Math.abs(date22 - date11);
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleSelect = async (event) => {
    if (event.target.value == "all") {
      await axios.get(`${PORT}/getLatestProducts`).then((response, err) => {
        setAllProduct(response);
      });
    } else if (event.target.value == "active") {
      await axios
        .post(`${PORT}/get_product_status`, { params: { status: true } })
        .then((response, err) => {
          setAllProduct(response);
        });
    } else if (event.target.value == "inactive") {
      await axios
        .post(`${PORT}/get_product_status`, { params: { status: false } })
        .then((response, err) => {
          setAllProduct(response);
        });
    }
  };

  const showReviewsHandler = async (id) => {
    await axios
      .post(`${PORT}/getNewReviews`, { params: { id } })
      .then((response, err) => {
        if (response && response.data) {
          setAllReviews(response.data.data);
          document.body.classList.add("modal-open");
          document.getElementById("exampleModalCenter").style.display = "block";
          document
            .getElementById("exampleModalCenter")
            .classList.remove("fade");
        }
      });
  };

  const closeReviewModal = () => {
    document.body.classList.remove("modal-open");
    document.getElementById("exampleModalCenter").classList.add("fade");
    document.getElementById("exampleModalCenter").style.display = "none";

    document.getElementById("exampleModalCenter1").classList.add("fade");
    document.getElementById("exampleModalCenter1").style.display = "none";
  };

  const approveReviewHandler = async (reviewId, productId) => {
    await axios
      .post(`${PORT}/approvedReview`, { params: { reviewId } })
      .then((response, err) => {
        if (response && response.data) {
          toast.success(response.data.message);
          showReviewsHandler(productId);
        }
      });
  };

  const showReportHandler = async (id) => {
    await axios.get(`${PORT}/review/${id}/reports`).then((response, err) => {
      if (response && response.data) {
        setAllReviewReports(response.data.data);
        document.body.classList.add("modal-open");
        document.getElementById("exampleModalCenter1").style.display = "block";
        document.getElementById("exampleModalCenter1").classList.remove("fade");
      }
    });
  };

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Products</li>
              </ul>
              <h2 class="main_title">Products</h2>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              {/* <div className="text-right">
                            <Link to="/newUser" id="add-newUser" style={{backgroundColor:"#FE5F55", marginRight:"15px"}} className="btn btn-primary">Add new user</Link>
                          </div>
                          <br/> */}
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  <div class="dropdownsid">
                    <select class="fuilddesh" onChange={handleSelect}>
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">#ID</div>
                        <div class="table_cell">Title</div>
                        <div class="table_cell">Review</div>

                        <div class="table_cell">User Name</div>
                        <div class="table_cell">Posted</div>
                        <div class="table_cell">Plan</div>
                        <div class="table_cell">Status</div>
                        <div class="table_cell">Actions</div>
                      </div>

                      {currentPosts &&
                        currentPosts.map((product, index) => {
                          return (
                            <div class="table_row">
                              <div class="table_cell">
                                <div class="checkBtabls">
                                  <ul>
                                    <li>
                                      <div class="check-box">
                                        <input
                                          type="checkbox"
                                          name="option"
                                          id="Company01"
                                        />
                                        <label for="Company01"></label>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="table_cell">#{index + 1}</div>

                              <div class="table_cell">
                                <p>{product.productName}</p>
                                <div class="catetabl_image">
                                  <div class="cateimag">
                                    <div class="acttiimmg">
                                      {/* <img
                                        src="images/profile-img.jpg"
                                        alt="image"
                                      /> */}
                                      {product.productMainImage ? (
                                        <img
                                          src={
                                            `${PORT}/image/products/resize-180-180/` +
                                            product.productMainImage
                                          }
                                          alt={product.productName}
                                        />
                                      ) : (
                                        <img
                                          src="/images/no-image.jpg"
                                          alt="product"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div class="catetlist">
                                    <div class="listofca_tbl">
                                      <div class="listosa_tbl">
                                        <div class="leftlist_tbl">
                                          Category:
                                        </div>
                                        <div class="rightlist_tbl">
                                          {product.categoryId.categoryName}
                                        </div>
                                      </div>
                                      <div class="listosa_tbl">
                                        <div class="leftlist_tbl">Price:</div>
                                        <div class="rightlist_tbl">
                                          &#x20A6; {product.productPrice}
                                        </div>
                                      </div>
                                      <div class="listosa_tbl">
                                        <div class="leftlist_tbl">Email:</div>
                                        <div class="rightlist_tbl">
                                          {product.userId?.email}
                                        </div>
                                      </div>
                                      <div class="listosa_tbl">
                                        <div class="leftlist_tbl">Mobile:</div>
                                        <div class="rightlist_tbl">
                                          {product.userId?.mobileNumber}
                                        </div>
                                      </div>
                                      <div class="listosa_tbl">
                                        <div class="leftlist_tbl">
                                          Location:
                                        </div>
                                        <div class="rightlist_tbl">
                                          {product.location}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="table_cell">
                                <span
                                  class="labltable greenlab cursor-pointer mr-2"
                                  // data-toggle="modal"
                                  // data-target="#exampleModalCenter"
                                  onClick={() =>
                                    showReviewsHandler(product._id)
                                  }
                                >
                                  Approved
                                </span>

                                <span
                                  class="labltable cursor-pointer lablesct_red"
                                  onClick={() => showReportHandler(product._id)}
                                >
                                  Review reports
                                </span>
                              </div>

                              <div class="table_cell">
                                {product.userId?.firstName}{" "}
                                {product.userId?.lastName}
                              </div>
                              <div class="table_cell">
                                {DateDiffrence(product.time)} Days ago
                                <p>Added: {product.time.split("T")[0]}</p>
                                {/* <p>Expires: Jan 08, 2021</p> */}
                              </div>
                              <div class="table_cell">Free Member</div>
                              <div className="table_cell">
                                {product.active == true ? (
                                  <span className="labltable lablesct">
                                    {"Active"}
                                  </span>
                                ) : (
                                  <span className="labltable lablesct_red">
                                    {"Inactive"}
                                  </span>
                                )}
                                {product.IsApprove == true ? (
                                  <span className="labltable greenlab">
                                    {"Approved"}
                                  </span>
                                ) : (
                                  <span className="labltable lablesct_red">
                                    {"Not Approved"}
                                  </span>
                                )}
                              </div>

                              <div class="table_cell">
                                {/* <span class="table_bbtnn">
                                      <Link to={'/ProductEdit/' + product._id}  data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link> 
                                     </span>  */}
                                <span class="table_bbtnn">
                                  <Link
                                    to={"/product/view/" + product._id}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="View"
                                  >
                                    <i class="fa fa-eye"></i>
                                  </Link>
                                </span>
                                {product.active == true ? (
                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Inactive"
                                      id="deactive"
                                      onClick={() =>
                                        handleModalDeactive(product._id)
                                      }
                                    >
                                      <i class="fa fa-ban"></i>
                                    </a>
                                  </span>
                                ) : (
                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      id="active"
                                      data-placement="top"
                                      title="Active"
                                      onClick={() =>
                                        handleModalActive(product._id)
                                      }
                                    >
                                      <i class="fa fa-check-circle-o"></i>
                                    </a>
                                  </span>
                                )}

                                {product.IsApprove == true ? (
                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Approved"
                                      id="notapprove"
                                      onClick={() =>
                                        handleModalReject(product._id)
                                      }
                                    >
                                      <i class="fa fa-thumbs-o-up"></i>
                                    </a>
                                  </span>
                                ) : (
                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      id="approve"
                                      data-placement="top"
                                      title="Not Approved"
                                      onClick={() =>
                                        handleModalApprove(product._id)
                                      }
                                    >
                                      <i class="fa fa-thumbs-o-down"></i>
                                    </a>
                                  </span>
                                )}

                                <span class="table_bbtnn">
                                  <a
                                    href="javascript:void(0)"
                                    onClick={(e) =>
                                      handleModalDelete(product._id)
                                    }
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Delete"
                                  >
                                    <i class="fa fa-times"></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
      <Modal isOpen={deleteModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to delete this product?</h6>
          </div>
          <Button
            onClick={handleDeleteYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={activeModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to active this product?</h6>
          </div>
          <Button
            onClick={handleActiveYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={approveModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to approve this product?</h6>
          </div>
          <Button
            onClick={handleApproveYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={rejectModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to reject this product?</h6>
          </div>
          <Button
            onClick={handleRejectYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={deactiveModal}>
        <div class="modalChild">
          <div class="modalchildPadding">
            <h6>Do you want to deactivate this product?</h6>
          </div>
          <Button
            onClick={handleDeactiveYes}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={false}>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">
            Reviews
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="customer-review-profile_block">
            <div class="review_block">
              <div class="row align-items-center">
                <div class="col-lg-9">
                  <div class="detail-owner-name-bx clearfix review_author align-items-start">
                    <div class="detail-owner-img">
                      <img
                        src="https://dlevelcdn.b-cdn.net/upload/profile/resize/profileImage_1640590353525.png"
                        alt="profileImage_1640590353525.png"
                      />
                    </div>
                    <div class="detail-owner-name">
                      <h3>Rakesh Jangid</h3>
                      <div class="rated-customer-bx-inner">
                        <div class="rated-star">
                          <span>Rated</span>
                          <div class="star-ratings" title="0 Stars">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </div>
                        </div>
                        <h2>Donec scelerisque nisi nibh feugiat consuat</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="rated-customer-bx d-block text-right">
                    <a class="number-reveal-btn" href="">
                      <img src="/images/reply-icon.png" alt="" />
                      <span class="labltable greenlab cursor-pointer">
                        Approved
                      </span>
                    </a>
                  </div>
                </div>
                <div class="review_reply">
                  <div class="row align-items-start w-100 no-gutters">
                    <div class="col-auto">
                      <div class="detail-owner-name-bx clearfix">
                        <div class="detail-owner-img reply_img_b">
                          <img
                            src="https://dlevelcdn.b-cdn.net/upload/profile/resize/profileImage_1640590353525.png"
                            alt="profileImage_1640590353525.png"
                          />
                          <span class="live-dot"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group mb-0">
                        <textarea
                          class="form-control-line h-30px"
                          rows="2"
                          name="reply"
                        ></textarea>

                        <div class="rely_textnotapp">
                          {" "}
                          <span class="labltable lablesct_red">
                            Not Approved
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="review_mess">
              <div class="review_reply pr-0 ">
                <div class="row w-100">
                  <div class="col-auto">
                    <div class="detail-owner-name-bx clearfix">
                      <div class="detail-owner-img">
                        <img
                          src="https://dlevelcdn.b-cdn.net/upload/profile/resize/profileImage_1640590353525.png"
                          alt="profileImage_1640590353525.png"
                        />
                        <span class="live-dot"></span>
                      </div>
                    </div>
                  </div>
                  <div class="col pl-3">
                    <div class="detail-owner-name pl-0">
                      <div class="rated-customer-bx-inner mess-sender-name">
                        <h2>Rakesh Jangid</h2>
                      </div>
                    </div>
                    <div class="form-group">
                      <textarea
                        name="review"
                        class="form-control-line h-20px"
                        rows="2"
                        placeholder="share your review"
                        maxlength="300"
                      ></textarea>
                    </div>
                    <div class="d-flex align-items-center">
                      0/300
                      <div class="flex-g">
                        <div class="rated-star mr-4">
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                          <i class="fa fa-star"></i>
                        </div>

                        <div class="form-group mb-0">
                          <button type="submit" class="btnx btn-secondaryx">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Modal Code::Start */}
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered review-modal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Reviews
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeReviewModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="customer-review-profile_block">
                {allReviews.length ? (
                  allReviews.map((review) => (
                    <div class="review_block">
                      <div class="row align-items-center">
                        <div class="col-lg-9">
                          <div class="detail-owner-name-bx clearfix review_author align-items-start">
                            <div class="detail-owner-img">
                              {/* <img
                                src="https://dlevelcdn.b-cdn.net/upload/profile/resize/profileImage_1640590353525.png"
                                alt="profileImage_1640590353525.png"
                              /> */}
                              {review?.userId?.profilePicture ? (
                                <img
                                  src={
                                    `${PORT}/upload/profile/resize/` +
                                    review?.userId?.profilePicture
                                  }
                                  alt={review?.userId?.firstName}
                                />
                              ) : (
                                <img src="/images/no-image.jpg" alt="user" />
                              )}
                            </div>
                            <div class="detail-owner-name">
                              <h3>
                                {review?.userId?.firstName +
                                  " " +
                                  review?.userId?.lastName}
                              </h3>
                              <div class="rated-customer-bx-inner">
                                <div class="rated-star">
                                  <span>Rated</span>
                                  <div class="star-ratings" title="0 Stars">
                                    {/* <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i>
                                    <i class="fa fa-star"></i> */}
                                    <Rating
                                      name="read-only"
                                      value={review.rating ? review.rating : 0}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <h2>{review.review}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="rated-customer-bx d-block text-right">
                            {/* <a class="number-reveal-btn" href="">
                              <span class="labltable greenlab cursor-pointer">
                                Approved
                              </span>
                              <span class="labltable lablesct_red">
                                Not Approved
                              </span>
                            </a> */}
                            <div className="d-flex justify-content-between text-center text-nowrap">
                              <span
                                class="labltable greenlab cursor-pointer"
                                onClick={() =>
                                  approveReviewHandler(
                                    review._id,
                                    review.productId
                                  )
                                }
                              >
                                Approved
                              </span>
                              <span class="labltable lablesct_red cursor-pointer">
                                Not Approved
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div class="review_reply">
                          <div class="row align-items-start w-100 no-gutters">
                            <div class="col-auto">
                              <div class="detail-owner-name-bx clearfix">
                                <div class="detail-owner-img reply_img_b">
                                  <img
                                    src="https://dlevelcdn.b-cdn.net/upload/profile/resize/profileImage_1640590353525.png"
                                    alt="profileImage_1640590353525.png"
                                  />
                                  <span class="live-dot"></span>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group mb-0">
                                <textarea
                                  class="form-control-line h-30px"
                                  rows="2"
                                  name="reply"
                                ></textarea>

                                <div class="rely_textnotapp">
                                  {" "}
                                  <span class="labltable lablesct_red">
                                    Not Approved
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="review_block">
                    <p className="text-center">No new Reviews</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
      {/* Modal Code::End */}

      {/* Modal Code::Start */}
      <div
        class="modal fade"
        id="exampleModalCenter1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered review-report-modal"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Review Reports
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeReviewModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="customer-review-profile_block">
                {allReviewReports.length ? (
                  allReviewReports.map((report) => (
                    <div class="review_block">
                      <div class="row align-items-center">
                        <div class="col-lg-9">
                          <div class="detail-owner-name-bx clearfix review_author align-items-start">
                            <div class="detail-owner-img">
                              {/* <img
                                src="https://dlevelcdn.b-cdn.net/upload/profile/resize/profileImage_1640590353525.png"
                                alt="profileImage_1640590353525.png"
                              /> */}
                              {report?.userId?.profilePicture ? (
                                <img
                                  src={
                                    `${PORT}/upload/profile/resize/` +
                                    report?.userId?.profilePicture
                                  }
                                  alt={report?.userId?.firstName}
                                />
                              ) : (
                                <img src="/images/no-image.jpg" alt="user" />
                              )}
                            </div>
                            <div class="detail-owner-name">
                              <h3>
                                {report?.userId?.firstName +
                                  " " +
                                  report?.userId?.lastName}
                              </h3>
                              <div class="rated-customer-bx-inner">
                                <h2>{report.reason}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="rated-customer-bx d-block text-right">
                            {/* <a class="number-reveal-btn" href="">
                              <span class="labltable greenlab cursor-pointer">
                                Approved
                              </span>
                              <span class="labltable lablesct_red">
                                Not Approved
                              </span>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div class="review_block">
                    <p className="text-center">No new Review Reports</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Code::End */}
    </div>
  );
};

export default ProductList;
