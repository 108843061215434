import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Product = ({ props }) => {
//  console.log("categorypropsdata", props);
  const allPathEdit = props.split("/");
 // console.log("allpath", allPathEdit);

  useEffect(() => {
    if (
      props == "/ProductList" ||
      allPathEdit[1] == "product" ||
      props == "/PendingProductList" ||
      props == "/ExpiredProductList" ||
      props == "/BoughtSoldProductList" ||
      props == "/DisputeProductList"
    ) {
      document.getElementById("collapseProduct").classList.add("show");
    }
  }, []);

  return (
    <li className="sidebar-dropdown mabot_mainli">
      <Link to="#" data-toggle="collapse" data-target="#collapseProduct">
        <span>Products</span>
        <i className="fa fa-tags"></i>
      </Link>
      <div
        className="sidebar-submenu"
        id="collapseProduct"
        class="collapse"
        data-parent="#accordion"
      >
        <ul>
          <li>
            <Link to="/ProductList">Products</Link>
          </li>
          <li>
            <Link to="/PendingProductList">Pending Products</Link>
          </li>
          <li>
            <Link to="/ExpiredProductList">Expired Products</Link>
          </li>
          <li>
            <Link to="/ReportProductList">Report Products</Link>
          </li>
          <li>
            <Link to="/BoughtSoldProductList">Bought & Sold Products</Link>
          </li>
          <li>
            <Link to="/DisputeProductList">Dispute Products</Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Product;
