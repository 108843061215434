import React, {useEffect} from 'react'
import {Link} from "react-router-dom";

const Monetization = ({props}) => {

    useEffect(() => {
        if(props == "/listing-plans" || props == "/listing-packages")
        {
          document.getElementById("collapseMonetization").classList.add("show");
        }
       },[])


    return (
        <li className="sidebar-dropdown mabot_mainli">
        <Link data-toggle="collapse" data-target="#collapseMonetization" to="#">
          <span>Monetization</span>
          <i className="fa fa-dollar"></i>
        </Link>
        <div className="sidebar-submenu" id="collapseMonetization" className="collapse" data-parent="#accordion">
          <ul>
            <li>
              <Link to="/listing-plans">Listing Plans</Link>
            </li>
            <li>
              <Link to="/listing-video-stream">Listing Video Stream</Link>
            </li>
            <li>
              <Link to="/listing-features">Listing Feature Setting</Link>
            </li>
            <li>
              <Link to="/listing-packages">Listing Packages</Link>
            </li>
            <li>
              <Link to="/Transactions">Transactions</Link>
            </li>
          </ul>
        </div>
     </li>
    )
}

export default Monetization
