import EYE from "../images/eye.svg";
import CANCEL from "../images/cancel.svg";
import LOGO03 from "../images/logo03.png";
import LOGO02 from "../images/logo02.png";
import LOGO from "../images/logo.png";


const AllImages = {
   EYE_IMAGE:EYE,
   CANCEL_IMAGE:CANCEL,
   LOGO03_IMAGE:LOGO03,
   LOGO02_IMAGE:LOGO02,
   LOGO_IMAGE:LOGO
}
export default AllImages;