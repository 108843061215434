import axios from "axios";
import React, { useEffect, useState } from "react";
import PORTNUMBER from "../../constant/api";
import { useHistory, useLocation } from "react-router-dom";
import AllImages from "../../constant/img";
import moment from "moment";

const Header = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const allPathEdit = pathname.split("/");
 // console.log("allpathinheader", allPathEdit);

  const [allAlerts, setAllAlerts] = useState([]);

  useEffect(() => {
    const getAllAlerts = async () => {
      await axios.get(`${PORT}/getAllAdminAlerts`).then((response, err) => {
        if (response && response.data) {
          setAllAlerts(response.data.data);
        }
      });
    };
    getAllAlerts();
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.clear();
    history.push({ pathname: "/", customdata: { pathname } });
  };

  const handleMedia = () => {
    document.body.classList.add("overflow-none");
  };
  const sideHandle = () => {
    document.body.classList.add("hidemenu_title");
  };
  const sideRemove = () => {
    document.body.classList.remove("hidemenu_title");
  };

  if (
    location.pathname === "/" ||
    location.pathname === "/forgetPassword" ||
    allPathEdit[1] === "confirmPassword"
  ) {
    return null;
  } else {
    return (
      <header class="header_head">
        <div class="header_inner">
          <div class="logomobil onlyfordesk">
            <a href="">
              <img src={AllImages.LOGO03_IMAGE} alt="Logo" />
            </a>
          </div>
          <div class="tabicon_logo text-left">
            <a
              onClick={sideHandle}
              id="tabicon_menu"
              class="menutab_design numone_click"
            >
              <i class="fa fa-caret-left"></i>
              <i class="fa fa-caret-right"></i>
            </a>
            <a
              onClick={sideRemove}
              id="tabicon_menu02"
              class="menutab_design numtwo_click"
            >
              <i class="fa fa-caret-left"></i>
              <i class="fa fa-caret-right"></i>
            </a>
          </div>
          <div class="welcomadmin">
            <p>Welcome, Administrator</p>
          </div>

          <div class="dropdown notification-list ntIcon">
            <a
              class="nav-link dropdown-toggle arrow-none waves-effect"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg">
              <h6 class="dropdown-item-text">My Alerts ({allAlerts.length})</h6>
              <div class="slimscroll notification-item-list">
                {allAlerts ? (
                  allAlerts.map((alert) => (
                    <a href class="dropdown-item notify-item active">
                      <div class="notify-icon">
                        <div>
                          <i class="fa fa-bell"></i>
                          <span class="live-dot"></span>
                        </div>
                        <h6>{moment(alert.time).fromNow(true)}</h6>
                      </div>
                      <p class="notify-details">
                        {alert.type}
                        <span class="text-muted">{alert.message}</span>
                      </p>
                    </a>
                  ))
                ) : (
                  <div>No alerts</div>
                )}
              </div>
              {/* <a
                href="javascript:void(0);"
                class="dropdown-item text-center text-primary py-2"
              >
                View all <i class="fi-arrow-right"></i>
              </a> */}
            </div>
          </div>

          <div class="icon_mail">
            <a href="">
              <i class="fa fa-envelope"></i>
            </a>
          </div>
          <div class="logout">
            <a onClick={handleLogout} href="">
              Log out
            </a>
          </div>
          <div class="menuformob onlyfordesk">
            <a onClick={handleMedia} id="filter-toggler">
              <i class="fa fa-bars"></i>
            </a>
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
