import React, {useEffect} from 'react';
import {Redirect} from "react-router-dom";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import Dashboard from '../../components/dashboard/dashboard';


const Admin = (props) => {

    console.log("login se aaaya hu", props);

    useEffect(()=>{
      document.getElementById("main_app1").classList.add("main_board");
      document.getElementById("main_app2").classList.add("main_board_inner");
      if(typeof props.location.customdata !="undefined" && props.location.customdata)
      {
        toast.success("You have successfully login");
      }
      else{
        return;
      }
    })

    console.log("localstorageusername", localStorage.getItem("username"));
    console.log("localstorageuserpassword", localStorage.getItem("password"));
    if(localStorage.getItem("username") == null && localStorage.getItem("password") == null)
    {
      return (
        <div>
        <Redirect to="/" />
        </div>    
      );
    }
    else{
     
      return (
         
          <Dashboard />
          
    )
    }
 
}

export default Admin
