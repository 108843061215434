import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const Cms = ({props}) => {

//  console.log("categorypropsdata",props);
  const allPathEdit = props.split("/");
 // console.log("allpath", allPathEdit);

  
  useEffect(() => {
    if(props == "/Pages")
    {
      document.getElementById("collapseCMS").classList.add("show");
    }
  },[])

    return (
      <li className="sidebar-dropdown mabot_mainli">
        <a href="#" data-toggle="collapse" data-target="#collapseCMS">
          <span>CMS</span>
          <i className="fa fa-tasks"></i>
        </a>
        <div className="sidebar-submenu" class="collapse" id="collapseCMS" data-parent="#accordion">
          <ul>
            <li>
              <Link to="/Pages">Pages</Link>
            </li>
            <li>
              <Link to="/Blocks">Blocks</Link>
            </li>
          </ul>
        </div>
      </li>
    )
}

export default Cms
