import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";

const ChangePassword = (props) => {

  console.log("props", props);
  const id = props.match.params.id;

  const PORT = PORTNUMBER.PORTNUMBER;

  const [password, setPassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();

  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!password) {
      document.getElementById("password").style.display = "block";
      return;
    }
    if (!confirmpassword) {
        document.getElementById("confirm-password").style.display = "block";
        return;
    }
    if(password != confirmpassword)
    {
        toast.info("Password and Confirm password must be same");
        return;
    }

    await axios.post(`${PORT}/change-password`, {id, password}).then((res, err) => {
      if (res) {
        if(res)
        {
          history.push({pathname:"/accounts", MatchValue:"changepassword"});
        }
      }
      else {
        toast.info("Something went wrong");
        return;
      }

    })
  }




  const handlePassword = (event) => {
    event.preventDefault();
    document.getElementById("password").style.display = "none";
    setPassword(event.target.value);
  }
  const handleConfirm = (event) => {
    event.preventDefault();
    document.getElementById("confirm-password").style.display = "none";
    setConfirmpassword(event.target.value);
  }

  const handleClose = (event) =>{
    document.body.classList.remove('fixedit_modal');
  }




  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd user-form-hd">
        <div class="row">
    <div class="col-md-12">
    <div class="titleof_page">
      <ul class="breadcrumb_list topsmall_bottom">
        <li>
          <a href="javascript:void(0)">Admin Panel </a>
        </li>
        <li>
          <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
        </li>
        <li>Change Password</li>
      </ul>
      <h2 class="main_title">Change Password</h2>
    </div>
  </div>
    <div class="main_bxshadow">
    {/* <div class="headeredi_fix text-right">
     <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
     <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
    </div> */}
     
    <form onSubmit={handleSubmit}>
      <div class="table_fixe">
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">Change Password</h4>
            <div class="Tbaleflex">
              <div class="Table">
              
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>New Password</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input type="password" onChange={handlePassword} class="fuilddesh" name="" placeholder="" />
                      <p id="password" className="text-danger" style={{ display: "none" }}>Password is required*</p>
                    </div>
                  </div>
                </div>

                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Confirm Password </span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input type="password" onChange={handleConfirm} class="fuilddesh" name="" placeholder="" />
                      <p id="confirm-password" className="text-danger" style={{ display: "none" }}>Confirm password is required*</p>
                    </div>
                  </div>
                </div>

         
              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center small_margin_top">
          <button type="submit" class="savesidebutt closefix">SAVE</button>
        </div>
      </div>
      </form>
      <ToastContainer />
    </div>

    </div></div></div>
  )
}
export default ChangePassword


{/* <div class="work_space">
<div class="workspace_title_hd  user-form-hd ">
  <div class="row">
    <div class="col-md-12">
      <div class="titleof_page">
        <ul class="breadcrumb_list topsmall_bottom">
          <li><a href="#">Admin Panel </a></li>
          <li><i style={{ padding: "5px" }} class="fa fa-angle-right"></i></li>
          <li>Change Password</li>
        </ul>
        <h2 class="main_title">Change Password</h2>
      </div>
    </div>
    <div class="main_bxshadow">
      <form onSubmit={handleSubmit}>
      <div class="table_fixe">
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">Change Password</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Password</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input type="password" onChange={handlePassword} class="fuilddesh" name="" placeholder="" />
                      <p id="password" className="text-danger" style={{ display: "none" }}>Password is required*</p>
                    </div>
                  </div>
                </div>
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Confirm Password </span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                      <input type="password" onChange={handleConfirm} class="fuilddesh" name="" placeholder="" />
                      <p id="confirm-password" className="text-danger" style={{ display: "none" }}>Confirm password is required*</p>
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center small_margin_top">
          <button type="submit" class="savesidebutt closefix">SAVE</button>
        </div>
      </div>
      </form>
    </div>
  </div></div>
  <ToastContainer />
  </div> */}
