import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const Security = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [captcha, setCaptcha] = useState();
    const [loginAttempt, setLoginAttempt] = useState();
    const[numberOfAtteptsAllowed,setNumberOfAtteptsAllowed] = useState();
  
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
      }, [])
      
      // Get Function for ALL DATA
      
    const GETALL = async () =>{
        await axios.get(`${PORT}/security/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    if(res.data.data[0].captcha_setting[0].captcha == true){
                        setCaptcha('1');
                    }else{
                        setCaptcha('0');
                    }
                    if(res.data.data[0].login_attempt_setting[0].loginAttempt == true){
                        setLoginAttempt('1');
                    }else{
                        setLoginAttempt('0');
                    }
                    setNumberOfAtteptsAllowed(res.data.data[0].login_attempt_setting[0].numberOfAttemptsAll);  
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
        var captcha_check           = '';
        var loginAttempt_check      = '';
        if(captcha == 1){
            captcha_check = true;
        }else{
            captcha_check = false;
        }
        if(loginAttempt == 1){
            loginAttempt_check = true;
        }else{
            loginAttempt_check = false;
        }
        var captcha_settings         = [ {
                                        'captcha': captcha_check,
                                    }];

        var login_attempt_settings = [ {
                                        'loginAttempt':loginAttempt_check,
                                        'numberOfAttemptsAll':numberOfAtteptsAllowed,
                                    }];   
                                    
        if(slug == 'SecuritySetting'){
            await axios.post(`${PORT}/security/update`, {captcha_setting:JSON.stringify(captcha_settings), login_attempt_setting:JSON.stringify(login_attempt_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/security/create`, {captcha_setting:JSON.stringify(captcha_settings), login_attempt_setting:JSON.stringify(login_attempt_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }

    }

    const handleCaptcha = (event) =>{
        setCaptcha(event.target.value);
    }

    const handleNumberOfAttemptsAllowed = (event) =>{
        setNumberOfAtteptsAllowed(event.target.value);
    }

    const handleLoginAttempt = (event)=> {
        setLoginAttempt(event.target.value);
    }

    return (
      
        <div class="tab-pane" id="basic03" role="tabpanel">
            <form onSubmit={handleSubmit}>
            <h3 class="tabcont_lable">Basic settings for <span>Security</span></h3>
                <div class="labelsidetop">
                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Captcha setting</h4>
                    <div class="Tbaleflex">
                        <div class="Table">
                            <div class="Table-row">
                                <div class="Table-row-item">
                                <div class="lefttabl_lable">
                                    <span>Captcha</span>
                                </div>
                                </div>
                                <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <div class="form_group">
                                        <ul class="list">                            
                                            <li class="list__item">
                                            <input type="radio" id="Captcha1" name="Captcha" value='1' onClick={handleCaptcha} checked={captcha == 1?'checked':''}/>
                                            <label for="Captcha1" class="label">Enable</label>
                                            </li>                            
                                            <li class="list__item">
                                            <input type="radio" id="Captcha2" name="Captcha" value='0' onClick={handleCaptcha} checked={captcha == 0?'checked':''}/>
                                            <label for="Captcha2" class="label">Disable</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Login Attempt Control For Admin panel</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Login Attempt Control</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" onClick={handleLoginAttempt} className="login_attempt" id="login_attempt1" name="login_attempt" value="1" checked={loginAttempt == 1?'checked':''}/>
                                        <label for="login_attempt1" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" onClick={handleLoginAttempt} className="login_attempt" id="login_attempt2" name="login_attempt" value="0"  checked={loginAttempt == 0?'checked':''}/>
                                        <label for="login_attempt2" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Number of attempst allowed</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="number" id="number_of_attempts_allowed" class="fuilddesh minwidth02" placeholder="" value={numberOfAtteptsAllowed} onChange={handleNumberOfAttemptsAllowed}/>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    </div>
                </div>
                </div>

                <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Security
