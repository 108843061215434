import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const Messaging = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [messagingModule,setMessagingModule] = useState();
    const [maximumMessageLength,setMaximumMessageLength] = useState();
    const [emailUsers,setEmailUsers] = useState();
    const [visitorsMessage,setVisitorsMessage] = useState();
    
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/messaging/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setMessagingModule(res.data.data[0].common_setting[0].messagingModule);
                    setMaximumMessageLength(res.data.data[0].common_setting[0].maximumMessageLength);
                    setEmailUsers(res.data.data[0].common_setting[0].emailUsers); 
                    setVisitorsMessage(res.data.data[0].common_setting[0].visitorsMessage);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
        if(messagingModule == 1){
            var messagingModule_check = true;
        }else{
            var messagingModule_check = false;
        }
        if(emailUsers == 1){
            var emailUsers_check = true;
        }else{
            var emailUsers_check = false;
        }
        if(visitorsMessage == 1){
            var visitorsMessage_check = true;
        }else{
            var visitorsMessage_check = false;
        }
       
        var common_settings         = [ {
                                        'messagingModule': messagingModule_check,
                                        'maximumMessageLength':maximumMessageLength,
                                        'emailUsers':emailUsers_check,
                                        'visitorsMessage':visitorsMessage_check,
                                    }];
       
        if(slug == 'Messaging'){
            await axios.post(`${PORT}/messaging/update`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/messaging/create`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();

    }
  
    const handleMessagingModule = (event) =>{
        setMessagingModule(event.target.value);
    }
    const handleEmailUsers = (event) =>{
        setEmailUsers(event.target.value);
    }
    const handleVisitorsMessage = (event) =>{
        setVisitorsMessage(event.target.value);
    }
    const handleMaximumMessageLength = (event) =>{
        setMaximumMessageLength(event.target.value);
    }
    
    return (
        <div class="tab-pane" id="basic08" role="tabpanel">
            <form onSubmit={handleSubmit}>
            <h3 class="tabcont_lable">Basic settings for <span>Messaging</span></h3>
            <div class="labelsidetop">
            <div class="boxrepetform">
                <div class="Tbaleflex">
                <div class="Table">
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Messaging module</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="messagingModule1" name="messagingModule" value='1' onClick={handleMessagingModule} checked={messagingModule == 1?'checked':''}/>
                                        <label for="messagingModule1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="messagingModule2" name="messagingModule" value='0' onClick={handleMessagingModule} checked={messagingModule == 0?'checked':''}/>
                                        <label for="messagingModule2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Maximum message length</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input type="number" class="fuilddesh" name="maximumMessageLength" onChange={handleMaximumMessageLength} placeholder="" value={maximumMessageLength} />
                        </div>
                    </div>
                    </div>
                    {/* <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Check for new messages every</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input type="text" class="fuilddesh" name="" placeholder="" /><span class="equalspan_cont">seconds</span>
                        </div>
                    </div>
                    </div>
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Allow visitors to contact sellers through the contact form</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                    <input type="radio" id="test121" name="radio-group71" checked />
                                    <label for="test121" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                    <input type="radio" id="test122" name="radio-group71" />
                                    <label for="test122" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}

                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Email users about new messages</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="emailUsers1" name="emailUsers" value='1' onClick={handleEmailUsers} checked={emailUsers == 1?'checked':''}/>
                                        <label for="emailUsers1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="emailUsers2" name="emailUsers" value='0' onClick={handleEmailUsers} checked={emailUsers == 0?'checked':''}/>
                                        <label for="emailUsers2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Send visitors messages to seller to My messages box</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                        <input type="radio" id="visitorsMessage1" name="visitorsMessage" value='1' onClick={handleVisitorsMessage} checked={visitorsMessage == 1?'checked':''}/>
                                        <label for="visitorsMessage1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="visitorsMessage2" name="visitorsMessage" value='0' onClick={handleVisitorsMessage} checked={visitorsMessage == 0?'checked':''}/>
                                        <label for="visitorsMessage2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Email users about new messages sent via chat</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">                            
                                    <li class="list__item">
                                    <input type="radio" id="test127" name="radio-group74" checked />
                                    <label for="test127" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                    <input type="radio" id="test128" name="radio-group74" />
                                    <label for="test128" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div> */}
                </div>
                </div>
            </div>
            </div>
            <div class="bottomsend_button text-center">
                <button type="submit" class="savesidebutt">SAVE</button>
                <ToastContainer />
            </div>
            </form>
        </div>
    )
}

export default Messaging
