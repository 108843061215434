import React, { useState, useEffect } from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { toast, ToastContainer } from "react-toastify";

const Maps = () => {

    const PORT = PORTNUMBER.PORTNUMBER;
    const [sendEmailNotification, setSendEmailNotification] = useState();
    const [commentAutoApproval, setCommentAutoApproval] = useState();
    const [maximumCharacters, setMaximumCharacters] = useState();
    const [commentsPerPage, setCommentsPerPage] = useState();
    const [showHourMinuteForComments, setShowHourMinuteForComments] = useState();
    const [slug, setSlug] = useState();

    useEffect(async () => {
        GETALL();
    }, [])


    const GETALL = async () => {
        await axios.get(`${PORT}/comment/all`).then((res, err) => {
            if (res) {
                console.log(res.data.data);

                if (res.data.data != '') {
                    setSlug(res.data.data[0].slug);
                    setSendEmailNotification(res.data.data[0].common_setting[0].sendEmailNotification);
                    setCommentAutoApproval(res.data.data[0].common_setting[0].commentAutoApproval);
                    setMaximumCharacters(res.data.data[0].common_setting[0].maximumCharacters);
                    setCommentsPerPage(res.data.data[0].common_setting[0].commentsPerPage);
                    setShowHourMinuteForComments(res.data.data[0].common_setting[0].showHourMinuteForComments);
                }
            }
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (sendEmailNotification == 1) {
            var sendEmailNotification_check = true;
        } else if (sendEmailNotification == 0) {
            var sendEmailNotification_check = false;
        } else {
            var sendEmailNotification_check = '';
        }

        if (commentAutoApproval == 1) {
            var commentAutoApproval_check = true;
        } else if (commentAutoApproval == 0) {
            var commentAutoApproval_check = false;
        } else {
            var commentAutoApproval_check = '';
        }

        if (showHourMinuteForComments == 1) {
            var showHourMinuteForComments_check = true;
        } else if (showHourMinuteForComments == 0) {
            var showHourMinuteForComments_check = false;
        } else {
            var showHourMinuteForComments_check = '';
        }

        var common_settings = [{
            'sendEmailNotification': sendEmailNotification_check,
            'commentAutoApproval': commentAutoApproval_check,
            'maximumCharacters': maximumCharacters,
            'commentsPerPage': commentsPerPage,
            'showHourMinuteForComments': showHourMinuteForComments_check,
        }];

        if (slug == 'CommentSetting') {
            await axios.post(`${PORT}/comment/update`, { common_settings: JSON.stringify(common_settings) }).then((res, err) => {
                if (res) {
                    toast.success("You have successfully changed the settings");
                }
            })
        } else {
            await axios.post(`${PORT}/comment/create`, { common_settings: JSON.stringify(common_settings) }).then((res, err) => {
                if (res) {
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();
    }

    const handleSendEmailNotification = (event) => {
        setSendEmailNotification(event.target.value);
    }
    const handleCommentAutoApproval = (event) => {
        setCommentAutoApproval(event.target.value);
    }
    const handleMaximumCharacters = (event) => {
        setMaximumCharacters(event.target.value);
    }
    const handleCommentsPerPage = (event) => {
        setCommentsPerPage(event.target.value);
    }
    const handleShowHourMinuteForComments = (event) => {
        setShowHourMinuteForComments(event.target.value);
    }
    return (
        <div class="tab-pane" id="basic015" role="tabpanel">
            <form onSubmit={handleSubmit}>
                <h3 class="tabcont_lable">Basic settings for <span>Comment</span></h3>
                <div class="labelsidetop">
                    <div class="boxrepetform">
                        <h4 class="tabcont_Sublable">Common</h4>
                        <div class="Tbaleflex">
                            <div class="Table">
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Send e-mail notofication to listing owner</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="sendEmailNotification1" name="sendEmailNotification" value='1' onClick={handleSendEmailNotification} checked={sendEmailNotification == 1 ? 'checked' : ''} />
                                                        <label for="sendEmailNotification1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="sendEmailNotification2" name="sendEmailNotification" value='0' onClick={handleSendEmailNotification} checked={sendEmailNotification == 0 ? 'checked' : ''} />
                                                        <label for="sendEmailNotification2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Security code</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test186" name="radio-group97" checked />
                                        <label for="test186" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test187" name="radio-group97" />
                                        <label for="test187" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div> */}
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Comment auto approval</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="commentAutoApproval1" name="commentAutoApproval" value='1' onClick={handleCommentAutoApproval} checked={commentAutoApproval == 1 ? 'checked' : ''} />
                                                        <label for="commentAutoApproval1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="commentAutoApproval2" name="commentAutoApproval" value='0' onClick={handleCommentAutoApproval} checked={commentAutoApproval == 0 ? 'checked' : ''} />
                                                        <label for="commentAutoApproval2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>maximum characters number of message text</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input type="number" class="fuilddesh" name="maximumCharacters" placeholder="" onChange={handleMaximumCharacters} value={maximumCharacters} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>open comment page in new window</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test192" name="radio-group100" checked />
                                        <label for="test192" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test193" name="radio-group100" />
                                        <label for="test193" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Prevent non logged in users to see the comments tabs</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test194" name="radio-group101" checked />
                                        <label for="test194" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test196" name="radio-group101" />
                                        <label for="test196" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Prevent non logged in users to post comments</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test197" name="radio-group102" checked />
                                        <label for="test197" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test198" name="radio-group102" />
                                        <label for="test198" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div> */}
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>comments per page</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <input type="number" class="fuilddesh" name="commentsPerPage" placeholder="" value={commentsPerPage} onChange={handleCommentsPerPage} />
                                        </div>
                                    </div>
                                </div>
                                <div class="Table-row">
                                    <div class="Table-row-item">
                                        <div class="lefttabl_lable">
                                            <span>Show hours and minutes for comments</span>
                                        </div>
                                    </div>
                                    <div class="Table-row-item">
                                        <div class="dropdownsid">
                                            <div class="form_group">
                                                <ul class="list">
                                                    <li class="list__item">
                                                        <input type="radio" id="showHourMinuteForComments1" name="showHourMinuteForComments" value='1' onClick={handleShowHourMinuteForComments} checked={showHourMinuteForComments == 1 && showHourMinuteForComments != null ? 'checked' : ''} />
                                                        <label for="showHourMinuteForComments1" class="label">Enable</label>
                                                    </li>
                                                    <li class="list__item">
                                                        <input type="radio" id="showHourMinuteForComments2" name="showHourMinuteForComments" value='0' onClick={handleShowHourMinuteForComments} checked={showHourMinuteForComments == 0 && showHourMinuteForComments != null ? 'checked' : ''} />
                                                        <label for="showHourMinuteForComments2" class="label">Disable</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Comment block</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>get comment type</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <select class="fuilddesh">
                                        <option value="">Last</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Comment numbers in block</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh" name="" placeholder="" />
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Character number for comment truncating</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh" name="" placeholder="" />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Rating</h4>
                    <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Comments rating</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <div class="form_group">
                                    <ul class="list">                            
                                        <li class="list__item">
                                        <input type="radio" id="test201" name="radio-group104" checked />
                                        <label for="test201" class="label">Enable</label>
                                        </li>                            
                                        <li class="list__item">
                                        <input type="radio" id="test202" name="radio-group104" />
                                        <label for="test202" class="label">Disable</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="Table-row">
                            <div class="Table-row-item">
                            <div class="lefttabl_lable">
                                <span>Stars number</span>
                            </div>
                            </div>
                            <div class="Table-row-item">
                            <div class="dropdownsid">
                                <input type="text" class="fuilddesh" name="" placeholder="" />
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div> */}

                </div>
                <div class="bottomsend_button text-center">
                    <button type="submit" class="savesidebutt">SAVE</button>
                    <ToastContainer />
                </div>
            </form>
        </div>
    )
}

export default Maps
