import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

const NewsLetter = ({props}) => {
    

    const allpath = props.split("/");

     
    useEffect(() => {
      if(props == "/news-letter-subscribers")
      {
        document.getElementById("collapsenewsletter").classList.add("show");
      }
     },[])



    return (
               <li class="sidebar-dropdown mabot_mainli">
                           <Link data-toggle="collapse" data-target="#collapsenewsletter" to="#">
                             <span>Newsletter Management</span>
                             <i class="fa fa-tasks"></i>
                           </Link>
                           <div class="sidebar-submenu" id="collapsenewsletter" class="collapse" data-parent="#accordion">
                             <ul>
                               <li>
                                 <Link to="/news-letter-subscribers">News Letter</Link>
                               </li>
                             </ul>
                           </div>
                        </li>
    )
}

export default NewsLetter
