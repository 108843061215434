import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";

const VideoChat = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 
    const [autoStart,setAutoStart] = useState();
    const [multiLevelStreaming,setMultiLevelStreaming] = useState();
    const [liveStreamTimeout,setLiveStreamTimeout] = useState();
    const [liveStreamForLoggedInUser,setLiveStreamForLoggedInUser] = useState();
    const [onlySubscribedUsers,setOnlySubscribedUsers] = useState();
    const [emailBroadcaster,setEmailBroadcaster] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
      
     
    const GETALL = async () =>{
        await axios.get(`${PORT}/videochat/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
              
               if(res.data.data != ''){
                    setSlug(res.data.data[0].slug);
                    setAutoStart(res.data.data[0].common_setting[0].autoStart);
                    setMultiLevelStreaming(res.data.data[0].common_setting[0].multiLevelStreaming);
                    setLiveStreamTimeout(res.data.data[0].common_setting[0].liveStreamTimeout); 
                    setLiveStreamForLoggedInUser(res.data.data[0].common_setting[0].liveStreamForLoggedInUser);
                    setOnlySubscribedUsers(res.data.data[0].common_setting[0].onlySubscribedUsers);
                    setEmailBroadcaster(res.data.data[0].common_setting[0].emailBroadcaster);
                }   
            }
        })
    }

    const handleSubmit = async (event) =>{
        event.preventDefault();
       
        if(autoStart == 1){
            var autoStart_check = true;
        }else{
            var autoStart_check = false;
        }
        if(multiLevelStreaming == 1){
            var multiLevelStreaming_check = true;
        }else{
            var multiLevelStreaming_check = false;
        }
        if(liveStreamTimeout == 1){
            var liveStreamTimeout_check = true;
        }else{
            var liveStreamTimeout_check = false;
        }
        if(liveStreamForLoggedInUser == 1){
            var liveStreamForLoggedInUser_check = true;
        }else{
            var liveStreamForLoggedInUser_check = false;
        }
        if(onlySubscribedUsers == 1){
            var onlySubscribedUsers_check = true;
        }else{
            var onlySubscribedUsers_check = false;
        }
        if(emailBroadcaster == 1){
            var emailBroadcaster_check = true;
        }else{
            var emailBroadcaster_check = false;
        }
        var common_settings         = [ {
                                        'autoStart': autoStart_check,
                                        'multiLevelStreaming':multiLevelStreaming_check,
                                        'liveStreamTimeout':liveStreamTimeout_check,
                                        'liveStreamForLoggedInUser':liveStreamForLoggedInUser_check,
                                        'onlySubscribedUsers':onlySubscribedUsers_check,
                                        'emailBroadcaster':emailBroadcaster_check,
                                    }];
       
        if(slug == 'VideoChat'){
            await axios.post(`${PORT}/videochat/update`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/videochat/create`, {common_settings:JSON.stringify(common_settings)}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }
        GETALL();

    }
  
    const handleAutoStart = (event) =>{
        setAutoStart(event.target.value);
    }
    const handleMultiLevelStreaming = (event) =>{
        setMultiLevelStreaming(event.target.value);
    }
    const handleLiveStreamTimeout = (event) =>{
        setLiveStreamTimeout(event.target.value);
    }
    const handleLiveStreamForLoggedInUser = (event) =>{
        setLiveStreamForLoggedInUser(event.target.value);
    }
    const handleOnlySubscribedUsers = (event) =>{
        setOnlySubscribedUsers(event.target.value);
    }
    const handleEmailBroadcaster = (event) =>{
        setEmailBroadcaster(event.target.value);
    }
   
    return (
        <div class="tab-pane" id="basic06" role="tabpanel">
            <form onSubmit={handleSubmit}>
            <h3 class="tabcont_lable">Basic settings for <span>Video</span></h3>
            <div class="labelsidetop">
            <div class="boxrepetform">
                <div class="Tbaleflex">
                <div class="Table">
                    {/* <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Display a video type</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                            <select class="fuilddesh">
                                <option value="">preview</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Thumbnail position</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                            <select class="fuilddesh">
                                <option value="">left</option>
                            </select>
                        </div>
                    </div>
                    </div>
                    <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Video width</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input type="text" class="fuilddesh" name="" placeholder="" /><span class="equalspan_cont">px</span>
                        </div>
                    </div>
                    </div>
                    <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Video height</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input type="text" class="fuilddesh" name="" placeholder="" /><span class="equalspan_cont">px</span>
                        </div>
                    </div>
                    </div> */}
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Autostart</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">
                                    <li class="list__item">
                                        <input type="radio" id="autostart" name="autostart" value='1' onClick={handleAutoStart} checked={autoStart == 1?'checked':''}/>
                                        <label for="autostart" class="label">Enable</label>
                                    </li>                         
                                    <li class="list__item">
                                        <input type="radio" id="autostart2" name="autostart" value='0' onClick={handleAutoStart} checked={autoStart == 0?'checked':''}/>
                                        <label for="autostart2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Buffer length</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input type="text" class="fuilddesh" name="" placeholder="" /><span class="equalspan_cont">Number of seconds a video shuld be buffered ahead befoe the player starts it.</span>
                        </div>
                    </div>
                    </div>
                    <div class="Table-row">
                    <div class="Table-row-item">
                        <div class="lefttabl_lable">
                        <span>Default volume</span>
                        </div>
                    </div>
                    <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input type="text" class="fuilddesh" name="" placeholder="" /><span class="equalspan_cont">px</span>
                        </div>
                    </div>
                    </div> */}
                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Multi- live streaming</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">
                                    <li class="list__item">
                                        <input type="radio" id="multiLevelStreaming1" name="multiLevelStreaming" value='1' onClick={handleMultiLevelStreaming} checked={multiLevelStreaming == 1?'checked':''}/>
                                        <label for="multiLevelStreaming1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="multiLevelStreaming2" name="multiLevelStreaming" value='0' onClick={handleMultiLevelStreaming} checked={multiLevelStreaming == 0?'checked':''}/>
                                        <label for="multiLevelStreaming2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Maximum allowed active broadcaster/min</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <input type="text" class="fuilddesh" name="" placeholder="" />
                        </div>
                        </div>
                    </div> */}

                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Live stream timeout</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">
                                    <li class="list__item">
                                        <input type="radio" id="liveStreamTimeout1" name="liveStreamTimeout" value='1' onClick={handleLiveStreamTimeout} checked={liveStreamTimeout == 1?'checked':''}/>
                                        <label for="liveStreamTimeout1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="liveStreamTimeout2" name="liveStreamTimeout" value='0' onClick={handleLiveStreamTimeout} checked={liveStreamTimeout == 0?'checked':''}/>
                                        <label for="liveStreamTimeout2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Time out for live stream publishin (sec)</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <input type="text" class="fuilddesh" name="" placeholder="" />
                        </div>
                        </div>
                    </div> */}

                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Live stream for logged in users only</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">
                                    <li class="list__item">
                                        <input type="radio" id="liveStreamForLoggedInUser1" name="liveStreamForLoggedInUser" value='1' onClick={handleLiveStreamForLoggedInUser} checked={liveStreamForLoggedInUser == 1?'checked':''}/>
                                        <label for="liveStreamForLoggedInUser1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="liveStreamForLoggedInUser2" name="liveStreamForLoggedInUser" value='0' onClick={handleLiveStreamForLoggedInUser} checked={liveStreamForLoggedInUser == 0?'checked':''}/>
                                        <label for="liveStreamForLoggedInUser2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Stream sneak peak</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <input type="text" class="fuilddesh" name="" placeholder="" />
                            <span class="equalspan_cont">If stream for logged in users in enable but visitor can watch for a sec, set here before being asked to login in</span>
                        </div>
                        </div>
                    </div> */}

                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>only subscribed users can live stream</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">
                                    <li class="list__item">
                                        <input type="radio" id="onlySubscribedUsers1" name="onlySubscribedUsers" value='1' onClick={handleOnlySubscribedUsers} checked={onlySubscribedUsers == 1?'checked':''}/>
                                        <label for="onlySubscribedUsers1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="onlySubscribedUsers2" name="onlySubscribedUsers" value='0' onClick={handleOnlySubscribedUsers} checked={onlySubscribedUsers == 0?'checked':''}/>
                                        <label for="onlySubscribedUsers2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Maximum allowed viewers per streaming</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <input type="text" class="fuilddesh" name="" placeholder="" />
                        </div>
                        </div>
                    </div> */}

                    <div class="Table-row">
                        <div class="Table-row-item">
                        <div class="lefttabl_lable">
                            <span>Email broadcaster about subscriber</span>
                        </div>
                        </div>
                        <div class="Table-row-item">
                        <div class="dropdownsid">
                            <div class="form_group">
                                <ul class="list">
                                    <li class="list__item">
                                        <input type="radio" id="emailBroadcaster1" name="emailBroadcaster" value='1' onClick={handleEmailBroadcaster} checked={emailBroadcaster == 1?'checked':''}/>
                                        <label for="emailBroadcaster1" class="label">Enable</label>
                                    </li>                            
                                    <li class="list__item">
                                        <input type="radio" id="emailBroadcaster2" name="emailBroadcaster" value='0' onClick={handleEmailBroadcaster} checked={emailBroadcaster == 0?'checked':''}/>
                                        <label for="emailBroadcaster2" class="label">Disable</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div class="bottomsend_button text-center">
                <button type="submit" class="savesidebutt">SAVE</button>
                <ToastContainer />
            </div>
            </form>
        </div>

    )
}

export default VideoChat
