import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";



const FeatureEdit = ({props}) => {
  const PORT = PORTNUMBER.PORTNUMBER;
  const [duration, setDuartion] = useState("");
  const [featurePrices, setFeaturePrices] = useState([{
      range : {
        from : "",
        to : ""
      },
      private_seller : "",
      business_seller : "",
      soletrader_seller: "",
    }]
  );

  const history = useHistory();
  const id = props;

  useEffect(async ()=>{
    await axios.post(`${PORT}/feature/get_single`, {id}).then((res, err) => {
      console.log("edit", res);
      setDuartion(res.data.data.duration)
      setFeaturePrices(res.data.data.charges)
    });
  },[props])


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!duration) {
      document.getElementById("duration").style.display = "block";
      return;
    }

    await axios
      .post(`${PORT}/feature/update`,
        {id, duration, featurePrices }
      )
      .then((res, err) => {
        if (res) {
          handleClose();
          setDuartion("");
          setFeaturePrices([{
            range : {
              from : "",
              to : ""
            },
            private_seller : "",
            business_seller : "",
            soletrader_seller: "",
          }]);
          history.push({ pathname: "/listing-features", MatchValue: "add" });
        }
        else {
          toast.info("Something went wrong");
        }
      });
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) => {
    document.body.classList.remove('fixedit_modal');
  }


  const handleDurationChange = (e) => {
    let {name , value} = e.target;
    setDuartion(value);
  };


  const handleFormChange = (index, event, name) => {

    if(name != "range"){
      if(Number(event.target.value) < 0 || Number(event.target.value) > 100){
         return false;
      }
    }

    let dataAll = [...featurePrices];

    if(name != "range"){
      dataAll[index][event.target.name] = (event.target.value != undefined)? event.target.value : "";
    }else{
      dataAll[index][name][event.target.name] = (event.target.value != undefined)? event.target.value : "";
    }
    setFeaturePrices(dataAll);

  }

  const addPriceRangeBlock = (i, e) => {
    let newfield = {
      range : {
        from : "",
        to : ""
      },
      private_seller : "",
      business_seller : "",
      soletrader_seller : "",
    };
    setFeaturePrices([...featurePrices, newfield]);
  };


  const removePriceRangeBlock = (i) => {
    let data = [...featurePrices];
    data.splice(i, 1);
    setFeaturePrices(data);
  };



  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a  href={void(0)} class="fixhbtn"><i class="fa fa-check"></i></a>
        <a  href={void(0)} onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Features Plan Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">
                      <div class="Table-row">
                        <div class="Table-row-item">
                          <div class="lefttabl_lable">
                            Duration
                          </div>
                        </div>
                        <div class="Table-row-item">
                          <div class="dropdownsid">
                            <input className="fuilddesh" name="duration" type="number" min="0" placeholder="Duration in days" onChange={ event => handleDurationChange(event) } value={duration} />
                            <p
                              className="text-danger"
                              id="duration"
                              style={{ display: "none" }}
                            >
                              Duration is required*
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="Table-row">
                        <div class="Table-row-item">
                          Charges
                        </div>
                        <div class="Table-row-item">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                Price Range
                              </th>
                              <th scope="col">
                                Private
                              </th>
                              <th scope="col">
                                Business
                              </th>
                              <th scope="col">
                                Soletrader
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {featurePrices && featurePrices.length > 0 && featurePrices.map((prices,index) => {
                              return( <tr key={index}>
                                <td>
                                  <input className="fuild-range" name="from" type="number" min="0" step="any" placeholder="From" onChange={ event => handleFormChange(index,event,'range') } value={prices.range?.from} />
                                  <input className="fuild-range" name="to" type="number" min="0" step="any" placeholder="To" onChange={ event => handleFormChange(index,event,'range') } value={prices.range?.to} />
                                </td>
                                <td>
                                  <input className="fuilddesh" name="private_seller" type="number" min="0" max="100" step="any" placeholder="Private %" onChange={ event => handleFormChange(index,event,'private_seller') }  value={prices?.private_seller}/>
                                </td>
                                <td>
                                  <input className="fuilddesh" name="business_seller" type="number" min="0" max="100" step="any" placeholder="Business %" onChange={ event => handleFormChange(index,event,'business_seller') } value={prices?.business_seller}/>
                                </td>
                                <td className="pos-relative">
                                  <input className="fuilddesh" name="soletrader_seller" type="number" min="0" max="100" step="any" placeholder="Soletrader %" onChange={ event => handleFormChange(index,event,'soletrader_seller') } value={prices?.soletrader_seller}/>
                                  {index === 0 ? 
                                  <div className="add-remove-button">
                                    <a href={void(0)} onClick= {event => addPriceRangeBlock(index, event)} ><i class="fa fa-plus" aria-hidden="true"></i></a>
                                  </div> : <div className="add-remove-button">
                                    <a href={void(0)} onClick= {event => removePriceRangeBlock(index, event)} ><i class="fa fa-remove" aria-hidden="true"></i></a>
                                  </div> }
  
                                </td>
                             </tr>)
                            })}
                           
                          </tbody>
                        </table>
                        </div>
                      </div>

                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">SAVE</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default FeatureEdit;

