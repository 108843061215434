import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";



const PlanEdit = ({props}) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allcategory, setAllCategory] = useState();
  const [allsubcategory, setAllSubcategory] = useState();
  const [adtype, setAdType] = useState();
  const [field, setField] = useState();
  const [amount, setAmount] = useState();
  const [category, setCategory] = useState();
  const [duration, setDuartion] = useState();
  const [status, setStatus] = useState();


  const history = useHistory();
  const id = props;


  useEffect(async () => {
    await axios.get(`${PORT}/getCategorys`).then((res, err) => {
      console.log("category", res)
      setAllCategory(res);
    });
  }, [])

  useEffect(async () => {
    await axios.get(`${PORT}/get_all_subcategory`).then((res, err) => {
      console.log("subcategory", res)
      setAllSubcategory(res);
    });
  }, [])

  useEffect(async ()=>{
    await axios.post(`${PORT}/plan/get_single`, {id}).then((res, err) => {
      console.log("edit", res);
      setAdType(res.data.data.ad_type)
      setAmount(res.data.data.amount)
      setField(res.data.data.field_name);
      setCategory(res.data.data.categoryId)
      setDuartion(res.data.data.duration)
      setStatus(res.data.data.status)
    });
  },[props])


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!adtype) {
      document.getElementById("ad-type").style.display = "block";
      return;
    }
    if (!duration) {
      document.getElementById("duration").style.display = "block";
      return;
    }
    if (!amount) {
      document.getElementById("amount").style.display = "block";
      return;
    }
    if (!field) {
      document.getElementById("field-name").style.display = "block";
      return;
    }
    if (!category) {
      document.getElementById("category").style.display = "block";
      return;
    }
    if (!status) {
      document.getElementById("status").style.display = "block";
      return;
    }

    await axios
      .post(`${PORT}/plan/update`,
        {id, adtype, duration, amount, category, field, status }
      )
      .then((res, err) => {
        if (res) {
          handleClose();
          setAdType("");
          setDuartion("");
          setAmount("");
          setCategory("");
          setStatus("");
          setField("");
          history.push({ pathname: "/listing-plans", MatchValue: "add" });
        }
        else {
          toast.info("Something went wrong");
        }
      });
  }



  const handleAmount = (event) => {
    event.preventDefault();
    document.getElementById("amount").style.display = "none";
    setAmount(event.target.value);
  };


  const handleStatus = (event) => {
    event.preventDefault();
    document.getElementById("status").style.display = "none";
    if (event.target.value == "") {
      document.getElementById("status").style.display = "block";
      setStatus(false);
      return;
    }
    else {
      setStatus(event.target.value);
      return;
    }

  }


  const handleAdType = (event) => {
    event.preventDefault();
    document.getElementById("ad-type").style.display = "none";
    setAdType(event.target.value);
  }

  const handleDuration = (event) => {
    document.getElementById("duration").style.display = "none";
    setDuartion(event.target.value)
  }

  const handleCategory = (data) => {
    document.getElementById("category").style.display = "none";
    setCategory(data)
  }

  const handleFieldName = (event) => {
    document.getElementById("field-name").style.display = "none";
    setField(event.target.value);
  }


  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleClose = (event) => {
    document.body.classList.remove('fixedit_modal');
  }



  return (
    <div class="editfix main_bxshadow">
      <div class="headeredi_fix text-right">
        <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
        <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
      </div>
      <form onSubmit={handleSubmit}>
        <div class="table_fixe">
          <div class="labelsidetop">
            <div class="boxrepetform">
              <h4 class="tabcont_Sublable">Plan Settings</h4>
              <div class="Tbaleflex">
                <div class="Table">


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Ad Type</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleAdType}
                          value={adtype}
                          class="fuilddesh"
                        >
                          <option value="">Select</option>
                          <option value="Urgent">Urgent</option>
                          <option value="Featured">Featured</option>
                          <option value="Spotlight">Spotlight</option>
                        </select>
                        <p
                          className="text-danger"
                          id="ad-type"
                          style={{ display: "none" }}
                        >
                          Ad Type is required*
                    </p>
                      </div>
                    </div>
                  </div>

                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Duration</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleDuration}
                          value={duration}
                          type="number"
                          class="fuilddesh"
                        />
                        <p
                          className="text-danger"
                          id="duration"
                          style={{ display: "none" }}
                        >
                          Duration is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Amount</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <input
                          onChange={handleAmount}
                          type="number"
                          className="fuilddesh"
                          placeholder=""
                          value={amount}
                        />
                        <p
                          className="text-danger"
                          id="amount"
                          style={{ display: "none" }}
                        >
                          Amount is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Field Name</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleFieldName}
                          value={field}
                          class="fuilddesh"
                        >
                          <option value="">{`Select Field Name`}</option>
                          {
                            allcategory && allcategory.data && allcategory.data.map((data) => {
                              return (
                                <option value={data._id}>{data.categoryName}</option>
                              )
                            })
                          }
                        </select>
                        <p
                          className="text-danger"
                          id="field-name"
                          style={{ display: "none" }}
                        >
                          Field Name is required*
                    </p>
                      </div>
                    </div>
                  </div>




                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Category</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid multiSelectBars">
                        <Multiselect
                          onSelect={handleCategory}
                          onRemove={handleCategory}
                          selectedValues={category}
                          options={allsubcategory && allsubcategory.data}
                          displayValue="subCategoryName"
                        />
                        <p
                          className="text-danger"
                          id="category"
                          style={{ display: "none" }}
                        >
                          Category is required*
                    </p>
                      </div>
                    </div>
                  </div>


                  <div class="Table-row">
                    <div class="Table-row-item">
                      <div class="lefttabl_lable">
                        <span>Status</span>
                      </div>
                    </div>
                    <div class="Table-row-item">
                      <div class="dropdownsid">
                        <select
                          onChange={handleStatus}
                          value={status}
                          class="fuilddesh"
                        >
                          <option value="">Select</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                        <p
                          className="text-danger"
                          id="status"
                          style={{ display: "none" }}
                        >
                          Status is required*
                    </p>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
            </div>
          </div>
          <div class="bottomsend_button text-center small_margin_top">
            <button type="submit" class="savesidebutt closefix">SAVE</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default PlanEdit;

