import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import PORTNUMBER from "../../../constant/api";
import Button from "react-bootstrap/Button";
import Pagination from "../../pagination/pagination";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import ReactPaginate from "react-paginate";
import EditPage from "../blocks/edit";

const Pages = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allPages, setAllPages] = useState();
  const [Id, setId] = useState();

  const [deleteModal, setDeleteModal] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [deactiveModal, setDeactiveModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();

  useEffect(async () => {
   GetAll();
  }, []);

  const GetAll = async () =>{
    await axios.get(`${PORT}/get_blocks`).then((response, err) => {
      console.log("block",response);
      setAllPages(response);
    });
  }

  useEffect(() => {
    if (typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "edit") {
          GetAll();
          toast.success("You have successfully edited page");
    }
  }, [typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "edit"]);

  useEffect(() => {
    if (typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "add") {
          GetAll();
          toast.success("You have successfully added page");
    }
  }, [typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue == "add"]);

  const handleClose = () => {
    setDeleteModal(false);
    setActiveModal(false);
    setDeactiveModal(false);
  };

  const handleModalDelete = (id) => {
    setDeleteModal(true);
    setId(id);
  };

  const handleModalActive = (id) => {
    setActiveModal(true);
    setId(id);
  };

  const handleModalDeactive = (id) => {
    setDeactiveModal(true);
    setId(id);
  };

  const handleDeleteYes = () => {
    handleDeleteBrand(Id);
    setDeleteModal(false);
  };

  const handleActiveYes = () => {
    handleClickActive(Id);
    setActiveModal(false);
  };

  const handleDeactiveYes = () => {
    handleClickDeactive(Id);
    setDeactiveModal(false);
  };

  const handleDeleteBrand = async (id) => {
    await axios.post(`${PORT}/delete_block`, { id }).then((response, err) => {
      toast.success("You have successfully deleted this block");
    });
    await axios.get(`${PORT}/get_blocks`).then((response, err) => {
      if (response) {
        setAllPages(response);
      } else {
        toast.info("Something went wrong");
      }
    });
  };

  const handleClickDeactive = async (id) => {
    await axios
      .post(`${PORT}/status_page`, { id, status: "Inactive" })
      .then((response, err) => {
        toast.success("You have successfully Inactivated this page");
      });
    await axios.get(`${PORT}/getPages`).then((response, err) => {
      if (response) {
        setAllPages(response);
      } else {
        toast.info("Something went wrong");
      }
    });
  };

  const handleClickActive = async (id) => {
    await axios
      .post(`${PORT}/status_page`, { id, status: "Active" })
      .then((response, err) => {
        toast.success("You have successfully activated this page");
      });
    await axios.get(`${PORT}/getPages`).then((response, err) => {
      if (response) {
        setAllPages(response);
      } else {
        toast.info("Something went wrong");
      }
    });
  };

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const pagesVisited = currentPage * postsPerPage;
  const pageCount = Math.ceil(
    (allPages &&
      allPages.data &&
      allPages.data.data &&
      allPages.data.data.length) / postsPerPage
  ) || 1;

  const currentPosts =
    allPages &&
    allPages.data &&
    allPages.data.data;
  // const currentPosts = allPages && allPages.data && allPages.data.data && allPages.data.data.slice(indexOfFirstPost, indexOfLastPost);
  console.log("sdbhdfhf", currentPosts);

  const paginate = (pagenumber, TagID) => {
    document.getElementById(TagID).classList.add("active");
    setCurrentPage(pagenumber);
  };

  const handleSearch = (event) => {
    setSearchItem(event.target.value);
  };

  const handlePageChange = ({ selected }) => {
    console.log("selectedpage", selected);
    console.log(pageCount);
    setCurrentPage(selected);
  };
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  const handleSelect = async (event) => {
    console.log("ccc", event.target.value);
    if (event.target.value == "all") {
      await axios.get(`${PORT}/getPages`).then((response, err) => {
        if (response) {
          setAllPages(response);
        } else {
          toast.info("Something went wrong");
        }
      });
    } else if (event.target.value == "active") {
      await axios
        .post(`${PORT}/get_page_status`, { status: "Active" })
        .then((response, err) => {
          if (response) {
            setAllPages(response);
          } else {
            toast.info("Something went wrong");
          }
        });
    } else if (event.target.value == "inactive") {
      await axios
        .post(`${PORT}/get_page_status`, { status: "Inactive" })
        .then((response, err) => {
          if (response) {
            setAllPages(response);
          } else {
            toast.info("Something went wrong");
          }
        });
    }
  };

  const handleEdit = (event, id) =>{
    document.body.classList.add('fixedit_modal');
    setPropsId(id)
  }

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Blocks</li>
              </ul>
              <h2 class="main_title">Blocks</h2>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                {/* <div className="text-right">
                 <Link to="/AddBlock" id="add-block" style={{backgroundColor:"#FE5F55", marginRight:"15px"}} className="btn btn-primary">Add Block</Link>
                </div> */}
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  <div class="dropdownsid">
                    <select class="fuilddesh" onChange={handleSelect}>
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">Title</div>
                        <div class="table_cell">Sub Title</div>
                        <div class="table_cell">Actions</div>
                      </div>

                      {currentPosts &&
                        currentPosts
                          .filter((val) => {
                            if (searchItem == null) {
                              return val;
                            } else if (
                              val.pageName
                                .toLowerCase()
                                .includes(searchItem.toLowerCase())
                            ) {
                              return val;
                            } else {
                              return false;
                            }
                          })
                          .map((page) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="table_cell">{page.title}</div>
                                <div class="table_cell">{page.subTitle}</div>
                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      // to={"/EditPage/" + page._id}
                                      onClick={(e)=>handleEdit(e, page._id)}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </Link>
                                  </span>
                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) =>
                                        handleModalDelete(page._id)
                                      }
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                  <ToastContainer />
      <Modal isOpen={deleteModal}>
      <div class="modalChild">
      <div class="modalchildPadding">
        <h6>Do you want to delete this block?</h6>
        </div>
        <Button
          onClick={handleDeleteYes}
          className="savesidebutt"
          variant="primary"
        >
          Yes
        </Button>
        <Button
          onClick={handleClose}
          className="savesidebutt close-savesidebutt"
          variant="secondary"
        >
          Close
        </Button>
        </div>
      </Modal>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
     {propsid == undefined
     ?
     null
     :
     <EditPage props={propsid} />
     }
     
    </div>
  );
};

export default Pages;


