import React, {useState, useEffect} from 'react';
import axios from "axios";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import "./newUser.css";
import PORTNUMBER from "../../constant/api";
import {useHistory, useLocation, Redirect} from "react-router-dom";


const NewUser = () => {

    const PORT = PORTNUMBER.PORTNUMBER;
    

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [seller, setSeller] = useState();


    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;




    const handleSubmit = async (event) =>{
      console.log("sdsdfsd");
        event.preventDefault();
        if(!firstName)
        {
          document.getElementById("first-name").style.display="block";
          return;
        }
        if(!lastName)
        {
          document.getElementById("last-name").style.display="block";
          return;
        }
        if(!email)
        {
          document.getElementById("email").style.display="block";
          return;
        }
        if(email)
        {
             let emailResult = validateEmail(email);
             if(!emailResult)
             {
              document.getElementById("email").style.display="block";
              return;
             }  
         }
        if(!phoneNumber)
        {
          document.getElementById("phone-number").style.display="block";
          return;
        }
        if(!password)
        {
          document.getElementById("password").style.display="block";
          return;
        }
        if(!confirmPassword)
        {
          document.getElementById("confirm-password").style.display="block";
          return;
        }

        if(password != confirmPassword) 
        {
          toast.info('Password and Confirm Password must be same');
          return; 
        }

        if(!seller)
        {
          document.getElementById("seller").style.display="block";
          return;
        }
        else{
          await axios.post(`${PORT}/userSignup`, {firstName, lastName, email, phoneNumber, password, confirmPassword, seller}).then((res, err) =>{;
            if(res)
            {
              handleClose();
              setFirstName("");
              setLastName("");
              setEmail("");
              setPhoneNumber("");
              setPassword("");
              setConfirmPassword("");
              history.push({pathname:"/accounts", MatchValue:'add'});
            }
        })
        }
    }




    const handleFirstName = (event) =>{
        event.preventDefault();
        document.getElementById("first-name").style.display="none";
        setFirstName(event.target.value);
    }
    const handleLastName = (event) =>{
        event.preventDefault();
        document.getElementById("last-name").style.display="none";
        setLastName(event.target.value);
    }

    const handleEmail = (event) =>{
        event.preventDefault();
        document.getElementById("email").style.display="none";
        setEmail(event.target.value);
    }
    const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const handlePhoneNumber = (event) =>{
        event.preventDefault();
        document.getElementById("phone-number").style.display="none";
        setPhoneNumber(event.target.value);
    }
    const handlePassword = (event) =>{
        event.preventDefault();
        document.getElementById("password").style.display="none";
        setPassword(event.target.value);
    }
    const handleConfirmPassword = (event) =>{
        event.preventDefault();
        document.getElementById("confirm-password").style.display="none";
        setConfirmPassword(event.target.value);
    }

    const handleSeller = (event, type) =>{
      console.log(event.target.value)
      document.getElementById("seller").style.display="none";
      if(event.target.value == 'on')
      {
        setSeller(type);
      }
    }

    const handleClose = (event) =>{
      document.body.classList.remove('fixedit_modal');
      // document.getElementById("edit-form").classList.remove("active");
      // document.getElementById("add-form").classList.remove("active");
    }

    return (
            <div class="editfix main_bxshadow">
            <div class="headeredi_fix text-right">
             <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
             <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="table_fixe">
                <div class="labelsidetop">
                  <div class="boxrepetform">
                    <h4 class="tabcont_Sublable">Profile Settings</h4>
                    <div class="Tbaleflex">
                      <div class="Table">
                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>First Name</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleFirstName} value={firstName} type="text" class="fuilddesh" name="" placeholder="" />
                              <p id="first-name" style={{display:"none"}} className="text-danger">First Name is required*</p>
                            </div>
                          </div>
                        </div>
            

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Last Name</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleLastName} value={lastName} type="text" class="fuilddesh" name="" placeholder="" />
                              <p id="last-name" style={{display:"none"}} className="text-danger">Last Name is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Email</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleEmail} value={email} type="text" class="fuilddesh" name="" placeholder="" />
                              <p id="email" style={{display:"none"}} className="text-danger">Valid Email Address is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Mobile Number</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handlePhoneNumber} value={phoneNumber} type="number" class="fuilddesh" name="" placeholder="" />
                              <p id="phone-number" style={{display:"none"}} className="text-danger">Mobile Number is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Password</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handlePassword} value={password} type="password" class="fuilddesh" name="" placeholder="" />
                              <p id="password" style={{display:"none"}} className="text-danger">Password is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Confirm Password </span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <input onChange={handleConfirmPassword} value={confirmPassword} type="password" class="fuilddesh" name="" placeholder="" />
                              <p id="confirm-password" style={{display:"none"}} className="text-danger">confirm password is required*</p>
                            </div>
                          </div>
                        </div>

                        <div class="Table-row">
                          <div class="Table-row-item">
                            <div class="lefttabl_lable">
                              <span>Seller Type</span>
                            </div>
                          </div>
                          <div class="Table-row-item">
                            <div class="dropdownsid">
                              <div class="form_group">
                                <ul class="list">
                                  <li class="list__item">
                                    <input onChange={(e) => handleSeller(e, 'Private')} type="radio" id="test44" name="radio-group44" />
                                    <label for="test44" class="label">Private</label>
                                  </li>
                                  <li class="list__item">
                                    <input onChange={(e) => handleSeller(e, 'Company')} type="radio" id="test45" name="radio-group44" />
                                    <label for="test45" class="label">Company</label>
                                  </li>
                                  <p id="seller" style={{display:"none"}} className="text-danger">seller type is required*</p>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bottomsend_button text-center small_margin_top">
                  <button type="submit" class="savesidebutt closefix">SAVE</button>
                </div>
              </div>
              </form>
              <ToastContainer />
            </div>
    )
}

export default NewUser







  //      <div class="work_space">
  //       <div class="workspace_title_hd">
  //         <div class="row">
  //               <div class="col-md-12">
  //                 <div class="titleof_page">
  //               <ul class="breadcrumb_list topsmall_bottom">
  //                 <li><a href="#">Admin Panel </a></li>
  //                 <li><i style={{padding:"5px"}} class="fa fa-angle-right"></i></li>
  //                 <li>New User</li>
  //               </ul>
  //                   <h2 class="main_title">Add New User</h2>
  //                 </div>
  //               </div>
  //           <div class="col-md-12">
  //             <div class="main_oflistingForm bg_light_gray onlyforcol_support">
  //               <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">
  //                 <form onSubmit={handleSubmit}>
  //                 <div class="row">
  //                   <div class="col-md-6">
  //                   <div class="form-group">
  //                   <h4 class="fieldsec_lab">First Name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
  //                   <div class="">
  //                     <div class="repwat_one">
  //                       <input onChange={handleFirstName} type="text" className="fuilddesh1 "  placeholder="First Name" value={firstName} />
  //                     </div>
  //                   </div>
  //                   <p id="first-name" style={{display:"none"}} className="text-danger">First Name is required*</p>
  //                 </div>
  //                 </div>

  //                 <div class="col-md-6">
  //                   <div class="form-group">
        
  //                   <h4 class="fieldsec_lab">Last Name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
  //                   <div class="">
  //                     <div class="repwat_one">
  //                       <input onChange={handleLastName} type="text" class="fuilddesh1 " placeholder="Last Name" value={lastName} />
  //                     </div>
  //                   </div>
  //                   <p id="last-name" style={{display:"none"}} className="text-danger">Last Name is required*</p>
  //              </div>
  //              </div>
  //              </div>
        



  //                 <div class="row">
  //                   <div class="col-md-6">


  //                   <div class="form-group">
  //                   <h4 class="fieldsec_lab">Email Address<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
  //                   <div class="">
  //                     <div class="repwat_one">
  //                       <input onChange={handleEmail} type="text" class="fuilddesh1 " name="" placeholder="Email Address" value={email} />
  //                     </div>
  //                   </div>
  //                   <p id="email" style={{display:"none"}} className="text-danger">Valid Email Address is required*</p>
  //                 </div>
  //                 </div>

  //                 <div class="col-md-6">


  //                 <div class="form-group">
  //                   <h4 class="fieldsec_lab">Phone Number<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
  //                   <div class="">
  //                     <div class="repwat_one">
  //                       <input onChange={handlePhoneNumber} type="number" class="fuilddesh1 " placeholder="Phone Number" value={phoneNumber} />
  //                     </div>
  //                   </div>
  //                   <p id="phone-number" style={{display:"none"}} className="text-danger">Phone Number is required*</p>
  //                 </div>

  //                 </div>
  //                 </div>
  //                 <div class="row">
  //                   <div class="col-md-6">


  //                   <div class="form-group">
  //                   <h4 class="fieldsec_lab">Password<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
  //                   <div class="">
  //                     <div class="repwat_one">
  //                       <input onChange={handlePassword} type="password" class="fuilddesh1 " placeholder="Password" value={password} />
  //                     </div>
  //                   </div>
  //                   <p id="password" style={{display:"none"}} className="text-danger">Password is required*</p>
  //                 </div>

  //                 </div>
  //                 <div class="col-md-6">
  //                 <div class="form-group">
  //                   <h4 class="fieldsec_lab">Confirm Password<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
  //                   <div class="">
  //                     <div class="repwat_one">
  //                       <input onChange={handleConfirmPassword} type="password" class="fuilddesh1 " placeholder="Confirm Password" value={confirmPassword} />
  //                     </div>
  //                   </div>
  //                   <p id="confirm-password" style={{display:"none"}} className="text-danger">confirm password is required*</p>
  //                 </div>
  //                 </div>
  //                 </div>



  //                 <div className="bottomsend_button text-center small_margin_top">
  //                 <button type="submit" className="savesidebutt closefix">Save</button>
  //                 <button type="button" onClick={handleReset} className="savesidebutt closefix">Reset</button>
  //                 </div>


  //                 </form>
  //               </div> 
  //              </div>
  //           </div>
  //         </div>
  //       </div>
  //       <ToastContainer />
  // </div>
