import React from 'react'
import BasicSettingMenu from "./BasicSettingMenu";
import General from "./components/General";
import Security from "./components/Security";
import CronJobs from "./components/CronJobs";
import ProductSafetyTips from "./components/ProductSafetyTips";
import Accounts from './components/Accounts';
import VideoChat from './components/VideoChat';
import Messaging from './components/Messaging';
import Maps from './components/Maps';
import GeoFilter from './components/GeoFilter';
import MassMailer from './components/MassMailer';
import Comment from './components/Comment';
import SearchByDistance from './components/SearchByDistance';
import Listings from './components/Listings';
import Pictures from './components/Pictures';
import LiveVideoStreaming from './components/LiveVideoStreaming';
const BasicSettingList = () => {
    return (
        <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
              <div class="col-md-12">
                <div class="titleof_page">
                  <ul class="breadcrumb_list topsmall_bottom">
                    <li><a href="#">Admin Panel </a></li>
                    <li><i style={{padding:"5px"}} class="fa fa-angle-right"></i></li>
                    <li>Basic Settings</li>
                  </ul>
                    <h2 class="main_title">Basic Settings</h2>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="main_oflistingForm bg_light_gray main_bxshadow">

                   <BasicSettingMenu />

                  <div class="right_all_TContent">
                      <div class="tab-content">

                        <General />
                        <Accounts/>
                        <Security />
                        <ProductSafetyTips/>
                        <Pictures/>
                        <Listings/>
                        <VideoChat />
                        <LiveVideoStreaming/>
                        <Messaging/>
                        <Maps/>  
                        <GeoFilter/>
                        <Comment/>
                        <SearchByDistance/>
                        <MassMailer/>

                        <div class="tab-pane" id="basic020" role="tabpanel">
                        </div>


                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    )
}

export default BasicSettingList
