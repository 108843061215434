import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Categories = ({ props }) => {
  //console.log("categorypropsdata", props);
  const allPathEdit = props.split("/");
 // console.log("allpath", allPathEdit);

  useEffect(() => {
    if (
      props == "/categories" ||
      allPathEdit[1] == "categories/edit" ||
      allPathEdit[1] == "categories/add" ||
      allPathEdit[1] == "sub-categories" ||
      allPathEdit[1] == "sub-categories/add" ||
      allPathEdit[1] == "sub-categories/edit" ||
      allPathEdit[1] == "fields" ||
      allPathEdit[1] == "fields/add" ||
      allPathEdit[1] == "fields/edit"
    ) {
      document.getElementById("collapseCategory").classList.add("show");
    }
  }, []);

  return (
    <li className="sidebar-dropdown mabot_mainli">
      <Link to="#" data-toggle="collapse" data-target="#collapseCategory">
        <span>Categories</span>
        <i className="fa fa-tags"></i>
      </Link>
      <div
        className="sidebar-submenu"
        id="collapseCategory"
        class="collapse"
        data-parent="#accordion"
      >
        <ul>
          <li>
            <Link to="/categories">Categories</Link>
          </li>
          <li>
            <Link to="/sub-categories">Sub categories</Link>
          </li>
          <li>
            <Link to="/fields">Fields</Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default Categories;