import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const EditFooterLogo = ({props}) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [footerImage, setfooterImage] = useState();

  const id = props;
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const handleSubmit = async (event) => {
     event.preventDefault();
      await axios
        .post(`${PORT}/updateFooterLogo`, {
          params: { footerImage },
        })
        .then((res, err) => {
          if(res)
          {
            handleClose();
            history.push({ pathname: "/logo", MatchValue:"edit" });
          }
        });
    
  };

  const handleFooterLogoImage = (event) => {
    const files = event.target.files[0];

    let fData = new formData();
    fData.append("footerImage", files);

    var config = {
      method: "post",
      url: `${PORT}/uploadFooterLogo`,
      data: fData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success == 1) {
          console.log(response.data.image_url.split("/"));
          const image = response.data.image_url.split("/");
          console.log("image footer",image)
          setfooterImage(image[5]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const handleClose = (event) =>{
    document.body.classList.remove('fixedit_modal');
  }

  return (
    <div class="editfix main_bxshadow">
    <div class="headeredi_fix text-right">
     <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
     <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
    </div>
    <form onSubmit={handleSubmit}>
      <div class="table_fixe">
        <div class="labelsidetop">
          <div class="boxrepetform">
            <h4 class="tabcont_Sublable">Logo Settings</h4>
            <div class="Tbaleflex">
              <div class="Table">
                <div class="Table-row">
                  <div class="Table-row-item">
                    <div class="lefttabl_lable">
                      <span>Footer Logo</span>
                    </div>
                  </div>
                  <div class="Table-row-item">
                    <div class="dropdownsid">
                    <input
                    onChange={handleFooterLogoImage}
                    type="file"
                    class=""
                    name="img"
                    placeholder=""
                  />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottomsend_button text-center small_margin_top">
          <button type="submit" class="savesidebutt closefix">SAVE</button>
        </div>
      </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditFooterLogo;
