import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";
import AllImages from "../../../constant/img";
import "./brandAdd.css";
import Resizer from "react-image-file-resizer";

const AddBrand = () => {

    const PORT = PORTNUMBER.PORTNUMBER;
    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const [brandName, setProductName] = useState();
    const [brandImage, setBrandImage] = useState();

   


    const handleSubmit = async (event) =>{
        event.preventDefault();

        if(!brandName)
        {
          document.getElementById("brand-name").style.display = "block";
          return;
        }
        if(!brandImage)
        {
          document.getElementById("brand-image").style.display = "block";
          return;
        }

        await axios.post(`${PORT}/add_brand`, {params:{brandName,brandImage}}).then((response, err)=>{ 
        if(response)
        {
            handleClose();
            setProductName("");
            history.push({pathname:"/BrandList", MatchValue:"add"});
        }  
        else{
            toast.info("Something went wrong");
            return;
        }  
       
        })
      }


      const resizeFile = (file) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          180,
          180,
          "JPEG",
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });
    

    const handleBrandName = (event) =>{
        document.getElementById("brand-name").style.display = "none";
        setProductName(event.target.value);
    }


    const handleBrandImage = async (event) => {
        document.getElementById("brand-image").style.display = "none";
        const files = event.target.files[0];
        // const image = await resizeFile(files);

        let fData = new formData();
        await fData.append('BrandImage', files);        
       

        var config = {
            method: 'post',
            url: `${PORT}/uploadBrand`,
            data: fData
        };

        await axios(config)
        .then(function (response) {
            if(response.data.success == 1){
                console.log(response.data.image_url.split("/"));
                const brandimage = response.data.image_url.split("/");
                setBrandImage(brandimage[5]);
            }       
        })
        .catch(function (error) {
            console.log(error);
        });

    }

    const handleClose = (event) =>{
        document.body.classList.remove('fixedit_modal');
      }


    return (
        <div class="editfix main_bxshadow">
        <div class="headeredi_fix text-right">
         <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
         <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="table_fixe">
            <div class="labelsidetop">
              <div class="boxrepetform">
                <h4 class="tabcont_Sublable">Brand Settings</h4>
                <div class="Tbaleflex">
                  <div class="Table">
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Brand Name</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input onChange={handleBrandName} value={brandName} type="text" class="fuilddesh" placeholder="" />
                        <p id="brand-name" className="text-danger" style={{display:"none"}}>Brand Name is required*</p>
                        </div>
                      </div>
                    </div>
        

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Brand Image</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input onChange={handleBrandImage} type="file" name="img" placeholder="" />
                        <p id="brand-image" className="text-danger" style={{display:"none"}}>Brand Image is required*</p>
                        </div>
                      </div>
                    </div>

                 


                  </div>
                </div>
              </div>
            </div>
            <div class="bottomsend_button text-center small_margin_top">
              <button type="submit" class="savesidebutt closefix">SAVE</button>
            </div>
          </div>
          </form>
          <ToastContainer />
        </div>
    ) 
}

export default AddBrand


{/* <div class="work_space">
            <div class="workspace_title_hd">
                <div class="row">
                    <div class="col-md-12">
                        <div class="titleof_page">
                            <ul class="breadcrumb_list topsmall_bottom">
                                <li><a href="#">Admin Panel </a></li>
                                <li><i style={{ padding: "5px" }} class="fa fa-angle-right"></i></li>
                                <li>Add Brand</li>
                            </ul>
                            <h2 class="main_title">Add Brand</h2>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                            <div class="custom_fuild_addM topsmall_top flexcoluman edit-form">

                                <form onSubmit={handleSubmit}>
                                <div class="row">
                    <div class="col-md-6">
                                    <div class="form-group">

                                        <h4 class="fieldsec_lab">Brand Name<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>
                                        <div class="   ">
                                            <div class="repwat_one">
                                                <input onChange={handleBrandName} type="text" class="fuilddesh" placeholder="Brand Name" />
                                                <p id="brand-name" className="text-danger" style={{display:"none"}}>Brand Name is required*</p>
                                            </div>
                                          
                                        </div>
                                        </div>
                                        </div>

                                        <div class="col-md-6">
               

                                    <div class="form-group">

                                        <h4 class="fieldsec_lab">Brand Image<p className="text-danger" style={{display:"inline-block"}}>*</p></h4>

                                        <div class="   ">
                                            <div class="repwat_one">
                                                <input onChange={handleBrandImage} type="file" class="fuilddesh" name="img" placeholder="Brand Image" />
                                                <p id="brand-image" className="text-danger" style={{display:"none"}}>Brand Image is required*</p>
                                            </div>
                                          
                                        </div>
                                    </div>

                                    </div>   </div>
                                    <div className="bottomsend_button text-center small_margin_top">
                                        <button type="submit" className="savesidebutt closefix">Save</button>
                                        <button type="button" className="savesidebutt closefix">Reset</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div> */}
