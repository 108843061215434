import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PORTNUMBER from "../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import axios from "axios";

const Dashboard = () => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [totalusers, setTotalUsers] = useState("0");
  const [totalcategories, setTotalCategories] = useState("0");
  const [totalfaqs, setTotalFaqs] = useState("0");
  const [totalnewsletters, setTotalNewsLetters] = useState("0");
  const [totalcontactus, setTotalContactUs] = useState("0");
  const [totaslproducts, setTotalProducts] = useState("0");

  useEffect(async () => {
    await axios.get(`${PORT}/total_users`).then((res) => {
      // console.log(res);
      setTotalUsers(res);
    });
  }, []);

  useEffect(async () => {
    await axios.get(`${PORT}/total_categories`).then((res) => {
      // console.log(res);
      setTotalCategories(res);
    });
  }, []);

  useEffect(async () => {
    await axios.get(`${PORT}/total_faqs`).then((res) => {
      // console.log(res);
      setTotalFaqs(res);
    });
  }, []);

  useEffect(async () => {
    await axios.get(`${PORT}/total_newsletters`).then((res) => {
      // console.log(res);
      setTotalNewsLetters(res);
    });
  }, []);

  useEffect(async () => {
    await axios.get(`${PORT}/contact/total_contacts`).then((res) => {
      // console.log(res);
      setTotalContactUs(res);
    });
  }, []);

  useEffect(async () => {
    await axios.get(`${PORT}/total_product`).then((res) => {
      // console.log(res);
      setTotalProducts(res);
    });
  }, []);

  return (
    <div class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <h2 class="main_title">Dashboard</h2>
            </div>
          </div>
          <div class="col-md-12">
            <div class="statistics_main">
              <div class="stat_head">
                <h2 class="boxlabel_title">Statistics</h2>
              </div>
              <div
                class="stat_body bg_light_gray mCustomScrollbar"
                data-mcs-theme="dark"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <h3 class="listing_title">Accounts</h3>
                    <div class="colorbox_list">
                      <div class="color_bleft">
                        <span class="color_Gray colorbox"></span>
                        <Link to="/accounts">Total</Link>
                      </div>
                      <div class="color_bright">
                        <p>
                          {" "}
                          {totalusers &&
                            totalusers.data &&
                            totalusers.data.totalUsers}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <h3 class="listing_title">Category</h3>
                    <div class="colorbox_list">
                      <div class="color_bleft">
                        <span class="color_Gray colorbox"></span>
                        <Link to="/categories">Total</Link>
                      </div>
                      <div class="color_bright">
                        <p>
                          {totalcategories &&
                            totalcategories.data &&
                            totalcategories.data.totalCategories}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <h3 class="listing_title">Contact Us</h3>
                    <div class="colorbox_list">
                      <div class="color_bleft">
                        <span class="color_Gray colorbox"></span>
                        <Link to="/contact-us">Total</Link>
                      </div>
                      <div class="color_bright">
                        <p>
                          {totalcontactus &&
                            totalcontactus.data &&
                            totalcontactus.data.totalContacts}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <h3 class="listing_title">News Letter</h3>
                    <div class="colorbox_list">
                      <div class="color_bleft">
                        <span class="color_Gray colorbox"></span>
                        <Link to="/news-letter-subscribers">Total</Link>
                      </div>
                      <div class="color_bright">
                        <p>
                          {totalnewsletters &&
                            totalnewsletters.data &&
                            totalnewsletters.data.totalNewsletters}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <h3 class="listing_title">FAQ's</h3>
                    <div class="colorbox_list">
                      <div class="color_bleft">
                        <span class="color_Gray colorbox"></span>
                        <Link to="/faq">Total</Link>
                      </div>
                      <div class="color_bright">
                        <p>
                          {totalfaqs &&
                            totalfaqs.data &&
                            totalfaqs.data.totalFaqs}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <h3 class="listing_title">Products</h3>
                    <div class="colorbox_list">
                      <div class="color_bleft">
                        <span class="color_Gray colorbox"></span>
                        <Link to="/ProductList">Total</Link>
                      </div>
                      <div class="color_bright">
                        <p>
                          {totaslproducts &&
                            totaslproducts.data &&
                            totaslproducts.data.totalProducts}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

// <div class="work_space">
//   <div class="workspace_title_hd">
//     <div class="row">
//       <div class="col-md-12">
//         <div class="titleof_page">
//           <h2 class="main_title">Dashboard</h2>
//         </div>
//       </div>
//       <div class="col-md-12">
//         <div class="statistics_main">

//           <div
//             class=" bg_light_gray mCustomScrollbar custom-cards"
//             data-mcs-theme="dark"
//           >
//             <div class="row">
//               <div class="col-lg-6 col-xl-3">
//                 <div class="card text-white bg-primary mb-3">
//                   <div class="card-body">
//                     <div class="d-flex align-items-center">
//                       <svg
//                         aria-hidden="true"
//                         focusable="false"
//                         data-prefix="fas"
//                         data-icon="user-friends"
//                         role="img"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 640 512"
//                         class="svg-inline--fa fa-user-friends fa-w-20 fa-2x"
//                       >
//                         <path
//                           fill="currentColor"
//                           d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
//                           class=""
//                         ></path>
//                       </svg>

//                       <div class="pl-4 text-block">
//                         <h5 class="card-title">Total Users</h5>
//                         <p class="card-text">
//                           {totalusers &&
//                             totalusers.data &&
//                             totalusers.data.totalUsers}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="card-header">
//                     <Link to="/userAccount">
//                       More Info
//                       <i
//                         className="fa fa-arrow-right"
//                         style={{ paddingLeft: "5px" }}
//                       ></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <div class="col-lg-6 col-xl-3">
//                 <div class="card text-white bg-primary mb-3">
//                   <div class="card-body">
//                     <div class="d-flex align-items-center">
//                       <svg
//                         aria-hidden="true"
//                         focusable="false"
//                         data-prefix="fas"
//                         data-icon="shopping-cart"
//                         role="img"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 576 512"
//                         class="svg-inline--fa fa-shopping-cart fa-w-18 fa-2x"
//                       >
//                         <path
//                           fill="currentColor"
//                           d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
//                           class=""
//                         ></path>
//                       </svg>{" "}
//                       <div class="pl-4 text-block">
//                         <h5 class="card-title">Total Categories</h5>
//                         <p class="card-text">
//                           {totalcategories &&
//                             totalcategories.data &&
//                             totalcategories.data.totalCategories}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="card-header">
//                     <Link to="/categories">
//                       More Info
//                       <i
//                         className="fa fa-arrow-right"
//                         style={{ paddingLeft: "5px" }}
//                       ></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-lg-6 col-xl-3">
//                 <div class="card text-white stat_head mb-3">
//                   <div class="card-body">
//                     <div class="d-flex align-items-center">
//                       <svg
//                         aria-hidden="true"
//                         focusable="false"
//                         data-prefix="fas"
//                         data-icon="user-friends"
//                         role="img"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 640 512"
//                         class="svg-inline--fa fa-user-friends fa-w-20 fa-2x"
//                       >
//                         <path
//                           fill="currentColor"
//                           d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
//                           class=""
//                         ></path>
//                       </svg>
//                       <div class="pl-4 text-block">
//                         <h5 class="card-title">Total FAQ's</h5>
//                         <p class="card-text">
//                           {totalfaqs &&
//                             totalfaqs.data &&
//                             totalfaqs.data.totalFaqs}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="card-header">
//                     <Link to="/faq">
//                       More Info
//                       <i
//                         className="fa fa-arrow-right"
//                         style={{ paddingLeft: "5px" }}
//                       ></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-lg-6 col-xl-3">
//                 <div class="card text-white bg-primary mb-3">
//                   <div class="card-body">
//                     <div class="d-flex align-items-center">
//                       <svg
//                         aria-hidden="true"
//                         focusable="false"
//                         data-prefix="fas"
//                         data-icon="user-friends"
//                         role="img"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 640 512"
//                         class="svg-inline--fa fa-user-friends fa-w-20 fa-2x"
//                       >
//                         <path
//                           fill="currentColor"
//                           d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
//                           class=""
//                         ></path>
//                       </svg>
//                       <div class="pl-4 text-block">
//                         <h5 class="card-title">Total Newsletters</h5>
//                         <p class="card-text">
//                           {totalnewsletters &&
//                             totalnewsletters.data &&
//                             totalnewsletters.data.totalNewsletters}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="card-header">
//                     <Link to="/news-letter-subscribers">
//                       More Info
//                       <i
//                         className="fa fa-arrow-right"
//                         style={{ paddingLeft: "5px" }}
//                       ></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-lg-6 col-xl-3">
//                 <div class="card text-white stat_head mb-3">
//                   <div class="card-body">
//                     <div class="d-flex align-items-center">
//                       <svg
//                         aria-hidden="true"
//                         focusable="false"
//                         data-prefix="fas"
//                         data-icon="user-friends"
//                         role="img"
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 640 512"
//                         class="svg-inline--fa fa-user-friends fa-w-20 fa-2x"
//                       >
//                         <path
//                           fill="currentColor"
//                           d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"
//                           class=""
//                         ></path>
//                       </svg>
//                       <div class="pl-4 text-block">
//                         <h5 class="card-title">Total Contact Us</h5>
//                         <p class="card-text">
//                           {totalcontactus &&
//                             totalcontactus.data &&
//                             totalcontactus.data.totalContacts}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="card-header text-center">
//                     <Link to="/contact-us">
//                       More Info
//                       <i
//                         className="fa fa-arrow-right"
//                         style={{ paddingLeft: "5px" }}
//                       ></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <ToastContainer />
// </div>
