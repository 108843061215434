import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from "react-html-parser";

const EditBlock = ({props}) => {

    const PORT = PORTNUMBER.PORTNUMBER;

    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;
    
    const [allCategory, setAllCategory] = useState([{ value: null }]);
    const [inputField , setInputField] = useState({
        categoryId: '',
        title: '',
        subTitle: '',
        description: ''
    });

    useEffect(async ()=>{
        await axios.get(`${PORT}/getCategorys`).then((response, err) => {
          setAllCategory(response);
        });

        await axios.post(`${PORT}/find_block`, {id:props}).then((response, err)=>{
            if(response){
              setInputField(() => ({
                categoryId: response.data.data.categoryId,
                title: response.data.data.title,
                subTitle: response.data.data.subTitle,
                description: response.data.data.description
              }));
            }
          })
    },[props])

    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(!inputField.categoryId){
            document.getElementById("categoryId").style.display="block";
            return;
        }
        if(!inputField.title){
            document.getElementById("title").style.display="block";
            return;
        }
        await axios.post(`${PORT}/update_block`, {id:props, inputField}).then((response, err)=>{ 
        if(response){
            handleClose();
            history.push({pathname:"/Blocks", MatchValue:"edit"});
        }else{
            toast.info("Something went wrong");
        }     
      })
    }

    const handlePageDescription = (event, editor) =>{
      setInputField((prevState) => ({
        ...prevState,
        description: editor.getData(),
      }));
    }

    const handleClose = (event) =>{
      document.body.classList.remove('fixedit_modal');
    }

    const inputsHandler = (e) =>{
      const { name, value } = e.target;
      setInputField((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    return (
        <div class="editfix main_bxshadow">
        <div class="headeredi_fix text-right">
         <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
         <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="table_fixe">
            <div class="labelsidetop">
              <div class="boxrepetform">
                <h4 class="tabcont_Sublable">Block Settings</h4>
                <div class="Tbaleflex">
                  <div class="Table">
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Title</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <input
                            onChange={inputsHandler}
                            name="title"
                            type="text"
                            class="fuilddesh1"
                            placeholder="Title"
                            value={inputField.title}
                          />
                          <p className="text-danger" id="title" style={{ display: "none" }}>Title is required*</p>
                        </div>
                      </div>
                    </div>
        
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Sub Title</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                          <input
                            onChange={inputsHandler}
                            name="subTitle"
                            type="text"
                            class="fuilddesh1"
                            placeholder="Sub Title"
                            value={inputField.subTitle}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          Category
                          <span
                            className="text-danger"
                            style={{ display: "inline-block" }}
                          >
                            *
                          </span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="repwat_one">
                          <select
                            onChange={inputsHandler}
                            name="categoryId"
                            value={inputField.categoryId}
                            class="fuilddesh1  "
                          >
                            <option value={""}>{"SELECT CATEGORY"}</option>
                            {allCategory &&
                              allCategory.data &&
                              allCategory.data.map((cat) => {
                                return (
                                  <option value={cat._id}>
                                    {cat.categoryName}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                        <p
                          className="text-danger"
                          id="categoryId"
                          style={{ display: "none" }}
                        >
                          Category Name is required*
                        </p>
                    </div>

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Description</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <CKEditor
                        editor={ClassicEditor}
                        onChange={(e, editor) =>
                        handlePageDescription(e, editor)
                        }
                        data={inputField.description}
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomsend_button text-center small_margin_top">
              <button type="submit" class="savesidebutt closefix">SAVE</button>
            </div>
          </div>
          </form>
          <ToastContainer />
        </div>
    )
}

export default EditBlock
