import React, {useState, useEffect} from 'react';
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import {toast, ToastContainer} from "react-toastify";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductSafetyTips = () => {

    const PORT = PORTNUMBER.PORTNUMBER; 

    const [productSafetyTips, setProductSafetyTips] = useState();
    const [slug,setSlug]  = useState();

    useEffect(async () => {
        GETALL();
    }, [])
        
    const GETALL = async () =>{
        await axios.get(`${PORT}/productSafetyTips/all`).then((res, err)=>{
            if(res){
                console.log(res.data.data);
                
                if(res.data.data != ''){
                   setSlug(res.data.data[0].slug);
                   setProductSafetyTips(res.data.data[0].value);
                   //setNumberOfListingPerTask(res.data.data[0].checking_listing_setting[0].numberOfListingPerTask); 
                }
            }
        })
    }


    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(slug == 'ProductSafetySetting'){
            await axios.post(`${PORT}/productSafetyTips/update`, {productSafetyTips:productSafetyTips}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }else{
            await axios.post(`${PORT}/productSafetyTips/create`, {productSafetyTips:productSafetyTips}).then((res, err)=>{
                if(res){
                    toast.success("You have successfully changed the settings");
                }
            })
        }

    }
    return (
        <div class="tab-pane" id="productSafetyTips" role="tabpanel">
        <form onSubmit={handleSubmit}>
            <h3 class="tabcont_lable">Basic settings for <span>Product Safety Tips</span></h3>
            <div class="labelsidetop">
            <div class="boxrepetform">
                {/* <h4 class="tabcont_Sublable">Checking for Listings</h4> */}
                <div class="Tbaleflex">
                    <div class="Table">
                        <div class="Table-row">
                            <div class="Table-row-item">
                                <div class="dropdownsid">
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={productSafetyTips}
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setProductSafetyTips(data);
                                        } }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
            </div>
            <div class="bottomsend_button text-center">
                <button type="submit" class="savesidebutt">SAVE</button>
                <ToastContainer />
            </div>
        </form>
        </div>
    )
}

export default ProductSafetyTips
