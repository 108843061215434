import React from 'react'

const BasicSettingMenu = () => {
    return (
        <div class="left_ofall_tabs">
        <div class="listtabbs">
          <ul class="nav nav-tabs mycuetTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#basic01" role="tab">General</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic02" role="tab">Accounts</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic03" role="tab">Security</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#productSafetyTips" role="tab">Product Safety Tips</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic04" role="tab">Pictures</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic05" role="tab">Listings</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic06" role="tab">Video Chat</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic07" role="tab">Live Video Streaming</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic08" role="tab">Messaging</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic09" role="tab">Maps/Geocoding</a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic010" role="tab">Cron Jobs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic011" role="tab">Cache</a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic012" role="tab">Geo Filter</a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic013" role="tab">Similar Ads</a>
            </li> */}
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic014" role="tab">Social Meta Data</a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic015" role="tab">Comment / Review</a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic016" role="tab">Cookie Policy</a>
            </li> */}
            
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic017" role="tab">Search by Distance</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic018" role="tab">Massmailer/Newsletters</a>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic019" role="tab">Monetizer</a>
            </li> */}
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic020" role="tab">Booking</a>
            </li> */}
            {/* <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic021" role="tab">PDF Export</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic022" role="tab">Social Network Login</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic023" role="tab">Recently Viewed</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#basic024" role="tab">Street View</a>
            </li> */}
          </ul>
        </div>
      </div>
    )
}

export default BasicSettingMenu
