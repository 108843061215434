import React, { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import PORTNUMBER from "../../../constant/api";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import formData from "form-data";

const EditBrand = ({props}) => {

    const PORT = PORTNUMBER.PORTNUMBER;
    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    const [brandName, setBrandName] = useState();
    const [brandImage, setBrandImage] = useState();

    console.log("props", props);
    // console.log("propsdata", props.match.params.id);
    // const id = props.match.params.id;
   
    
    useEffect(async ()=>{
        await axios.post(`${PORT}/brand_get_by_id`, {params:{id:props}}).then((response, err)=>{ 
            console.log("brandedit", response);
            setBrandName(response.data.data.brandName);
            setBrandImage(response.data.data.imageUrl);
        })
    },[props])

    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(!brandName)
        {
            document.getElementById("brand-name").style.display="block";
            return;
        }
        if(!brandImage)
        {
            document.getElementById("brand-image").style.display="block";
            return;
        }
        await axios.post(`${PORT}/brand_update`, {params:{id:props,brandName,brandImage}}).then((response, err)=>{ 
        if(response)
        {
            handleClose();
            history.push({pathname:"/BrandList", MatchValue:"edit"});
        }    
      })
      }
    

    const handleBrandName = (event) =>{
        document.getElementById("brand-name").style.display="none";
        setBrandName(event.target.value);
    }


    const handleBrandImage = (event) => {
        document.getElementById("brand-image").style.display="none";
        const files = event.target.files[0];

        let fData = new formData();
        fData.append('BrandImage', files);        
       

        var config = {
            method: 'post',
            url: `${PORT}/uploadBrand`,
            data: fData
        };

        axios(config)
        .then(function (response) {
            if(response.data.success == 1){
                console.log(response.data.image_url.split("/"));
                const image = response.data.image_url.split("/");
                setBrandImage(image[5]);
            }       
        })
        .catch(function (error) {
            console.log(error);
        });

    }
    const handleClose = (event) =>{
        document.body.classList.remove('fixedit_modal');
      }




    return (
        <div class="editfix main_bxshadow">
        <div class="headeredi_fix text-right">
         <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
         <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="table_fixe">
            <div class="labelsidetop">
              <div class="boxrepetform">
                <h4 class="tabcont_Sublable">Brand Settings</h4>
                <div class="Tbaleflex">
                  <div class="Table">
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Brand Name</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input value={brandName} onChange={handleBrandName} type="text" class="fuilddesh" placeholder="" />
                        <p id="brand-name" className="text-danger" style={{display:"none"}}>Brand Name is required*</p>
                        </div>
                      </div>
                    </div>
        

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Brand Image</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input onChange={handleBrandImage} type="file" name="img" placeholder="" />
                        <p id="brand-image" className="text-danger" style={{display:"none"}}>Brand Image is required*</p>
                        </div>
                      </div>
                    </div>

                 


                  </div>
                </div>
              </div>
            </div>
            <div class="bottomsend_button text-center small_margin_top">
              <button type="submit" class="savesidebutt closefix">SAVE</button>
            </div>
          </div>
          </form>
          <ToastContainer />
        </div>
    )
}

export default EditBrand
