import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import Pagination from "../pagination/pagination";
import "./userAccount.css";
import PORTNUMBER from "../../constant/api";
import axios from "axios";
import Modal from "react-modal";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
import NewUser from "../newUser/newUser";
import EditUser from "../editUser/editUser";
import ChangePassword from "../changePassword/changePassword";

const PendingAccount = (props) => {
  const PORT = PORTNUMBER.PORTNUMBER;

  const [AllUsers, setAllUsers] = useState(["not"]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalDeactive, setModalDeactive] = useState(false);
  const [deleteUser, setDeleteUser] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [postsPerPage, setPostPerPage] = useState(15);
  const [searchItem, setSearchItem] = useState();
  const [propsid, setPropsId] = useState();
  const [confirmid, setConfirmID] = useState(undefined);

  useEffect(async () => {
    if (
      typeof props.location != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue === "edit"
    ) {
      toast.success("You have successfully edited user");
      getAll();
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue === "edit",
  ]);

  useEffect(async () => {
    if (
      typeof props.location != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue === "add"
    ) {
      toast.success("You have successfully added user");
      getAll();
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue === "add",
  ]);

  useEffect(async () => {
    if (
      typeof props.location != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue === "changepassword"
    ) {
      toast.success("You have successfully change your password");
    }
  }, [
    typeof props.location.MatchValue != "undefined" &&
      props.location.MatchValue &&
      props.location.MatchValue === "changepassword",
  ]);

  const getAll = async () => {
    await axios.get(`${PORT}/getPendingUsers`).then((res, err) => {
      setAllUsers(res);
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const pagesVisited = currentPage * postsPerPage;
  const pageCount =
    Math.ceil(
      (AllUsers && AllUsers.data && AllUsers.data.length) / postsPerPage
    ) || 1;

  const currentPosts =
    AllUsers &&
    AllUsers.data &&
    AllUsers.data.slice(pagesVisited, pagesVisited + postsPerPage);

  const paginate = (pagenumber) => {
    setCurrentPage(pagenumber);
  };

  const handleSearch = async (event) => {
    setSearchItem(event.target.value);
    await axios
      .post(`${PORT}/search_user`, { search: event.target.value })
      .then((res, err) => {
        setAllUsers(res);
      });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSelect = async (event) => {
    event.preventDefault();
    if (event.target.value == "active") {
      await axios
        .post(`${PORT}/get_status`, { status: "Active" })
        .then((res, err) => {
          console.log("allusersdata", res);
          setAllUsers(res);
        });
    } else if (event.target.value == "inactive") {
      await axios
        .post(`${PORT}/get_status`, { status: "Inactive" })
        .then((res, err) => {
          console.log("allusersdata", res);
          setAllUsers(res);
        });
    } else {
      await axios.get(`${PORT}/getUsers`).then((res, err) => {
        console.log("allusersdata", res);
        setAllUsers(res);
      });
    }
  };

  const handleClickDelete = (event) => {
    setDeleteUser(event);
    setIsOpen(true);
  };
  const closeModal = (event) => {
    setIsOpen(false);
  };
  const handleYes = async (event) => {
    event.preventDefault();
    await axios
      .post(`${PORT}/deleteUser`, { params: { deleteUser } })
      .then((res, err) => {
        console.log("deletedusers", res);
      });
    await axios.get(`${PORT}/getUsers`).then((res, err) => {
      console.log("allusersdata", res);
      setAllUsers(res);
    });
    setIsOpen(false);
    toast.success("You have successfully deleted this user");
  };
  const handleClose = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  const handleClickActive = (event) => {
    setDeleteUser(event);
    setModalActive(true);
  };
  const closeModalActive = (event) => {
    setModalActive(false);
  };
  const handleCloseActive = (event) => {
    event.preventDefault();
    setModalActive(false);
  };
  const handleYesActive = async (event) => {
    event.preventDefault();
    console.log("deleteuserid", deleteUser);
    await axios
      .post(`${PORT}/changeDeactiveToActive`, { params: { deleteUser } })
      .then((res, err) => {
        console.log("deletedusers", res);
      });
    await axios.get(`${PORT}/getUsers`).then((res, err) => {
      console.log("allusersdata", res);
      setAllUsers(res);
    });
    setModalActive(false);
    toast.success("You have successfully activated this user");
  };

  const handleClickDeactive = (event) => {
    setDeleteUser(event);
    setModalDeactive(true);
  };
  const closeModalDeactive = (event) => {
    setModalDeactive(false);
  };
  const handleCloseDeactive = (event) => {
    event.preventDefault();
    setModalDeactive(false);
  };
  const handleYesDeactive = async (event) => {
    event.preventDefault();
    console.log("deleteuserid", deleteUser);
    await axios
      .post(`${PORT}/changeActiveToDeactive`, { params: { deleteUser } })
      .then((res, err) => {
        console.log("deletedusers", res);
      });
    await axios.get(`${PORT}/getUsers`).then((res, err) => {
      console.log("allusersdata", res);
      setAllUsers(res);
    });
    setModalDeactive(false);
    toast.success("You have successfully Inactivated this user");
  };

  const handleadd = (event) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(undefined);
  };
  const handleEdit = (event, id) => {
    document.body.classList.add("fixedit_modal");
    setPropsId(id);
  };

  return (
    <div style={{ width: "100%" }} class="work_space">
      <div class="workspace_title_hd">
        <div class="row">
          <div class="col-md-12">
            <div class="titleof_page">
              <ul class="breadcrumb_list topsmall_bottom">
                <li>
                  <a href="javascript:void(0)">Admin Panel </a>
                </li>
                <li>
                  <i style={{ padding: "5px" }} class="fa fa-angle-right"></i>
                </li>
                <li>Pending Accounts</li>
              </ul>
              <h2 class="main_title">Accounts</h2>
            </div>
            <div className="addpackk">
              <Link onClick={handleadd} className="addpkgbtn savesidebutt">
                <i className="fa fa-plus" style={{ padding: "5px" }}></i>Add
                Account
              </Link>
            </div>
          </div>

          <div class="col-md-12">
            <div class="main_oflistingForm bg_light_gray onlyforcol_support">
              <div class="inner_listing_formS">
                <div class="left_innserSearchone">
                  <div class="dropdownsid">
                    <select class="fuilddesh" onChange={handleSelect}>
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
                <div class="left_innserSearch">
                  <div class="srch_fuildsecT leftlabs">
                    <div class="leftlabs listing_title">Search:</div>
                    <div class="lefelab_fuild">
                      <input
                        onChange={handleSearch}
                        type="text"
                        class="fuilddesh"
                        name=""
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ overflowX: "auto" }} class="tabscroll_perent">
                <div class="tabscroll_inner">
                  <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">
                    <div class="tableflex topsmall_margin_top">
                      <div class="tableHead_row">
                        <div class="table_cell">
                          <div class="checkBtabls">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <input
                                    type="checkbox"
                                    name="option"
                                    id="Company01"
                                  />
                                  <label for="Company01"></label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="table_cell">First Name</div>
                        <div class="table_cell">Last Name</div>
                        <div class="table_cell">Phone</div>
                        <div class="table_cell">Email</div>
                        <div class="table_cell">Status</div>
                        <div class="table_cell">Actions</div>
                      </div>

                      <ToastContainer />

                      {currentPosts &&
                        currentPosts
                          // .filter((val) => {
                          //   if (searchItem == null) {
                          //     return val;
                          //   } else if (
                          //     val.email
                          //       .toLowerCase()
                          //       .includes(searchItem.toLowerCase())
                          //   ) {
                          //     return val;
                          //   } else {
                          //     return false;
                          //   }
                          // })
                          .map((usersdata) => {
                            return (
                              <div class="table_row">
                                <div class="table_cell">
                                  <div class="checkBtabls">
                                    <ul>
                                      <li>
                                        <div class="check-box">
                                          <input
                                            type="checkbox"
                                            name="option"
                                            id="Company01"
                                          />
                                          <label for="Company01"></label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="table_cell">
                                  {usersdata.firstName}
                                </div>
                                <div class="table_cell">
                                  {usersdata.lastName}
                                </div>
                                <div class="table_cell">
                                  {usersdata.mobileNumber}
                                </div>
                                <div class="table_cell">{usersdata.email}</div>

                                <div className="table_cell">
                                  {usersdata.active == "Active" ? (
                                    <span
                                      //  onClick={() => handleApprove(usersdata._id)}
                                      className="labltable lablesct"
                                    >
                                      Active
                                    </span>
                                  ) : (
                                    <span
                                      //  onClick={() => handleReject(usersdata._id)}
                                      className="labltable lablesct_red"
                                    >
                                      Inactive
                                    </span>
                                  )}
                                </div>

                                <div class="table_cell">
                                  <span class="table_bbtnn">
                                    <Link
                                      // to={"/editUser/" + usersdata._id}
                                      title="Edit"
                                      onClick={(e) =>
                                        handleEdit(e, usersdata._id)
                                      }
                                    >
                                      <i class="fa fa-pencil"></i>
                                    </Link>
                                  </span>

                                  {usersdata.active == "Active" ? (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Inactive"
                                        id="Inactive"
                                        onClick={() =>
                                          handleClickDeactive(usersdata._id)
                                        }
                                      >
                                        <i class="fa fa-ban"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span class="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        id="active"
                                        data-placement="top"
                                        title="Active"
                                        onClick={() =>
                                          handleClickActive(usersdata._id)
                                        }
                                      >
                                        <i class="fa fa-check-circle-o"></i>
                                      </a>
                                    </span>
                                  )}

                                  <span class="table_bbtnn">
                                    <Link
                                      to={"/change-password/" + usersdata._id}
                                      // onClick={(e) => handleConfirmPsswordKey(e, usersdata._id)}
                                      title="ChangePassword"
                                    >
                                      <i class="fa fa-key"></i>
                                    </Link>
                                  </span>

                                  <span class="table_bbtnn">
                                    <a
                                      href="javascript:void(0)"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() =>
                                        handleClickDelete(usersdata._id)
                                      }
                                    >
                                      <i class="fa fa-times"></i>
                                    </a>
                                  </span>
                                </div>
                              </div>
                            );
                          })}

                      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
                        <div class="modalChild">
                          <div class="modalchildPadding">
                            <h6>Do you want to delete this user?</h6>
                          </div>
                          <Button
                            onClick={handleYes}
                            className="savesidebutt"
                            variant="primary"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={handleClose}
                            className="savesidebutt close-savesidebutt"
                            variant="secondary"
                          >
                            Close
                          </Button>
                        </div>
                      </Modal>

                      <Modal
                        isOpen={modalActive}
                        onRequestClose={closeModalActive}
                      >
                        <div class="modalChild">
                          <div class="modalchildPadding">
                            <h6>Do you want to Active this user?</h6>
                          </div>
                          <Button
                            onClick={handleYesActive}
                            className="savesidebutt"
                            variant="primary"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={handleCloseActive}
                            className="savesidebutt close-savesidebutt"
                            variant="secondary"
                          >
                            Close
                          </Button>
                        </div>
                      </Modal>

                      <Modal
                        isOpen={modalDeactive}
                        onRequestClose={closeModalDeactive}
                      >
                        <div class="modalChild">
                          <div class="modalchildPadding">
                            <h6>Do you want to Inactive this user?</h6>
                          </div>
                          <Button
                            onClick={handleYesDeactive}
                            className="savesidebutt"
                            variant="primary"
                          >
                            Yes
                          </Button>
                          <Button
                            onClick={handleCloseDeactive}
                            className="savesidebutt close-savesidebutt"
                            variant="secondary"
                          >
                            Close
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="showingflexx">
          <div class="showingleft">
            Showing {currentPage + 1}-{pageCount} of {pageCount}
          </div>
          <div class="left_paginistion">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </div>
      {propsid == undefined ? <NewUser /> : <EditUser props={propsid} />}
    </div>
  );
};

export default PendingAccount;
