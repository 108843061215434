import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PORTNUMBER from "../../../../constant/api";
import axios from "axios";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from "react-html-parser";

const FaqEdit = ({props}) => {
  console.log("idididdi", props)
  const PORT = PORTNUMBER.PORTNUMBER;

  const [allfaqcategories, setAllfaqcategories] = useState();
  const [allfaqsubcategories, setAllfaqsubcategories] = useState();

  const [category_name, setCategory] = useState();
  const [sub_category_name, setSubcategory] = useState();
  const [title, setTitle] = useState();
  const [content, setContent] = useState();


  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  const id = props;

 

  useEffect(async ()=>{
    await axios.get(`${PORT}/faqcategories`).then((response, err)=>{
        setAllfaqcategories(response);
    })
  },[])

  useEffect(async ()=>{
    await axios.get(`${PORT}/faqsubcategories`).then((response, err)=>{
        setAllfaqsubcategories(response);
    })
  },[])

  
  useEffect(async ()=>{
    await axios.post(`${PORT}/faqs/singlerecord`, {id}).then((response, err)=>{
        setTitle(response.data.data.title);
        setContent(response.data.data.content);
        setCategory(response.data.data.faqcategoryId);
        setSubcategory(response.data.data.faqsubcategoryId);
    })
  },[props])



  const handleSubmit = async (event) => {
    event.preventDefault();

    if(!category_name)
    {
        document.getElementById("category-name").style.display="block";
        return;
    }
    if(!sub_category_name)
    {
       document.getElementById("sub-category-name").style.display="block";
       return;
    }
    if(!title)
    {
        document.getElementById("title").style.display="block";
        return;
    }
    if(!content)
    {
        document.getElementById("content").style.display="block";
        return;
    }
      await axios
        .post(`${PORT}/faqs/update`,
        {id, title, content, faqsubcategoryId:sub_category_name, faqcategoryId: category_name},
        )
        .then((res, err) => {
          handleClose();
          history.push({pathname:"/faq", MatchValue:"edit"});
        });
    }



  const handleSubCategory = (event) => {
    event.preventDefault();
    document.getElementById("sub-category-name").style.display="none";
    setSubcategory(event.target.value);
  };


  const handleCategory = (event) =>{
    event.preventDefault();
    document.getElementById("category-name").style.display="none";
    setCategory(event.target.value);
  }


  const handleTitle = (event) =>{
    event.preventDefault();
    document.getElementById("title").style.display="none";
    setTitle(event.target.value);
  }


  const handleContent = (event, editor) =>{
    document.getElementById("content").style.display="none";
    console.log(ReactHtmlParser(editor.getData()));
    setContent(editor.getData());
  }


  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  const handleClose = (event) =>{
    document.body.classList.remove('fixedit_modal');
  }



  return (
    <div class="editfix main_bxshadow">
        <div class="headeredi_fix text-right">
         <a href="#" class="fixhbtn"><i class="fa fa-check"></i></a>
         <a onClick={handleClose} class="fixhbtn closefix"><i class="fa fa-times"></i></a>
        </div>
        <form onSubmit={handleSubmit}>
          <div class="table_fixe">
            <div class="labelsidetop">
              <div class="boxrepetform">
                <h4 class="tabcont_Sublable">FAQ Settings</h4>
                <div class="Tbaleflex">
                  <div class="Table">
                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Category</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <select
                          onChange={handleCategory}
                          value={category_name}
                          class="fuilddesh"
                        >
                          <option value={""}>{"SELECT CATEGORY"}</option>
                          {allfaqcategories &&
                            allfaqcategories.data &&
                            allfaqcategories.data.data &&
                            allfaqcategories.data.data.map((category) => {
                              return (
                                <option value={category._id}>{category.category_name}</option>
                              );
                            })}
                        </select>
                        <p
                      className="text-danger"
                      id="category-name"
                      style={{ display: "none" }}
                    >
                      Category Name is required*
                    </p>
                        </div>
                      </div>
                    </div>
        

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Sub category</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <select
                          onChange={handleSubCategory}
                          value={sub_category_name}
                          class="fuilddesh"
                        >
                          <option value={""}>{"SELECT SUB CATEGORY"}</option>
                          {allfaqsubcategories &&
                            allfaqsubcategories.data &&
                            allfaqsubcategories.data.data &&
                            allfaqsubcategories.data.data.map((category) => {
                              return (
                                <option value={category._id}>{category.sub_category_name}</option>
                              );
                            })}
                        </select>
                        <p
                      className="text-danger"
                      id="sub-category-name"
                      style={{ display: "none" }}
                    >
                      Sub Category Name is required*
                    </p>
                        </div>
                      </div>
                    </div>

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Title</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <input
                          onChange={handleTitle}
                          type="text"
                          className="fuilddesh"
                          placeholder=""
                          value={title}
                        />
                             <p
                      className="text-danger"
                      id="title"
                      style={{ display: "none" }}
                    >
                      Title is required*
                    </p>
                        </div>
                      </div>
                    </div>

                    <div class="Table-row">
                      <div class="Table-row-item">
                        <div class="lefttabl_lable">
                          <span>Content</span>
                        </div>
                      </div>
                      <div class="Table-row-item">
                        <div class="dropdownsid">
                        <CKEditor
                       editor={ClassicEditor}
                       onChange={(e, editor)=> handleContent(e, editor)}
                       data={content}
                       />
                    <p
                      className="text-danger"
                      id="content"
                      style={{ display: "none" }}
                    >
                      Content is required*
                    </p>
                        </div>
                      </div>
                    </div>


                   
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomsend_button text-center small_margin_top">
              <button type="submit" class="savesidebutt closefix">SAVE</button>
            </div>
          </div>
          </form>
          <ToastContainer />
        </div>
  );
};

export default FaqEdit;
