import React, {useState, useEffect} from 'react';
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import PORTNUMBER from "../../../constant/api";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import formData from "form-data";

const EditCategory = (props) => {


    const PORT = PORTNUMBER.PORTNUMBER;

    
    const [category, setCategory] = useState();
    const [slug, setSlug] = useState();
    const [mainImage, setMainImage] = useState();
    const [popular, setPopular] = useState();
    const [order, setOrder] = useState();

    const [categoryFields, setCategoryFields] = useState([{value:null}]);
    const [categoryTypes, setCategoryTypes] = useState([{value:null}]);
    const [temp, setTemp] = useState();

    const id = props.match.params.id;
    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(async () => {
      await axios.post(`${PORT}/getCategory`, {params:{id}}).then((res, err) =>{
        console.log("editedcategory",res);
        setCategory(res.data.categoryName);
        setSlug(res.data.slug);
        setPopular(res.data.IsPopular);
        setOrder(res.data.categoryOrder);
        setMainImage(res.data.categoryImage);
    })
    },[])
    


    const handleSubmit = async (event) =>{
        event.preventDefault();

        if(!category)
        {
          document.getElementById("category-name").style.display="block";
          return;
        }
        if(!slug)
        {
          document.getElementById("slug").style.display="block";
          return;
        }
        if(!mainImage)
        {
          document.getElementById("category-image").style.display="block";
          return;
        }
        if(!order)
        {
          document.getElementById("category-order").style.display="block";
          return;
        }
        if(temp == -1)
        {
          document.getElementById("exist-orderid").style.display="block";
          return;
        }
        else{
          await axios.post(`${PORT}/updateCategory`, { params: { id, category, slug, mainImage, popular, order } }).then((res, err) => {
            console.log(res);
            toast.success("You have successfully updated category");
          })
          history.push({ pathname: "/category", customdata: { pathname } });
        }
     
    }



    const handleCategory = (event) =>{
        event.preventDefault();
        document.getElementById("category-name").style.display="none";
        setCategory(event.target.value);

        let data = event.target.value;
        let data1 = data.toLowerCase();

        data1 = data1.replace(/\s/g , "-");
        
        setSlug(data1);
    }

    const handleSlug = (event) =>{
        event.preventDefault();
        document.getElementById("slug").style.display="none";
        setSlug(event.target.value);
    }

  //  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 
    const handleChangeFields = (i, event) =>{
      console.log("fields", i, event.target.value);
      const values = [...categoryFields];
      values[i].value = event.target.value;
      setCategoryFields(values);
      console.log("fields", categoryFields);
    }

  const handleAddFields = () => {
    const values = [...categoryFields];
    values.push({ value: null });
    setCategoryFields(values);
  }


  const handleRemoveFields = (i) => {
    const values = [...categoryFields];
    values.splice(i, 1);
    setCategoryFields(values);
  }
    const handleChangeTypes = (i, event) =>{
      console.log("fields", i, event.target.value);
      const values = [...categoryTypes];
      values[i].value = event.target.value;
      setCategoryTypes(values);
      console.log("types",categoryTypes);
    }
    const handleAddTypes = () => {
      const values = [...categoryTypes];
      values.push({ value: null });
      setCategoryTypes(values);
    }
    
    
    const handleRemoveTypes = (i) => {
      console.log(i);
      const values = [...categoryTypes];
      values.splice(i, 1);
      setCategoryTypes(values);
    }
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    
    const handleMainCategoryImage = (event) => {
      document.getElementById("category-image").style.display="none";

      const files = event.target.files[0];
    
      let fData = new formData();
      fData.append('categoryImage', files);        
     
    
      var config = {
          method: 'post',
          url: `${PORT}/uploadCategory`,
          data: fData
      };
    
      axios(config)
      .then(function (response) {
          if(response.data.success == 1){
              console.log(response.data.image_url.split("/"));
              const image = response.data.image_url.split("/");
              setMainImage(image[6]);
          }       
      })
      .catch(function (error) {
          console.log(error);
      });
    
    }

    const handlePopular = (e) =>{
      console.log("checked", e.target.checked);
      if (e.target.checked == true) {
        console.log("checked");
        setPopular(true);
      }
      else {
        console.log("not checked");
        setPopular(false);
      }
    }




      const handleCategoryOrder = async (event) =>{
      event.preventDefault();
      document.getElementById("category-order").style.display="none";
      setOrder(event.target.value);
      setTemp(0);
    
        await axios.post(`${PORT}/get_by_order`, {orderId:event.target.value}).then((res, err) =>{
          if(res.data.categoryOrder)
          {
            document.getElementById("exist-orderid").style.display="block";
            setTemp(-1);
            return;
          }
          else{
            document.getElementById("exist-orderid").style.display="none";
            setOrder(event.target.value);
          }
        })
    }



    return (
        <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
                <div class="col-md-12">
                  <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li><a href="#">Admin Panel </a></li>
                  <li><i style={{padding:"5px"}} class="fa fa-angle-right"></i></li>
                  <li>Edit Category</li>
                </ul>
                    <h2 class="main_title">Edit Category</h2>
                  </div>
                </div>
            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">
                <div class="custom_fuild_addM topsmall_top flexcoluman">
                  
                  
                  
                  <form onSubmit={handleSubmit}>

                  <ToastContainer />

                  <div class="bindfields">
                    <h4 class="fieldsec_lab">Category Name</h4>
                    <div class="field_sec_repetM">
                      <div class="repwat_one">
                        <input onChange={handleCategory} type="text" class="fuilddesh" placeholder="Category Name" value={category} /><span class="requlab_span">
                            <div class="checkBtablss">     
                              <ul>
                                <li>
                                  <div class="check-box">
                                    {/* <input type="checkbox" name="option" id="Company103" /> */}
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </span>
                      </div>
                    </div>
                    <p className="text-danger" id="category-name" style={{display:"none"}}>Category Name is required*</p>
                  </div>


                  <div class="bindfields">
                    <h4 class="fieldsec_lab">Slug</h4>
                    <div class="field_sec_repetM">
                      <div class="repwat_one">
                        <input onChange={handleSlug} type="text" class="fuilddesh" name="" placeholder="Slug" value={slug} /><span class="requlab_span">
                            <div class="checkBtablss">     
                              <ul>
                                <li>
                                  <div class="check-box">
                                    {/* <input type="checkbox" name="option" id="Company103" /> */}
                                    <label for="Company103">*Required Field</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            </span>
                      </div>
                    </div>
                    <p className="text-danger" id="slug" style={{display:"none"}}>Slug is required*</p>
                  </div>

    
                  <div class="bindfields">
                        <h4 class="fieldsec_lab">Category Image</h4>
                        <div class="field_sec_repetM">
                            <div class="repwat_one">
                                <input onChange={handleMainCategoryImage} type="file" class="fuilddesh" name="img" placeholder="Category Image" />
                                
                            </div>
                        </div>
                        <p className="text-danger" id="category-image" style={{display:"none"}}>Category Image is required*</p>
                    </div>



                  <div class="bindfields">
                      <h4 class="fieldsec_lab">Is Popular</h4>
                      <div class="field_sec_repetM">
                        <div class="repwat_one">
                          <input type="checkbox" checked={popular} onClick={handlePopular} />
                        </div>
                      </div>
                    </div>


                    <div class="bindfields">
                    <h4 class="fieldsec_lab">Category Order</h4>
                    <div class="field_sec_repetM">
                      <div class="repwat_one">
                        <input onChange={handleCategoryOrder} type="text" class="fuilddesh" placeholder="Category Order"  value={order} />
                        <p style={{display:"none"}} id="exist-orderid" className="text-danger">Order Id already exist</p>
                      </div>
                        <span class="requlab_span">
                          <div class="checkBtablss">
                            <ul>
                              <li>
                                <div class="check-box">
                                  <label for="Company103">*Required Field</label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </span>
                    </div>
                    <p className="text-danger" id="category-order" style={{display:"none"}}>Category Order is required</p>
                  </div>

                    {/* <div class="bindfields">
                      <h4 class="fieldsec_lab">Category Order<a href="javascript:void(0)"><i class="fas fa-times"></i></a></h4>
                      <div class="field_sec_repetM">
                        <div class="repwat_one">
                          <input onChange={handleCategoryOrder} value={order} type="number" class="fuilddesh" placeholder="order" />

                        </div>
                      </div>
                    </div> */}





                  <div className="bottomsend_button text-center small_margin_top">
                  <button type="submit" className="savesidebutt closefix">Save</button>
                  {/* <button type="button" onClick={handleReset} className="savesidebutt closefix">Reset</button> */}
                  </div>


                  </form>
                

                </div>
             
               </div>
            </div>
          </div>
        </div>
     
  </div>
    )
}

export default EditCategory
