import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import "./categoryList.css";
import PORTNUMBER from "../../../constant/api";
import Modal from 'react-modal';
import Button from "react-bootstrap/Button";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";
import EditCategory from "../editCategory/editCategory";

const CategoryList = (props) => {

    console.log("customfieldprops", props.location.customdata);


    const PORT = PORTNUMBER.PORTNUMBER; 

    const [allCategory, setAllCategory] = useState();
    const [firstsubbtuton, setFirstsubbutton] = useState();
    const [firstsubcat, setFirstsubcat] = useState();
    const [secondsubbutton, setSecondsubbutton] = useState();
    const [secondsubcat, setSecondsubcat] = useState();
    const [thirdsubbutton, setThirdsubbutton] = useState();
    const [thirdsubcat, setThirdsubcat] = useState();
    const [modalActive, setModalActive] = useState(false);
    const [modalDeactive, setModalDeactive] = useState(false);
    const [catModal, setCatModal] = useState(false);
    const [firstModal, setFirstModal] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const [thirdModal, setThirdModal] = useState(false);
    const [id, setId] = useState();
    const [propsid, setPropsId] = useState();

    const [firstCategoryId, setfirstCategoryId] = useState();
    const [secondCategoryId, setsecondCategoryId] = useState();
    const [thirdCategoryId, setthirdCategoryId] = useState();

    const [deleteFrom, setDeleteFrom] = useState();


  // category
    useEffect(() => {
      getAll();
    },[])

    const getAll = async () =>{
      await axios.get(`${PORT}/getCategorys`).then((res, err)=>{
        console.log("allcategorydata", res);
        setAllCategory(res);
      })
    }

    useEffect(async()=>{
      if (typeof props.location != "undefined" && props.location.MatchValue && props.location.MatchValue === "edit") 
      {
          toast.success("You have successfully edited user");
          getAll();
      }
    },[typeof props.location.MatchValue != "undefined" && props.location.MatchValue && props.location.MatchValue === "edit"])
    
    // const handleDelete = async (id) =>{
    //     console.log(id);
    //    await axios.post(`${PORT}/deleteCategory`, {params:{id}}).then((res, err)=>{
    //      console.log("allusersdata", res);
    //      toast.success("You have successfully deleted this category")
    //    })
    //    await axios.get(`${PORT}/getCategorys`).then((res, err)=>{
    //      console.log("allusersdata", res);
    //      setAllCategory(res);
    //    })
    //  }

     const handleModalCategory = (id) =>{
      setCatModal(true);
      setId(id);
    }

    const handleCategoryModalYes = () =>{
      // handleDelete(id);
      setCatModal(false);
    }
    const handleClickDeactive = (id) => {
      setModalDeactive(true);
      setId(id);
    };
    const handleClickActive = (id) => {
      setModalActive(true);
      setId(id);
    };
    const handleModalClose = () =>{
      setCatModal(false);
    }

    const handleEdit = (event, id) =>{
      document.body.classList.add('fixedit_modal');
      setPropsId(id)
    }
    const handleYesDeactive = async () => {
      await axios
        .post(`${PORT}/update/categoryStatus`, { params: { id, status: "Inactive" } })
        .then((res, err) => {
          toast.success("You have successfully deactived this category");
          setModalDeactive(false);
        });
        getAll();
    };
    const handleYesActive = async () => {
      await axios
        .post(`${PORT}/update/categoryStatus`, { params: { id, status: "Active" } })
        .then((res, err) => {
          toast.success("You have successfully activated this category");
  
          setModalActive(false);
        });
        getAll();
    };
    const handleClose = () => {
      setModalActive(false);
      setModalDeactive(false);
    };


    return (
        <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
                <div class="col-md-12">
                  <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li><a href="javascript:void(0)" >Admin Panel </a></li>
                  <li><i style={{padding:"5px"}} className="fa fa-angle-right"></i></li>
                  <li>Categories</li>
                </ul>
                    <h2 class="main_title">Categories</h2>
                  </div>
                </div>

              

            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">

              <div className="text-right">
                 <Link to="/categories/add" id="add-category" style={{backgroundColor:"#FE5F55", marginRight:"15px"}} className="btn btn-primary">Add Category</Link>
              </div>

              <div style={{overflowX:'auto'}} class="tabscroll_perent">
                  <div class="tabscroll_inner">
                    <div class="tablesecton_mian topsmall_top flexcoluman tablespecial_accounts">                       
                      <div class="tableflex topsmall_margin_top">
                        <div class="tableHead_row">
                          <div class="table_cell">
                            Category Name
                          </div>
                          <div class="table_cell">
                            Display Order
                          </div>
                          <div class="table_cell">
                            Actions
                          </div>
                        </div>               
                     
   
                          {allCategory && allCategory.data && allCategory.data.map((cate)=>{
                          return(
                            <div class="table_row">
                            <div class="table_cell">
                              {cate.categoryName}
                            </div> 
                            <div class="table_cell">
                              {cate.displayOrder}
                            </div>  
                            <div class="table_cell"> 
                              <span class="table_bbtnn">
                              <Link onClick={(e)=>handleEdit(e, cate._id)}  data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link> 
                              </span> 
                              {cate.status == "Active" ? (
                                    <span className="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Inactive"
                                        id="deactive"
                                        onClick={() =>
                                          handleClickDeactive(cate._id)
                                        }
                                      >
                                        <i className="fa fa-ban"></i>
                                      </a>
                                    </span>
                                  ) : (
                                    <span className="table_bbtnn">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="tooltip"
                                        id="active"
                                        data-placement="top"
                                        title="Active"
                                        onClick={() =>
                                          handleClickActive(cate._id)
                                        }
                                      >
                                        <i className="fa fa-check-circle-o"></i>
                                      </a>
                                    </span>
                                  )}
                              {/*<span class="table_bbtnn">
                              <a href="javascript:void(0)" onClick={(e)=>handleModalCategory(cate._id)} data-toggle="tooltip" data-placement="top" title="Delete" ><i class="fa fa-trash"></i></a> 
                              </span> */}
                            </div>
                          </div>
                          )
                        })
                      }
         
                      </div>
                    </div>
                  </div>
                </div>
                <ToastContainer />


<Modal 
isOpen={catModal}
>

<Button onClick={handleCategoryModalYes} className="button-conf" variant="primary">Yes</Button>
<Button onClick={handleModalClose} className="button-conf" variant="secondary">Close</Button>
</Modal>
<Modal isOpen={modalActive}>
        <div className="modalChild">
          <div className="modalchildPadding">
            <h6>Do you want to Active this Category?</h6>
          </div>
          <Button
            onClick={handleYesActive}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal isOpen={modalDeactive}>
        <div className="modalChild">
          <div className="modalchildPadding">
            <h6>Do you want to Inactive this Category?</h6>
          </div>
          <Button
            onClick={handleYesDeactive}
            className="savesidebutt"
            variant="primary"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            className="savesidebutt close-savesidebutt"
            variant="secondary"
          >
            Close
          </Button>
        </div>
      </Modal>
                
              </div>
            </div>
          </div>
        </div>
        {
          propsid == undefined
          ?
          null
          :
          <EditCategory props={propsid} />
        }
  </div>
    )
}

export default CategoryList
