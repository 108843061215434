import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import "./categoryField.css";
import PORTNUMBER from "../../../constant/api";
import Modal from 'react-modal';
import Button from "react-bootstrap/Button";
import {ToastContainer, toast, Zoom, Bounce} from "react-toastify";

const CategoryField = (props) => {

    console.log("customfieldprops", props.location.customdata);


    const PORT = PORTNUMBER.PORTNUMBER; 

    const [allCategory, setAllCategory] = useState();
    const [firstsubbtuton, setFirstsubbutton] = useState();
    const [firstsubcat, setFirstsubcat] = useState();
    const [secondsubbutton, setSecondsubbutton] = useState();
    const [secondsubcat, setSecondsubcat] = useState();
    const [thirdsubbutton, setThirdsubbutton] = useState();
    const [thirdsubcat, setThirdsubcat] = useState();

    const [catModal, setCatModal] = useState(false);
    const [firstModal, setFirstModal] = useState(false);
    const [secondModal, setSecondModal] = useState(false);
    const [thirdModal, setThirdModal] = useState(false);
    const [id, setId] = useState();

    const [firstCategoryId, setfirstCategoryId] = useState();
    const [secondCategoryId, setsecondCategoryId] = useState();
    const [thirdCategoryId, setthirdCategoryId] = useState();

    const [deleteFrom, setDeleteFrom] = useState();


  // category
    useEffect(() => {
        axios.get(`${PORT}/getCategorys`).then((res, err)=>{
          console.log("allusersdata", res);
          setAllCategory(res);
        })
    },[])

    useEffect(async () =>{
   
        if(typeof props.location.customdata !="undefined" && props.location.customdata.pathname)
        {
          let pathSplit = props.location.customdata.pathname.split("/");
          console.log("splitdata", pathSplit);

          if(pathSplit[2] == "AddSubCategory")
          {
            if(pathSplit[1])
            {
              await axios.post(`${PORT}/getCategory`, {params:{id:pathSplit[1]}}).then((res, err)=>{
                handleFirstSub(res.data.categoryName, res.data._id);
              })             
            }
            toast.success("You have successfully added category");
          }  
          else if(pathSplit[3] == "AddSubSecond")
          {
            if(pathSplit[1])
            {
              await axios.post(`${PORT}/getCategory`, {params:{id:pathSplit[1]}}).then((res, err)=>{
                handleFirstSub(res.data.categoryName, res.data._id);
              })
            } 
            if(pathSplit[2])
            {
              console.log("pathsecond",pathSplit[2]);
              await axios.post(`${PORT}/getFirstSubCategoryById`, {params:{id:pathSplit[2]}}).then((res, err)=>{
                console.log("pathsecondres", res);
                handleSecondSub(res.data[0].subcategoryName, res.data[0]._id);
              })
            }
            toast.success("You have successfully added category");
          }
          else if(pathSplit[4] == "AddSubThird")
          {
            if(pathSplit[1])
            {
              await axios.post(`${PORT}/getCategory`, {params:{id:pathSplit[1]}}).then((res, err)=>{
                handleFirstSub(res.data.categoryName, res.data._id);
              })
            } 
            if(pathSplit[2])
            {
              await axios.post(`${PORT}/getFirstSubCategoryById`, {params:{id:pathSplit[2]}}).then((res, err)=>{
                handleSecondSub(res.data[0].subcategoryName, res.data[0]._id);
              })
            }
            if(pathSplit[3])
            {
              await axios.post(`${PORT}/getSecondCategory`, {params:{id:pathSplit[3]}}).then((res, err)=>{  
                handleThirdSub(res.data.secondSubCategoryName, res.data._id);              
              })
             
            } 
            toast.success("You have successfully added category");
          }
        } 
    }, [])


    useEffect(async () =>{

      if(typeof props.location.customdata !="undefined" && props.location.customdata.pathname)
      {
        let pathSplit = props.location.customdata.pathname.split("/");
        console.log("splitdataedit", pathSplit);
        if(pathSplit[3] == "EditSubCategory")
        {
            if(pathSplit[1])
            {
              // handleFirstSub(pathSplit[1]);
              if(pathSplit[1])
              {
                await axios.post(`${PORT}/getCategory`, {params:{id:pathSplit[1]}}).then((res, err)=>{
                  handleFirstSub(res.data.categoryName, res.data._id);
                })             
              }
            }
            toast.success("You have successfully edited category");
        } 
        else if(pathSplit[4] == "EditSubSecond")
        {
          if(pathSplit[1])
          {
            // handleFirstSub(pathSplit[1]);
            await axios.post(`${PORT}/getCategory`, {params:{id:pathSplit[1]}}).then((res, err)=>{
              handleFirstSub(res.data.categoryName, res.data._id);
            })
          } 
          if(pathSplit[2])
          {
            // handleSecondSub(pathSplit[2]);
            // console.log("pathsecond",pathSplit[2]);
            await axios.post(`${PORT}/getFirstSubCategoryById`, {params:{id:pathSplit[2]}}).then((res, err)=>{
              console.log("pathsecondres", res);
              handleSecondSub(res.data[0].subcategoryName, res.data[0]._id);
            })
          }
          toast.success("You have successfully edited category");
        }
        else if(pathSplit[5] == "EditSubThird")
        {
          if(pathSplit[1])
          {
            // handleFirstSub(pathSplit[1]);
            await axios.post(`${PORT}/getCategory`, {params:{id:pathSplit[1]}}).then((res, err)=>{
              handleFirstSub(res.data.categoryName, res.data._id);
            })
          } 
          if(pathSplit[2])
          {
            // handleSecondSub(pathSplit[2]);
            await axios.post(`${PORT}/getFirstSubCategoryById`, {params:{id:pathSplit[2]}}).then((res, err)=>{
              console.log("pathsecondres", res);
              handleSecondSub(res.data[0].subcategoryName, res.data[0]._id);
            })
          }
          if(pathSplit[3])
          {
            // handleThirdSub(pathSplit[3]);
            await axios.post(`${PORT}/getSecondCategory`, {params:{id:pathSplit[3]}}).then((res, err)=>{  
              handleThirdSub(res.data.secondSubCategoryName, res.data._id);              
            })
          } 
          toast.success("You have successfully edited category");
        }
      }

    }, [])


    useEffect(()=>{
      if(typeof props.location.customdata !="undefined" && props.location.customdata.pathname)
      {
        let pathSplit = props.location.customdata.pathname.split("/");
        console.log("splitdataadd", pathSplit);
        if(pathSplit[1] == "addCategory")
        {
          toast.success("You have successfully added a category");
          return;
        }
        else if(pathSplit[1] == "editCategory")
        {
          toast.success("You have successfully edited category");
          return;
        }
      }
    },[])

    useEffect(()=>{
      if(typeof props.location.customdata !="undefined" && props.location.customdata.pathname)
      {
        let pathSplit = props.location.customdata.pathname.split("/");
        console.log("splitdataadd", pathSplit);
        if(pathSplit[1] == "AddCategoryField")
        {
          toast.success("You have successfully added a category field");
          return;
        }
      }
    },[])
    
    const handleDelete = async (id) =>{
        console.log(id);
       await axios.post(`${PORT}/deleteCategory`, {params:{id}}).then((res, err)=>{
         console.log("allusersdata", res);
         toast.success("You have successfully deleted this category")
       })
       await axios.get(`${PORT}/getCategorys`).then((res, err)=>{
         console.log("allusersdata", res);
         setAllCategory(res);
       })
     }

     const handleModalCategory = (id) =>{
      setCatModal(true);
      setId(id);
    }

    const handleCategoryModalYes = () =>{
      handleDelete(id);
      setCatModal(false);
    }

  // firstsubcategory

  const handleFirstSub = async (subname, id) =>{

    document.getElementById("firstsubid").style.display="block";
    document.getElementById("secondsubid").style.display="none";
    document.getElementById("thirdsubid").style.display="none";
    setFirstsubbutton(subname);
    setfirstCategoryId(id);
    
    await axios.post(`${PORT}/getFirstSubCategory`, {params:{id}}).then((res, err)=>{
    console.log("allfirstsubcateg", res);
    setFirstsubcat(res);
    })  
  }

  const handleDeleteFirst = async (id) =>{
    await axios.post(`${PORT}/deleteFirstSubCategory`, {params:{id}}).then((res, err)=>{
      toast.success("you have successfully deleted the category");
    })
    await axios.post(`${PORT}/getFirstSubCategory`, {params:{id:deleteFrom}}).then((res, err)=>{
      setFirstsubcat(res);
    })
  }

  const handleModalFirst = (id, categoryName) =>{
    setDeleteFrom(categoryName);
    setFirstModal(true);
    setId(id);
  }

  const handleModalClose = () =>{
    setFirstModal(false);
    setSecondModal(false);
    setThirdModal(false);
    setCatModal(false);
  }
  const handleFirstModalYes = () =>{
    handleDeleteFirst(id);
    setFirstModal(false);
  }

  // SECONDSUBCATEGORY

  const handleSecondSub = async (subname, id) =>{
   
    document.getElementById("secondsubid").style.display="block";
    document.getElementById("thirdsubid").style.display="none";
    
    console.log("subname", subname);
    console.log("id", id); 

    setsecondCategoryId(id);
    setSecondsubbutton(subname);

    await axios.post(`${PORT}/getSecondCategorys`, {params:{firstSubCategory:id}}).then((res, err)=>{
    setSecondsubcat(res);
    }) 
  }

  const handleDeleteSecond = async (id) =>{
    await axios.post(`${PORT}/deleteSecondCategory`, {params:{id}}).then((res, err)=>{
      console.log("deletesecondsubcateg", res);
      toast.success("you have successfully deleted the category");
    })
    await axios.post(`${PORT}/getSecondCategorys`, {params:{firstSubCategory:deleteFrom}}).then((res, err)=>{
      setSecondsubcat(res);
    }) 
  }

  const handleModalSecond = (id, categoryName) =>{
    setDeleteFrom(categoryName);
    setSecondModal(true);
    setId(id);
  }

  const handleSecondModalYes = () =>{
    handleDeleteSecond(id);
    setSecondModal(false);
  }


  //THIRDSUBCATEGORY

  const handleThirdSub = async (subname, id) =>{

    document.getElementById("thirdsubid").style.display="block";
    setThirdsubbutton(subname);
    setthirdCategoryId(id);

    await axios.post(`${PORT}/getThirdCategorys`, {params:{secondSubCategory:id}}).then((res, err)=>{
      console.log("allfirstsubcateg", res);
      setThirdsubcat(res);
    })
  }

  const handleDeleteThird = async (id) =>{
    await axios.post(`${PORT}/deleteThirdCategory`, {params:{id}}).then((res, err)=>{
      console.log("allfirstsubcateg", res);
      toast.success("you have successfully deleted the category");
    })
    await axios.post(`${PORT}/getThirdCategorys`, {params:{secondSubCategory:deleteFrom}}).then((res, err)=>{
      console.log("allfirstsubcateg", res);
      setThirdsubcat(res);
    })
  }

  const handleModalThird = (id, categoryName) =>{
    setDeleteFrom(categoryName);
    setThirdModal(true);
    setId(id);
  }

  const handleThirdModalYes = () =>{
    handleDeleteThird(id);
    setThirdModal(false);
  }


    return (
        <div class="work_space">
        <div class="workspace_title_hd">
          <div class="row">
                <div class="col-md-12">
                  <div class="titleof_page">
                <ul class="breadcrumb_list topsmall_bottom">
                  <li><a href="javascript:void(0)" >Admin Panel </a></li>
                  <li><i style={{padding:"5px"}} className="fa fa-angle-right"></i></li>
                  <li>Category</li>
                </ul>
                    <h2 class="main_title">Category</h2>
                  </div>
                </div>

              

            <div class="col-md-12">
              <div class="main_oflistingForm bg_light_gray onlyforcol_support">

              <div className="text-right">
                 <Link to="/addCategory" id="add-category" style={{backgroundColor:"#FE5F55", marginRight:"15px"}} className="btn btn-primary">Add Category</Link>
              </div>

                <div class="custom_fuildall topsmall_top flexcoluman">
                  <div class="customfuid_mainp">
                    <h4 class="tabcont_Sublable customfild_lab">All Categories</h4>
                    <div class="allcatedit_list">
                     <ul class="listofa_categ nav nav-tabs" role="tablist">
                   
                    {
                        allCategory && allCategory.data && allCategory.data.map((cate) =>{
                            return(
                                  <li style={{marginTop:"10px"}} class="nav-item">
                                    <span className="nav-link" data-toggle="tab" role="tab">
                                      {/* <Link to={'/' + cate.categoryName + "/SubFirst"}>{cate.categoryName}</Link> */}
                                      <a id="bg-cat" href="javascript:void(0)"  onClick={() => handleFirstSub(cate.categoryName, cate._id)}>{cate.categoryName}</a>
                                      <div class="postionedt_cate">
                                      <Link to={'/editCategory/' + cate._id}  data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link> 
                                        &nbsp;
                                      <a href="javascript:void(0)"  onClick={() => handleModalCategory(cate._id)} data-toggle="tooltip" data-placement="top" title="Close"><i class="fa fa-trash"></i></a>
                                      </div>
                                    </span>
                                  </li>
                            )
                        })
                    }
                     </ul>
                    </div>

                    <div class="tab-content">
                     <div class="tab-pane active" id="alcatshow02" role="tabpanel">
                      <div class="row">



 {/* FIRST STATE */}
        <div style={{display:"none"}} id="firstsubid" class="col-sm-6 col-md-4 col-lg-3 using_onresponsie">
        <div class="addmore_cat small_margin_top">
          <div class="addmore_flex">
            <div class="flexleft_addmore">
              <span>{firstsubbtuton}</span>
            </div>
            <div class="flexright_addmore">
              <Link to={'/' + firstCategoryId + '/' + 'AddSubCategory'}><i class="fa fa-plus"></i>Category</Link>
            </div>
          </div>
          {
            firstsubcat && firstsubcat.data && firstsubcat.data.map((sub)=>{
              return(
                <div class="subsmall_cat">
                <div class="subsml_flrex topsmall_margin_top">
                  <div class="clsubsml_left">
                    <a href="javascript:void(0)" onClick={()=> handleSecondSub(sub.subcategoryName, sub._id)}>{sub.subcategoryName}</a>
                  </div>
                  <div class="clsubsml_right">                                          
                    <Link to={firstCategoryId + '/' + sub._id + '/EditSubCategory'} class="editfix_bttnn" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>&nbsp;
                    <a href="javascript:void(0)"  data-toggle="tooltip" data-placement="top" title="Close"><i onClick={()=>handleModalFirst(sub._id, sub.categoryName)} class="fa fa-trash"></i></a>
                  </div>
                </div>
                </div>
              )
            })
          } 
        </div>
        </div>
  {/* FIRST STATE         */}


  {/* SECOND STATE */}
  <div style={{display:"none"}} id="secondsubid" class="col-sm-6 col-md-4 col-lg-3 using_onresponsie subbottom_extra margin_top_cate01">
                            <div class="addmore_cat small_margin_top">
                              <div class="addmore_flex">
                                <div class="flexleft_addmore">
                                  <span>{secondsubbutton}</span>
                                </div>
                                <div class="flexright_addmore">
                                  <Link to={'/' + firstCategoryId + '/' + secondCategoryId + '/AddSubSecond'}><i class="fa fa-plus"></i>Category</Link>
                                </div>
                              </div>
                              {
                                secondsubcat && secondsubcat.data && secondsubcat.data.map((sub)=>{
                                  return(
                                    <div class="subsmall_cat">
                                    <div class="subsml_flrex topsmall_margin_top">
                                      <div class="clsubsml_left">
                                        {/* <Link to={'/' + category + '/' + firstSubCategory + '/' + secondsub.secondSubCategoryName + '/SubThird'}>{secondsub.secondSubCategoryName}</Link> */}
                                        <a href="javascript:void(0)"  onClick={() => handleThirdSub(sub.secondSubCategoryName, sub._id)}>{sub.secondSubCategoryName}</a>
                                      </div>
                                      <div class="clsubsml_right">                                          
                                        <Link to={'/' + firstCategoryId + '/' + secondCategoryId + '/' + sub._id + '/EditSubSecond'} class="editfix_bttnn" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>&nbsp;
                                        <a href="javascript:void(0)"  onClick={() => handleModalSecond(sub._id, sub.firstSubCategoryName)} data-toggle="tooltip" data-placement="top" title="Close"><i class="fa fa-trash"></i></a>
                                      </div>
                                    </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
  </div>
  {/* SECOND STATE */}


  {/* THIRD STATE */}
  <div style={{display:"none"}} id="thirdsubid" class="col-sm-6 col-md-4 col-lg-3 using_onresponsie subbottom_extra margin_top_cate02">
        <div class="addmore_cat small_margin_top">
          <div class="addmore_flex">
            <div class="flexleft_addmore">
              <span>{thirdsubbutton}</span>
            </div>
            <div class="flexright_addmore">
            <Link to={'/' + firstCategoryId + '/' + secondCategoryId + '/' + thirdCategoryId + '/AddSubThird'}><i class="fa fa-plus"></i>Category</Link>
            </div>
          </div>
          {
            thirdsubcat && thirdsubcat.data && thirdsubcat.data.map((sub)=>{
              return(
                <div class="subsmall_cat">
                <div class="subsml_flrex topsmall_margin_top">
                  <div class="clsubsml_left">
                    {sub.thirdSubCategoryName}
                  </div>
                  <div class="clsubsml_right">                                          
                    <Link to={'/' + firstCategoryId + '/' + secondCategoryId + '/' + thirdCategoryId + '/' + sub._id + '/EditSubThird'} class="editfix_bttnn" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil"></i></Link>&nbsp;
                    <a href="javascript:void(0)"  onClick={() => handleModalThird(sub._id, sub.secondSubCategoryName)} data-toggle="tooltip" data-placement="top" title="Close"><i class="fa fa-trash"></i></a>
                  </div>
                </div>
              </div>
              )
            })
          }
        </div>
  </div>
  {/* THIRD STATE */}


                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  <ToastContainer />

  <Modal 
  isOpen={firstModal}
  // onRequestClose={}
  >
  <h6>
  Do you want to delete this?
  </h6>
  <Button onClick={handleFirstModalYes} className="button-conf" variant="primary">Yes</Button>
  <Button onClick={handleModalClose} className="button-conf" variant="secondary">Close</Button>
  </Modal>

  <Modal 
  isOpen={secondModal}
  // onRequestClose={}
  >
  <h6>
  Do you want to delete this?
  </h6>
  <Button onClick={handleSecondModalYes} className="button-conf" variant="primary">Yes</Button>
  <Button onClick={handleModalClose} className="button-conf" variant="secondary">Close</Button>
  </Modal>

  <Modal 
  isOpen={thirdModal}
  // onRequestClose={}
  >
  <h6>
  Do you want to delete this?
  </h6>
  <Button onClick={handleThirdModalYes} className="button-conf" variant="primary">Yes</Button>
  <Button onClick={handleModalClose} className="button-conf" variant="secondary">Close</Button>
  </Modal>

  <Modal 
  isOpen={catModal}
  // onRequestClose={}
  >
  <h6>
  Do you want to delete this?
  </h6>
  <Button onClick={handleCategoryModalYes} className="button-conf" variant="primary">Yes</Button>
  <Button onClick={handleModalClose} className="button-conf" variant="secondary">Close</Button>
  </Modal>


  {/* firstaddcategorymodal */}

  <Modal 
  isOpen={catModal}
  >
 
  <Button onClick={handleCategoryModalYes} className="button-conf" variant="primary">Yes</Button>
  <Button onClick={handleModalClose} className="button-conf" variant="secondary">Close</Button>
  </Modal>
  </div>
    )
}

export default CategoryField
